/*
---
name: pd_b-plan-01
category: block
tag:
  - text
  - checkBox
  - btn
  - img
---
```html
<div class="pd_b-plan-01">
  <form action="">
    <div class="pd_b-plan-01_inner">
      <div class="pd_b-freeArea">
        <div class="pd_b-plan-01_head">
          <h1 class="pd_b-plan-01_heading">契約プランのご案内</h1>
        </div>
        <div class="pd_b-plan-01_body-01">
          <div class="pd_c-group-01">
            <h1 class="pd_c-group-01_heading">ジアイーノ　1台コース</h1>
            <p class="pd_c-group-01_text-01">試したい”に応える、シンプルなベーシックコースです。</p>
            <figure class="pd_c-figure-01"><img class="pd_c-figure-01_img" src="http://placehold.jp/3d4070/ffffff/800x500.png" alt=""></figure>
          </div>
          <div class="pd_c-group-01">
            <h1 class="pd_c-group-01_heading">ジアイーノ　1台コース</h1>
            <p class="pd_c-group-01_text-01">試したい”に応える、シンプルなベーシックコースです。</p>
            <figure class="pd_c-figure-01"><img class="pd_c-figure-01_img" src="http://placehold.jp/3d4070/ffffff/800x500.png" alt=""></figure>
          </div>
        </div>
      </div>
      <div class="pd_b-plan-01_body-02">
        <div class="pd_b-plan-01_row-01">
          <div class="pd_b-plan-01_leftCol">
            <div class="pd_b-plan-02">
              <div class="pd_b-plan-02_head">
                <h1 class="pd_b-plan-02_heading">プランを選択</h1>
              </div>
              <div class="pd_b-plan-02_body">
                <div class="pd_b-plan-02_inner-01">
                  <div class="pd_b-plan-02_headSub"><span class="pd_b-plan-02_title">センサー</span><span class="pd_e-required-01">必須  </span></div>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor1" name="sensor1"><span class="pd_c-planSelect_btn_label" for="sensor1"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">センサー3年</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円/月(税込)</span><br><span class="pd_e-planTag-01">在庫あり</span>
                                                      </div>
                                                    </label>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor1" name="sensor1"><span class="pd_c-planSelect_btn_label" for="sensor1"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-01">在庫あり</span>
                                                      </div>
                                                    </label>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor1" name="sensor1" disabled><span class="pd_c-planSelect_btn_label" for="sensor1"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-02">在庫なし</span>
                                                      </div>
                                                    </label>
                </div>
                <div class="pd_b-plan-02_inner">
                  <div class="pd_b-plan-02_headSub"><span class="pd_b-plan-02_title">照明</span><span class="pd_e-required-01">必須  </span></div>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor2" name="sensor2" disabled><span class="pd_c-planSelect_btn_label" for="sensor2"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-02">販売停止中</span>
                                                      </div>
                                                    </label>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor2" name="sensor2" disabled><span class="pd_c-planSelect_btn_label" for="sensor2"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-02">在庫なし</span>
                                                      </div>
                                                    </label>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor2" name="sensor2" disabled><span class="pd_c-planSelect_btn_label" for="sensor2"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-02">販売終了</span>
                                                      </div>
                                                    </label>
                </div>
                <div class="pd_b-plan-02_inner">
                  <div class="pd_b-plan-02_headSub"><span class="pd_b-plan-02_title">設置</span><span class="pd_e-required-01">必須  </span></div>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor3" name="sensor3"><span class="pd_c-planSelect_btn_label" for="sensor3"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">標準設置</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span>
                                                      </div>
                                                    </label>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor3" name="sensor3"><span class="pd_c-planSelect_btn_label" for="sensor3"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span>
                                                      </div>
                                                    </label>
                </div>
                <div class="pd_b-plan-02_inner">
                  <div class="pd_b-plan-02_headSub"><span class="pd_b-plan-02_title">定期お掃除</span></div>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor4" name="sensor4"><span class="pd_c-planSelect_btn_label" for="sensor4"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">選択なし</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"></div>
                                                    </label>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor4" name="sensor4"><span class="pd_c-planSelect_btn_label" for="sensor4"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span>
                                                      </div>
                                                    </label>
                </div>
                <div class="pd_b-plan-02_inner">
                  <div class="pd_b-plan-02_headSub"><span class="pd_b-plan-02_title">延長保証</span></div>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor5" name="sensor5"><span class="pd_c-planSelect_btn_label" for="sensor5"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">選択なし</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"></div>
                                                    </label>
                                                    <label class="pd_c-planSelect">
                                                      <div class="pd_c-planSelect_btn">
                                                        <input class="pd_c-planSelect_btn_input" type="radio" id="sensor5" name="sensor5"><span class="pd_c-planSelect_btn_label" for="sensor5"></span>
                                                      </div>
                                                      <div class="pd_c-planSelect_left"><span class="pd_e-planName">説明が入ります</span>
                                                      </div>
                                                      <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span>
                                                      </div>
                                                    </label>
                </div>
              </div>
              <div class="pd_m-plan-01_row">
                <div class="pd_m-plan-01_leftCol">
                  <div class="pd_m-itemImage-01">
                    <div class="pd_m-itemImage-01_img"><img src="http://placehold.jp/ffffff/000000/50x500.png?text=サムネイル" alt=""></div>
                    <div class="pd_m-itemImage-01_contents">
                      <p class="pd_m-itemImage-01_name">温感かっさ（2019年発売）ピンク</p><a class="pd_c-linkText-01 pd_e-hover-01 pd_js-popup-modal" href="#modal1">商品詳細はこちら</a>
                    </div>
                  </div>
                </div>
                <div class="pd_m-plan-01_rightCol">
                  <div class="pd_m-itemImage-01">
                    <div class="pd_m-itemImage-01_img"><img src="http://placehold.jp/ffffff/000000/50x500.png?text=サムネイル" alt=""></div>
                    <div class="pd_m-itemImage-01_contents">
                      <p class="pd_m-itemImage-01_name">温感かっさ（2019年発売）ピンク</p><a class="pd_c-linkText-01 pd_e-hover-01 pd_js-popup-modal" href="#modal1">商品詳細はこちら</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pd_m-plan-01_row">
                <div class="pd_m-plan-01_leftCol">
                  <div class="pd_m-itemImage-01">
                    <div class="pd_m-itemImage-01_img"><img src="http://placehold.jp/ffffff/000000/50x500.png?text=サムネイル" alt=""></div>
                    <div class="pd_m-itemImage-01_contents">
                      <p class="pd_m-itemImage-01_name">温感かっさ（2019年発売）ピンク</p><a class="pd_c-linkText-01 pd_e-hover-01 pd_js-popup-modal" href="#modal1">商品詳細はこちら</a>
                    </div>
                  </div>
                </div>
                <div class="pd_m-plan-01_rightCol"></div>
              </div>
            </div>
          </div>
          <div class="pd_b-plan-01_rightCol">
            <div class="pd_m-breakdown-02">
              <div class="pd_m-breakdown-02_head">
                <h1 class="pd_m-breakdown-02_heading">内訳</h1>
              </div>
              <div class="pd_m-breakdown-02_body-01">
                <div class="pd_m-breakdown-02_inner-01">
                  <p class="pd_m-breakdown-02_text-01">商品代金(税込)</p><span class="pd_e-money-01">3,000円</span>
                </div>
                <div class="pd_m-breakdown-02_inner-01">
                  <p class="pd_m-breakdown-02_text-01">初期費用(税込)</p><span class="pd_e-money-01">3,000円</span>
                </div>
                <div class="pd_m-breakdown-02_inner-01">
                  <p class="pd_m-breakdown-02_text-01">月額請求(税込)</p><span class="pd_e-money-01">1,500円</span>
                </div>
                <div class="pd_m-breakdown-02_inner-01">
                  <p class="pd_m-breakdown-02_text-01">年額請求(税込)</p><span class="pd_e-money-01">2,000円</span>
                </div>
                <div class="pd_m-breakdown-02_border"></div>
                <div class="pd_m-breakdown-02_inner-01">
                  <p class="pd_m-breakdown-02_text-02">支払総額(税込)</p><span class="pd_e-money-02">56,000円</span>
                </div>
                <p class="pd_m-breakdown-02_note"><span>※</span>xxヶ月</p>
              </div>
              <div class="pd_m-breakdown-02_body-02">
                <button class="pd_c-btn-01 pd_e-hover-01" onclick=""><span>契約へ進む</span></button>
                <button class="pd_c-btn-04 pd_e-hover-01" onclick="" disabled><span>契約へ進む</span></button>
                <p class="pd_m-breakdown-02_link"></p><a class="pd_c-linkText-01 pd_e-hover-01" href="/">マイプランに保存する </a>
                <ul class="pd_c-notes-02">
                  <li class="pd_c-notes-02_listItem pd_c-js_scrollTrigger"><span>※</span>解約時は、24ヶ月間どのタイミングにおいても解約手数料としてご利用料金の2ヶ月分相当の6,600円(税込)がかかります。</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pd_b-freeArea">
        <div class="pd_b-plan-01_foot"> 
          <div class="pd_b-plan-01_links--foot"> 
            <div class="pd_b-plan-01_linkItem--foot"> <a class="pd_c-btn-02 pd_e-hover-01 pd_e-hover-01--bgGray" href="/">スチーマー ナノケア定額利用サービスへ  </a></div>
            <div class="pd_b-plan-01_linkItem--foot"> <a class="pd_c-btn-02 pd_e-hover-01 pd_e-hover-01--bgGray" href="/">定額利用サービスへ  </a></div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_b-plan-01 {
  @media screen and (min-width:MIN_POINT) {
    margin-top 60px
  }
  @media screen and (max-width:MAX_POINT) {
    margin-top 40px
  }
  img {
    vertical-align bottom
  }
  &_inner {
    @media screen and (min-width:MIN_POINT) {
      max-width 980px
      margin-right auto
      margin-left auto
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right 15px
      margin-left 15px
    }
  }
  &_heading {
    text-align center
    font-weight bold
    @media screen and (min-width:MIN_POINT) {
      font-size 30px
      line-height (45/30)
    }
    @media screen and (max-width:MAX_POINT) {
      font-size 22px
    }
  }


  &_body-02 {
    background-color rgba(0,0,0,.05)
    @media screen and (min-width:MIN_POINT) {
      margin 60px -112px 0
      padding 60px 112px
    }
    @media screen and (max-width:MAX_POINT) {
      margin 24px -15px 0
      padding 40px 15px
    }
  }

  &_row-01 {
    @media screen and (min-width:MIN_POINT) {
      display flex
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }

  &_leftCol,
  &_rightCol {
  }

  &_leftCol {
    @media screen and (min-width:MIN_POINT) {
      width 662px
      padding-right 20px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }

  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
      width 312px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 32px
    }
  }
  &_foot {
    margin-top 40px
  }
  &_links--foot {
    @media screen and (min-width:MIN_POINT) {
      display flex
      justify-content center
      margin-right -10px
      margin-left -10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_linkItem--foot {
    padding-bottom 6px
    @media screen and (min-width:MIN_POINT) {
      width 345px
      padding-right 10px
      padding-left 10px
    }
    &+& {
      @media screen and (max-width:MAX_POINT) {
        margin-top 14px
      }
    }
  }
}
