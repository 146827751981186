/*
---
name: pd_m-plan-01
category: module
tag:
  - text
  - checkBox
  - img
  - icon
---
```html
<div class="pd_m-plan-01">
  <div class="pd_m-plan-01_head">
    <h1 class="pd_m-plan-01_heading">プランを選択</h1>
  </div>
  <div class="pd_m-plan-01_body">
    <ul class="pd_m-plan-01_checkList">
      <li class="pd_m-plan-01_checkItem">
        <div class="pd_c-inputForm-01">
          <label class="pd_c-inputForm-01_label-01 pd_e-hover-01 pd_e-hover-01--bgGray">
            <input class="pd_c-inputForm-01_input-01 pd_js-c-inputForm-01_input-01" type="checkbox" name=""><span class="pd_c-inputForm-01_indicator-01 pd_c-inputForm-01_indicator-01--checkbox"></span><span class="pd_c-inputForm-01_text-01">定額利用サービス　スチーマー ナノケア</span>
          </label><span class="pd_e-required-01">必須  </span>
        </div>
      </li>
      <li class="pd_m-plan-01_checkItem">
        <div class="pd_c-inputForm-01">
          <label class="pd_c-inputForm-01_label-01 pd_e-hover-01 pd_e-hover-01--bgGray">
            <input class="pd_c-inputForm-01_input-01 pd_js-c-inputForm-01_input-01" type="checkbox" name=""><span class="pd_c-inputForm-01_indicator-01 pd_c-inputForm-01_indicator-01--checkbox"></span><span class="pd_c-inputForm-01_text-01">定額利用サービス　スチーマー ナノケア</span>
          </label><span class="pd_e-required-01">必須  </span>
        </div>
      </li>
    </ul>
    <div class="pd_m-plan-01_row">
      <div class="pd_m-plan-01_leftCol">
        <div class="pd_m-itemImage-01">
          <div class="pd_m-itemImage-01_img"><img src="http://placehold.jp/ffffff/000000/500x500.png?text=サムネイル" alt=""></div>
          <div class="pd_m-itemImage-01_contents">
            <p class="pd_m-itemImage-01_name">温感かっさ（2019年発売）ピンク</p><a class="pd_c-linkText-01 pd_e-hover-01 pd_js-popup-modal" href="#modal1">商品詳細はこちら</a>
          </div>
        </div>
      </div>
      <div class="pd_m-plan-01_rightCol">
        <div class="pd_m-itemImage-01">
          <div class="pd_m-itemImage-01_img"><img src="http://placehold.jp/ffffff/000000/500x500.png?text=サムネイル" alt=""></div>
          <div class="pd_m-itemImage-01_contents">
            <p class="pd_m-itemImage-01_name">温感かっさ（2019年発売）ピンク</p><a class="pd_c-linkText-01 pd_e-hover-01 pd_js-popup-modal" href="#modal1">商品詳細はこちら</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-plan-01 {
  &_heading {
    font-size 22px
    line-height (33/22)
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_body {
    margin-top 12px
  }
  &_checkItem {
    &+& {
      margin-top 15px
    }
  }
  &_row {
    margin-top 16px
    @media screen and (min-width:MIN_POINT) {
      display flex
      margin-right -10px
      margin-left -10px
      margin-top 32px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_leftCol,
  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
      width 50%
      padding-right 10px
      padding-left 10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_rightCol {
    @media screen and (max-width:MAX_POINT) {
      margin-top 16px
    }
  }
}
