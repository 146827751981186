/*
---
name: pd_b-mv
category: block
tag:
  - text
  - img
---
```html
<section class="pd_b-mv">
    <div class="pd_b-mv_Area">
        <div class="pd_b-mv_ttlWrap">
            <h2 class="pd_b-mv_ttl"></h2>
            <p class="pd_b-mv_subTtl">Panasonic Store Plusが新しく生まれ変わりました<br />あなたのライフスタイルやくらしのシーンに合わせた<br />情報をご提案します</p><a class="pd_b-mv_subTtlLink" href="">Panasonic<span></span>Store+とは</a>
        </div>
    </div>
    <ul class="pd_b-mv-linkList">
        <li class="pd_b-mv-linkListItem"><a href=""><img src="/store/images/block/mv_link_thumnail01.jpg" alt="ビジネスモバイルPCの最前線 Let's note" /></a></li>
        <li class="pd_b-mv-linkListItem"><a href=""><img src="/store/images/block/mv_link_thumnail02.jpg" alt="初月無料安心お試し 定額利用サービス" /></a></li>
        <li class="pd_b-mv-linkListItem"><a href=""><img src="/store/images/block/mv_link_thumnail03.jpg" alt="おすすめの消耗品を探す 消耗品。付属品" /></a></li>
        <li class="pd_b-mv-linkListItem"><a href=""><img src="/store/images/block/mv_link_thumnail04.jpg" alt="ストア厳選の商品・特集をお届け プレミアム・限定商品" /></a></li>
    </ul>
</section>
```
*/
// mv
.pd_b-mv
    &_Area
        background-image: url('/store/images/block/mv.jpg')
        background-size: cover
        height: 0
        margin: 0 auto
        padding-top: 510px
        position: relative
        width: 1200px
    &_ttlWrap
        position: absolute
        right: 91px
        top: 165px
        width: 448px
    &_ttl
        background-image: url('/store/images/block/mv_ttl.svg')
        background-size: cover
        height: 32px
        width: 100%
    &_subTtl
        color: #fff
        font-size 16px
        line-height 1.875
        text-align: right
        margin: 13px 22px 0 0
    &_subTtlLink
        color: #fff
        display: block
        font-size: 17px
        padding-right: 40px
        position: relative
        text-align: right
        margin: 25px 0px 0 0
        span
            display: inline-block
            height: 1rem
            width: 1rem
        &::after
            border: 2px solid #23354F
            border-top: transparent
            border-left: transparent
            content: ""
            display: block
            height: 6px
            position: absolute
            right: 20px
            top: 50%
            transform rotate(-45deg) translateY(-50%)
            width: 6px
    &-linkList
        display: flex
        justify-content: space-between
        margin: 40px auto
        padding: 0 5px 0 0
        width: 976px
    &-linkListItem
        a
            display: block
            height: 100%
            width: 100%
            img
                display: block
@media screen and (max-width: 640px)
    .pd_b-mv
        &_Area
            background-image: url('/store/images/block/mv_sp.jpg')
            background-size: 100vw 100vw
            background-position: top center
            padding-top: 100vw
            height: auto
            width: 100vw
        &_ttlWrap
            background-color: #364563
            padding 25px 15px 24px 20px
            position: relative
            top: auto
            right: auto
            width: 100%
        &_ttl
            background-size: cover
            height: 6.4vw
            width: auto
        &_subTtl
            display: none
        &_subTtlLink
            font-size: 15px
            margin-top: 14px
            padding-right: 14px
            span
                display: none
            &::after
                height: 8px
                right: 0px
                width: 8px
        &-linkList
            flex-wrap: wrap
            margin: 14px auto 30px
            padding 0 15px
            width: 100%
        &-linkListItem
            margin-bottom: 1.34vw
            width 49.2%
            img
                width: 100%

