/*
---
name: pd_c-filterButton
category: component
tag:
  - btn
---
```html
<button class="pd_c-filterButton pd_c-filterButton-02" id="pd_c-filteringCategory" type="button">
    <p class="pd_c-filterButton_text">商品カテゴリを絞り込む</p>
</button>
```
*/
.pd_c-filterButton
    align-items: center
    background-color: #fff
    border: 1px solid #364563
    border-radius: 8px
    box-shadow: 0px 2px 2px rgba(0,0,0,0.2)
    color: #364563
    cursor: pointer
    display: none
    font-size: 15px
    justify-content: center
    margin-top: 20px
    height: 52px
    width: 100%
    &_icon
        display: block
        height: 20px
        width: 20px
    &_text
        color: #364563
        font-size: 15px
    &-03
        background-color: #364563
        p
            color: #fff
@media screen and (max-width 640px)
    .pd_c-filterButton
        &-01
            img
                margin: -2px 10px 0 0
        &-02
            display: flex
            margin-top: 0
        &-03
            display: flex
        &-04
            display: flex
            -webkit-box-sizing: content-box;
            -webkit-appearance: button;
            appearance: button;
            box-sizing: border-box;
            &::-webkit-search-decoration
                display: none;
            &::focus
                outline-offset: -2px;