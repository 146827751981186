/*
---
name: pd_c-notes-02
category: component
tag:
  - text
---
```html
  <ul class="pd_c-notes-02">
      <li class="pd_c-notes-02_listItem"><span>※</span>注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト</li>
      <li class="pd_c-notes-02_listItem"><span>※</span>注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト</li>
  </ul>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-notes-02 {
  color rgba(0,0,0,.6)
  margin-top 16px
  font-size: 13px
  line-height: (22/15)
  &_listItem {
    padding-left 1.2em
    > span {
      width 1.2em
      text-align center
      display inline-block
      margin-left -1.2em
    }
  }
}
