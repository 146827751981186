/*
---
name: pd_c-priceChoice
category: component
tag:
  - text
---
```html
    <div class="pd_c-priceChoice pd_c-priceChoice-01">
        <div class="pd_c-priceChoice_inputWrap">
            <input class="pd_c-priceChoice_input" type="text" />
            <p class="pd_c-priceChoice_inputText">~</p>
            <input class="pd_c-priceChoice_input" type="text" />
            <p class="pd_c-priceChoice_inputText">円</p>
        </div>
    </div>
```
*/
.pd_c-priceChoice
    &_inputWrap
        align-items: center
        display: flex
        justify-content: space-between
        width: 100%
    &_input
        align-items: center
        border: 1px solid #707070
        border-radius: 8px
        display: flex
        font-size: 15px
        height:30px
        padding: 0 15px
        width: 80px
    &_inputText
        font-size: 13px
        &:last-child
            padding-left: 1px
@media screen and (max-width 640px)
    .pd_c-priceChoice
        &_input
            height: 52px
            width: 110px
