/*
---
name: pd_b-searchResultWrap
category: block
tag:
  - text
  - img
  - tag
---
```html
<div class="pd_b-searchResultWrap pd_b-searchResultWrap-article">
    <div class="pd_b-searchResultList">
        <ul class="pd_b-searchResultList_list">
            <li class="pd_b-searchResultList_listItem"><a class="pd_m-searchResultContent" href="#">
                    <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="" /></div>
                    <div class="pd_m-searchResultContent_detaiWrap">
                        <div class="pd_c-searchListItem">
                            <p class="pd_c-searchListItem_kind">記事</p>
                            <object class="pd_c-searchListItem_favorite"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></a></object>
                        </div>
                        <div class="pd_m-searchResultContent_ttlArea">
                            <p class="pd_m-searchResultContent_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                            <p class="pd_m-searchResultContent_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                        </div>
                        <ul class="pd_c-tagList">
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test.html">#快適な空間づくり</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test2.html">#家事・暮らし</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test3.html">#タグ</a></object>
                            </li>
                        </ul>
                    </div>
                </a></li>
            <li class="pd_b-searchResultList_listItem"><a class="pd_m-searchResultContent" href="#">
                    <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="" /></div>
                    <div class="pd_m-searchResultContent_detaiWrap">
                        <div class="pd_c-searchListItem">
                            <p class="pd_c-searchListItem_kind">記事</p>
                            <object class="pd_c-searchListItem_favorite"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></a></object>
                        </div>
                        <div class="pd_m-searchResultContent_ttlArea">
                            <p class="pd_m-searchResultContent_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                            <p class="pd_m-searchResultContent_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                        </div>
                        <ul class="pd_c-tagList">
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test.html">#快適な空間づくり</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test2.html">#家事・暮らし</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test3.html">#タグ</a></object>
                            </li>
                        </ul>
                    </div>
                </a></li>
            <li class="pd_b-searchResultList_listItem"><a class="pd_m-searchResultContent" href="#">
                    <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="" /></div>
                    <div class="pd_m-searchResultContent_detaiWrap">
                        <div class="pd_c-searchListItem">
                            <p class="pd_c-searchListItem_kind">記事</p>
                            <object class="pd_c-searchListItem_favorite"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></a></object>
                        </div>
                        <div class="pd_m-searchResultContent_ttlArea">
                            <p class="pd_m-searchResultContent_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                            <p class="pd_m-searchResultContent_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                        </div>
                        <ul class="pd_c-tagList">
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test.html">#快適な空間づくり</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test2.html">#家事・暮らし</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test3.html">#タグ</a></object>
                            </li>
                        </ul>
                    </div>
                </a></li>
            <li class="pd_b-searchResultList_listItem"><a class="pd_m-searchResultContent" href="#">
                    <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="" /></div>
                    <div class="pd_m-searchResultContent_detaiWrap">
                        <div class="pd_c-searchListItem">
                            <p class="pd_c-searchListItem_kind">記事</p>
                            <object class="pd_c-searchListItem_favorite"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></a></object>
                        </div>
                        <div class="pd_m-searchResultContent_ttlArea">
                            <p class="pd_m-searchResultContent_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                            <p class="pd_m-searchResultContent_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                        </div>
                        <ul class="pd_c-tagList">
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test.html">#快適な空間づくり</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test2.html">#家事・暮らし</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test3.html">#タグ</a></object>
                            </li>
                        </ul>
                    </div>
                </a></li>
            <li class="pd_b-searchResultList_listItem"><a class="pd_m-searchResultContent" href="#">
                    <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="" /></div>
                    <div class="pd_m-searchResultContent_detaiWrap">
                        <div class="pd_c-searchListItem">
                            <p class="pd_c-searchListItem_kind">記事</p>
                            <object class="pd_c-searchListItem_favorite"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></a></object>
                        </div>
                        <div class="pd_m-searchResultContent_ttlArea">
                            <p class="pd_m-searchResultContent_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                            <p class="pd_m-searchResultContent_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                        </div>
                        <ul class="pd_c-tagList">
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test.html">#快適な空間づくり</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test2.html">#家事・暮らし</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test3.html">#タグ</a></object>
                            </li>
                        </ul>
                    </div>
                </a></li>
            <li class="pd_b-searchResultList_listItem"><a class="pd_m-searchResultContent" href="#">
                    <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="" /></div>
                    <div class="pd_m-searchResultContent_detaiWrap">
                        <div class="pd_c-searchListItem">
                            <p class="pd_c-searchListItem_kind">記事</p>
                            <object class="pd_c-searchListItem_favorite"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></a></object>
                        </div>
                        <div class="pd_m-searchResultContent_ttlArea">
                            <p class="pd_m-searchResultContent_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                            <p class="pd_m-searchResultContent_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                        </div>
                        <ul class="pd_c-tagList">
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test.html">#快適な空間づくり</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test2.html">#家事・暮らし</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test3.html">#タグ</a></object>
                            </li>
                        </ul>
                    </div>
                </a></li>
            <li class="pd_b-searchResultList_listItem"><a class="pd_m-searchResultContent" href="#">
                    <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="" /></div>
                    <div class="pd_m-searchResultContent_detaiWrap">
                        <div class="pd_c-searchListItem">
                            <p class="pd_c-searchListItem_kind">記事</p>
                            <object class="pd_c-searchListItem_favorite"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></a></object>
                        </div>
                        <div class="pd_m-searchResultContent_ttlArea">
                            <p class="pd_m-searchResultContent_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                            <p class="pd_m-searchResultContent_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                        </div>
                        <ul class="pd_c-tagList">
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test.html">#快適な空間づくり</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test2.html">#家事・暮らし</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test3.html">#タグ</a></object>
                            </li>
                        </ul>
                    </div>
                </a></li>
            <li class="pd_b-searchResultList_listItem"><a class="pd_m-searchResultContent" href="#">
                    <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="" /></div>
                    <div class="pd_m-searchResultContent_detaiWrap">
                        <div class="pd_c-searchListItem">
                            <p class="pd_c-searchListItem_kind">記事</p>
                            <object class="pd_c-searchListItem_favorite"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></a></object>
                        </div>
                        <div class="pd_m-searchResultContent_ttlArea">
                            <p class="pd_m-searchResultContent_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                            <p class="pd_m-searchResultContent_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                        </div>
                        <ul class="pd_c-tagList">
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test.html">#快適な空間づくり</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test2.html">#家事・暮らし</a></object>
                            </li>
                            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                <object><a href="/test3.html">#タグ</a></object>
                            </li>
                        </ul>
                    </div>
                </a></li>
        </ul>
    </div>
    <div class="pd_m-pagenation">
        <div class="pd_m-pagenation_inner">
            <div class="pd_m-pagenation_btn pd_m-pagenation_prevBtn pd_e-hover-01"><a href="#"></a></div>
            <ol class="pd_m-pagenation_list">
                <li class="pd_m-pagenation_listItem pd_current pd_e-hover-01"><a href="#">1</a></li>
                <li class="pd_m-pagenation_listItem pd_e-hover-01 pd_e-hover-01--bgGray"><a href="#">2</a></li>
                <li class="pd_m-pagenation_listItem pd_e-hover-01 pd_e-hover-01--bgGray"><a href="#">3</a></li>
            </ol>
            <div class="pd_m-pagenation_btn pd_m-pagenation_nextBtn pd_e-hover-01"><a href="#"></a></div>
        </div>
    </div>
</div>
```
*/
.pd_b-searchResultWrap
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    margin: 0 auto
    width: 976px
    &_right
        width: 747px
    .pd_b-searchResultPanel_list
        width: 747px
        margin-left: 0
    .pd_m-pagenation
        margin: 40px 0 0px 15px
    .pd_m-searchResultContent_img
        img
            width 100%
    // 記事用のリストコンテンツの場合
    &-article,
    &-event,
    &-support
        .pd_m-searchResultContent_ttlArea
            width: 100%
    // &-product-02
    &-product-03
        .pd_b-searchResultPanel_list
            width: 996px
    &-product-04
        .pd_b-searchResultPanel_list
            width: 100%
@media screen and (max-width 640px)
    .pd_b-searchResultWrap
        width: 100%
        &_right
            width: 100%
        .pd_b-searchResultPanel_list
            width: 100%
        .pd_m-pagenation
            margin: 40px 0 0px