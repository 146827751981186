/*
---
name: pd_b-userInfo-01
category: block
tag:
  - table
  - radio
---
```html
<form class="pd_c-enqueteWrap" action=""></form>

<table class="pd_c-enqueteTable">
    <thead>
        <tr>
            <th>&nbsp;</th>
            <th>満足</th>
            <th>やや満足</th>
            <th>どちらとも言えない</th>
            <th>やや不満</th>
            <th>不満</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th>本体の大きさ（サイズ）</th>
            <td>
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="radio-enquete01">
                    <span class="pd_c-radioBtn-01_indicator"></span>
                </label>
            </td>
            <td>
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="radio-enquete01">
                    <span class="pd_c-radioBtn-01_indicator"></span>
                </label>
            </td>
            <td>
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="radio-enquete01">
                    <span class="pd_c-radioBtn-01_indicator"></span>
                </label>
            </td>
            <td>
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="radio-enquete01">
                    <span class="pd_c-radioBtn-01_indicator"></span>
                </label>
            </td>
            <td>
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="radio-enquete01">
                    <span class="pd_c-radioBtn-01_indicator"></span>
                </label>
            </td>
        </tr>
        <tr>
            <th>本体の重さ（重量）</th>
            <td>
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="radio-enquete02">
                    <span class="pd_c-radioBtn-01_indicator"></span>
                </label>
            </td>
            <td>
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="radio-enquete02">
                    <span class="pd_c-radioBtn-01_indicator"></span>
                </label>
            </td>
            <td>
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="radio-enquete02">
                    <span class="pd_c-radioBtn-01_indicator"></span>
                </label>
            </td>
            <td>
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="radio-enquete02">
                    <span class="pd_c-radioBtn-01_indicator"></span>
                </label>
            </td>
            <td>
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="radio-enquete02">
                    <span class="pd_c-radioBtn-01_indicator"></span>
                </label>
            </td>
        </tr>
    </tbody>
</table>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-enqueteTable{
    width: 100%;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    margin: 20px 0;
    border-collapse: separate;
    table-layout: auto !important;
}
.pd_c-enqueteTable thead tr th:first-child{
    width: calc(100% - 250px);
}
.pd_c-enqueteTable thead tr th{
    vertical-align: top;
    width: 50px;
    max-width: 50px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    min-height:150px;
    text-align:center;
}
.pd_c-enqueteTable thead tr th span{
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    word-break: break-all;
}
.pd_c-enqueteTable tbody th{

    width: calc(100% - 250px);

    padding: 2px 10px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    word-break: break-all;
    text-align:left;
    vertical-align: middle;
    line-height:1.3;
}
.pd_c-enqueteTable tbody td{
    position: relative;
    width: 50px;
    max-width: 50px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background-clip: padding-box;
    line-height:0;
}
.pd_c-enqueteTable_td-inner{
    @media screen and (min-width: MIN_POINT){
        position:relative;
        width:100%;
        height:100%;
        min-height:50px;
    }
}
.pd_c-enqueteTable tbody td .pd_c-radioBtn-01{
    display: block;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}
.pd_c-enqueteTable tbody td .pd_c-radioBtn-01:hover{
    background: #ecf4fc;
    transition: .1s;
}

.pd_c-enqueteTable tbody td .pd_c-radioBtn-01 .pd_c-radioBtn-01_indicator {
    position: absolute;
    top:calc(50% - 12px);
    left:calc(50% - 12px);
}

@media screen and (max-width:MAX_POINT) {
    .pd_c-enqueteWrap{
        padding: 0 15px;
        margin: 0 auto;
    }
    .pd_c-enqueteTable thead tr th{
        width: 40px;
        max-width: 40px;
        padding: 5px;
        font-size: 1.2rem;
    }

    .pd_c-enqueteTable tbody th{
        padding: 10px 5px;
        font-size: 1.2rem;
        min-width:140px;
    }
    .pd_c-enqueteTable tbody td{
        position: relative;
        width: 40px;
        max-width: 40px;
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }
}
