/*
---
name: pd_c-itemImage-01
category: component
tag:
  - text
  - img
---
```html
  <div class="pd_c-itemImage-01">
      <div class="pd_c-itemImage-01_img"><img src="http://placehold.jp/3d4070/ffffff/100x100.png" alt="" /></div>
      <div class="pd_c-itemImage-01_contents">
          <p class="pd_c-itemImage-01_name">コードレススティック掃除機 「パワーコードレス」</p>
          <p class="pd_c-itemImage-01_price"><span class="pd_c-itemImage-01_text-01">50,700</span><span class="pd_c-itemImage-01_text-02">円</span><span class="pd_c-itemImage-01_text-03">（税込）</span></p>
      </div>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-itemImage-01 {
  display flex
  align-items center
  &_img,
  &_contents {
  }
  &_img {
    width 70px
    height 70px
    display flex
    justify-content center
    align-items center
    img {
      max-width 70px
      max-height 100%
    }
  }
  &_contents {
    flex 1
    padding-left 10px
  }
  &_name {
    color #000
    font-size 14px
  }
  &_price {
  }
  &_text-01 {
    font-size 22px
    color rgba(0,0,0,.9)
  }
  &_text-02 {
    font-size 13px
    color rgba(0,0,0,.9)
  }
  &_text-03 {
    font-size 13px
    color rgba(0,0,0,.6)
  }
}
