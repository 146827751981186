/*
---
name: pd_c-favoriteBtn-01
category: component
tag:
  - btn
---
```html
  <button class="pd_c-favoriteBtn-01 pd_e-hover-01 pd_e-hover-01--bgGray"><span class="pd_c-favoriteBtn-01_text-01">お気に入りに追加</span><span class="pd_c-favoriteBtn-01_text-02">98</span></button>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-favoriteBtn-01 {
  background-image url("/store/images/component/favoriteBtn-01_icon_01.svg")
  background-position 15px center;
  background-size 16px 16px;
  background-repeat no-repeat;
  background-color #fff
  border:solid 1px #707070;
  border-radius 8px;
  display inline-block;
  line-height 1
  cursor pointer
  padding: 7px 15px 7px 36px;
  &_text-01,
  &_text-02 {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }
  &_text-01 {
    color: rgba(0, 0, 0 .6);
    font-size: 13px;
  }
  &_text-02 {
    color: #364563;
    font-size: 15px;
    margin-left 5px
  }
  &.pd_favoriteOn {
    background-image url("/store/images/module/icon_favoriteOn.svg")
  }
}

