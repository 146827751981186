/*
---
name: pd_b-flowStep1
category: block
tag:
  - text
  - btn
  - icon
---
```html
<div class="pd_b-flowStep1">
    <div class="pd_b-flowStep1_inner">
        <h1 class="pd_m-contentTtl-01">参加内容/アンケートの入力</h1>
        <div class="pd_m-eventBnr">
            <p class="pd_m-eventBnr_ttl">スマートコーヒー焙煎サービス「The Roast」体験会</p>
            <div class="pd_m-eventBnr_btm">
                <p class="pd_m-eventBnr_date">4月28日14:30～15:00</p>
                <div class="pd_m-eventBnr_price"><span class="pd_m-eventBnr_priceAbout">参加費</span><span class="pd_m-eventBnr_priceTotal">合計</span><span class="pd_m-eventBnr_priceNum">1,000</span><span class="pd_m-eventBnr_priceYen">円</span><span class="pd_m-eventBnr_priceTax">(税込)</span></div>
            </div>
        </div>
        <p class="pd_b-flowStep1_attention">以下の項目を選択・記入いただき、「次へ」ボタンを押してください。<br />必須の項目は必ずご記入ください。</p>
        <form class="pd_b-flowStep1_form" action="">
            <div class="pd_b-flowStep1_content pd_b-flowStep1_content01">
                <div class="pd_m-radioWrap">
                    <p class="pd_c-requiredTitle">1. 今回のイベントをどうやってお知りになりましたか？<span class="pd_c-requiredTitle_small">（単一回答）</span><span class="pd_e-required-01">必須 </span></p>
                    <ul class="pd_m-radioWrap_list">
                        <li class="pd_m-radioWrap_listItem">
                            <label class="pd_c-radioBtn-01">
                                <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="event" value="ウェブサイト" /><span class="pd_c-radioBtn-01_indicator"></span><span class="pd_c-radioBtn-01_text-01">ウェブサイト</span>
                            </label>
                        </li>
                        <li class="pd_m-radioWrap_listItem">
                            <label class="pd_c-radioBtn-01">
                                <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="event" value="友人からのすすめ" /><span class="pd_c-radioBtn-01_indicator"></span><span class="pd_c-radioBtn-01_text-01">友人からのすすめ</span>
                            </label>
                        </li>
                        <li class="pd_m-radioWrap_listItem">
                            <label class="pd_c-radioBtn-01">
                                <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="event" value="Facebook" /><span class="pd_c-radioBtn-01_indicator"></span><span class="pd_c-radioBtn-01_text-01">Facebook</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pd_b-flowStep1_content pd_b-flowStep1_content02">
                <p class="pd_c-requiredTitle">2. 質問文が入ります<span class="pd_c-requiredTitle_small">（複数回答）</span><span class="pd_e-required-01">必須 </span></p>
                <ul class="pd_b-flowStep1_content02List">
                    <li class="pd_b-flowStep1_check01">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check01">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check01">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check01">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキストが入ります選択肢テキストが入ります</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check01">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキストが入ります</span>
                        </label>
                    </li>
                </ul>
                <label class="pd_c-checkTextArea-01">
                    <input class="pd_c-checkTextArea-01_input-01 pd_js-c-checkTextArea-01_checkTextArea" type="checkbox" name="" /><span class="pd_c-checkTextArea-01_indicator-01"></span><span class="pd_c-checkTextArea-01_text-01">その他</span>
                </label>
                <textarea class="pd_c-checkTextArea-01_textArea" type="textarea"></textarea>
            </div>
            <div class="pd_b-flowStep1_content pd_b-flowStep1_content03">
                <p class="pd_c-requiredTitle">3. 質問文が入ります<span class="pd_c-requiredTitle_small">（複数回答）</span><span class="pd_e-required-01">必須 </span></p>
                <ul class="pd_b-flowStep1_content03List">
                    <li class="pd_b-flowStep1_check03">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="question" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check03">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="question" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check03">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="question" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check03">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="question" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check03">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="question" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check03">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="question" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check03">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="question" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check03">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="question" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                        </label>
                    </li>
                    <li class="pd_b-flowStep1_check03">
                        <label class="pd_c-checkBox-01">
                            <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="question" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                        </label>
                    </li>
                </ul>
                <label class="pd_c-checkTextArea-01">
                    <input class="pd_c-checkTextArea-01_input-01 pd_js-c-checkTextArea-01_checkTextArea" type="checkbox" name="" /><span class="pd_c-checkTextArea-01_indicator-01"></span><span class="pd_c-checkTextArea-01_text-01">その他</span>
                </label>
                <textarea class="pd_c-checkTextArea-01_textArea" type="textarea"></textarea>
            </div>
            <div class="pd_b-flowStep1_content pd_b-flowStep1_content04">
                <p class="pd_c-requiredTitle">4. 質問文が入ります<span class="pd_c-requiredTitle_small">（自由回答）</span><span class="pd_e-required-01">必須 </span></p>
                <textarea class="pd_b-flowStep1_textarea"></textarea>
            </div>
        </form>
        <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
            <p class="pd_c-filterButton_text">次へ</p>
        </button>
    </div>
</div>
```
*/
.pd_b-flowStep1
    margin: 0 auto 60px
    &_inner
        margin: 0 auto
        width: 976px
    &_attention
        margin: 39px 0 0 0;
        letter-spacing: 1px;
        padding: 0 0 44px
    &_form
        border-bottom: 1px solid #CCCCCC
        border-top: 1px solid #CCCCCC
    &_content
        margin: 40px 0
        .pd_c-checkTextArea-01
            display: block
            margin-top: 15px
    // &_content02List
    &_check01
        margin-top: 16px
        letter-spacing: 1px
    &_content03List
        display: flex
        flex-wrap wrap
    &_check03
        margin-top: 16px
        letter-spacing: 1px
        width: calc(100% / 3)
    &_textarea
        box-sizing: border-box
        border: 1px solid  #707070
        border-radius: 8px
        display: block
        margin-top: 16px
        height: 104px
        padding 15px
        vertical-align: top
        width 100%
    .pd_c-filterButton
        margin: 37px auto 0
        display: block
        width: 345px
        @media screen and (max-width 640px)
            width: 100%
@media screen and (max-width 640px)
    .pd_b-flowStep1
        &_inner
            width: 92%
        .pd_m-contentTtl-01
            margin: 34px auto 18px
            padding: 0
        &_check01
            margin-top: 16px
            letter-spacing: 1px
        .pd_c-checkBox-01_text-01
            width: 85%
            margin-top: 2px
        &_check03
            width: calc(100% / 2)
            .pd_c-checkBox-01_text-01
                width: calc(100% - 34px)