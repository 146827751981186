/*
---
name: pd_e-smallTag-03
category: element
tag:
  - tag
---
```html
<span class="pd_e-smallTag-03">必須</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-smallTag-03 {
    display inline-block
    background-color #CC0E00
    border-radius 4px
    padding 6px 5px
    line-height 1
    font-size 13px
    color #fff
    &+.pd_m-smallBoxItem_ttl {
        margin-top 6px
    }
}