/*
---
name: search-impl（検索バー）
category: pd
---
```html
<div class="pd_serchimpl2_wrap">
    <div class="pd_serchimpl2_wrap-inner pd_serchimpl2_wrap-pc">
        <ul id="pd_main-header-sub__search">
            <li id="pd_main-header-sub__search-category"><a class="pd_e-hover-01" href="javascript:void(0);"><span>カテゴリから探す</span></a>
                <div id="pd_main-header-sub__search-category-body">
                    <div class="pd_main-header-sub__search-category-inner">
                        <ul class="pd_main-header-sub__search-category-body__list pd_main-header-sub__search-category-body__list01">
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem01"><img class="pd_main-header-sub__categoryIcon-black" src="/store/images/common/header_tv.svg" alt="映像" /><img class="pd_main-header-sub__categoryIcon-white" src="/store/images/common/header_tv_white.svg" alt="映像" />
                                <p class="pd_main-header-sub__search-category-itemName">映像</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem01"><img class="pd_main-header-sub__categoryIcon-black" src="/store/images/common/header_camera.svg" alt="カメラ" /><img class="pd_main-header-sub__categoryIcon-white" src="/store/images/common/header_camera_white.svg" alt="カメラ" />
                                <p class="pd_main-header-sub__search-category-itemName">カメラ</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem01"><img class="pd_main-header-sub__categoryIcon-black" src="/store/images/common/header_audio.svg" alt="オーディオ" /><img class="pd_main-header-sub__categoryIcon-white" src="/store/images/common/header_audio_white.svg" alt="オーディオ" />
                                <p class="pd_main-header-sub__search-category-itemName">オーディオ</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem01"><img class="pd_main-header-sub__categoryIcon-black" src="/store/images/common/header_phone.svg" alt="電話・FAX ・テレビドアホン" /><img class="pd_main-header-sub__categoryIcon-white" src="/store/images/common/header_phone_white.svg" alt="電話・FAX ・テレビドアホン" />
                                <p class="pd_main-header-sub__search-category-itemName">電話・FAX ・テレビドアホン</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem01"><img class="pd_main-header-sub__categoryIcon-black" src="/store/images/common/header_music.svg" alt="メディア・アクセサリー" /><img class="pd_main-header-sub__categoryIcon-white" src="/store/images/common/header_music_white.svg" alt="メディア・アクセサリー" />
                                <p class="pd_main-header-sub__search-category-itemName">メディア・アクセサリー</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem01"><img class="pd_main-header-sub__categoryIcon-black" src="/store/images/common/header_cooking.svg" alt="調理" /><img class="pd_main-header-sub__categoryIcon-white" src="/store/images/common/header_cooking_white.svg" alt="調理" />
                                <p class="pd_main-header-sub__search-category-itemName">調理</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem01"><img class="pd_main-header-sub__categoryIcon-black" src="/store/images/common/header_air.svg" alt="空調・季節" /><img class="pd_main-header-sub__categoryIcon-white" src="/store/images/common/header_air_white.svg" alt="空調・季節" />
                                <p class="pd_main-header-sub__search-category-itemName">空調・季節</p>
                            </li>
                        </ul>
                        <ul class="pd_main-header-sub__search-category-body__list pd_main-header-sub__search-category-body__list02">
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem02">
                                <p class="pd_main-header-sub__search-category-item02Name">テレビ</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem02">
                                <p class="pd_main-header-sub__search-category-item02Name">ブルーレイ/DVDレコーダー・プレーヤー</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem02">
                                <p class="pd_main-header-sub__search-category-item02Name">ポーターブルAV</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem02">
                                <p class="pd_main-header-sub__search-category-item02Name">液晶プロジェクター</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem02">
                                <p class="pd_main-header-sub__search-category-item02Name">チューナー</p>
                            </li>
                        </ul>
                        <ul class="pd_main-header-sub__search-category-body__list pd_main-header-sub__search-category-body__list03">
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem03">
                                <p class="pd_main-header-sub__search-category-item03Name">第三階層テキストテキスト</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem03">
                                <p class="pd_main-header-sub__search-category-item03Name">第三階層テキストテキスト</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem03">
                                <p class="pd_main-header-sub__search-category-item03Name">第三階層テキストテキスト</p>
                            </li>
                            <li class="pd_main-header-sub__search-category-body__listItem pd_main-header-sub__search-category-body__listItem03">
                                <p class="pd_main-header-sub__search-category-item03Name">第三階層テキストテキスト</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="overlay_headernav"></div>
            </li>
            <li id="pd_main-header-sub__search-sean"><a class="pd_e-hover-01" href="javascript:void(0);"><span>くらしのシーン</span></a>
                <div id="pd_main-header-sub__search-sean-body">
                    <div class="pd_main-header-sub__search-category-inner"><a class="pd_sean_menuLink pd_e-hover-01" href="">くらしのシーンから探す</a>
                        <ul class="pd_menu_detailList header_slider">
                            <li class="pd_menu_detailListItem pd_e-hover-01"><a href=""><img src="/store/images/common/hum_sean01.png" alt="リビング" />
                                    <p>リビング</p>
                                </a></li>
                            <li class="pd_menu_detailListItem pd_e-hover-01"> <a href=""><img src="/store/images/common/hum_sean02.png" alt="キッチン" />
                                    <p>キッチン</p>
                                </a></li>
                            <li class="pd_menu_detailListItem pd_e-hover-01"> <a href=""><img src="/store/images/common/hum_sean03.png" alt="洗面所・バスルーム" />
                                    <p>洗面所・バスルーム</p>
                                </a></li>
                            <li class="pd_menu_detailListItem pd_e-hover-01"> <a href=""><img src="/store/images/common/hum_sean04.png" alt="ベッドルーム" />
                                    <p>ベッドルーム</p>
                                </a></li>
                            <li class="pd_menu_detailListItem pd_e-hover-01"> <a href=""><img src="/store/images/common/hum_sean05.png" alt="書斎・仕事場" />
                                    <p>書斎・仕事場</p>
                                </a></li>
                            <li class="pd_menu_detailListItem pd_e-hover-01"> <a href=""><img src="/store/images/common/hum_sean06.png" alt="屋外" />
                                    <p>屋外</p>
                                </a></li>
                        </ul>
                    </div>
                </div>
                <div class="overlay_headernav"></div>
            </li>
            <li id="pd_main-header-sub__search-lifeStyle"><a class="pd_e-hover-01" href="javascript:void(0);"><span>ライフスタイル</span></a>
                <div id="pd_main-header-sub__search-style-body">
                    <div class="pd_main-header-sub__search-category-inner"><a class="pd_style_menuLink pd_e-hover-01" href="">ライフスタイルから探す</a>
                        <ul class="pd_menu_detailList header_slider">
                            <li class="pd_menu_detailListItem pd_e-hover-01"><a href=""><img src="/store/images/common/hum_style01.png" alt="家事・暮らし" />
                                    <p>家事・暮らし</p>
                                </a></li>
                            <li class="pd_menu_detailListItem pd_e-hover-01"><a href=""><img src="/store/images/common/hum_style02.png" alt="ペット" />
                                    <p>ペット</p>
                                </a></li>
                            <li class="pd_menu_detailListItem pd_e-hover-01"> <a href=""><img src="/store/images/common/hum_style03.png" alt="空気" />
                                    <p>空気</p>
                                </a></li>
                            <li class="pd_menu_detailListItem pd_e-hover-01"> <a href=""><img src="/store/images/common/hum_style04.png" alt="エンタメ" />
                                    <p>エンタメ</p>
                                </a></li>
                            <li class="pd_menu_detailListItem pd_e-hover-01"> <a href=""><img src="/store/images/common/hum_style05.png" alt="美容" />
                                    <p>美容</p>
                                </a></li>
                            <li class="pd_menu_detailListItem pd_e-hover-01"> <a href=""><img src="/store/images/common/hum_style06.png" alt="在宅勤務" />
                                    <p>在宅勤務</p>
                                </a></li>
                        </ul>
                    </div>
                </div>
                <div class="overlay_headernav"></div>
            </li>
        </ul>
        <div class="pd_serchimpl2-main_SelectDetail_ph2">
            <div class="pd_serchimpl2-main_inputWrap">
                <input class="face-text eccom-keyword ui-autocomplete-input pd_serchimpl2-main_inputDetail" type="text" placeholder="テレビ" id="pd_suggest-autocomplete" />
                <button class="eccom-search-button pd_serchimpl2-main_inputBtn pd_e-hover-01" id="pd_main-header-sub__form-submit" type="button"></button>
            </div>
            <div id="pd_main-header-sub__search-result-body">
                <div class="pd_main-header-sub__search-result-wrap">
                    <div class="pd_main-header-sub__search-result-inner">
                        <ul class="pd_search-result-list">
                            <li class="pd_search-result-listItem"><a href="">テレビ </a></li>
                            <li class="pd_search-result-listItem"><a href="">4K</a></li>
                            <li class="pd_search-result-listItem"><a href="">ビエラ</a></li>
                            <li class="pd_search-result-listItem"><a href="">テレビ 65v型</a></li>
                            <li class="pd_search-result-listItem"><a href="">テレビ おすすめ</a></li>
                        </ul>
                        <div class="pd_search-result_content">
                            <p class="pd_search-result_contentTtl">商品カテゴリ</p>
                            <ul class="pd_search-result-list">
                                <li class="pd_search-result-listItem"><a href="">映像</a></li>
                                <li class="pd_search-result-listItem"><a href="">テレビ</a></li>
                                <li class="pd_search-result-listItem"><a href="">有機ELテレビ</a></li>
                                <li class="pd_search-result-listItem"><a href="">液晶テレビ</a></li>
                            </ul>
                        </div>
                        <div class="pd_search-result_content">
                            <p class="pd_search-result_contentTtl">#タグ</p>
                            <p class="pd_search-result_contentSubTtl">関連タグ
                            <ul class="pd_search_tagList">
                                <li class="pd_search_tagListItem"><a href="">#テレビ</a></li>
                                <li class="pd_search_tagListItem"><a href="">#4K</a></li>
                                <li class="pd_search_tagListItem"><a href="">#ビエラ</a></li>
                                <li class="pd_search_tagListItem"><a href="">#壁掛けテレビ</a></li>
                                <li class="pd_search_tagListItem"><a href="">#リビング</a></li>
                                <li class="pd_search_tagListItem"><a href="">#ブルーレイ</a></li>
                                <li class="pd_search_tagListItem"><a href="">#画面サイズの選び方</a></li>
                            </ul>
                            </p>
                        </div>
                        <div class="pd_search-result_contentFlex">
                            <div class="pd_search-result_content">
                                <p class="pd_search-result_contentSubTtl">あなたに合った＃タグで検索</p>
                                <ul class="pd_search_tagList">
                                    <li class="pd_search_tagListItem"><a href="">#時短家電</a></li>
                                    <li class="pd_search_tagListItem"><a href="">#頑固な汚れ</a></li>
                                    <li class="pd_search_tagListItem"><a href="">#ペットと暮らしたい</a></li>
                                    <li class="pd_search_tagListItem"><a href="">#コーヒーメーカー</a></li>
                                    <li class="pd_search_tagListItem"><a href="">#おしゃれしたい</a></li>
                                    <li class="pd_search_tagListItem"><a href="">#時短レシピ</a></li>
                                </ul>
                            </div>
                            <div class="pd_search-result_content">
                                <p class="pd_search-result_contentSubTtl">あなたに合った＃タグで検索</p>
                                <ul class="pd_search_tagList">
                                    <li class="pd_search_tagListItem"><a href="">#食・レシピ</a></li>
                                    <li class="pd_search_tagListItem"><a href="">#タグ</a></li>
                                    <li class="pd_search_tagListItem"><a href="">#タグ</a></li>
                                    <li class="pd_search_tagListItem"><a href="">#タグ</a></li>
                                    <li class="pd_search_tagListItem"><a href="">#タグ</a></li>
                                    <li class="pd_search_tagListItem"><a href="">#タグ</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay_headernav"></div>
            </div>
        </div>
    </div>
</div>

<script>
    $(function() {
        $('.header_slider').slick({
            nextArrow: '<div class="nextArrow header-sliderArrow"></div>',
            //- prevArrow:'<div class="prevArrow header-sliderArrow"></div>',
            slidesToShow: 5,
            centerMode: true,
            centerPadding: '0px',
        });
    });
</script>
```
*/
.pd_serchimpl2_wrap_ph2
  background-color: #f7f7f7
  font-size: 14px
  position: relative
.pd_serchimpl2_wrap-inner
  align-items: center
  display: flex
  height: 60px
  justify-content: space-between
  margin: 0 auto
  width: 976px
.pd_serchimpl2-main_SelectDetail_ph2
  display: flex
  flex-wrap: nowrap
  width: 345px
  display: block
.pd_serchimpl2-main_inputWrap
  height: auto
  position: relative
  height: 100%
  width: 100%;
  overflow: hidden;
  .pd_js-input_search_sp
    display: none
    @media screen and (max-width: 640px)
      display: block
      padding-right: 56px
.pd_serchimpl2-main_inputDetail_ph2
    font-size: 15px
    height: 52px
    width: 100%
.pd_serchimpl2-main_inputDetail_ph2::-ms-clear{
  visibility:hidden
}
#pd_main-header-sub__form-delete{
  height: 33px;
  width: 33px;
  top: 1px;
  right: 33px;
  position: absolute;
  background-color: transparent;
  border: 0px;
}
#pd_main-header-sub__form-delete:before{
  content: "+";
  position: absolute;
  font-size: 30px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0;
  left: 0;
  right: 0;
  line-height: 33px;
}
.pd_main-header-sub__search {
  align-items: center
  display: flex;
  justify-content: flex-start
  height: 100%
  padding: 0 5px;
  width 480px
}

.pd_main-header-sub__search > li
  background: transparent;
  padding 0 10px
  height: 100%;
  border-bottom: 2px solid transparent
  &:hover
    border-bottom: 2px solid #364563

.pd_main-header-sub__search > li > a {
  align-items: center
  cursor: default;
  color #364563
  display: flex
  font-size: 1.5rem;
  height: 100%
  line-height: 1.2;
  position: relative;
  pointer-events: none
  white-space: nowrap;
}

.pd_main-header-sub__search li#pd_main-header-sub__search-series > a::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 23px;
  left: 13px;
  margin-top: -7px;
  width: 12px;
  height: 12px;
  background-image: url('/store/images/common/icon_series.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 12px 12px;
}


.pd_main-header-sub__search li#pd_main-header-sub__search-series > a::before
  content: ' ';
  display: block;
  position: absolute;
  top: 23px;
  left: 13px;
  margin-top: -7px;
  width: 12px;
  height: 12px;
  background-image: url('/store/images/common/icon_series.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 12px 12px;

.pd_main-header-sub__search > li a {
  position: relative;
  z-index: 10;
}
.pd_menu_detailListItem{
  height: 250px;
}

.pd_menu_detailListItem a{
  display: block;
  width: 180px;
  height: 180px;
}

.pd_main-header-sub__search > li.pd_on {
  border: 1px solid #CCCCCC;
  border-bottom: none;
  background: #F7F7F7;
}

.pd_main-header-sub__search > li.pd_on > a {
  border: none;
  height: 55px;
  padding-bottom: 7px;
  background-color: #F7F7F7;
}
#pd_main-header-sub__search-result-body
  display none

#pd_main-header-sub__search-category,#pd_main-header-sub__search-sean{
  margin-right 24px
}

.pd_main-header-sub__search-category-body,
#pd_main-header-sub__search-sean-body,
#pd_main-header-sub__search-style-body,
#pd_main-header-sub__search-result-body
  visibility hidden
  background: #f7f7f7;
  bottom: 0
  left 0
  position: absolute;
  transform: translateY(100%)
  width: 100%;
  z-index: 1001
  height: 300px
  &.pd_isOpen
    visibility visible
    display none

#pd_main-header-sub__search-style-body{
  height: 350px
}
#pd_main-header-sub__search-sean-body,
#pd_main-header-sub__search-lifeStyle
  .pd_main-header-sub__search-category-inner
    display: flex
    flex-direction: column
    align-items: flex-start
    margin 0 auto
    width: 996px
    height: 310px
  .pd_menu_detailList
    width: 100%
    margin: 29px 10px 0
    display: none
    &.slick-initialized
      display block


.pd_sean_menuLink,
.pd_style_menuLink
  color #364563
  display: inline-block
  letter-spacing: 1px
  position: relative
  padding: 3px 9px 3px 13px
  text-decoration: underline
  &::after
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: auto;
    border-top: 2px solid #23354F
    border-right:2px solid #23354F
    position: absolute;
    top: 0;
    right: 0px;
    bottom: 0;
    transform: rotate(45deg);
.pd_menu_detailList
  .header-sliderArrow
    background-color: #fff
    background-size: 7.78px 12.73px
    background-position: center
    background-repeat: no-repeat
    border-radius: 50%
    box-shadow: 1px 1px 2px rgba(0,0,0,0.1)
    height: 58px
    width: 58px
    z-index: 1;
    cursor: pointer;
    &:hover {
      background-color #F2F2F2
    }
  .nextArrow
    background-image: url('/store/images/top/slider_arrow_right.svg')
    display: block;
    position: absolute;
    right: -30px;
    transform: translateY(-50%);
    top: calc(50% - 20px);
  .slick-prev,
  .prevArrow
    background-image: url('/store/images/top/slider_arrow_left.svg')
    display: none!important
    position: absolute;
    left: -30px;
    transform: translateY(-50%);
    top: calc(50% - 20px);
.pd_menu_detailList
  margin-top: 35px
  .slick-slide
    margin 0 10px
.pd_menu_detailListItem p
  color: #000000
  font-size: 15px
  text-align: center
  transform: translateY(15px);

.pd_menu_detailListItem img
  width: 100%
// 検索結果
.pd_main-header-sub__search-result-wrap
  background-color: #fff;
  padding-bottom: 30px;
  position: relative;
  z-index: 11;
.pd_main-header-sub__search-result-inner
  margin 0 auto
  padding: 27px 0 0 0
  width: 976px
.pd_search-result-list
  display flex
  flex-wrap: wrap
  a
    color #364563
    display: block
    font-size 15px
    line-height 25px
    width 100%
.pd_search-result-listItem
  width: 25%;
  margin-bottom: 8px;
.pd_search-result_content
  margin-top: 11px
  padding-bottom: 4px
  .pd_search_tagListItem
    margin: 0px 9px 8px 0
    a
      padding: 7px 11px
.pd_search-result_contentTtl
  border-bottom: 1px solid #CCCCCC
  font-size: 15px;
  font-weight: 400;
  line-height: 2.8;
  letter-spacing: 0.2px;
  margin-bottom: 25px
  padding-bottom: 1px
.pd_search-result_contentSubTtl
  margin-bottom: 11px
.pd_search-result_contentFlex
  display: flex
  flex-wrap: wrap
  .pd_search-result_content
    width: 50%


.pd_main-header-sub__search-category-body > ul {
  width: 240px;
  height: 300px;
  background: #F7F7F7;
  overflow: auto;
}

.pd_main-header-sub__search-category-body > ul > li {
  border-bottom: 1px solid #E5E5E5;
}

.pd_main-header-sub__search-category-body > ul > li:last-child {
  border-bottom: none;
}

.pd_main-header-sub__search-category-body > ul > li > a {
  position: relative;
  display: block;
  padding: 8px 15px;
  color: #000;
  font-size: 1.4rem;
}

.pd_main-header-sub__search-category-body > ul > li dl {
  display: none;
  /*  */
  position: absolute;
  top: 0;
  left: 240px;
  width: calc(976px - 240px);
  height: 300px;
  overflow: auto;
  background: #fff;
  padding: 20px;
  border: 1px solid #E5E5E5;
}

.pd_main-header-sub__search-category-body > ul > li dl dt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.pd_main-header-sub__search-category-body > ul > li dl dt figure {
  line-height: 0;
  width: 40px;
  display: none;
}

.pd_main-header-sub__search-category-body > ul > li dl dt strong {
  display: block;
  width: calc(100% - 40px);
  font-weight: bold;
  font-size: 1.8rem;
}

.pd_main-header-sub__search-category-body > ul > li dl dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_main-header-sub__search-category-body > ul > li dl dd ul li {
  font-size: 1.4rem;
  width: 33.33%;
  padding-right: 10px;
  margin: 0 0 10px 0;
}

.pd_main-header-sub__search-category-body > ul > li.pd_on {
  background: #666666;
}

.pd_main-header-sub__search-category-body > ul > li.pd_on > a {
  color: #fff;
}

.pd_main-header-sub__search-category-body > ul > li.pd_on dl {
  display: block;
}
.pd_hum_menuLink
  display: block
/* PC */
@media screen and (min-width: 641px) {
  .pd_main-header-sub__search-category-body > ul > li dl dd {
    display: block !important;
  }

  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_main-header-sub__search-category-body {
    position: absolute;
    z-index: 2;
    top: calc(100% - 4px) !important;
    left: 0 !important;
    /*
    margin-top: -1px;
    */
    margin-bottom: 8px;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: calc(100% + 20px);
    margin: 0px -10px 0;
    padding: 0 10px 10px;
    height: auto;
    border: none;
    min-width: inherit;
    max-height: inherit;
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  .pd_hum_menu_detailListItem{
    width: 48%
  }

  .pd_main-header-sub__search-category-body > ul {
    border: 1px solid #CCCCCC;
    border-radius: 0px 3px 3px 3px;
    width: auto;
    height: auto;
    background: #F7F7F7;
    padding: 10px 10px 4px 10px;
  }

  .pd_main-header-sub__search-category-body > ul > li {
    border-bottom: none;
    margin-bottom: 6px;
    background: none !important;
  }

  .pd_main-header-sub__search-category-body > ul > li > a {
    display: none;
  }

  .pd_main-header-sub__search-category-body > ul > li dl {
    display: block !important;
    /*  */
    position: static;
    top: inherit;
    left: inherit;
    width: auto;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 0;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dt {
    cursor: pointer;
    /*  */
    padding: 13px 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0px;
    background: url('/store/images/common/icon_plus.svg') calc(100% - 10px) 50% no-repeat;
    background-size: 12px 12px;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dt.pd_open {
    background: url('/store/images/common/icon_minus.svg') calc(100% - 10px) 50% no-repeat;
    background-size: 12px 12px;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dt figure {
    width: 40px;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dt strong {
    width: calc(100% - 40px);
    padding-left: 12px;
    font-size: 1.6rem;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dd {
    display: none;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dd ul {
    display: block;
    padding: 5px 20px 10px;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dd ul li {
    font-size: 1.4rem;
    width: auto;
    padding-right: 0px;
    margin: 0 0 10px 0;
  }

  /* ------------- */
}

/* END カテゴリから探す（main-header-sub__search-category-body） */
/* シリーズから探す（main-header-sub__search-series-body） */
#pd_main-header-sub__search-series-body {
  display: none;
  /*  */
  position: absolute;
  top: calc(100% - 14px);
  z-index: 100;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-width: 240px;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-series-body ul li {
  border-bottom: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-series-body ul li:last-child {
  border-bottom: none;
}

#pd_main-header-sub__search-series-body ul li a {
  display: block;
  padding: 8px 15px;
  color: #000;
  font-size: 1.4rem;
  white-space: nowrap;
}

#pd_main-header-sub__search-series-body ul li a figure {
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-header-sub__search-series-body {
    position: absolute;
    z-index: 2;
    top: calc(100% - 4px) !important;
    left: 0 !important;
    /*
    margin-top: -1px;
    */
    margin-bottom: 8px;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: calc(100% + 20px);
    margin: 0px -10px 0;
    padding: 0 10px 10px;
    height: auto;
    border: none;
    min-width: inherit;
    max-height: inherit;
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  #pd_main-header-sub__search-series-body > ul {
    border: 1px solid #CCCCCC;
    border-radius: 3px 0px 3px 3px;
    width: auto;
    height: auto;
    background: #F7F7F7;
    padding: 10px 10px 4px 10px;
  }

  #pd_main-header-sub__search-series-body > ul li {
    border-bottom: none;
    margin-bottom: 6px;
  }

  #pd_main-header-sub__search-series-body > ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 13px 10px;
    font-weight: bold;
    font-size: 1.6rem;
    white-space: normal;
  }

  #pd_main-header-sub__search-series-body > ul li a figure {
    display: block;
    width: 40px;
    line-height: 0;
  }

  #pd_main-header-sub__search-series-body > ul li a span {
    display: block;
    width: calc(100% - 40px);
    padding-left: 12px;
  }

  /* ------------- */
}
@media screen and (max-width 640px)
  .pd_serchimpl2-main_SelectDetail_ph2
    width: 100%
    position: relative

.pd_serchimpl2-main_inputBtn_ph2
  background-color: #364563
 /* background-image: url('/store/com/images/top/header_search_white.svg')
 */ background-repeat: no-repeat
  background-size: 16px 16px
  background-position: center
  display: block
  height: 35px
  width: 35px
  outline: none
  position: absolute
  right: -1px
  top: -1px
  border: 0;
  border-radius: 0 5px 5px 0;
  @media screen and (max-width 640px)
    width: 53px;
    background-size: 22px 22px;

.pd_serchimpl2-main_inputDetail_ph2
  border-radius: 5px
  border: 1px solid #666;
  box-sizing: border-box
  height: 33px
  padding:0 70px 0 14.3px
  width: 100%
.pd_serchimpl2_wrap-sp
    display: none
@media screen and (max-width: 640px)
    .pd_serchimpl2_wrap-pc
        display: none !important
    .pd_serchimpl2_wrap-inner
        border: 1px solid #1876E9
        border-radius: 3px
        display: flex
        width: 100%
    .pd_serchimpl2_ChooseLabel
        width: 32.676%
        &::before
            display: none
        &::after
            background-image: url("/store/images/common/select_arrow_twoway.svg")
            background-size: cover
            border: 0px
            width: 6px
            height: 11px
            right: 11px
    .pd_serchimpl2_ChooseAddress
        background-color: #EDF4FF
        border: 0px
        border-right: 1px solid #1876E9
        border-radius: 0px
        padding: 0 15px 0 16px
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    .pd_serchimpl2-main_inputDetail_ph2
        padding: 0 0 0 11px
        width: 100%
@media screen and (max-width: 330px)
    .pd_serchimpl2_ChooseAddress
        padding: 0 15px 0 10px


.pd_main-header-sub__search-category-body > ul {
  width: 240px;
  height: 300px;
  background: #F7F7F7;
  overflow: auto;
}

.pd_main-header-sub__search-category-body > ul > li {
  border-bottom: 1px solid #E5E5E5;
}

.pd_main-header-sub__search-category-body > ul > li:last-child {
  border-bottom: none;
}

.pd_main-header-sub__search-category-body > ul > li > a {
  position: relative;
  display: block;
  padding: 8px 15px;
  color: #000;
  font-size: 1.4rem;
}

.pd_main-header-sub__search-category-body > ul > li dl {
  display: none;
  /*  */
  position: absolute;
  top: 0;
  left: 240px;
  width: calc(976px - 240px);
  height: 300px;
  overflow: auto;
  background: #fff;
  padding: 20px;
  border: 1px solid #E5E5E5;
}

.pd_main-header-sub__search-category-body > ul > li dl dt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.pd_main-header-sub__search-category-body > ul > li dl dt figure {
  line-height: 0;
  width: 40px;
  display: none;
}

.pd_main-header-sub__search-category-body > ul > li dl dt strong {
  display: block;
  width: calc(100% - 40px);
  font-weight: bold;
  font-size: 1.8rem;
}
.pd_main-header-sub__search-category-body > ul > strong {
  display: block;
  width: calc(100% - 40px);
  font-weight: bold;
  font-size: 1.8rem;
}

.pd_main-header-sub__search-category-body > ul > li dl dd ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pd_main-header-sub__search-category-body > ul > li dl dd ul li {
  font-size: 1.4rem;
  width: 33.33%;
  padding-right: 10px;
  margin: 0 0 10px 0;
}

.pd_main-header-sub__search-category-body > ul > li.pd_on {
  background: #666666;
}

.pd_main-header-sub__search-category-body > ul > li.pd_on > a {
  color: #fff;
}

.pd_main-header-sub__search-category-body > ul > li.pd_on dl {
  display: block;
}

/* PC */
@media screen and (min-width: 641px) {
  .pd_main-header-sub__search-category-body > ul > li dl dd {
    display: block !important;
  }

  /* ------------- */
}

/* SP */
@media screen and (max-width: 640px) {
  .pd_main-header-sub__search-category-body {
    position: absolute;
    z-index: 2;
    top: calc(100% - 4px) !important;
    left: 0 !important;
    /*
    margin-top: -1px;
    */
    margin-bottom: 8px;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: calc(100% + 20px);
    margin: 0px -10px 0;
    padding: 0 10px 10px;
    height: auto;
    border: none;
    min-width: inherit;
    max-height: inherit;
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  .pd_main-header-sub__search-category-body > ul {
    border: 1px solid #CCCCCC;
    border-radius: 0px 3px 3px 3px;
    width: auto;
    height: auto;
    background: #F7F7F7;
    padding: 10px 10px 4px 10px;
  }

  .pd_main-header-sub__search-category-body > ul > li {
    border-bottom: none;
    margin-bottom: 6px;
    background: none !important;
  }

  .pd_main-header-sub__search-category-body > ul > li > a {
    display: none;
  }

  .pd_main-header-sub__search-category-body > ul > li dl {
    display: block !important;
    /*  */
    position: static;
    top: inherit;
    left: inherit;
    width: auto;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 0;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dt {
    cursor: pointer;
    /*  */
    padding: 13px 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0px;
    background: url('/store/images/common/icon_plus.svg') calc(100% - 10px) 50% no-repeat;
    background-size: 12px 12px;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dt.pd_open {
    background: url('/store/images/common/icon_minus.svg') calc(100% - 10px) 50% no-repeat;
    background-size: 12px 12px;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dt figure {
    width: 40px;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dt strong {
    width: calc(100% - 40px);
    padding-left: 12px;
    font-size: 1.6rem;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dd {
    display: none;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dd ul {
    display: block;
    padding: 5px 20px 10px;
  }

  .pd_main-header-sub__search-category-body > ul > li dl dd ul li {
    font-size: 1.4rem;
    width: auto;
    padding-right: 0px;
    margin: 0 0 10px 0;
  }

  /* ------------- */
}

/* END カテゴリから探す（serchimpl2__search-category-body） */
/* シリーズから探す（serchimpl2__search-series-body） */
#pd_main-header-sub__search-series-body {
  display: none;
  /*  */
  position: absolute;
  top: calc(100% - 14px);
  z-index: 100;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-width: 240px;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-series-body ul li {
  border-bottom: 1px solid #E5E5E5;
}

#pd_main-header-sub__search-series-body ul li:last-child {
  border-bottom: none;
}

#pd_main-header-sub__search-series-body ul li a {
  display: block;
  padding: 8px 15px;
  color: #000;
  font-size: 1.4rem;
  white-space: nowrap;
}

#pd_main-header-sub__search-series-body ul li a figure {
  display: none;
}

/* SP */
@media screen and (max-width: 640px) {
  #pd_main-header-sub__search-series-body {
    position: absolute;
    z-index: 2;
    top: calc(100% - 4px) !important;
    left: 0 !important;
    /*
    margin-top: -1px;
    */
    margin-bottom: 8px;
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: calc(100% + 20px);
    margin: 0px -10px 0;
    padding: 0 10px 10px;
    height: auto;
    border: none;
    min-width: inherit;
    max-height: inherit;
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  }

  #pd_main-header-sub__search-series-body > ul {
    border: 1px solid #CCCCCC;
    border-radius: 3px 0px 3px 3px;
    width: auto;
    height: auto;
    background: #F7F7F7;
    padding: 10px 10px 4px 10px;
  }

  #pd_main-header-sub__search-series-body > ul li {
    border-bottom: none;
    margin-bottom: 6px;
  }

  #pd_main-header-sub__search-series-body > ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 3px;
    padding: 13px 10px;
    font-weight: bold;
    font-size: 1.6rem;
    white-space: normal;
  }

  #pd_main-header-sub__search-series-body > ul li a figure {
    display: block;
    width: 40px;
    line-height: 0;
  }

  #pd_main-header-sub__search-series-body > ul li a span {
    display: block;
    width: calc(100% - 40px);
    padding-left: 12px;
  }

  /* ------------- */
}
// 検索メニューホバー時のオーバーレイのスタイル
.overlay_headernav {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 60px;
  left: 0;
  height: 100vw;
  width: 100%;
  z-index 2
}
