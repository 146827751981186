/*
---
name: pd_m-bigBox
category: module
tag:
  - text
  - img
  - tag
  - icon
---
```html
<a class="pd_m-bigBox" href="#">
    <div class="pd_m-bigBox_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="記事画像"></div>
    <div class="pd_m-bigBox_mid">
        <p class="pd_m-bigBox_kind">記事</p>
        <object class="pd_m-bigBox_favorite"><a><img class="pd_m-bigBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-bigBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
    </div>
    <p class="pd_m-bigBox_ttl">パワーコードレス 「からまないブラシ」は本当にからまない！？実証編【Panasonic NEWS FACTORY】</p>
    <ul class="pd_c-tagList">
        <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
            <object><a href="/test.html">#快適な空間づくり</a></object>
        </li>
        <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
            <object><a href="/test2.html">#家事・暮らし</a></object>
        </li>
        <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
            <object><a href="/test3.html">#タグ</a></object>
        </li>
    </ul>
</a>
```
*/
.pd_m-bigBox
    cursor: pointer
    color: #000
    display block
    padding-bottom: 40px
    width: 478px
    &_img
        height: 478px
        width: 478px
    &_mid
        display: flex
        margin-top: 14px
        justify-content: space-between
        position: relative
        width: 100%
    &_kind
        color: rgba(0,0,0,0.6)
        font-size: 13px
    &_favorite
        height: 24px
        position: absolute
        right: 0
        transform: translateY(-10%)
        width: 24px
        z-index 1
        cursor: pointer
        .pd_m-bigBoxItem_favorite_img-01
            display: block
        .pd_m-bigBoxItem_favorite_img-02
            display: none
        &.pd_favoriteOn
            .pd_m-bigBoxItem_favorite_img-01
                display: none
            .pd_m-bigBoxItem_favorite_img-02
                display: block
        a,
        img
            display: block
            height: 100%
            width: 100%

    &_ttl
        font-size: 22px
        font-weight: 500
        line-height: 1.3636
        margin-top: 13px
    &_tagList
        display: flex
        flex-wrap: wrap
        margin-top: 24px
    &_tagListItem
        border: 1px solid rgba(0,0,0,0.3)
        color: rgba(0,0,0,0.6)
        border-radius: 15px
        font-size 13px
        line-height: 28px
        margin-right: 8px
        padding: 0 11px
@media screen and (max-width 640px)
    .pd_m-bigBox
        width: 100%
        &_img
            display: block
            height: 100%
            width: 100%
        // &_mid
        // &_kind
        &_favorite
            transform: translate(-100%,-10%)
        //     a,
        //     img
        // &_ttl
        &_tagList
            margin-top: 14px
        &_tagListItem
            margin-top: 10px