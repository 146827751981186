/*
---
name: modalBox-ar-sp
category: module
tag: 
  - text
  - img
---
```html
<ul class="pd_m-modalBox-ar-sp">
  <li class="pd_m-modalBox-ar-sp-content">
    <div class="ar-button" id="ar-button1"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></div>
  </li><model-viewer id="ar-model1" class="ar-model" style="display: none" ar-modes="quick-look scene-viewer" reveal="manual" src="data/MODELID.glb" ios-src="data/MODELID.usdz" ar-scale="fixed" ar camera-controls modelCacheSize=0></model-viewer>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
  <li class="pd_m-modalBox-ar-sp-content"><a href="#"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt=""></a></li>
</ul>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-modalBox-ar-sp
  background-color #fff
  padding 80px 2%
  display -webkit-box
  display -ms-flexbox
  display flex
  -ms-flex-wrap wrap
  flex-wrap wrap
  -webkit-box-pack justify
  -ms-flex-pack justify
  justify-content space-between
  .pd_m-modalBox-ar-sp-content
    width 48%
    margin-bottom 16px
    .ar-button
      width 100%
      position relative
      text-align center
      img
        position absolute
        top 0
        left 0
        bottom 0
        right 0
        max-width 100%
        max-height 100%
        margin auto
      model-viewer
        width 100%
    .ar-button:before
      content ""
      display block
      padding-top 100%
    p
      font-size 12px
      margin-top 8px
      line-height 18px
      