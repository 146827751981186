/*
---
name: pd_m-searchOption
category: module
tag:
  - text
  - select
  - btn
---
```html
<div class="pd_m-searchOption pd_m-searchOption-01">
  <div class="pd_m-searchOption_inner">
    <p class="pd_m-searchOption_num">
      検索結果：<span>81</span>件</p>
    <div class="pd_m-searchOption_selectorWrap">
      <div class="pd_m-searchOption_selectorBox">
        <select class="pd_m-searchOption_selector" name="option1">
          <option value="">関連順 </option>
          <option value="">関連順 </option>
          <option value="">関連順 </option>
          <option value="">関連順 </option>
        </select>
      </div>
      <div class="pd_m-searchOption_selectorBox">
        <select class="pd_m-searchOption_selector" name="option2">
          <option value="">50件表示 </option>
          <option value="">50件表示 </option>
          <option value="">50件表示 </option>
          <option value="">50件表示</option>
        </select>
      </div>
      <button class="pd_m-searchOption_thumnail pd_e-hover-01"></button>
      <button class="pd_m-searchOption_airticle pd_e-hover-01"></button>
    </div>
    <button class="pd_c-filterButton pd_c-filterButton-01"><img class="pd_c-filterButton_icon" src="/store/images/component/filterButton.svg" alt="条件を指定して絞り込む">
      <p class="pd_c-filterButton_text">条件を指定して絞り込む</p>
    </button>
  </div>
</div>
```
*/
.pd_m-searchOption
    margin: 40px 0
    &_inner
        align-items: center
        display: flex
        font-size: 15px
        justify-content: space-between
        margin: 0 auto
        width: 976px
    &_num
        span
            font-size: 17px
            font-weight: bold
    &_selectorWrap
        align-items: center
        display: flex
    &_selectorBox
        cursor: pointer
        position: relative
        &::before
            background-image: url('/store/images/module/searchForm_arrow.svg')
            background-repeat: no-repeat
            background-size: contain
            content ""
            display: block
            height: 10px
            position: absolute
            right: 12px
            top: 50%
            transform: translateY(-50%)
            width: 18px
            z-index 1
            pointer-events: none
        // &:hover&::after
        //     background-color rgba(0,0,0,0.05)
        //     content: ""
        //     display: block
        //     height: 100%
        //     right: 0
        //     position: absolute
        //     top: 0
        //     width: 200px
        //     z-index 1
    &_selector
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        align-items: center
        border: 1px solid #707070
        border-radius: 8px
        display: flex
        font-size: 15px
        margin-left: 10px
        height: 52px
        letter-spacing: 1px
        padding: 0 0 0 15px
        width: 200px
        &::-ms-expand
            display: none
    &_thumnail
        background-image: url('/store/images/module/searchOption-panel.svg')
        background-color transparent
        background-size: auto
        background-position: center
        background-repeat: no-repeat
        border: 0px
        margin-left: 30px
        height: 24px
        width: 24px
    &_airticle
        background-image: url('/store/images/module/searchOption-list.svg')
        background-color transparent
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        border: 0px
        margin-left: 20px
        height: 24px
        width: 24px
    &-02
        .pd_m-searchOption_thumnail
            background-image: url('/store/images/module/searchOption-panel-02.svg')
        .pd_m-searchOption_airticle
            background-image: url('/store/images/module/searchOption-list-02.svg')
@media screen and (max-width: 640px)
    .pd_m-searchOption
        &_inner
            flex-wrap: wrap
            padding: 0 15px
            width: 100%
        // &_num
        //     span
        &_selectorWrap
            justify-content: space-between
            margin-top: 20px
            position: relative
            width: 100%
        // &_selectorBox
        //     &:hover&::after
        //         width: 44vw
        //     &::before
        &_selector
            margin-left: 0
            width: 44vw
        &_thumnail
            position: absolute
            right: 44px
            top: -20px
            transform: translateY(-100%)
        &_airticle
            position: absolute
            right: 0
            top: -20px
            transform: translateY(-100%)
        // &-02
        &-03
            .pd_m-searchOption_selectorWrap
                margin-top: 0
                width: auto
