/*
---
name: pd_c-infoEmail-btn
category: component
tag:
  - btn
---
```html
<div class="pd_c-infoEmail-btn">
    <button class="pd_c-infoEmail-btn_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)">
        <p class="pd_c-infoEmail-btn_Text-01"><span class="pd_c-infoEmail-btn_Text-02">お見積もり手続きへ</span></p>
    </button>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;


.pd_c-infoEmail-btn {
    width 100%

    &_btn {
        color #fff
        text-align center
        padding 0
        background #364563
        border none
        display block;
        width 100%
        border-radius 8px
        padding 14px
        margin-top 24px
    }

    &_Text-01 {
        display inline-block
        line-height 1
        background-image url("/store/images/component/infoEmail-btn.svg")
        background-position left 50%
        background-repeat no-repeat
        background-size 19px auto
        &::before {
            content ''
            display inline-block
            height 24px
            vertical-align middle
        }
        > span {
            display inline-block
            padding-top 8px
            margin-left 34px
        }
    }
}
