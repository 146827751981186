/*
---
name: pd_c-selectBox-01
category: component
tag:
  - text
---
```html
  <div class="pd_c-selectBox-01">
      <select class="pd_c-selectBox-01_selector">
          <option value="option1" selected="" disabled="">2021年</option>
          <option value="1">1</option>
          <option value="2">2</option>
      </select>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_c-selectBox-01 {
  position relative
  background-color #fff
  &::before {
    background-image url('/store/images/module/searchForm_arrow.svg')
    background-repeat no-repeat
    background-size contain
    content ""
    display block
    height 10px
    position absolute
    right 12px
    top 50%
    transform translateY(-50%)
    width 18px
    z-index 1
  }
  &_selector {
    background-color #fff
    margin-top: 8px
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    align-items: center
    border: 1px solid #707070
    border-radius: 8px
    display: flex
    font-size: 15px
    height: 52px
    letter-spacing: 1px
    padding: 0 0 0 13px
    width: 100%
    &::-ms-expand {
      display: none
    }
  }
}
