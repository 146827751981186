/*
---
name: pd_c-inputForm-01
category: component
tag:
  - checkBox
---
```html
  <div class="pd_c-inputForm-01">
      <label class="pd_c-inputForm-01_label-01 pd_e-hover-01 pd_e-hover-01--bgGray">
          <input class="pd_c-inputForm-01_input-01 pd_js-c-inputForm-01_input-01" type="checkbox" name="" /><span class="pd_c-inputForm-01_indicator-01 pd_c-inputForm-01_indicator-01--checkbox"></span><span class="pd_c-inputForm-01_text-01">定額利用サービス　スチーマー ナノケア</span>
      </label><span class="pd_e-required-01">必須 </span>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-inputForm-01 {
  position relative
  &_label-01  {
    background-color #fff
    border-radius 8px
    box-shadow 0 3px 6px rgba(0,0,0,.16)
    position relative
    @media screen and (min-width:MIN_POINT) {
      padding 20px 70px 24px 50px
    }
    @media screen and (max-width:MAX_POINT) {
      padding 16px 70px 24px 50px
    }
    display block
  }
  &_input-01 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  &_indicator-01 {
    position absolute
    background-repeat: no-repeat;
    width:24px;
    height: 24px;
    margin-right: 10px;
    @media screen and (min-width:MIN_POINT) {
      top 20px
      left 16px
    }
    @media screen and (max-width:MAX_POINT) {
      top 19px
      left 16px
    }
    &--checkbox {
      background-image: url("/store/images/component/inputForm-01_icon_checkbox.svg");
    }
    &--radio {
      background-image: url("/store/images/component/inputForm-01_icon_radioBtn.svg");
    }
  }
  &_text-01 {
    @media screen and (min-width:MIN_POINT) {
      padding-top 0px;
    }
    @media screen and (max-width:MAX_POINT) {
      padding-top 3px;
    }
  }
  &_indicator-01,
  &_text-01 {
    display inline-block
    vertical-align top
    @media screen and (min-width:MIN_POINT) {
      margin-top .15em
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top .2em
    }
  }

  &_type {
    display: block;
    position: relative;
    width: 100%;
    border: solid 1px #707070;
    border-radius: 8px;
    margin-top: 8px
    padding: 15px;
  }

  &.pd_js-isActive {
    .pd_c-inputForm-01_indicator-01 {
      &--checkbox {
        background-image: url("/store/images/component/inputForm-01_icon_checkbox_checked.svg");
      }
      &--radio {
        background-image: url("/store/images/component/inputForm-01_icon_radioBtn_checked.svg");
      }
    }
  }

  .pd_e-required-01 {
    position absolute
    right 18px
    top 50%
    transform translateY(-50%)
  }

}
