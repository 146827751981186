/*
---
name: pd_c-okBtn
category: component
tag:
  - btn
---
```html
    <button class="pd_c-okBtn pd_e-hover-01">OK</button>
```
*/

.pd_c-okBtn
    background-color: #364563
    box-shadow: 0px 2px 3px rgba(0,0,0,0.3)
    border 0px
    border-radius: 8px
    color: #FFFFFF
    font-size: 15px
    line-height: 34px
    margin-top: 16px
    width: 100%