/*
---
name: pd_c-colorSelect
category: component
tag:
  - checkBox
---
```html
<div class="pd_c-colorSelect">
    <p class="pd_c-colorSelect_ttl">カラー</p>
    <ul class="pd_c-colorSelect_list">
        <li class="pd_c-colorSelect_list_item">
        <div class="pd_c-colorSelect_list_item_a">
            <input type="checkbox" name="color">
        </div>
        </li>
        <li class="pd_c-colorSelect_list_item"> 
        <div class="pd_c-colorSelect_list_item_b">
            <input type="checkbox" name="color">
        </div>
        </li>
        <li class="pd_c-colorSelect_list_item"> 
        <div class="pd_c-colorSelect_list_item_c">
            <input type="checkbox" name="color">
        </div>
        </li>
        <li class="pd_c-colorSelect_list_item"> 
        <div class="pd_c-colorSelect_list_item_d">
            <input type="checkbox" name="color">
        </div>
        </li>
        <li class="pd_c-colorSelect_list_item"> 
        <div class="pd_c-colorSelect_list_item_e">
            <input type="checkbox" name="color">
        </div>
        </li>
        <li class="pd_c-colorSelect_list_item"> 
        <div class="pd_c-colorSelect_list_item_f">
            <input type="checkbox" name="color">
        </div>
        </li>
    </ul>
</div>

```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;


.pd_c-colorSelect
    &_ttl-02
      font-size 16px
      line-height 1.5
      font-weight bold
      span
        font-weight normal
        margin-left 1em
    &_list-02
      display flex
      margin-left -10px
      flex-wrap wrap
      @media screen and (max-width 640px)
        flex-wrap nowrap
        overflow scroll

    &_list_item-02
      width 92px
      height 78px
      margin-left 10px
      margin-top 10px
      @media screen and (max-width 640px)
        min-width 92px
      a 
        width 92px
        height 78px
        display flex
        justify-content center
        align-items center
        img
          max-width 92px
          max-height 100%

    margin-top 30px
    &_ttl 
        font-size 15px
    &_list
        width 270px
        margin-top 10px
        margin-left 25px
        .slick-arrow
            background-color: transparent;
            background-repeat: no-repeat;
            position: absolute;
            background-image: url(/store/images/component/sliderBox-01_icon_arrow_02.svg);
            background-size: 8px auto;
            background-position: center center;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width 30px
            height 30px
            border none 
            top 48%
        .slick-prev
            left -33px
            -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
            &.slick-disabled
                opacity 0
        .slick-next
            right -25px
            &.slick-disabled
                opacity 0
        
        &_item 
            position relative
            cursor pointer
            padding 1px 1px
            width 38px !important
            height 38px
            &_a 
                width 40px
                height 40px
                background-color #fff
                border 2px solid #E6E6E6
            &_b
                width 40px
                height 40px
                background-color #C3C3C3
                border 2px solid #E6E6E6
            &_c
                width 40px
                height 40px
                background-color #D87F7F
                border 2px solid #E6E6E6
            &_d
                width 40px
                height 40px
                background-color #FFAD46
                border 2px solid #E6E6E6
            &_e
                width 40px
                height 40px
                background-color #000
                border 2px solid #E6E6E6
            &_f
                width 40px
                height 40px
                background-color #007BFF
                border 2px solid #E6E6E6
            &.is-current
                &::after
                    content ""
                    display block
                    width 100%
                    height 100%
                    border 2px solid #364563
                    position absolute
                    left 0
                    top 0
                    z-index 1
            input 
                display none

