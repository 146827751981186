// mixinがcommon.stylから継承されています
// mixinには赤色の設定をREDでいれたり初期設定をしています
// 適宜使いやすいように更新してください

//pd
@import "../../../_global/com/css/pd/common.styl"
@import "../../../_global/com/css/pd/product/search-impl.styl"
@import "../../../_global/com/css/pd/common/footer_nav.styl"
@import "../../../_global/com/css/pd/common/header_simple.styl"

//- 重複してるcss-----------------------------------------------------------------
@import 'slick.styl'
@import 'photoswipe.styl'
@import 'magnific-popup.styl'
@import 'https://fonts.gstatic.com'
@import 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap'

// block
@import "../../../_global/com/css/block/categorySearch"
@import "../../../_global/com/css/block/detail-01"
@import "../../../_global/com/css/block/detail-02"
@import "../../../_global/com/css/block/detail-03"
@import "../../../_global/com/css/block/emphasis-01"
@import "../../../_global/com/css/block/eventsDetail"
@import "../../../_global/com/css/block/eventsDetailModal"
@import "../../../_global/com/css/block/eventsDetailModal01"
@import "../../../_global/com/css/block/eventsFirstView"
@import "../../../_global/com/css/block/flowStep1"
@import "../../../_global/com/css/block/flowStep2"
@import "../../../_global/com/css/block/flowStep3"
@import "../../../_global/com/css/block/guest_mypage-01"
@import "../../../_global/com/css/block/mv"
@import "../../../_global/com/css/block/news"
@import "../../../_global/com/css/block/plan-01"
@import "../../../_global/com/css/block/searchResultList"
@import "../../../_global/com/css/block/searchResultPanel"
@import "../../../_global/com/css/block/searchResultTab"
@import "../../../_global/com/css/block/searchResultWrap"
@import "../../../_global/com/css/block/section-01"
@import "../../../_global/com/css/block/status-01"
@import "../../../_global/com/css/block/tabArea"
@import "../../../_global/com/css/block/tabDetail-02"
@import "../../../_global/com/css/block/tabDetail"
@import "../../../_global/com/css/block/textAbout"
@import "../../../_global/com/css/block/userInfo-01"
@import "../../../_global/com/css/block/freeArea"
@import "../../../_global/com/css/block/plan-02"
@import "../../../_global/com/css/block/questionnaire-01"
@import "../../../_global/com/css/block/honin"


// component
@import "../../../_global/com/css/component/attention"
@import "../../../_global/com/css/component/borderText"
@import "../../../_global/com/css/component/breadcrumb"
@import "../../../_global/com/css/component/btn-01"
@import "../../../_global/com/css/component/btn-02"
@import "../../../_global/com/css/component/btn-03"
@import "../../../_global/com/css/component/btn-04"
@import "../../../_global/com/css/component/Buy-in-bulk"
@import "../../../_global/com/css/component/calendar"
@import "../../../_global/com/css/component/cart-01"
@import "../../../_global/com/css/component/checkBox-01"
@import "../../../_global/com/css/component/checkBox-02"
@import "../../../_global/com/css/component/checkBoxList"
@import "../../../_global/com/css/component/checkTextArea-01"
@import "../../../_global/com/css/component/coin-01"
@import "../../../_global/com/css/component/colorSelect"
@import "../../../_global/com/css/component/dataPanel-white"
@import "../../../_global/com/css/component/dateArea"
@import "../../../_global/com/css/component/discontinued-01"
@import "../../../_global/com/css/component/emphasis-01"
@import "../../../_global/com/css/component/entryMember"
@import "../../../_global/com/css/component/errormessage"
@import "../../../_global/com/css/component/eventsSubTitle"
@import "../../../_global/com/css/component/favoriteBtn-01"
@import "../../../_global/com/css/component/favoriteBtn-02"
@import "../../../_global/com/css/component/figure-01"
@import "../../../_global/com/css/component/filterButton"
@import "../../../_global/com/css/component/filteringItem-01"
@import "../../../_global/com/css/component/group-01"
@import "../../../_global/com/css/component/headingLv1-01"
@import "../../../_global/com/css/component/headingLv2-01"
@import "../../../_global/com/css/component/hr-01"
@import "../../../_global/com/css/component/imageSet-01"
@import "../../../_global/com/css/component/imageSet-02"
@import "../../../_global/com/css/component/infoEmail-btn"
@import "../../../_global/com/css/component/inputForm-01"
@import "../../../_global/com/css/component/inputText-01"
@import "../../../_global/com/css/component/itemImage-01"
@import "../../../_global/com/css/component/itemImage-02"
@import "../../../_global/com/css/component/itemImage-03"
@import "../../../_global/com/css/component/largeBtn-01"
@import "../../../_global/com/css/component/linkText-01"
@import "../../../_global/com/css/component/mailIconBtn"
@import "../../../_global/com/css/component/normalList-01"
@import "../../../_global/com/css/component/notes-01"
@import "../../../_global/com/css/component/notes-02"
@import "../../../_global/com/css/component/notes-03"
@import "../../../_global/com/css/component/no-stock-btn"
@import "../../../_global/com/css/component/okBtn-01"
@import "../../../_global/com/css/component/option-01"
@import "../../../_global/com/css/component/panelItem-checkbox-addCart"
@import "../../../_global/com/css/component/panelItem-tag"
@import "../../../_global/com/css/component/pdfLink"
@import "../../../_global/com/css/component/price-01"
@import "../../../_global/com/css/component/priceChoice-01"
@import "../../../_global/com/css/component/productListItem"
@import "../../../_global/com/css/component/productPanelItem-guideArea"
@import "../../../_global/com/css/component/productListItem_modelNum"
@import "../../../_global/com/css/component/productPanelItem"
@import "../../../_global/com/css/component/quote-btn"
@import "../../../_global/com/css/component/radioBtn-01"
@import "../../../_global/com/css/component/radioBtn-02"
@import "../../../_global/com/css/component/rank-01"
@import "../../../_global/com/css/component/released-01"
@import "../../../_global/com/css/component/requiredTitle"
@import "../../../_global/com/css/component/row-01"
@import "../../../_global/com/css/component/row-02"
@import "../../../_global/com/css/component/row-03"
@import "../../../_global/com/css/component/searchListItem"
@import "../../../_global/com/css/component/selectBox-01"
@import "../../../_global/com/css/component/sideAcc-01"
@import "../../../_global/com/css/component/sizeSelect"
@import "../../../_global/com/css/component/sliderBox-01"
@import "../../../_global/com/css/component/sliderBox-02"
@import "../../../_global/com/css/component/stocked-01"
@import "../../../_global/com/css/component/store_name"
@import "../../../_global/com/css/component/table-01"
@import "../../../_global/com/css/component/tag-01"
@import "../../../_global/com/css/component/tagList"
@import "../../../_global/com/css/component/textRed"
@import "../../../_global/com/css/component/viewer-01"
@import "../../../_global/com/css/component/youtubeMovie-01"
@import "../../../_global/com/css/component/btn-02-type-totop"
@import "../../../_global/com/css/component/planSelect"
@import "../../../_global/com/css/component/title"
@import "../../../_global/com/css/component/enquete"


// module
@import "../../../_global/com/css/module/accordion-01"
@import "../../../_global/com/css/module/appeal"
@import "../../../_global/com/css/module/attention"
@import "../../../_global/com/css/module/bigBox"
@import "../../../_global/com/css/module/breakdown-01"
@import "../../../_global/com/css/module/breakdown-02"
@import "../../../_global/com/css/module/campaign"
@import "../../../_global/com/css/module/cart-01"
@import "../../../_global/com/css/module/confFlow"
@import "../../../_global/com/css/module/contentSubText-01"
@import "../../../_global/com/css/module/contentTtl-01"
@import "../../../_global/com/css/module/contract-01"
@import "../../../_global/com/css/module/eventBnr"
@import "../../../_global/com/css/module/eventsAccess"
@import "../../../_global/com/css/module/eventsInChange"
@import "../../../_global/com/css/module/eventsOverview"
@import "../../../_global/com/css/module/eventsPast"
@import "../../../_global/com/css/module/eventsProduct"
@import "../../../_global/com/css/module/eventsProduct01"
@import "../../../_global/com/css/module/eventsRelated"
@import "../../../_global/com/css/module/eventsReport"
@import "../../../_global/com/css/module/eventsRequest"
@import "../../../_global/com/css/module/eventsSimultaneous"
@import "../../../_global/com/css/module/fieldset-01"
@import "../../../_global/com/css/module/filteringCondition"
@import "../../../_global/com/css/module/filterModal"
@import "../../../_global/com/css/module/flowBar"
@import "../../../_global/com/css/module/form-01"
@import "../../../_global/com/css/module/form-02"
@import "../../../_global/com/css/module/itemImage-01"
@import "../../../_global/com/css/module/itemImages-01"
@import "../../../_global/com/css/module/keywords-01"
@import "../../../_global/com/css/module/linkList-01"
@import "../../../_global/com/css/module/modalBox-01"
@import "../../../_global/com/css/module/modalBox-02"
@import "../../../_global/com/css/module/modalBox-03"
@import "../../../_global/com/css/module/modalBox-ar-pc"
@import "../../../_global/com/css/module/modalBox-ar-sp"
@import "../../../_global/com/css/module/notes-01"
@import "../../../_global/com/css/module/pageHistory"
@import "../../../_global/com/css/module/pagenation"
@import "../../../_global/com/css/module/plan-01"
@import "../../../_global/com/css/module/productHistory"
@import "../../../_global/com/css/module/productHistory-02"
@import "../../../_global/com/css/module/radioBtnList-01"
@import "../../../_global/com/css/module/radioWrap"
@import "../../../_global/com/css/module/recommend"
@import "../../../_global/com/css/module/requestCalendar"
@import "../../../_global/com/css/module/requestPanel"
@import "../../../_global/com/css/module/requestPanel01"
@import "../../../_global/com/css/module/requestTop"
@import "../../../_global/com/css/module/searchForm-tag"
@import "../../../_global/com/css/module/searchForm"
@import "../../../_global/com/css/module/searchOption"
@import "../../../_global/com/css/module/searchResultContent"
@import "../../../_global/com/css/module/searchResultTagList"
@import "../../../_global/com/css/module/searchSidebar"
@import "../../../_global/com/css/module/searchSidebar-01"
@import "../../../_global/com/css/module/selectGroup-01"
@import "../../../_global/com/css/module/slider-01"
@import "../../../_global/com/css/module/smallBox"
@import "../../../_global/com/css/module/smallBoxItem"
@import "../../../_global/com/css/module/submit-01"
@import "../../../_global/com/css/module/tabList-01"
@import "../../../_global/com/css/module/tabList-02"
@import "../../../_global/com/css/module/tabList-03"
@import "../../../_global/com/css/module/userInfo-01"


// element
@import "../../../_global/com/css/element/bnr-01"
@import "../../../_global/com/css/element/bnrText-01"
@import "../../../_global/com/css/element/circleIcon-01"
@import "../../../_global/com/css/element/date-01"
@import "../../../_global/com/css/element/hover-01"
@import "../../../_global/com/css/element/label-gray"
@import "../../../_global/com/css/element/numberIcon-01"
@import "../../../_global/com/css/element/panelItem-01"
@import "../../../_global/com/css/element/required-01"
@import "../../../_global/com/css/element/required-02"
@import "../../../_global/com/css/element/required-03"
@import "../../../_global/com/css/element/releaseDate"
@import "../../../_global/com/css/element/smallTag-01"
@import "../../../_global/com/css/element/smallTag-02"
@import "../../../_global/com/css/element/centerText-01"
@import "../../../_global/com/css/element/planName"
@import "../../../_global/com/css/element/planView"
@import "../../../_global/com/css/element/planTag-01"
@import "../../../_global/com/css/element/planTag-02"
@import "../../../_global/com/css/element/smallTag-03"
@import "../../../_global/com/css/element/money-01"
@import "../../../_global/com/css/element/money-02"
@import "../../../_global/com/css/element/honin-number"
@import "../../../_global/com/css/element/honin-box-text"












// 例外・いつか分類する
.eccom-contents > .pd_m-modalBox-01 {
  margin: 0;
  padding: 0;
  height: 0;
  overflow: hidden;
  padding: 0
}
.eccom-contents > .pd_m-modalBox-01#modalBox-eventForm {
  padding 0
}
.pd_e-date-01 {
  margin-top: 8px;
  color: rgba(0,0,0,0.9);
  font-size: 13px;
  line-height: 1.538461538461539;
}


//- search-css---------------------------------------------------------------------------
//- search-02-css-----------------------------------------------------------------------
//- search-03-css-------------------------------------------------------------------------
//- search-04-css-----------------------------------------------------------------------
//- search-tag-css---------------------------------------------------------------------
.pd_b-searchResultTab a
  position: relative
  &:hover::after
    background-color rgba(255,255,255,0.1)
    content: ""
    display: block
    height: 100%
    left: 0
    position: absolute
    pointer-events: none
    top: 0
    width: 100%
    z-index 1
