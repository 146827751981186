/*
---
name: pd_m-itemImages-01
category: module
tag: 
  - img 
  - slider
---
```html
<div class="pd_m-itemImages-01">
                        <div class="pd_c-sliderBox-01">
                            <div class="pd_c-sliderBox-01_list pd_c-sliderBox-01_list--top pd_js-productSlider">
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d4070/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d40dd/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d40dd/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d4070/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d40dd/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d40dd/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d4070/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d40dd/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d40dd/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d4070/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d40dd/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d40dd/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                            </div>
                            <div class="pd_c-sliderBox-01_thumbnails">
                                <ul class="pd_c-sliderBox-01_thumbnailList pd_c-sliderBox-01_thumbnailList--top">
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/3d40dd/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/3d40dd/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/3d40dd/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/3d40dd/ffffff/600x600.png" alt="" /></li>
                                </ul>
                            </div>
                        </div>
                        <div class="pd_c-viewer-01">
                            <div class="pd_c-viewer-01_inner">
                                <div class="pd_c-viewer-01_leftCol"><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-pc pd_js-popup-modal" href="#modalBox-viewer-360">
                                        <div class="pd_c-viewer-01_group-01">
                                            <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_360.svg" /></div>
                                            <div class="pd_c-viewer-01_texts">
                                                <p class="pd_c-viewer-01_text-01">360&deg;ビュー</p>
                                                <p class="pd_c-viewer-01_text-02">商品を様々な角度から自由に見ることができます。</p>
                                            </div>
                                        </div>
                                    </a><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-sp pd_js-popup-modal" href="#modalBox-viewer-3602">
                                        <div class="pd_c-viewer-01_group-01">
                                            <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_360.svg" /></div>
                                            <div class="pd_c-viewer-01_texts">
                                                <p class="pd_c-viewer-01_text-01">360&deg;ビュー</p>
                                                <p class="pd_c-viewer-01_text-02">商品を様々な角度から自由に見ることができます。</p>
                                            </div>
                                        </div>
                                    </a></div>
                                <div class="pd_c-viewer-01_rightCol"><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-pc pd_js-popup-modal" href="#modalBox-viewer-AR">
                                        <div class="pd_c-viewer-01_group-01">
                                            <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_ar.svg" /></div>
                                            <div class="pd_c-viewer-01_texts">
                                                <p class="pd_c-viewer-01_text-01">AR設置シミュレーション</p>
                                                <p class="pd_c-viewer-01_text-02">商品を部屋に設置したイメージをご確認いただけます。</p>
                                            </div>
                                        </div>
                                    </a><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-sp pd_js-popup-modal" href="#modalBox-viewer-AR2">
                                        <div class="pd_c-viewer-01_group-01">
                                            <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_ar.svg" /></div>
                                            <div class="pd_c-viewer-01_texts">
                                                <p class="pd_c-viewer-01_text-01">AR設置シミュレーション</p>
                                                <p class="pd_c-viewer-01_text-02">商品を部屋に設置したイメージをご確認いただけます。</p>
                                            </div>
                                        </div>
                                    </a></div>
                            </div>
                            <p class="pd_c-viewer-text"><a href="">詳しいご利用方法はこちら</a></p>
                        </div>
                        <div class="pd_m-modalBox-02 is-pc" id="modalBox-viewer-360"><img src="http://placehold.jp/666666/ffffff/1640x1230.png?text=360°ビュー" alt="" /></div>
                        <div class="pd_m-modalBox-02 is-sp" id="modalBox-viewer-3602"><img src="http://placehold.jp/666666/ffffff/750x1624.png?text=仕様確定後再調整" alt="" /></div>
                        <div class="pd_m-modalBox-02 is-pc" id="modalBox-viewer-AR"><img src="http://placehold.jp/666666/ffffff/1640x1230.png?text=AR" alt="" /></div>
                        <div class="pd_m-modalBox-02 is-sp" id="modalBox-viewer-AR2"><img src="http://placehold.jp/666666/ffffff/750x1624.png?text=ARSP" alt="" /></div>
                        <div class="pd_c-colorSelect">
                            <p class="pd_c-colorSelect_ttl-02">カラー選択
                            </p>
                            <ul class="pd_c-colorSelect_list-02">
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                            </ul>
                        </div>
                        <div class="pd_c-colorSelect">
                            <p class="pd_c-colorSelect_ttl-02">サイズ選択<span>: 25</span>
                            </p>
                            <ul class="pd_c-colorSelect_list-02">
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                            </ul>
                        </div>
                        <div class="pd_c-colorSelect">
                            <p class="pd_c-colorSelect_ttl-02">サイズ選択<span>: 26</span>
                            </p>
                            <ul class="pd_c-colorSelect_list-02">
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                            </ul>
                        </div>
                    </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-itemImages-01 {
  .pd_c-viewer-01 {
    @media screen and (min-width:MIN_POINT) {
      margin-top 32px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
}
