/*
---
name: pd_c-rank-01
category: component
tag:
  - tag
---
```html
  <div class="pd_c-rank-01 pd_c-rank-01--gold">ゴールド </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-rank-01 {
  background-position 0 center;
  background-size 22px auto;
  background-repeat no-repeat;
  padding-left 35px
  font-size 15px
  &--gold {
    background-image url("/store/images/component/gold-01_icon_01.svg")
  }
  &--bronze {
    background-image url("/store/images/component/icon_bronze.svg")
  }
  &--entry {
    background-image url("/store/images/common/icon_entry_e.svg")
  }
  &--silver {
    background-image url("/store/images/component/icon_silver.svg")
  }
}
