@charset 'UTF-8'
@import '../../../_mixin.styl';


.eccom-cart-mark:empty {
  display: none;
}

body:not([data-eccom-login]) .eccom-login, body:not([data-eccom-login]) .eccom-logout {
  visibility: hidden;
}

body[data-eccom-login='0'] .eccom-login {
  display: none !important;
}

body[data-eccom-login='1'] .eccom-logout {
  display: none !important;
}

body:not([data-eccom-login]) .pd_main-header-hum-menu__btnArea {
  display: none;
}

/* Body setting
------------------------------------------------------------------------------- */
html {
  font-size: 62.5%;
}
body {
  background: #434343;
 /* font-family: noto-sans-cjk-jp,'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif; */ 
}

#page {
  max-width: inherit;
  background: #fff;
}

main {
 /* font-family: noto-sans-cjk-jp, 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'ヒラギノ角ゴ W3', 'メイリオ', Meiryo, Osaka, 'sans-serif'; */ 
  color: #000000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  overflow-y: auto;
  overflow-x: hidden
  word-break: break-all;
}
main * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#mobile-navigation {
  display: none;
}
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, main {
  /*  font-family: "Noto Sans JP", 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'ヒラギノ角ゴ W3', 'メイリオ', Meiryo, Osaka, 'sans-serif'; */ 
  }
  /* IE11 ONLY */
}
#search-normal {
  display: none;
}
/* SP */
@media screen and (max-width: 640px) {
  body {
    padding-bottom: env(safe-area-inset-bottom);
  }

  #page {
    min-width: inherit;
  }

  main {
    min-width: inherit;
  }

  #globalheader {
    min-height: 40px;
  }

  #globalheader #brandlogo, #globalheader #brandlogo a {
    height: 40px;
  }

  #globalheader #brandlogo img {
    display: none;
  }

  #globalheader #brandlogo img.rwd {
    display: block;
    height: 40px;
  }

  #globalheader-nav {
    display: none;
  }

  #search-normal {
    display: none;
  }

  /* ------------- */
}

/* common style
------------------------------------------------------------------------------- */
.content {
  background: #fff;
  overflow: hidden;
}

.pd_content__inner {
  position: relative;
  max-width: 980px;
  padding: 0 2px 0 2px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
select::-ms-expand {
    display: none;
}
img {
  max-width: 100%;
  height: auto;
}

td img,
th img {
  max-width: inherit;
  height: inherit;
}
@media screen and (max-width: 640px) {
  .pd_content__inner {
    padding: 0 10px;
  }
}

.pd_content__description {
  margin-bottom: 20px;
  font-size: 1.6rem;
}

.pd_content__description span {
  font-size: 1.4rem;
}
.pd_img-center
  display: flex
  align-items: center
  justify-content: center
  img
    height: auto
    width: auto
label,button
  cursor: pointer
[class*="btn"][disabled] {
  background: #b4b4b4 !important;
  color: white !important;
  border: none !important;
  font-weight: normal !important;
  cursor: not-allowed !important;
}
/* bread-crumb　パンくず
------------------------------------------------------------------------------- */
.pd_bread-crumb {
  font-size: 1.2rem;
  margin: 20px 0 35px;
}

.pd_bread-crumb ul {
  margin: 0 auto;
  max-width: 980px;
  padding: 0 2px 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #000000;
  line-height: 1.1;
}

.pd_bread-crumb ul li::before {
  content: ">";
  margin: 0 8px;
}

.pd_bread-crumb ul li:first-child::before {
  content: "";
  margin: 0;
}

.pd_bread-crumb ul a {
  color: rgba(0,0,0,0.3);
  text-decoration: none;
}
.is-pc
  display block
.is-sp
  display none
.is-none
  display none

@media screen and (max-width: 640px)
  .is-pc
    display none
  .is-sp
    display block
/* SP  */
@media screen and (max-width: 640px) {
  .pd_bread-crumb {
    display: none;
    margin: 0px
  }
  .pd_bread-crumb ul{
    align-items: center;
    border-top: 1px solid rgba(0,0,0,0.2);
    display: flex;
    font-size: 15px;
    height: 60px;
    padding: 10px 15px 0 20px;
  }
  .pd_bread-crumb li {
    padding-right: 20px;
    position: relative;
    margin-bottom: 10px;
  }
  .pd_bread-crumb ul li:first-child::before{
    display: none;
  }
  .pd_bread-crumb ul li::before{
    background-image: url('/store/images/common/bread-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
    display: block;
    height: 13px;
    left: -20px;
    top: 50%;
    transform:translateY(-50%);
    position: absolute;
    width: 8px;
  }

// margin-top:
.pd_margin-top_10{
    margin-top: 10px;
}
// margin-bottom
  .pd_margin-bottom_10{
    margin-bottom: 10px;
  }
}

.c_black{
  color: rgba(0,0,0,0.9);
}

