/*
---
name: pd_m-linkList-01
category: module
tag:
  - text
  - icon
  - img
---
```html
<div class="pd_m-linkList-01">
    <ul class="pd_m-linkList-01_list">
        <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--features pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">商品の特長</span></a></li>
        <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--spec pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">詳細スペック</span></a></li>
        <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--compare pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">商品を比較する</span></a></li>
        <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--extra pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">消耗品・別売品</span></a></li>
    </ul>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-linkList-01 {
  @media screen and (min-width:MIN_POINT) {
    margin-top 40px
  }
  @media screen and (max-width:MAX_POINT) {
    margin-top 23px
  }
  // &_list { }
  &_listItem {
    box-sizing: border-box;
    display block
    margin-bottom 20px
  }

  &_anchor {
    border: solid 1px #364563
    border-radius: 8px;
    display: block;
    text-align center;
    position relative
    padding: 75px 15px 20px;
    box-shadow 0 3px 6px rgba(0,0,0,.16)
    &::before {
      content: '';
      position absolute;
      background-repeat: no-repeat;
      background-size: 100% auto;
      transform: translateX(-50%);
      width 44px
      height 44px
      position absolute
      left 50%
      top: 20px
    }
    &--features::before {
      background: url("/store/images/module/linkList-01_icon_features.svg")
    }
    &--spec::before {
      background: url("/store/images/module/linkList-01_icon_spec.svg")
    }
    &--compare::before {
      background: url("/store/images/module/linkList-01_icon_compare.svg")
    }
    &--extra::before {
      background: url("/store/images/module/linkList-01_icon_extra.svg")
    }
  }

  &_linkText {
    color: #364563
    font-weight: bold;
    @media screen and (min-width:MIN_POINT) {
      font-size: 17px;
      margin-top 10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }

}
