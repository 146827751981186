/*
---
name: pd_c-smallTag_bkg
category: component
tag:
  - tag
---
```html
<ul class="pd_c-smallTag_bkg_gray">
    <li class="pd_c-smallTag-gray">受付中</li>
    <li class="pd_c-smallTag-gray">定額</li>
    <li class="pd_c-smallTag-gray">近日発売</li>
    <li class="pd_c-smallTag-gray">セット商品</li>
</ul>
<ul class="pd_c-smallTag_bkg_orange">
    <li class="pd_c-smallTag-orange">NEW</li>
    <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
    <li class="pd_c-smallTag-orange">1000コイン進呈</li>
    <li class="pd_c-smallTag-orange">プレゼント付</li>
    <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
    <li class="pd_c-smallTag-orange">予約商品</li>
    <li class="pd_c-smallTag-orange">特別価格</li>
</ul>
```
*/
MAX_POINT = 640px;

MIN_POINT = MAX_POINT+1px;

.pd_c-smallTag_bkg_gray{
    display: flex
    flex-wrap: wrap;
    margin-top: 8px;
 }

.pd_c-smallTag-gray {
  display inline-block
  background-color #E6E6E6
  border-radius 4px
  padding 3px 8px
  line-height 1
  font-size 13px
  color rgba(0,0,0,.9)
  margin-top 4px
  margin: 0 8px 8px 0;
}

.pd_c-smallTag_bkg_orange{
    display: flex
    flex-wrap: wrap;
 }

.pd_c-smallTag-orange {
    display inline-block
    background-color #FF8833
    border-radius 4px
    padding 3px 8px
    line-height 1
    font-size 13px
    color #fff
    margin: 0 8px 8px 0;
}