/*
---
name: pd_m-appeal
category: module
tag:
  - text
---
```html
<div class="pd_m-appeal">
  <div class="pd_m-appeal_inner">
    <ul class="pd_m-appeal_list"> 
      <li class="pd_m-appeal_item"><a class="pd_m-appeal_anchor pd_e-hover-01" href="/"><strong>5/26 - 5/31 までタイムセール実施中！</strong></a><a class="pd_m-appeal_detail pd_e-hover-01" href="/">詳細はこちら</a></li>
      <li class="pd_m-appeal_item"><a class="pd_m-appeal_anchor pd_e-hover-01" href="/"><strong>5/26 - 5/31 までタイムセール実施中！</strong></a><a class="pd_m-appeal_detail pd_e-hover-01" href="/">詳細はこちら</a></li>
      <li class="pd_m-appeal_item"><a class="pd_m-appeal_anchor pd_e-hover-01" href="/"><strong>5/26 - 5/31 までタイムセール実施中！</strong></a><a class="pd_m-appeal_detail pd_e-hover-01" href="/">詳細はこちら</a></li>
    </ul>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_m-appeal {
  background: #F58A3D;
  overflow: hidden;
  .js & {
    height: 50px;
  }
  .js &--js-slick_initialized {
    height: auto;
  }
  @media screen and (min-width:MIN_POINT) {
    padding: 9px 0;
  }
  @media screen and (max-width:MAX_POINT) {
    padding: 16px 0;
  }
  &_inner {
    max-width: 980px;
    @media screen and (min-width:MIN_POINT) {
      margin-right: auto;
      margin-left auto;
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right: 15px;
      margin-left: 15px;
    }
  }
  &_list {
    position: relative;
    padding-right: 13px;
    padding-left: 13px;
    overflow: hidden;
  }
  &_item {
    float: left;
    color: #fff;
    width: 100%;
    text-align: center;
    .js & {
      margin-top: 100px;
    }
    .js .pd_top_appeal--js-slick_initialized & {
      margin-top: 0;
    }
    @media screen and (min-width:MIN_POINT) {
      font-size: 17px;
    }
    @media screen and (max-width:MAX_POINT) {
      font-size: 15px;
      padding-top: 2px;
      line-height: 1;
    }
  }
  &_anchor {
    vertical-align: middle;
    &:link,
    &:hover,
    &:visited,
    &:visited:hover,
    &:active,
    &:focus {
      color: #fff;
    }
  }
  &_detail {
    vertical-align: middle;
    @media screen and (min-width:MIN_POINT) {
      display: inline-block;
      border: solid 1px #fff;
      border-radius: 16px;
      padding: 8px 31px 10px 21px;
      position: relative;
      margin-left: 18px;
      font-size: 13px;
      line-height: 1;
      &::after {
        position: absolute;
        right: 12px;
        top: 50%;
        content: '';
        display: block;
        width: 4px;
        height: 8px;
        background: url('/store/com/images/block/appeal_icon_arrow_right_white.svg') no-repeat;
        background-size: 100% auto;
        transform: translateY(-50%);
      }
      &:link,
      &:hover,
      &:visited,
      &:visited:hover,
      &:active,
      &:focus {
        color: #fff;
      }
    }
    @media screen and (max-width:MAX_POINT) {
      display: none;
    }
  }
  .slick-arrow {
    padding: 0;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    text-indent:- 9999px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      opacity: .9;
    }
    @media screen and (min-width:MIN_POINT) {
      width: 20px;
      height: 26px;
      background-size: 8px 13px;
    }
    @media screen and (max-width:MAX_POINT) {
      width: 20px;
      height: 26px;
      background-size: 8px 13px;
    }
  }
  .slick-prev {
    z-index: 2;
    background-image: url('/store/com/images/block/appeal_icon_arrow_left_black.svg');
    left: 0;
    background-position : left center;
  }
  .slick-next {
    z-index: 2;
    background-image: url('/store/com/images/block/appeal_icon_arrow_right_black.svg');
    right: 0;
    background-position: right center;
  }
}
