/*
---
name: pd_m-smallBox
category: module
tag:
  - text
  - img
  - icon
  - tag
---
```html
<li class="pd_b-tabDetail_listItem pd_circleImg"><a class="pd_m-smallBox" href="/">
                            <div class="pd_m-smallBoxItem">
                              <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                              <div class="pd_m-smallBoxItem_mid">
                                <p class="pd_m-smallBoxItem_kind">商品</p>
                                <div class="pd_m-smallBoxItem_favoriteWrap">
                                  <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                </div>
                              </div>
                                                        <ul class="pd_c-smallTag_bkg_gray">
                                                          <li class="pd_c-smallTag-gray">受付中</li>
                                                          <li class="pd_c-smallTag-gray">定額</li>
                                                          <li class="pd_c-smallTag-gray">近日発売</li>
                                                          <li class="pd_c-smallTag-gray">セット商品</li>
                                                          <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                        </ul>
                                                        <ul class="pd_c-smallTag_bkg_orange">
                                                          <li class="pd_c-smallTag-orange">NEW</li>
                                                          <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                          <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                          <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                          <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                          <li class="pd_c-smallTag-orange">予約商品</li>
                                                          <li class="pd_c-smallTag-orange">特別価格</li>
                                                        </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                            </div>
                            <p class="pd_m-smallBoxItem_ttl">液晶テレビ 55インチ</p>
    <div class="pd_c-productPanelItem">
      <p class="pd_c-productPanelItem_modelNum">TH-65GZ1800</p>
      <p class="pd_c-productPanelItem_modelNum-02">TH-65GZ1800後継機</p>
      <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
      <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
      <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
      </div>
      <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
      <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
      <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
      <div class="pd_c-productPanelItem_guideArea-01">
        <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
        <p class="pd_c-productPanelItem_note">※枠不要</p>
        <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
      </div>
    </div>
    <ul class="pd_c-tagList">
      <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
        <object><a href="">#食・レシピ</a></object>
      </li>
      <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
        <object><a href="">#タグ</a></object>
      </li>
    </ul>
  </a>
</li>
```
*/
// ここからSmallBox全体のスタイル
.pd_m-smallBox
    color: #000
    cursor: pointer
    display: block
    margin-right: 10px;
    margin-left: 10px;
    padding-bottom: 40px
    position: relative
    width: 229px
    &:hover&::after
        background-color rgba(255,255,255,0.1)
        content: ""
        display: block
        height: 100%
        left: 0
        position: absolute
        top: 0
        width: 100%
@media screen and (max-width: 640px)
    .pd_m-smallBox
        margin: 0
        width: 44vw
