/*
---
name: pd_m-filteringCondition
category: module
tag:
  - text
  - tag
---
```html
<div class="pd_m-filteringCondition">
                      <p class="pd_m-filteringCondition_text">絞り込み条件：</p>
                      <ul class="pd_m-filteringCondition_list">
                                                <li class="pd_c-filteringItem">
                                                  <p class="pd_c-filteringItem_text">映像</p>
                                                  <button class="pd_c-filteringItem_button pd_e-hover-01 pd_e-hover-01--bgDarkGrayk">×</button>
                                                </li>
                                                <li class="pd_c-filteringItem">
                                                  <p class="pd_c-filteringItem_text">テレビ</p>
                                                  <button class="pd_c-filteringItem_button pd_e-hover-01 pd_e-hover-01--bgDarkGrayk">×</button>
                                                </li>
                                                <li class="pd_c-filteringItem">
                                                  <p class="pd_c-filteringItem_text">有機ELテレビ</p>
                                                  <button class="pd_c-filteringItem_button pd_e-hover-01 pd_e-hover-01--bgDarkGrayk">×</button>
                                                </li>
                                                <li class="pd_c-filteringItem">
                                                  <p class="pd_c-filteringItem_text">4K</p>
                                                  <button class="pd_c-filteringItem_button pd_e-hover-01 pd_e-hover-01--bgDarkGrayk">×</button>
                                                </li>
                                                <li class="pd_c-filteringItem">
                                                  <p class="pd_c-filteringItem_text">55インチ</p>
                                                  <button class="pd_c-filteringItem_button pd_e-hover-01 pd_e-hover-01--bgDarkGrayk">×</button>
                                                </li>
                                                <li class="pd_c-filteringItem">
                                                  <p class="pd_c-filteringItem_text">ブラック</p>
                                                  <button class="pd_c-filteringItem_button pd_e-hover-01 pd_e-hover-01--bgDarkGrayk">×</button>
                                                </li>
                                                <li class="pd_c-filteringItem">
                                                  <p class="pd_c-filteringItem_text">100,000円〜150,000円</p>
                                                  <button class="pd_c-filteringItem_button pd_e-hover-01 pd_e-hover-01--bgDarkGrayk">×</button>
                                                </li>
                      </ul>
                    </div>
```
*/
.pd_m-filteringCondition
    display: flex
    margin-bottom: 24px
    &_text
        font-size: 15px
        margin-right: 8px
        width: 107px
    &_list
        display: flex
        flex-wrap: wrap
        width: calc(100% - 107px);
@media screen and (max-width 640px)
    .pd_m-filteringCondition
        flex-wrap: wrap
        margin-top: 1px
        margin-bottom: 32px
        padding: 0 15px
        &_text
            width: 100%
        &_list
            margin-top: 2px
            width: 100%
            .pd_c-filteringItem
                margin: 0 16px 8px  0