/*
---
name: pd_m-filterModal
category: module
tag:
  - text
  - modal
  - checkBox
---
```html
<form class="pd_m-filterModal" action="" id="pd_m-filterModal-01">
                    <div class="pd_m-filterModal_bg"></div>
                    <div class="pd_m-filterModal_area">
                      <div class="pd_m-filterModal_close"></div>
                      <ul class="pd_m-filterModal_list">
                        <li class="pd_m-filterModal_listItem">
                          <p class="pd_m-filterModal_ttl">キーワード</p>
                          <input class="pd_m-filterModal_input" type="text">
                        </li>
                        <li class="pd_m-filterModal_listItem">
                          <p class="pd_m-filterModal_ttl">価格</p>
                          <div class="pd_c-priceChoice pd_c-priceChoice-01">
                            <div class="pd_c-priceChoice_inputWrap">
                              <input class="pd_c-priceChoice_input" type="text">
                              <p class="pd_c-priceChoice_inputText">~</p>
                              <input class="pd_c-priceChoice_input" type="text">
                              <p class="pd_c-priceChoice_inputText">円</p>
                            </div>
                          </div>
                        </li>
                        <li class="pd_m-filterModal_listItem pd_js-button_product_category">
                                                    <button class="pd_c-filterButton pd_c-filterButton-02" id="pd_c-filteringCategory" type="button">
                                                      <p class="pd_c-filterButton_text">商品カテゴリを絞り込む</p>
                                                    </button>
                          <div class="pd_js-selectedCategoryItems"></div>
                        </li>
                        <li class="pd_m-filterModal_listItem">
                          <ul class="pd_c-checkBoxList">
                            <li class="pd_c-checkBoxList_item pd_e-hover-01">
                              <input type="checkBox" id="pd_c-filteringModal_input1">
                              <label class="pd_c-checkBoxList_label" for="pd_c-filteringModal_input1">
                                <p>在庫あり</p>
                              </label>
                            </li>
                            <li class="pd_c-checkBoxList_item pd_e-hover-01">
                              <input type="checkBox" id="pd_c-filteringModal_input2">
                              <label class="pd_c-checkBoxList_label" for="pd_c-filteringModal_input2">
                                <p>キャンペーン商品</p>
                              </label>
                            </li>
                            <li class="pd_c-checkBoxList_item pd_e-hover-01">
                              <input type="checkBox" id="pd_c-filteringModal_input3">
                              <label class="pd_c-checkBoxList_label" for="pd_c-filteringModal_input3">
                                <p>定額利用サービス</p>
                              </label>
                            </li>
                            <li class="pd_c-checkBoxList_item pd_e-hover-01">
                              <input type="checkBox" id="pd_c-filteringModal_input4">
                              <label class="pd_c-checkBoxList_label" for="pd_c-filteringModal_input4">
                                <p>おすすめ・限定商品</p>
                              </label>
                            </li>
                            <li class="pd_c-checkBoxList_item pd_e-hover-01">
                              <input type="checkBox" id="pd_c-filteringModal_input5">
                              <label class="pd_c-checkBoxList_label" for="pd_c-filteringModal_input5">
                                <p>消耗品・付属品</p>
                              </label>
                            </li>
                          </ul>
                        </li>
                      </ul>
                                            <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                              <p class="pd_c-filterButton_text">この条件で絞り込み</p>
                                            </button>
                                            <button class="pd_js-button_category_clear pd_c-filterButton pd_c-filterButton-04 pd_e-hover-01 pd_e-hover-01--bgGray" type="reset">条件をクリア</button>
                    </div>
                  </form>
```
*/
.pd_m-filterModal
    display: none
    left: 0
    height: 100%
    overflow-y: scroll
    padding: 60px 0 65px
    position: fixed
    top: 0
    width: 100vw
    z-index 1002
    &.pd_open
        display: flex
    &_bg
        background-color: rgba(0,0,0,0.3)
        left: 0
        height 100%
        position: fixed
        top: 0
        width: 100%
        z-index 0
    &_area
        background-color: #fff
        border-radius 16px
        box-shadow: 0px 5px 5px rgba(0,0,0,0.4)
        display: table
        margin: 0 auto
        padding: 34px 30px 60px
        position: relative
        width: 92%
        height: 100%
        z-index 1
    &_close
        background-image: url('/store/images/module/icon_close.svg')
        background-repeat: no-repeat
        cursor pointer
        height: 20px
        position: absolute
        top: 23px
        right: 34px
        width: 20px
    &_ttl
        font-size: 17px
        font-weight: bold
        margin-bottom: 7px
    &_input
        border 1px solid #707070
        border-radius: 8px
        font-size: 15px
        padding: 15px
        width: 100%
    .pd_c-priceChoice_inputWrap
        padding-right: 10px
    // &_list
    &_listItem
        border-bottom: 1px solid #CCCCCC
        padding: 24px 0 25px
    .pd_c-filterButton-03
        margin-top: 38px
    // -02のstyle
    &-02
        display: none
        height: 100vh
        .pd_m-filterModal_bg
            height: 100vh
            width: 100vw
        &_ttl
            font-size: 17px
            font-weight: 500
            margin: 25px 0 40px 0
            text-align: center
    // &_selectorList
    &_selectorListItem
        margin-bottom: 24px
        position: relative
        &:last-child
            margin-bottom: 40px
        &::before
            background-image: url(/store/images/module/searchForm_arrow.svg);
            background-repeat: no-repeat;
            background-size: cover;
            content: "";
            display: block;
            height: 9px;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            z-index: 1;
    &_selector
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        align-items: center;
        border: 1px solid #666;
        border-radius: 8px;
        cursor: pointer
        display: flex;
        height: 52px;
        padding: 15px
        position: relative;
        width: 100%;
    // -03のstyle
    // &-03
        // display: block
    &_itemWrap
        display: flex
    &_itemList
        display: flex
        flex-wrap: wrap
        margin-bottom: -10px
        .pd_c-filteringItem
            padding-left: 15px
            position: relative
            margin: 0 8px 8px 0
            &::after
                border-top: 2px solid #364563
                border-right: 2px solid #364563
                content: ""
                display: block
                height: 5px
                left: 0
                position: absolute
                top: 50%
                transform: translateY(-50%) rotate(45deg)
                width: 5px
            &:first-child
                padding-left: 0
                &::after
                    display: none
    &_subTtl
        font-size: 15px
        font-weight: 500
        margin-bottom: 16px
    &_sizeWrap
        margin-top: 20px
        .pd_c-checkBoxList
            display: flex
            flex-wrap: wrap
        .pd_c-checkBoxList_item:nth-child(2n)
            margin-left: 52px
        .pd_c-checkBoxList_item:last-child
            margin-bottom: 16px;
    &_sizeList
        display: flex
        flex-wrap: wrap
    &_colorWrap
        margin-top: 7px
    &_colorList
        display: flex
    &_color
        border 1px solid rgba(0,0,0,0.2)
        height: 40px
        width: 40px
    &_colorList
        overflow-x: scroll
        width: 100%
    &_colorListItem
        margin-right: 20px
        &:first-child
            .pd_m-filterModal_color
                background-color #fff
        &:nth-child(2)
            .pd_m-filterModal_color
                background-color #000
        &:nth-child(3)
            .pd_m-filterModal_color
                background-color #666666
        &:nth-child(4)
            .pd_m-filterModal_color
                background-color #195DE6
        &:nth-child(5)
            .pd_m-filterModal_color
                background-color #14B81A
        &:last-child
            margin-right: 0
    &_colorText
        font-size: 10px
        margin-top: 11px
        text-align: center
        span
            display: block
            text-align: center
            line-height: 1
    &_funcWrap
        margin-top: 25px
body.pd_modalOpen
    height: 100vw
    overflow: hidden
    width: 100vw

.pd_js-back-searchModal
    display: flex
    flex-wrap: wrap
.pd_js-selected_category
    display: flex
    align-items: center
    position: relative
    margin: 20px 23px 5px 0
    &:not(:last-child)
        &:after
            content: "";
            background-image: url("/store/com/images/component/breadcrumb_icon_arrow.svg");
            background-size: contain;
            display: block;
            width: 8px;
            height: 13px;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: -15px;
.pd_js-selectedCategoryItems
    display: flex
    flex-wrap: wrap
