/*
---
name: pd_m-flowBar
category: module
tag:
  - text
  - icon
---
```html
<div class="pd_m-flowBar">
                  <ul class="pd_m-flowBar_inner"> 
                    <li class="pd_m-flowBar_numWrap">
                      <p class="pd_m-flowBar_num">1</p>
                      <p class="pd_m-flowBar_text">アンケートの入力</p>
                    </li>
                    <li class="pd_m-flowBar_line"></li>
                    <li class="pd_m-flowBar_numWrap">
                      <p class="pd_m-flowBar_num">2</p>
                      <p class="pd_m-flowBar_text">参加内容/アンケートの確認</p>
                    </li>
                    <li class="pd_m-flowBar_line pd_m-flowBar_Off"></li>
                    <li class="pd_m-flowBar_numWrap">
                      <p class="pd_m-flowBar_num pd_m-flowBar_OffCheck"></p>
                      <p class="pd_m-flowBar_text">申し込み完了</p>
                    </li>
                  </ul>
                </div>
```
*/
.pd_m-flowBar
    margin-top: 60px
    margin-bottom: 46px
    &_inner 
        align-items: center
        display: flex
        justify-content: center
        margin: 0 auto
        padding-bottom: 27px
        padding-left: 14px
        width: 660px
    &_numWrap
        position: relative
        &:last-child
            .pd_m-flowBar_num
                background-image: url('/store/com/images/module/flowBar_check_white.svg')
                background-repeat: no-repeat
                background-position: center
    &_numWrap
        .pd_m-flowBar_OffCheck.pd_m-flowBar_num
                background-image: url('/store/com/images/module/flowBar_check_grey.svg')
                background-color: #fff
                border:  2px solid #CCCCCC
                color #CCCCCC
    &_num
        align-items: center
        background-color: #364563
        border-radius: 50%
        color: #fff
        display: flex
        font-size: 13px
        justify-content: center
        height: 30px
        text-align: center
        width: 30px
    &_Off
        background-color: #fff
        border:  2px solid #CCCCCC
        color #CCCCCC
    &_text
        bottom: -28px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px
        position: absolute
        text-align: center
        white-space: nowrap
    &_line
        background-color: #364563
        height: 4px
        width: 255px
@media screen and (max-width 640px)
    .pd_m-flowBar
        margin: 40px 0 0 0
        &_inner
            align-items: flex-start
            flex-direction: column
            justify-content: unset
            padding-bottom 0px
            padding-left 30px
            width: 100%
        &_numWrap
             display: flex
             align-items: center
            &:last-child
                .pd_m-flowBar_num
                    background-size: 11.5px 7.5px
        &_num
            font-size: 11px
            height: 20px
            width: 20px
        &_Off
            background-color: #fff
            border:  2px solid #CCCCCC
            color #CCCCCC
        &_OffCheck
            background-color: #fff
            border:  2px solid #CCCCCC
            color #CCCCCC
        &_text
            bottom: 0;
            left: 0;
            padding-left: 10px
            position: relative
            transform: translateX(0);
        &_line
            margin-left: 8px
            height: 8px
            width: 4px