
/*
---
name: section-01
category: block
tag:
  - text
  - btn
  - img
  - icon
---
```html
<div class="pd_b-emphasis-01">
    <div class="pd_b-section-01">
        <div class="pd_b-section-01_inner">
            <div class="pd_b-section-01_head">
                <h1 class="pd_c-headingLv2-01 pd_c-headingLv2-01--center">イベント・体験会</h1>
                <p class="pd_b-section-01_subText-01 pd_b-section-01_subText-01--center">予約状況を見るテキストが入りますテキストが入りますテキストが入ります</p>
            </div>
            <div class="pd_b-section-01_body">
                <div class="pd_b-section-01_row-01">
                    <div class="pd_b-section-01_col-01"><a class="pd_c-largeBtn-01 pd_e-hover-01 pd_c-largeBtn-01--calendar pd_e-hover-01--bgGray" href="#"><span class="pd_c-largeBtn-01_inner"><span class="pd_c-largeBtn-01_period">10月5日 17:00-19:00</span><span class="pd_c-largeBtn-01_eventTitle">予約イベントタイトル<span class="pd_e-numberIcon-01">3</span></span></span></a></div>
                    <div class="pd_b-section-01_col-02"><a class="pd_c-largeBtn-01 pd_c-largeBtn-01--calendar pd_e-hover-01 pd_e-hover-01--bgGray" href="#"><span class="pd_c-largeBtn-01_inner"><span class="pd_c-largeBtn-01_period">10月5日 17:00-19:00</span><span class="pd_c-largeBtn-01_eventTitle">予約イベントタイトル</span></span></a></div>
                </div>
                <div class="pd_b-section-01_link"><a class="pd_c-linkText-01 pd_e-hover-01" href="#">予約中の予定一覧<span class="pd_e-numberIcon-01">3</span></a></div>
            </div>
        </div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_b-section-01 {
  @media screen and (min-width:MIN_POINT) {
    margin-top 60px
  }
  @media screen and (max-width:MAX_POINT) {
    margin-top 60px
  }

  &_inner {
    @media screen and (min-width:MIN_POINT) {
      max-width 980px
      margin-right auto
      margin-left auto
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right 15px
      margin-left 15px
    }
  }
  &_subText-01 {
    margin-top 24px
    font-size 15px
    color rgba(0,0,0,.9)
    &--center {
      text-align center
    }
  }
  &_body {
    margin-top 23px
  }
  &_row-01 {
    @media screen and (min-width:MIN_POINT) {
      display flex
      margin-left -10px
      margin-right -10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_col-01,
  &_col-02 {
    @media screen and (min-width:MIN_POINT) {
      width 50%
      padding-right 10px
      padding-left 10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_col-02 {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 20px
    }
  }
  &_link {
    margin-top 40px
    text-align center
  }
  .pd_b-news_list {
    margin-top 0
  }
}
