/*
---
name:  pd_e-required-02
category: element
tag:
  - tag
---
```html
<span class="pd_e-required-02">必須 </span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-required-02 {
    display inline-block
    background-color #fff
    border solid 1px #CC0E00
    border-radius 13px
    color #CC0E00
    font-size 13px
    padding 0 8px
    line-height (20/13)
}
