/*
---
name: pd_c-btn-03
category: component
tag:
  - btn
---
```html
  <a class="pd_c-btn-03 pd_e-hover-01 pd_e-hover-01--bgGray" href="#">閉じる</a>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-btn-03 {
  background-color #fff
  display block
  border solid 1px #364563
  border-radius 8px
  font-size 15px
  line-height (23/15)
  text-align center
  position: relative
  box-shadow 0 3px 6px rgba(0,0,0,.16)
  &::after{
    background-image: url('/store/com/images/component/btn_close.svg')
    content: ""
    display: block
    height 24px
    position: absolute
    right: 15px
    top: 50%
    transform: translateY(-50%)
    width 24px
  }

  @media screen and (min-width:MIN_POINT) {
    padding 14px 14px 13px
  }
  @media screen and (max-width:MAX_POINT) {
    padding 14px 14px 11px
  }
  &:link,
  &:hover,
  &:visited,
  &:visited:hover,
  &:active,
  &:focus {
    color #364563
  }
}
