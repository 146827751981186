/*
---
name: pd_c-inputText-01
category: component
tag:
  - checkBox
---
```html
  <input class="pd_c-inputText-01" placeholder="イベント名、エキスパート名、製品名、店舗名" type="text" name="" />
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-inputText-01
  background-color #fff
  border solid 1px #707070
  border-radius 8px
  padding 15px
  width 100%
  display block
  box-sizing border-box

.pd_c-inputText_event
  input[type="text"]
    background-color #fff
    border solid 1px #707070
    border-radius 8px
    padding 15px
    width 90%
    display block
    box-sizing border-box

