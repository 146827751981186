/*
---
name: pd_m-product_history
category: module
tag:
  - text
  - img
  - icon
  - tag
---
```html
<div class="pd_product_history recommend">
  <div class="pd_product_history__inr">
    <div id="recommender_">
      <div class="dv_recommend">
        <!-- header-->
        <div class="dv_rc_header"></div>
        <div class="dv_rc_title">最近見た商品</div>
        <!-- items-->
        <ul class="pd_product_history__ul">
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- sale--><span class="pd_product_history__sale">SALE 40%OFF</span>
                </div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- sale-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- sale-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- sale-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- 予約-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- 予約-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- 予約-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- 予約-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- NEW-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- NEW-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- NEW-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168">
                  <!-- NEW-->
                </div>
                <div class="pd_product_history_item__option">
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 通常表示-->
                <p class="pd_product_history__p--black"><span class="pd_product_history__p--black_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--black"><span class="pd_product_history__p--black_bold">2,222,222</span>円(税込)</p>
                <!-- /通常表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 通常表示-->
                <p class="pd_product_history__p--black"><span class="pd_product_history__p--black_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--black"><span class="pd_product_history__p--black_bold">2,222,222</span>円(税込)</p>
                <!-- /通常表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- セット--><span class="pd_product_history_item__tag--black">セット商品</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 通常表示-->
                <p class="pd_product_history__p--black"><span class="pd_product_history__p--black_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--black"><span class="pd_product_history__p--black_bold">2,222,222</span>円(税込)</p>
                <!-- /通常表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- 近日発売--><span class="pd_product_history_item__tag--blue">近日発売</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 通常表示-->
                <p class="pd_product_history__p--black"><span class="pd_product_history__p--black_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--black"><span class="pd_product_history__p--black_bold">2,222,222</span>円(税込)</p>
                <!-- /通常表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 割引表示-->
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--red">特別価格<span class="pd_product_history__p--red_bold">2,222,222</span>円(税込)</p>
                <!-- /割引表示-->
                <!-- 割引前の価格表示-->
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--line"><span class="pd_product_history__p--line">2,222,222</span>円(税込)</p>
                <!-- /割引前の価格表示-->
              </div></a></li>
          <li class="pd_product_history__li"><a class="pd_product_history__a" href="【リンク先URL】">
              <div class="pd_product_history_item">
                <div class="pd_product_history_item__image"><img src="/store/images/dummy/dummy_product_history01.png" alt="" width="224" height="168"></div>
                <div class="pd_product_history_item__option">
                  <!-- 軽減税率--><span class="pd_bold">軽減税率</span>
                </div>
                <p class="pd_relation_item__description">アイテム名</p>
                <p class="pd_relation_item__part_number">品番：【アイテム説明】</p>
                <!-- 通常表示-->
                <p class="pd_product_history__p--black"><span class="pd_product_history__p--black_bold">2,222,222</span>円(税抜)</p>
                <p class="pd_product_history__p--black"><span class="pd_product_history__p--black_bold">2,222,222</span>円(税込)</p>
                <!-- /通常表示-->
              </div></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
```
*/

MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.dv_recommend　{
  width 996px
  margin 40px auto
  display: block
}

@media screen and (max-width: 640px /* breakpointになる値を設定 */) {
    .dv_recommend　{
      width 100vw
      padding 0 15px
      margin 40px auto
  }
}

.dv_rc_title,
.pd_product_history__heading {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
}
@media screen and (max-width: 640px /* breakpointになる値を設定 */) {
  .dv_rc_title,
  .pd_product_history__heading {
    padding: 0 10px;
  }
}
.pd_product_history__inr {
  max-width: 980px;
  margin: 0 auto;
}
.dv_recommend .pd_product_history_item__img {
  width: 224px;
  // height: 168px;
  position relative
}
.dv_recommend .pd_product_history_item__img > img {
  width: 224px;
  height: 168px;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: 'object-fit: contain;';
}
@media screen and (max-width: 640px /* breakpointになる値を設定 */) {
  .dv_recommend .pd_product_history_item__img {
    width: 100%;
    height: auto;
    position: relative;
  }
  .dv_recommend .pd_product_history_item__img::before {
    content: '';
    display: block;
    padding-top: 75%;
    position: relative;
  }
  .dv_recommend .pd_product_history_item__img > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
.dv_recommend .pd_product_history_item__image {
  height: 168px;
  width: 224px;
}
.dv_recommend .pd_product_history_item__image img {
  height: 168px;
  width: 224px;
}
.dv_recommend .pd_product_history__sale,
.dv_recommend .pd_product_history__new,
.dv_recommend .pd_product_history__reserve {
  display: inline-block;
  line-height: 1;
  padding: 5px 7px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #f83;
  position absolute
  top 0
  left 0
}
.dv_recommend .pd_product_history__new {
  background-color: #f83;
}
.dv_recommend .pd_product_history__reserve {
  background-color: #f83;
}
.dv_recommend .pd_product_history__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  overflow-x: scroll;
  scrollbar-face-color: #000;
  scrollbar-track-color: #e5e5e5;
}
.dv_recommend .pd_product_history__ul::-webkit-scrollbar {
  height: 8px;
}
.dv_recommend .pd_product_history__ul::-webkit-scrollbar-track {
  background: #e5e5e5;
  border: none;
  border-radius: 8px;
}
.dv_recommend .pd_product_history__ul::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 8px;
}
@media screen and (max-width: 640px /* breakpointになる値を設定 */) {
  .dv_recommend .pd_product_history__ul::-webkit-scrollbar {
    height: 12px;
  }
  .dv_recommend .pd_product_history__ul::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 12px;
  }
}
.dv_recommend .pd_product_history__li {
  margin-right: 1.5%;
  padding: 0;
}
.dv_recommend .pd_relation_item__description,
.dv_recommend .pd_relation_item__part_number {
  display: none;
}
.dv_recommend .pd_product_history__p--red_bold,
.dv_recommend .pd_product_history__p--black_bold {
  margin-top: 0;
}
@media screen and (max-width: 640px /* breakpointになる値を設定 */) {
  .dv_recommend .pd_product_history__ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 2%;
  }
  .dv_recommend .pd_product_history__li {
    min-width: 44vw;
    width: 44vw;
  }
}
// .dv_recommend .pd_product_history_item__option {
//   -webkit-box-pack: start;
//       -ms-flex-pack: start;
//           justify-content: flex-start;
//   -ms-flex-wrap: wrap;
//       flex-wrap: wrap;
//   margin-bottom: 5px;
// }
// @media screen and (max-width: 640px /* breakpointになる値を設定 */) {
//   .dv_recommend .pd_product_history_item__option {
//     -webkit-box-align: start;
//         -ms-flex-align: start;
//             align-items: flex-start;
//     -ms-flex-wrap: wrap;
//       flex-wrap: wrap;
//     margin-bottom: 5px;
//   }
// }
// @media screen and (max-width: 640px /* breakpointになる値を設定 */) {
//   .dv_recommend .pd_product_history_item__option > span {
//     min-width: 21vw;
//     margin-right: 0.8vw;
//   }
// }
// @media screen and (max-width: 640px /* breakpointになる値を設定 */) {
//   .dv_recommend .pd_product_history_item__option .pd_bold {
//     margin-top: 0.4vw;
//   }
// }
.dv_recommend .pd_product_history__p--red,
.dv_recommend .pd_product_history__p--black {
  font-size: 12px;
}
@media screen and (max-width: 640px /* breakpointになる値を設定 */) {
  .dv_recommend .pd_product_history__p--red,
  .dv_recommend .pd_product_history__p--black {
    font-size: 11px;
  }
}
.dv_recommend .pd_product_history__p--red_bold,
.dv_recommend .pd_product_history__p--black_bold {
  font-size: 16px;
}
@media screen and (max-width: 640px /* breakpointになる値を設定 */) {
  .dv_recommend .pd_product_history__p--red_bold,
  .dv_recommend .pd_product_history__p--black_bold {
    font-size: 14px;
  }
}
@media screen and (max-width: 640px) {
  .dv_recommend .pd_product_history__p--red,
  .dv_recommend .pd_product_history__p--black,
  .dv_recommend .pd_product_history__p--line {
    font-size: 2.66vw;
  }
  .dv_recommend .pd_product_history__p--red_bold,
  .dv_recommend .pd_product_history__p--black_bold {
    font-size: 2.93vw;
  }
}
.pd_product_history__sale,
.pd_product_history__sale--blue {
  padding: 5px 7px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #d00000;
}
.pd_product_history__sale--blue {
  background-color: #1876e9;
}

.pd_product_history__a {
  display: block;
  height: 100%;
  padding-bottom: 27px;
  color: #000;
  position: relative;
}

.pd_product_history_item__img {
  border: 1px solid #E5E5E5;
}

.pd_product_history__p,
.pd_product_history__p--red,
.pd_product_history__p--red_bold,
.pd_product_history__p--black,
.pd_product_history__p--black_bold,
.pd_product_history__p--gray,
.pd_product_history__p--line {
  margin-top: 5px;
  font-size: 1.2rem;
  text-align: right;
}
.pd_product_history__p--red,
.pd_product_history__p--red_bold {
  color: #D00000;
}
.pd_product_history__p--red_bold {
  margin-top: 44px;
  font-weight: bold;
}
.pd_product_history__p--red_bold .pd_fs_large {
  font-size: 1.4rem;
}
.pd_product_history__p--black,
.pd_product_history__p--black_bold {
  color: #000;
}
.pd_product_history__p--black_bold {
  margin-top: 44px;
  font-weight: bold;
}
.pd_product_history__p--black_bold .pd_fs_large {
  font-size: 1.4rem;
}
.pd_product_history__p--gray {
  color: #666;
}
.pd_product_history__p--gray + .pd_relation_item__description {
  margin-top: 3px;
}
.pd_product_history__p--line {
  text-decoration: line-through;
}
.pd_product_history__p--line .pd_fs_large {
  font-size: 1.4rem;
}
.pd_product_history_item__img {
  margin-bottom: 10px;
  font-size: 0;
  position: relative;
}
.pd_product_history_item__img img:first-child {
  width: 100%;
}
.pd_product_history_icons {
  position: absolute;
  top: 10px;
  left: 0;
}
.pd_product_history_icons .pd_product_history__sale,
.pd_product_history_icons .pd_product_history__sale--blue,
.pd_product_history_icons .pd_product_history__sale--yellow {
  display: inline-block;
  padding: 5px 7px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #D00000;
}
.pd_product_history_icons .pd_product_history__sale--blue {
  background-color: #1876E9;
}
.pd_product_history_icons .pd_product_history__sale--yellow {
  background-color: #FFBA00;
}
.pd_product_history_item__img.pd_end_item::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.pd_product_history_item__img_end_text {
  width: 100%;
  margin: auto;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  z-index: 11;
}
.pd_product_history_item__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 29px;
}

@media screen and (max-width: 640px) {
  .pd_product_history_item__option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 29px;
  }
}
.pd_product_history_item__option span {
  font-size: 1.2rem;
}
.pd_product_history_item__option + .pd_product_history__p--red_bold,
.pd_product_history_item__option + .pd_product_history__p--black_bold {
  margin-top: 5px;
}
@media screen and (max-width: 640px) {
  .pd_product_history_item__option .pd_product_history_item__tag,
  .pd_product_history_item__option .pd_product_history_item__tag--black,
  .pd_product_history_item__option .pd_product_history_item__tag--blue {
    margin-right: 5px;

  }
}
.pd_product_history_item__option .pd_product_history_item__tag--black {
  color: rgba(0,0,0,0.9);
  background-color: #e6e6e6;
  border-radius 5px
  padding 1px 7px
  margin-right 8px
}
.pd_product_history_item__option .pd_product_history_item__tag--blue {
  color: rgba(0,0,0,0.9);
  background-color: #e6e6e6;
  border-radius 5px
  padding 1px 7px
  margin-right 8px
}
.pd_product_history_item__option .pd_bold {
  color: rgba(0,0,0,0.9);
  background-color: #e6e6e6;
  border-radius 5px
  padding 1px 7px
  margin-right 8px
}

@media screen and (max-width: 640px) {
    .pd_product_history_item__option .pd_product_history_item__tag--black {
    color: rgba(0,0,0,0.9);
    background-color: #e6e6e6;
    border-radius 5px
    padding 1px 7px
    margin-right 8px
    margin-top 7px
  }
  .pd_product_history_item__option .pd_product_history_item__tag--blue {
    color: rgba(0,0,0,0.9);
    background-color: #e6e6e6;
    border-radius 5px
    padding 1px 7px
    margin-right 8px
    margin-top 7px
  }
  .pd_product_history_item__option .pd_bold {
    color: rgba(0,0,0,0.9);
    background-color: #e6e6e6;
    border-radius 5px
    padding 1px 7px
    margin-right 8px
    margin-top 7px
  }
}
.pd_product_history .pd_favorite__productReview {
  width: 100%;
  text-align: right;
  position: absolute;
  bottom: 0;
  left: 0;
}
.pd_product_history .pd_favorite__productReview .pd_icon-star img {
  display: inline-block;
}