/*
---
name: pd_m-smallBoxItem
category: module
tag:
  - text
  - icon
  - tag
  - img
---
```html
<div class="pd_m-smallBoxItem">
  <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
  <div class="pd_m-smallBoxItem_mid">
    <p class="pd_m-smallBoxItem_kind">商品</p>
    <div class="pd_m-smallBoxItem_favoriteWrap">
      <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
    </div>
  </div>
  <ul class="pd_c-smallTag_bkg_gray">
    <li class="pd_c-smallTag-gray">受付中</li>
    <li class="pd_c-smallTag-gray">定額</li>
    <li class="pd_c-smallTag-gray">近日発売</li>
    <li class="pd_c-smallTag-gray">セット商品</li>
    <li class="pd_c-smallTag-gray">軽減税率対象</li>
  </ul>
  <ul class="pd_c-smallTag_bkg_orange">
    <li class="pd_c-smallTag-orange">NEW</li>
    <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
    <li class="pd_c-smallTag-orange">1000コイン進呈</li>
    <li class="pd_c-smallTag-orange">プレゼント付</li>
    <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
    <li class="pd_c-smallTag-orange">予約商品</li>
    <li class="pd_c-smallTag-orange">特別価格</li>
  </ul>
  <p class="pd_e-releaseDate">2020年1月発売</p>
</div>
```
*/
.pd_m-smallBoxItem_img
    align-items: center
    background-color: transparent
    display: flex
    height: 229px
    justify-content: center
    width: 229px
    img
        max-width: 229px
        max-height: 100%
.pd_m-smallBoxItem_mid
    display: flex
    justify-content: space-between
    margin-top: 18px
    position: relative
    width: 100%
.pd_m-smallBoxItem_kind
    color: rgba(0,0,0,0.6)
    font-size: 13px
pd_m-smallBoxItem_favoriteWrap
    z-index 10
.pd_m-smallBoxItem_favorite
    height: 24px
    position: absolute
    right: 0
    transform: translateY(-10%)
    width: 24px
    z-index 10
    cursor: pointer
    .pd_m-smallBoxItem_favorite_img-01
        display: block
    .pd_m-smallBoxItem_favorite_img-02
        display: none
    &.pd_favoriteOn
        .pd_m-smallBoxItem_favorite_img-01
            display: none
        .pd_m-smallBoxItem_favorite_img-02
            display: block
    img
        height: 100%
        width: 100%
.pd_m-smallBoxItem_ttl
    font-size: 17px
    font-weight: 500
    line-height: 1.5
    margin-top: 2px;
    letter-spacing: -0.6px;

.pd_m-smallBoxItem
    .pd_m-smallBoxItem_img
        background-color transparent
@media screen and (max-width: 640px)
    .pd_m-smallBoxItem_img
        width: 44vw
        height: 44vw
        img
            max-width: 44vw
            max-height: 100%
    .pd_m-smallBoxItem_price
        font-size: 17px
