/*
---
name: pd_c-pdfLink
category: component
tag:
  - text
---
```html
    <a class="pd_c-pdfLink pd_e-hover-01" href="#">PDFファイル:蔦屋家電内マップはこちら</a>
```
*/
.pd_c-pdfLink
    color: #364563
    font-size: 15px
    padding-left: 34px
    position: relative
    text-decoration: underline
    &::after
        background-image: url('/store/images/component/pdfLink_Icon.svg')
        background-size: contain
        background-position: center
        background-repeat: no-repeat
        content: ""
        display: block
        height: 24px
        left: 0
        position: absolute
        top: 50%
        transform: translateY(-50%)
        width: 24px