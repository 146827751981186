/*
---
name: pd_c-checkBox-01
category: component
tag:
  - checkBox
---
```html
  <label class="pd_c-checkBox-01">
      <input class="pd_c-checkBox-01_input-01 pd_js-c-checkBox-01_checkbox" type="checkbox" name="status" /><span class="pd_c-checkBox-01_indicator-01"></span><span class="pd_c-checkBox-01_text-01">配信中/開催中</span>
  </label>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-checkBox-01 {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  &_input-01 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    &:checked+span{
      background-image: url(../../com/images/component/checkBox-01_checked.svg);
    }
  }

  &_indicator-01 {
    background-image: url("/store/com/images/component/checkBox-01.svg");
    background-repeat: no-repeat;
    display: inline-block;
    width:24px;
    height: 24px;
    margin-right: 10px;
  }
  &_indicator-01,
  &_text-01 {
    display inline-block
    vertical-align top
  }
  &+.pd_b-tabDetail_list {
    margin-top 34px
  }
}

