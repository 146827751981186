/*
---
name: pd_c-borderText
category: component
tag:
  - btn
---
```html
    <button class="pd_c-btn-01_btn pd_c-btn-arrow" type="button" onclick="">
        <p class="pd_c-btn-01_btnText-01"><span>お申し込みへすすむ</span></p>
    </button>
```
*/
.pd_c-borderText
    border: 1px solid #666666
    color: rgba(0,0,0,0.6)
    line-height: 50px
    margin-top: 24px
    text-align: center
    &-02
        line-height: 1.5
        padding: 14px 0
        text-align: center
.pd_m-eventsRequest
    .pd_c-borderText-pc
        display: none
@media screen and (max-width 640px)
    .pd_c-borderText
        &-pc
            display: none