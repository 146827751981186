SP_MAX = 640px /* breakpointになる値を設定 */
SP = 640px /* breakpointになる値を設定 */
RED = #CC0E00

.pd_e-marginTop_40
  margin-top 40px

sp()
  @media screen and (max-width: SP_MAX)
    {block}
imgFit_child(wid, hei)
  width wid
  height hei
  > img
    width wid
    height hei
    object-fit contain
    font-family: 'object-fit: contain;';
  +sp()
    width 100%
    height auto
    position relative
    &::before
      content ''
      display block
      padding-top 75%
      position relative
    > img
      position absolute
      top 0
      left 0
      bottom: 0
      right: 0
      width 100%
      height 100%


imgFit(wid, hei)
  width wid
  height hei
  img
    width wid
    height hei
    object-fit contain
    font-family: 'object-fit: contain;';
  +sp()
    width 100%
    height auto
    position relative
    &::before
      content ''
      display block
      padding-top 75%
    img
      position absolute
      top 0
      left 0
      bottom: 0
      right: 0
      width 100%
      height 100%

//// boforeの画像アイコン
$icon =
  content: '';
  display: inline-block;
  background-size: contain;
  background-repeat no-repeat
  vertical-align: middle;
  // 下記を呼び出した後設定してください
  // width
  // height
  // background-image
