/*
---
name: pd_m-slider-01
category: module
tag:
  - text
  - img
  - icon
  - slider
---
```html
<div class="pd_m-slider-01">
  <div class="pd_m-slider-01_inner">
    <div class="pd_m-slider-01_list">
      <div class="pd_m-slider-01_listItem"><a class="pd_m-slider-01_anchor pd_e-hover-01" href="/">
          <figure class="pd_m-slider-01_figure"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" alt=""></figure>
          <div class="pd_m-slider-01_texts">
            <p class="pd_m-slider-01_title">スマートコーヒー焙煎サービス「The Roast」体験会</p>
            <p class="pd_m-slider-01_date">毎週　水曜日・土曜日・日曜日</p>
            <p class="pd_m-slider-01_place">RELIFE STUDIO FUTAKO</p>
          </div></a></div>
      <div class="pd_m-slider-01_listItem"><a class="pd_m-slider-01_anchor pd_e-hover-01" href="/">
          <figure class="pd_m-slider-01_figure"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" alt=""></figure>
          <div class="pd_m-slider-01_texts">
            <p class="pd_m-slider-01_title">ストレートヘアW実感キャンペーン</p>
            <p class="pd_m-slider-01_date">毎週　水曜日・土曜日・日曜日</p>
            <p class="pd_m-slider-01_place">RELIFE STUDIO FUTAKO</p>
          </div></a></div>
      <div class="pd_m-slider-01_listItem"><a class="pd_m-slider-01_anchor pd_e-hover-01" href="/">
          <figure class="pd_m-slider-01_figure"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" alt=""></figure>
          <div class="pd_m-slider-01_texts">
            <p class="pd_m-slider-01_title">スマートコーヒー焙煎サービス「The Roast」体験会</p>
            <p class="pd_m-slider-01_date">毎週　水曜日・土曜日・日曜日</p>
            <p class="pd_m-slider-01_place">RELIFE STUDIO FUTAKO</p>
          </div></a></div>
      <div class="pd_m-slider-01_listItem"><a class="pd_m-slider-01_anchor pd_e-hover-01" href="/">
          <figure class="pd_m-slider-01_figure"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" alt=""></figure>
          <div class="pd_m-slider-01_texts">
            <p class="pd_m-slider-01_title">ストレートヘアW実感キャンペーン</p>
            <p class="pd_m-slider-01_date">毎週　水曜日・土曜日・日曜日</p>
            <p class="pd_m-slider-01_place">RELIFE STUDIO FUTAKO</p>
          </div></a></div>
    </div>
    <div class="pd_js-m-slider-01_control"><span class="pd_js-m-slider-01_playBtn pd_e-hover-01"></span></div>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-slider-01 {
  margin-top 40px
  @media screen and (min-width:MIN_POINT) {
  }
  @media screen and (max-width:MAX_POINT) {
  }
  &_inner {
    @media screen and (min-width:MIN_POINT) {
      max-width 980px
      margin-right auto
      margin-left auto
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right 15px
      margin-left 15px
    }
  }
  &_list {
    position relative
    &::after {
      content ''
      display ''
      clear both
    }
    @media screen and (min-width:MIN_POINT) {
      margin-right -10px
      margin-left -10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_listItem {
    float left
    box-sizing border-box
    @media screen and (min-width:MIN_POINT) {
      width 50%
      padding 10px
    }
    @media screen and (max-width:MAX_POINT) {
      width 100%
      padding 10px
    }
  }
  &_anchor {
    display block
    background-color #fff
    border-radius 8px
    box-shadow 0 3px 6px rgba(0,0,0,.16)
    overflow hidden
  }
  &_texts {
    padding 20px 20px 31px
  }
  &_title {
    font-size 17px
    font-weight 500
    line-height (20/17)
    color rgba(0,0,0,.9)
  }
  &_date {
    font-size 13px
    line-height (20/13)
    margin-top 16px
    color rgba(0,0,0,.9)
  }
  &_place {
    margin-top 4px
    font-size 13px
    line-height (20/13)
    color rgba(0,0,0,.9)
  }
  .slick-dots {
    display inline-block
    vertical-align middle
  }
  .slick-dots li {
    display inline-block
    vertical-align middle
  }
  .slick-dots li button {
    font-size: 0
    line-height: 0
    display: block
    width: 6px
    height: 6px
    padding: 0px
    cursor: pointer
    border: 0
    outline: none
    background: #CCC
    border-radius: 50%
    margin-right 4px
    margin-left 4px
    &::hover,
    &::focus {
        outline: none;
    }
  }
  .slick-dots li.slick-active button {
    background: #666
  }
  .slick-arrow {
    position absolute
    top 50%
    border none
    z-index 2
    text-indent -9999px
    overflow hidden
    transform translateY(-50%)
    background-color #fff
    border-radius 50%
    box-shadow 0 3px 6px rgba(0,0,0,.16)
    &:hover {
      background-color #F2F2F2
    }
    @media screen and (min-width:MIN_POINT) {
      width 40px
      height 40px
    }
    @media screen and (max-width:MAX_POINT) {
      display none!important
    }
    &::after {
      content ''
      width 8px
      height 13px
      background-position center center
      background-repeat no-repeat
      position absolute
      left 50%
      top 50%
      background-size 100% auto
      transform translate(-50%,-50%)
      background-image url('/store/images/common/sliderBox-01_icon_arrow_01.svg')
    }
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      z-index -100
    }
  }
  .slick-next {
    @media screen and (min-width:981px) {
      right -10px
    }
    @media screen and (min-width:MIN_POINT) and (max-width:980px ){
      right 10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  .slick-prev {
    @media screen and (min-width:981px) {
      left -10px
    }
    @media screen and (min-width:MIN_POINT) and (max-width:980px ){
      left 10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
    &::after {
      transform-origin center
      transform translate(-50%,-50%) rotate( 180deg )
    }
  }
}

.pd_js-m-slider-01 {
  &_control {
    &::before {
      content ''
      margin-right 15px
      vertical-align middle
      display inline-block
      width 24px
      height 24px
    }
    text-align center
  }
  &_playBtn {
    vertical-align middle
    display inline-block
    width 24px
    height 24px
    background-repeat no-repeat
    background-size 100% auto
    margin-left 15px
    cursor pointer
    background-image url('/store/images/module/slider-01_icon_play.svg')
    &.pd_js-active {
      background-image url('/store/images/module/slider-01_icon_pause.svg')
    }
  }
}
