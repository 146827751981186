/*
---
name: pd_c-productListItem
category: component
tag:
  - text
---
```html
    <p class="pd_c-productListItem_modelNum">MC-RSF1000</p>
```
*/
.pd_c-productListItem
    &_price
        color: rgba(0,0,0,0.9)
        font-size: 22px
        font-weight: 500
        text-align: right;
    &_priceUnit
        color: rgba(0,0,0,0.9);
        font-size: 13px;
        padding-left: 2px;
    &_priceTax
        font-size: 13px;
        color: rgba(0,0,0,0.6);
        padding-left: 3px
    &_stock
        color: rgba(0,0,0,0.6);
        font-size: 13px;
        margin-top: 5px;
        text-align: right;
    &_price-bar
        color: rgba(0,0,0,0.9);
        text-align: right;
        margin-top: 8px
        span:first-child
            font-size: 17px
            font-weight: 500
            text-decoration: line-through;
    &_specialText
        color: #CC0E00
        font-size: 13px
        margin-top: -5px;
        .pd_c-productListItem_priceUnit,
        .pd_c-productListItem_priceTax
            color: #CC0E00
            font-size: 13px
    &_specialPrice
        color: #CC0E00
        font-size: 22px
        font-weight: 500
        padding-left: 10px
    &_price-mini
        color: rgba(0,0,0,0.9);
        font-size: 17px
        font-weight: 500
        text-align: right;
        margin-top: 9px
    &_monthlyText
        color: rgba(0,0,0,0.9)
        font-size: 13px
        margin-top: -6px
    &_monthlyPrice
        color: rgba(0,0,0,0.9);
        color: rgba(0,0,0,0.9)
        font-size: 22px
        font-weight: 500
        padding-left: 10px
@media screen and (max-width 640px)
    .pd_c-productListItem
        width: 100%
        // &_price
        // &_priceUnit
        // &_priceTax
        // &_stock
        // &_price-bar
        &_specialText
            text-align: right
        // &_specialPrice
        // &_price-mini
        &_monthlyText
            text-align: right
        // &_monthlyPrice