/*
---
name: pd_c-productPanelItem_guidArea-01
category: component
tag:
  - text
---
```html
<li class="aigis-moduleList__item">
    <a href="#pd_c-productPanelItem_guidArea-01">pd_c-productPanelItem_guidArea-01</a>
</li>
```
*/
.pd_c-productPanelItem_guideArea-01
    .pd_c-productPanelItem_guideline
        font-size 13px
        text-align left
        margin 5px 0 15px
    .pd_c-productPanelItem_note
        font-size 13px
        text-align left

.pd_c-productPanelItem_guideArea-02
    padding-left 160px
    .pd_c-productPanelItem_guideline
        font-size 13px
        text-align left
        margin 5px 0 15px
    .pd_c-productPanelItem_note
        font-size 13px
        text-align left

.pd_c-productPanelItem_guideArea-03
    padding-left 213px  
    .pd_c-productPanelItem_guideline
        font-size 13px
        text-align left
        margin 5px 0 15px
    .pd_c-productPanelItem_note
        font-size 13px
        text-align left

@media screen and (max-width: 640px)
    .pd_c-productPanelItem_guideArea-02
        padding-left 0
    .pd_c-productPanelItem_guideArea-03
        padding-left 0