/*
---
name: pd_m-tabList-03
category: module
tag: 
  - text
  - tab
---
```html
<ul class="pd_m-tabList pd_m-tabList-03">
  <li class="pd_m-tabList_item pd_active pd_e-hover-01" data-tab="pd_openEvent">開催予定のイベント</li>
  <li class="pd_m-tabList_item pd_e-hover-01" data-tab="pd_closedEvent">終了したイベント</li>
</ul>
```
*/
.pd_m-tabList
    display: flex
    font-size: 15px
    justify-content: space-between
    margin:  0 auto -1px
    width 976px
    &_item
        border-bottom: 4px solid transparent
        color: rgba(0,0,0,0.3)
        cursor: pointer
        line-height: 42px
        text-align: center
        width: 488px
        br
            display: none
        a
            display: block
            height: 100%
            width: 100%
        &.pd_active
            border-bottom: 4px solid #364563
            color: rgba(0,0,0,0.9)
@media screen and (max-width: 640px)
    .pd_m-tabList
        font-size: 13px
        overflow-x: scroll
        width: max-content
        &_item
            align-items: center
            display: flex
            justify-content: center
            line-height: 1.5
            height: 44px
            padding: 0 20px
            width: auto
            br
                display: none
