/*
---
name: pd_c-dateArea
category: component
tag:
  - text
---
```html
    <div class="pd_c-dateArea">
        <p class="pd_c-dateArea_date">2021年04月15日</p>
        <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
    </div>
```
*/
.pd_c-dateArea
    &_date
        color rgba(0,0,0,0.6)
        font-size 13px
        margin-top: 9px;
    &_text
        color #000
        font-size 13px
        margin-top: 7px