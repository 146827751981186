/*
---
name: pd_c-searchListItem
category: component
tag:
  - text
---
```html
    <div class="pd_c-searchListItem">
        <p class="pd_c-searchListItem_kind">記事</p>
        <object class="pd_c-searchListItem_favorite"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></a></object>
    </div>
```
*/
.pd_c-searchListItem
    position: relative
    width: 100%
    &_kind
        color: rgba(0,0,0,0.6);
        font-size: 13px;
    &_favorite
        height: 24px;
        position: absolute;
        right: 0;
        top: -5%
        width: 24px;
        z-index: 1;
        cursor: pointer
        .pd_m-searchListItem_favorite_img-01
            display: block
        .pd_m-searchListItem_favorite_img-02
            display: none
        &.pd_favoriteOn
            .pd_m-searchListItem_favorite_img-01
                display: none
            .pd_m-searchListItem_favorite_img-02
                display: block
        a,img
            height: 100%;
            width: 100%;