/*
---
name: pd_e-bnr-01
category: element
tag:
  - bnr
---
```html
<div class="pd_e-bnr-01">
  <a class="bd_e-bnr-01_anchor pd_e-hover-01 is-pc" href="/"><img src="http://placehold.jp/3d4070/ffffff/976x160.png?text=PCバナー" alt="" /></a>
  <a class="bd_e-bnr-01_anchor pd_e-hover-01 is-sp" href="/"><img src="http://placehold.jp/3d4070/ffffff/976x160.png?text=スマホバナー" alt="" /></a>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_e-bnr-01 {
  &_anchor {
    display block
  }
}
