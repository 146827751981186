/*
---
name: pd_e-bnrText-01
category: element
---
```html
<div class="pd_e-centerText-01">
    <span class="pd_e-centerText-01_text">お探しの商品は見つかりませんでした。</span>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-centerText-01 {
    text-align center
    &_text {
        font-size 17px
    }
}
@media screen and (min-width:MIN_POINT) {
    .pd_e-centerText-01 {
        margin 140px auto 134px
    }
}
@media screen and (max-width:MAX_POINT) {
    .pd_e-centerText-01 {
        margin 88px auto 114px
    }
}




