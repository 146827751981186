/*
---
name: pd_m-modalBox-01
category: module
tag:
  - text
  - btn
  - checkBox
---
```html
<div class="pd_m-modalBox-01" id="modalBox-eventForm">
                  <div class="pd_m-form-02">
                    <form action="">
                      <fieldset class="pd_m-form-02_field">
                        <div class="pd_m-form-02_legend">キーワード</div>
                        <div class="pd_m-form-02_inputGroup pd_c-inputText_event">
                          <input type="text">
                        </div>
                      </fieldset>
                      <fieldset class="pd_m-form-02_field">
                        <div class="pd_m-fieldset-01_legend">開催日時</div>
                        <div class="pd_m-form-02_inputGroup">
                          <ul class="pd_m-radioBtnList-01">
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="date" value="指定する"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">指定する</span>
                                                        </label>
                            </li>
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="date" value="指定しない"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">指定しない</span>
                                                        </label>
                            </li>
                          </ul>
                          <div class="pd_m-selectGroup-01">
                            <div class="pd_m-selectGroup-01_row-01">
                              <div class="pd_m-selectGroup-01_col-01">
                                                          <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
                                                            <select>
                                                              <option value="option1" selected="" disabled="">2021年</option>
                                                              <option value="1">1</option>
                                                              <option value="2">2</option>
                                                            </select>
                                                          </div>
                              </div>
                              <div class="pd_m-selectGroup-01_col-02">
                                                          <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
                                                            <select>
                                                              <option value="option1" selected="" disabled="">4月</option>
                                                              <option value="1">1</option>
                                                              <option value="2">2</option>
                                                            </select>
                                                          </div>
                              </div>
                              <div class="pd_m-selectGroup-01_col-03">
                                                          <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
                                                            <select>
                                                              <option value="option1" selected="" disabled="">30日</option>
                                                              <option value="1">1</option>
                                                              <option value="2">2</option>
                                                            </select>
                                                          </div>
                              </div>
                              <div class="pd_m-selectGroup-01_col-04">
                                <p class="pd_m-selectGroup-01_text-01">から</p>
                              </div>
                            </div>
                            <div class="pd_m-selectGroup-01_row-02">
                              <div class="pd_m-selectGroup-01_col-01">
                                                          <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
                                                            <select>
                                                              <option value="option1" selected="" disabled="">2021年</option>
                                                              <option value="1">1</option>
                                                              <option value="2">2</option>
                                                            </select>
                                                          </div>
                              </div>
                              <div class="pd_m-selectGroup-01_col-02">
                                                          <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
                                                            <select>
                                                              <option value="option1" selected="" disabled="">4月</option>
                                                              <option value="1">1</option>
                                                              <option value="2">2</option>
                                                            </select>
                                                          </div>
                              </div>
                              <div class="pd_m-selectGroup-01_col-03">
                                                          <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
                                                            <select>
                                                              <option value="option1" selected="" disabled="">30日</option>
                                                              <option value="1">1</option>
                                                              <option value="2">2</option>
                                                            </select>
                                                          </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="pd_m-form-02_field">
                        <div class="pd_m-form-02_legend">開催形式</div>
                        <div class="pd_m-form-02_inputGroup">
                          <ul class="pd_m-radioBtnList-01">
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="type" value="店舗で開催"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">店舗で開催</span>
                                                        </label>
                            </li>
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="type" value="オンライン"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">オンライン</span>
                                                        </label>
                            </li>
                          </ul>
                        </div>
                      </fieldset>
                      <fieldset class="pd_m-form-02_field">
                        <div class="pd_m-form-02_legend">地域</div>
                        <div class="pd_m-form-02_inputGroup">
                                                    <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
                                                      <select>
                                                        <option value="option1" selected="" disabled="">エリア</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                      </select>
                                                    </div>
                        </div>
                      </fieldset>
                      <fieldset class="pd_m-form-02_field">
                        <div class="pd_m-form-02_legend">受付状況</div>
                        <div class="pd_m-form-02_inputGroup">
                          <ul class="pd_m-radioBtnList-01">
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="status1" value="すべて"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">すべて</span>
                                                        </label>
                            </li>
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="status1" value="受付中"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">受付中</span>
                                                        </label>
                            </li>
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="status1" value="受付終了"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">受付終了</span>
                                                        </label>
                            </li>
                          </ul>
                        </div>
                      </fieldset>
                      <fieldset class="pd_m-form-02_field">
                        <div class="pd_m-form-02_legend">空席状況</div>
                        <div class="pd_m-form-02_inputGroup">
                          <ul class="pd_m-radioBtnList-01">
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="status2" value="すべて"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">すべて</span>
                                                        </label>
                            </li>
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="status2" value="あり"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">あり</span>
                                                        </label>
                            </li>
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="status2" value="なし"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">なし</span>
                                                        </label>
                            </li>
                          </ul>
                        </div>
                      </fieldset>
                      <fieldset class="pd_m-form-02_field">
                        <div class="pd_m-form-02_legend">参加費</div>
                        <div class="pd_m-form-02_inputGroup">
                          <ul class="pd_m-radioBtnList-01">
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="cost" value="すべて"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">すべて</span>
                                                        </label>
                            </li>
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="cost" value="有料"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">有料</span>
                                                        </label>
                            </li>
                            <li class="pd_m-radioBtnList-01_listItem">
                                                        <label class="pd_c-radioBtn-01__event">
                                                          <input type="radio" name="cost" value="無料"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">無料</span>
                                                        </label>
                            </li>
                          </ul>
                        </div>
                      </fieldset>
                      <fieldset class="pd_m-form-02_field">
                        <div class="pd_m-form-02_legend">エキスパート指定</div>
                        <div class="pd_m-form-02_inputGroup">
                                                    <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
                                                      <select>
                                                        <option value="option1" selected="" disabled="">カテゴリー</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                      </select>
                                                    </div>
                                                    <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
                                                      <select>
                                                        <option value="option1" selected="" disabled="">エキスパート名</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                      </select>
                                                    </div>
                        </div>
                      </fieldset>
                      <div class="pd_m-submit-01">
                        <div class="pd_m-submit-01_row">
                          <div class="pd_m-submit-01_col-01">
                                                        <button class="pd_c-filterButton pd_c-filterButton-04 pd_e-hover-01 pd_e-hover-01--bgGray" type="reset">条件をクリア</button>
                          </div>
                          <div class="pd_m-submit-01_col-02">
                                                        <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                                          <p class="pd_c-filterButton_text">この条件で絞り込み</p>
                                                        </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-modalBox-01 {
  background-color #fff
  border-radius 16px
  position relative
  margin-top 50px
  margin-bottom 58px
  &#modalBox-eventForm {
    padding-top 60px
    @media screen and (min-width:MIN_POINT) {
      padding-right 30px
      padding-left 30px
    }
    @media screen and (max-width:MAX_POINT) {
      padding-right 20px
      padding-left 20px
    }
  }
  @media screen and (min-width:MIN_POINT) {
    max-width 810px
    margin-right auto
    margin-left auto
    padding 60px 85px
  }
  @media screen and (max-width:MAX_POINT) {
    padding 60px 30px
    margin-right 15px
    margin-left 15px
  }
  &,div,ul,li {
    box-sizing border-box
  }
  .pd_m-itemImages-01 {
    .slick-prev {
      left -55px
    }
    .slick-next {
      right -55px
    }
  }
  .mfp-close {
    text-indent -9999px
    overflow hidden
    background-repeat no-repeat
    background-size 100% auto
    background-image url('/store/images/module/modal_icon_close.svg')
    width 17px
    height 17px
    top 30px
    right 30px
  }
  .pd_c-sliderBox-01_thumbnails {
    width 100%
  }
  .pd_c-sliderBox-01_thumbnailList {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    // 235px/285px
      width 82%
      margin-right auto
      margin-left auto
    }
    .slick-arrow {
      @media screen and (min-width:MIN_POINT) {
      }
      @media screen and (max-width:MAX_POINT) {
        z-index 2
      }
    }
    .slick-next {
      @media screen and (min-width:MIN_POINT) {
      }
      @media screen and (max-width:MAX_POINT) {
        // -28px/-285px
        right -10%;
      }
    }
    .slick-prev {
      @media screen and (min-width:MIN_POINT) {
      }
      @media screen and (max-width:MAX_POINT) {
        // -28px/-285px
        left -10%;
      }
    }
  }
  .pd_c-sliderBox-01_thumbnail {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    // 78px/235px
      width 33%
    }
  }
}

.mfp-bg {
  opacity .3
}
