/*
---
name: footer_nav
category: pd
---
```html
<div class="eccom-cart-header-body">
    <div class="eccom-cart-header-inner">
        <a href="/">
            <picture class="eccom-cart-header-logo"><img class="eccom-cart-header-logo-img" src="/store/images/common/header_logo.png" alt=""></picture>
        </a>
    </div>
</div>
```
*/
/* カートヘッダー */
.eccom-cart-header-body {
  width: 100%;
  height: 89px;
  background: #fff;
}

.eccom-cart-header-inner {
  width: 980px;
  margin: 0 auto;
}

.eccom-cart-header-logo {
  width: 100%;
  height: 100%;
  display: block;
  padding: 29px 0 0 2px;
}

.eccom-cart-header-logo-img {
  width: 219px;
}

/* SP */
@media screen and (max-width: 640px) {
  .eccom-cart-header-body{
    height 76px
  }
  .eccom-cart-header-inner {
    width: 100%;
    margin: 0;
  }

  .eccom-cart-header-logo-img {
    width: 162px;
  }

  .eccom-cart-header-logo {
    padding: 25px 0 0 15px;
  }
}

/* /カートヘッダー */