/*
---
name: pd_m-selectGroup-01
category: module
tag:
  - select
---
```html
<div class="pd_m-selectGroup-01">
  <div class="pd_m-selectGroup-01_row-01">
    <div class="pd_m-selectGroup-01_col-01">
      <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
        <select>
          <option value="option1" selected="" disabled="">2021年</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
      </div>
    </div>
    <div class="pd_m-selectGroup-01_col-02">
      <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
        <select>
          <option value="option1" selected="" disabled="">4月</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
      </div>
    </div>
    <div class="pd_m-selectGroup-01_col-03">
      <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
        <select>
          <option value="option1" selected="" disabled="">30日</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
      </div>
    </div>
    <div class="pd_m-selectGroup-01_col-04">
      <p class="pd_m-selectGroup-01_text-01">から</p>
    </div>
  </div>
  <div class="pd_m-selectGroup-01_row-02">
    <div class="pd_m-selectGroup-01_col-01">
      <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
        <select>
          <option value="option1" selected="" disabled="">2021年</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
      </div>
    </div>
    <div class="pd_m-selectGroup-01_col-02">
      <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
        <select>
          <option value="option1" selected="" disabled="">4月</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
      </div>
    </div>
    <div class="pd_m-selectGroup-01_col-03">
      <div class="pd_c-selectBox-01 pd_c-selectBox-01_selector">
        <select>
          <option value="option1" selected="" disabled="">30日</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
      </div>
    </div>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_m-selectGroup-01 {
  font-size 15px
  &_row-01,
  &_row-02 {
    display flex
    margin-right -4px
    margin-left -4px
    align-items center
    flex-wrap wrap
  }
  &_col-01,
  &_col-02,
  &_col-03,
  &_col-04 {
    padding-right 4px
    padding-left 4px
    margin-top 8px
  }
  &_col-01 {
    @media screen and (min-width:MIN_POINT) {
      width 28%
    }
    @media screen and (max-width:MAX_POINT) {
      width 40%
    }
  }
  &_col-02 {
    @media screen and (min-width:MIN_POINT) {
      width 24%
    }
    @media screen and (max-width:MAX_POINT) {
      width 28%
    }
  }
  &_col-03 {
    @media screen and (min-width:MIN_POINT) {
      width 28%
    }
    @media screen and (max-width:MAX_POINT) {
      width 32%
    }
  }
  &_col-04 {
    @media screen and (max-width:MAX_POINT) {
      width 100%
      margin-top 12px
    }
  }
}
