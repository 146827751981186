/*
---
name: pd_c-radioBtn-01
category: component
tag:
  - checkBox
---
```html
<label class="pd_c-radioBtn-01">
    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="event" value="ウェブサイト" /><span class="pd_c-radioBtn-01_indicator"></span><span class="pd_c-radioBtn-01_text-01">ウェブサイト</span>
</label> 
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-fieldset-01_inputGroup {
  label {
    position relative
    font-size 15px
    line-height (22/15)
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
    
    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
    .pd_c-radioBtn-02_indicator {
      position absolute
      background-repeat no-repeat
      width 24px
      height 24px
      background-image url("/store/images/component/radioBtn-01.svg")
      left 0
    }
    .pd_c-radioBtn-02_text-01 {
      display inline-block
      vertical-align top
      @media screen and (min-width:MIN_POINT) {
        padding-left 35px
      }
      @media screen and (max-width:MAX_POINT) {
        padding-left 30px
      }
    }
    &.pd_js-isActive {
      .pd_c-radioBtn-02_indicator {
        background-image: url("/store/images/component/radioBtn-01_checked.svg");
      }
    }
  }
}
