/*
---
name: pd_c-favoriteBtn-02
category: component
tag:
  - btn
---
```html
  <button class="pd_c-favoriteBtn-02 pd_e-hover-01 pd_e-hover-01--bgGray"><span class="pd_c-favoriteBtn-02_text-01">お気に入り</span></button>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-favoriteBtn-02 {
  background-image url("/store/images/component/favoriteBtn-02_icon_01.svg")
  background-position 21px center;
  background-size 22px auto;
  background-repeat no-repeat;
  background-color #fff
  border:solid 1px #364563;
  border-radius 8px;
  display inline-block;
  line-height 1
  cursor pointer
  padding: 7px 21px 7px 51px;
  &_text-01 {
    line-height: 1;
    color: #364563
    font-size: 15px;
    line-height (25/15)
    display: inline-block;
    vertical-align: middle;
  }
  &.pd_favoriteOn {
    background-image url("/store/images/module/icon_favoriteOn.svg")
  }
}
