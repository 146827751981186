/*
---
name: pd_m-tabList-01
category: module
tag:
  - text
  - tab
---
```html
<ul class="pd_m-tabList pd_m-tabList-01">
  <li class="pd_m-tabList_item pd_active pd_e-hover-01" data-tab="pd_latestInfo">最新情報</li>
  <li class="pd_m-tabList_item pd_e-hover-01" data-tab="pd_lifeStyle">ライフ<br>スタイル</li>
  <li class="pd_m-tabList_item pd_e-hover-01" data-tab="pd_productInfo">商品情報</li>
  <li class="pd_m-tabList_item pd_e-hover-01" data-tab="pd_event">イベント・<br>キャンペーン</li>
</ul>
```
*/
.pd_m-tabList
    display: flex
    font-size: 15px
    justify-content: space-between
    margin:  0 auto -1px
    width 976px
    &_item
        border-bottom: 4px solid transparent
        cursor: pointer
        line-height: 42px
        text-align: center
        width: 244px
        br
            display: none
        a
            color: rgba(0,0,0,0.3)
            display: block
            height: 100%
            width: 100%
        &.pd_active
            border-bottom: 4px solid #364563
            a
                color: rgba(0,0,0,0.9)
@media screen and (max-width: 640px)
    .pd_m-tabList
        font-size: 13px
        width: 100%
        &_item
            align-items: center
            display: flex
            justify-content: center
            line-height: 1.5
            height: 57px
            width: 25%
            br
                display: block