/*
---
name: pd_m-requestTop
category: module
tag:
  - text
  - btn
---
```html
<div class="pd_m-requestTop">
                          <p class="pd_c-eventsSubTitle">イベント申込</p>
  <div class="pd_m-requestTop_panelWrap">
                              <div class="pd_m-requestPanel pd_m-requestPanel01">
                                <ul class="pd_m-requestPanel_textList">
                                  <li class="pd_m-requestPanel_textListItem">
                                    <p class="pd_m-requestPanel_textListTtl">開催日：</p>
                                    <p class="pd_m-requestPanel_textListText">4月18日（土）</p>
                                  </li>
                                </ul>
                                <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                  <p class="pd_c-filterButton_text">申し込む</p>
                                </button>
                              </div>
                              
                              <div class="pd_m-requestPanel pd_m-requestPanel01">
                                <ul class="pd_m-requestPanel_textList">
                                  <li class="pd_m-requestPanel_textListItem">
                                    <p class="pd_m-requestPanel_textListTtl">開催日：</p>
                                    <p class="pd_m-requestPanel_textListText">4月18日（日）</p>
                                  </li>
                                </ul>
                                                            <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                                              <p class="pd_c-filterButton_text">申し込む</p>
                                                            </button>
                              </div>
                              <div class="pd_m-requestPanel pd_m-requestPanel01">
                                <ul class="pd_m-requestPanel_textList">
                                  <li class="pd_m-requestPanel_textListItem">
                                    <p class="pd_m-requestPanel_textListTtl">開催日：</p>
                                    <p class="pd_m-requestPanel_textListText">4月18日（月）</p>
                                  </li>
                                </ul>
                                                            <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                                              <p class="pd_c-filterButton_text">申し込む</p>
                                                            </button>
                              </div>
                              <div class="pd_m-requestPanel pd_no-shadow pd_m-requestPanel01">
                                <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                  <p class="pd_c-filterButton_text">申し込む</p>
                                </button>
                              </div>
  </div>
</div>
```
*/
.pd_m-requestTop
    &_panelWrap
        display: flex
        justify-content: space-between
@media screen and (max-width 640px)
    .pd_m-requestTop
        &_panelWrap
            flex-wrap: wrap