/*
---
name: pd_b-news
category: block
tag:
  - text
---
```html
<section class="pd_b-news">
    <div class="pd_b-news_inner">
        <h3 class="pd_b-news_ttl">お知らせ</h3>
        <div class="pd_b-news_importantWrap">
            <p class="pd_b-news_importantTtl">重要なお知らせ</p>
            <ul class="pd_b-news_importantList">
                <li class="pd_b-news_importantListItem">
                    <p class="pd_b-news_importantDate">2020年01月15日</p><a class="pd_b-news_importantLink pd_e-hover-01" href="">カスタマイズレッツノート SSD 3TB搭載＆オリジナル天板が選択可能な『2020春限定モデル』を発表！ </a>
                </li>
                <li class="pd_b-news_importantListItem">
                    <p class="pd_b-news_importantDate">2020年01月15日</p><a class="pd_b-news_importantLink pd_e-hover-01" href="">カスタマイズレッツノート SSD 3TB搭載＆オリジナル天板が選択可能な『2020春限定モデル』を発表！ </a>
                </li>
            </ul>
        </div>
        <ul class="pd_b-news_list">
            <li class="pd_b-news_listItem">
                <p class="pd_b-news_date">2020年01月15日</p><a class="pd_b-news_link pd_e-hover-01" href="">カスタマイズレッツノート SSD 3TB搭載＆オリジナル天板が選択可能な『2020春限定モデル』を発表！ </a>
            </li>
            <li class="pd_b-news_listItem">
                <p class="pd_b-news_date">2020年01月15日</p><a class="pd_b-news_link pd_e-hover-01" href="">カスタマイズレッツノート SSD 3TB搭載＆オリジナル天板が選択可能な『2020春限定モデル』を発表！カスタマイズレッツノート SSD 3TB搭載＆オリジナル天板が選択可能な『2020春限定モデル』を発表！</a>
            </li>
            <li class="pd_b-news_listItem">
                <p class="pd_b-news_date">2020年01月15日</p><a class="pd_b-news_link pd_e-hover-01" href="">カスタマイズレッツノート SSD 3TB搭載＆オリジナル天板が選択可能な『2020春限定モデル』を発表！</a>
            </li>
            <li class="pd_b-news_listItem">
                <p class="pd_b-news_date">2020年01月15日</p><a class="pd_b-news_link pd_e-hover-01" href="">カスタマイズレッツノート SSD 3TB搭載＆オリジナル天板が選択可能な『2020春限定モデル』を発表！</a>
            </li>
            <li class="pd_b-news_listItem">
                <p class="pd_b-news_date">2020年01月15日</p><a class="pd_b-news_link pd_e-hover-01" href="">カスタマイズレッツノート SSD 3TB搭載＆オリジナル天板が選択可能な『2020春限定モデル』を発表！</a>
            </li>
        </ul>
        <div class="pd_b-news_linkWrap"><a class="pd_e-hover-01" href=""> 商品に関する大切なお知らせ</a><a class="pd_e-hover-01" href=""> お知らせ一覧</a></div>
    </div>
</section>
```
*/
// お知らせ
.pd_b-news
    background-color: rgba(0,0,0,0.05)
    padding: 57px 0 40px
    width: 100vw
.pd_b-news_inner
    margin: 0 auto
    width: 976px
.pd_b-news_ttl
    font-size: 30px
    font-weight: 500
    line-height: 1
    letter-spacing: -0.1px
    position: relative
    text-align: center
.pd_b-news_importantWrap
    background-color: #fff
    border-radius: 10px
    border 1px solid #007BFF
    margin-top: 27px
    padding: 28px 28px 13px 28px
    width: 100%
.pd_b-news_important_ttl
    color: #007BFF
    font-size: 17px
    font-weight: 500
.pd_b-news_importantTtl
    color: #007BFF
    font-size: 17px
    font-weight: 500
.pd_b-news_importantList
    margin-top: 3px
.pd_b-news_importantListItem
    border-bottom: 1px solid #CCCCCC
    display: flex
    padding: 15px 0
    &:last-child
        border 0px
.pd_b-news_importantDate
    color rgba(0,0,0,0.54)
    font-size: 15px
    width: 125px
.pd_b-news_importantDate_tag
    display inline-block
    color #003da5
    font-size 1.2rem
    font-weight bold
    text-decoration none
    margin-right 5px
.pd_b-news_importantDate_new
    display inline-block
    color: #d00000
    font-size 1.2rem
    font-weight bold
    text-decoration none
.pd_b-news_importantLink
    color #364563
    font-size: 15px
    margin-left: 55px
    text-decoration: underline
    width 745px
.pd_b-news_list
    margin-top: 20px
.pd_b-news_listItem
    border-bottom: 1px solid #D4D4D4
    display: flex
    padding: 28px 0
.pd_b-news_date
    color rgba(0,0,0,0.54)
    font-size: 15px
    width: 125px
.pd_b-news_link
    color #364563
    font-size: 15px
    margin-left: 55px
    text-decoration: underline
    width 808px
.pd_b-news_text
    color #364563
    font-size: 15px
    margin-left: 55px
    width 808px
.pd_b-news_linkWrap
    display: flex
    justify-content: flex-end
    padding: 20px
    a
        color #364563
        letter-spacing: 1px;
        margin-left: 30px;
        padding: 0 13px 0 0;
        position: relative;
        text-decoration: underline
        &::after
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            margin: auto;
            border-top: 2px solid #23354f;
            border-right: 2px solid #23354f;
            position: absolute;
            top: 0;
            right: 3px;
            bottom: 0;
            transform: rotate(45deg);
@media screen and (max-width 640px)
    // お知らせ
    .pd_b-news
        margin-top: 84px
        padding: 60px 4vw
        width: 100%
    .pd_b-news_inner
        width: 100%
    .pd_b-news_ttl
        font-size: 22px
    .pd_b-news_important_wrap
        padding: 18px 18px 6px 18px
    // .pd_b-news_important_ttl
    // .pd_b-news_importantList
    .pd_b-news_importantListItem
        flex-wrap: wrap
        padding: 8px 0 15px
    //     &:last-child
    .pd_b-news_importantDate
        font-size: 13px
        width: 100%
    .pd_b-news_importantLink
        margin: 8px 0 0 0;
        line-height: 1.5;
        width: 100%
    .pd_b-news_list
        margin-top: 30px
    .pd_b-news_listItem
        flex-wrap: wrap
        padding: 15px
    .pd_b-news_date
        font-size: 13px
        width: 100%
    .pd_b-news_link
        margin: 5px 0 0 0
        width: 100%
    .pd_b-news_text
        margin: 5px 0 0 0
        width: 100%
    .pd_b-news_linkWrap
        flex-wrap: wrap
        flex-direction: column
        padding: 15px 0 0
        a
            display: block
            margin: 15px 0 0 auto
    .pd_b-news_importantDate_tag
      margin-left 5px
