/*
---
name: pd_b-tabArea
category: block
tag:
  - text
---
```html
<div class="pd_b-tabArea">
    <ul class="pd_m-tabList">
        <li class="pd_m-tabList_item pd_active" data-tab="pd_latestInfo">最新情報</li>
        <li class="pd_m-tabList_item" data-tab="pd_lifeStyle">ライフ<br />スタイル</li>
        <li class="pd_m-tabList_item" data-tab="pd_productInfo">商品情報</li>
        <li class="pd_m-tabList_item" data-tab="pd_event">イベント・<br />キャンペーン</li>
    </ul>
</div>
```
*/
// タブ切り替え部分
.pd_b-tabArea
    border-bottom: 1px solid #364563
    padding-top: 7px
.pd_b-tabArea-03
    padding-top: 30px
@media screen and ( max-width 640px)
    .pd_b-tabArea-02
        overflow-x: scroll
        padding-top: 50px
        position: relative
        .pd_b-tabAreaInner-02
            background-color: #fff
            position: absolute
            top: 0
            left: 0
            right: 0
            z-index 2
            &.pd_active
                position: fixed