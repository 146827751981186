/*
---
name: pd_c-calendar
category: component
tag:
  - calendar
---
```html
    <div class="pd_c-calendarTemp pd_c-calendarTemp-march">
        <p class="pd_c-calendarTemp_ttl">2021年3月</p>
        <table class="pd_c-calendarTemp_table">
            <tr class="pd_c-calendarTemp_week">
                <th>日</th>
                <th>月</th>
                <th>火</th>
                <th>水</th>
                <th>木</th>
                <th>金</th>
                <th>土</th>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td> </td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>13</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>14</td>
                <td>15</td>
                <td>16</td>
                <td>17</td>
                <td>18</td>
                <td>19</td>
                <td>20</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>21</td>
                <td>22</td>
                <td>23</td>
                <td class="pd_c-calendarTemp_active">24</td>
                <td class="pd_c-calendarTemp_active">25</td>
                <td class="pd_c-calendarTemp_active">26</td>
                <td class="pd_c-calendarTemp_active">27</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td class="pd_c-calendarTemp_active">28</td>
                <td class="pd_c-calendarTemp_active">29</td>
                <td class="pd_c-calendarTemp_active">30</td>
                <td class="pd_c-calendarTemp_active">31</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </table>
</div>
```
*/
.pd_c-calendarTemp
    width: 478px
    &_ttl
        font-size: 17px
        text-align: center
    &_table
        width: 100%;
        margin-top: 15px
        td,th
            border: 1px solid #C2C2C2
            text-align: center
            line-height: 39px
    &_week
        background-color: #F2F2F2
    // &_date
    &_active
        background-color: #DEE3ED
        cursor: pointer
@media screen and (max-width 640px)
    .pd_c-calendarTemp
        margin-bottom: 20px
        width: 100%
        // &_ttl
        //     font-size: 17px
        //     text-align: center
        // &_table
        //     margin-top: 15px
        //     td,th
        //         border: 1px solid #C2C2C2
        //         text-align: center
        //         line-height: 39px
        // &_week
        //     background-color: #F2F2F2
        // // &_date
        // &_active
        //     background-color: #DEE3ED
        //     cursor: pointer