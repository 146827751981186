/*
---
name: bd_b-statsu-01
category: block
tag:
  - text
  - img
  - icon
---
```html
<div class="pd_b-status-01">
  <div class="pd_b-status-01_inner">
    <div class="pd_b-status-01_colGroup">
      <div class="pd_b-status-01_col-01">
        <div class="pd_b-status-01_coin">
          <div class="pd_b-status-01_coinItem-01">コイン</div>
          <div class="pd_b-status-01_coinItem-02">555</div>
        </div>
        <div class="pd_b-status-01_linkListWrapper">
          <div class="pd_b-status-01_linkListBody">
            <ul class="pd_b-status-01_linkList">
              <li class="pd_b-status-01_linkListItem"><a class="pd_b-status-01_linkListAnchor pd_e-hover-01" href="/">コイン確認画面</a></li>
              <li class="pd_b-status-01_linkListItem"><a class="pd_b-status-01_linkListAnchor pd_e-hover-01" href="/">コインモール</a></li>
            </ul>
          </div>
          <div class="pd_b-status-01_linkListFoot"><a class="pd_c-linkText-01 pd_e-hover-01" href="/">コインとは</a>
          </div>
        </div>
        <div class="pd_b-status-01_linkListWrapper">
          <div class="pd_b-status-01_linkListHead">
            <p class="pd_b-status-01_linkListHeading">保有クーポン</p>
            <p class="pd_b-status-01_linkListSub">10枚</p>
          </div>
          <div class="pd_b-status-01_linkListBody">
            <ul class="pd_b-status-01_linkListBody">
              <li class="pd_b-status-01_linkListItem"><a class="pd_b-status-01_linkListAnchor pd_e-hover-01" href="/">クーポン詳細</a></li>
            </ul>
          </div>
          <div class="pd_b-status-01_linkListFoot"><a class="pd_c-linkText-01 pd_e-hover-01" href="/">クーポンの使い方</a>
          </div>
        </div>
        <div class="pd_b-status-01_linkListWrapper">
          <div class="pd_b-status-01_linkListHead pd_js-b-status-01_linkListHead pd_e-hover-01"><span class="pd_b-status-01_closeIcon"></span>
            <p class="pd_b-status-01_linkListHeading">登録情報の変更・追加</p>
          </div>
          <div class="pd_b-status-01_linkListBody">
            <ul class="pd_b-status-01_linkList">
              <li class="pd_b-status-01_linkListItem"><a class="pd_b-status-01_linkListAnchor pd_e-hover-01" href="/">お客さま情報変更</a></li>
              <li class="pd_b-status-01_linkListItem"><a class="pd_b-status-01_linkListAnchor pd_e-hover-01" href="/">クレジットカード情報</a></li>
              <li class="pd_b-status-01_linkListItem"><a class="pd_b-status-01_linkListAnchor pd_e-hover-01" href="/">配送先情報</a></li>
              <li class="pd_b-status-01_linkListItem"><a class="pd_b-status-01_linkListAnchor pd_e-hover-01" href="/">メルマガ登録・解除</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pd_b-status-01_col-02">
        <div class="pd_b-status-01_statusItems">
          <div class="pd_b-status-01_statusItem">
            <div class="pd_b-status-01_itemIcon"><img class="pd_b-status-01_itemIconImg" src="/store/images/block/status_icon_order.svg"></div>
            <div class="pd_b-status-01_statusTexts"><a class="pd_b-status-01_statusHeading pd_e-hover-01" href="/">注文履歴</a>
              <p class="pd_b-status-01_statusText">過去にご注文された商品、返品依頼の履歴がご確認いただけます。</p>
            </div>
          </div>
          <div class="pd_b-status-01_statusItem">
            <div class="pd_b-status-01_itemIcon"><img class="pd_b-status-01_itemIconImg" src="/store/images/block/status_icon_contract.svg"></div>
            <div class="pd_b-status-01_statusTexts"><a class="pd_b-status-01_statusHeading pd_e-hover-01" href="/">契約履歴</a><span class="pd_e-numberIcon-01">3</span>
              <p class="pd_b-status-01_statusText">ご契約中または過去にご契約されたプラン、サービスがご確認いただけます。</p>
            </div>
          </div>
          <div class="pd_b-status-01_statusItem">
            <div class="pd_b-status-01_itemIcon"><img class="pd_b-status-01_itemIconImg" src="/store/images/block/status_icon_estimate.svg"></div>
            <div class="pd_b-status-01_statusTexts"><a class="pd_b-status-01_statusHeading pd_e-hover-01" href="/">見積一覧</a>
              <p class="pd_b-status-01_statusText">過去にご注文された商品、返品依頼の履歴がご確認いただけます。</p>
            </div>
          </div>
          <div class="pd_b-status-01_statusItem">
            <div class="pd_b-status-01_itemIcon"><img class="pd_b-status-01_itemIconImg" src="/store/images/block/status_icon_myplan.svg"></div>
            <div class="pd_b-status-01_statusTexts"><a class="pd_b-status-01_statusHeading pd_e-hover-01" href="/">マイプラン</a>
              <p class="pd_b-status-01_statusText">保存されたマイプランがご確認いただけます。</p>
            </div>
          </div>
          <div class="pd_b-status-01_statusItem">
            <div class="pd_b-status-01_itemIcon"><img class="pd_b-status-01_itemIconImg" src="/store/images/block/status_icon_appliances.svg"></div>
            <div class="pd_b-status-01_statusTexts"><a class="pd_b-status-01_statusHeading pd_e-hover-01" href="/">マイ家電</a>
              <p class="pd_b-status-01_statusText">お持ちのPanasonic商品を登録すると、修理依頼や消耗品購入などがスムーズに行えます。</p>
              <ul class="pd_b-status-01_subLinkList">
                <li class="pd_b-status-01_subLinkItem"><a class="pd_c-linkText-01 pd_e-hover-01" href="/">マイ家電リスト</a>
                </li>
                <li class="pd_b-status-01_subLinkItem"><a class="pd_c-linkText-01 pd_e-hover-01" href="/">マイ家電登録</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="pd_b-status-01_statusItem">
            <div class="pd_b-status-01_itemIcon"><img class="pd_b-status-01_itemIconImg" src="/store/images/block/status_icon_appliances.svg"></div>
            <div class="pd_b-status-01_statusTexts"><a class="pd_b-status-01_statusHeading pd_e-hover-01" href="/">カスタマイズレッツノート<br>修理申し込み履歴</a>
              <p class="pd_b-status-01_statusText">カスタマイズレッツノートの修理申し込み履歴の一覧がご確認いただけます。</p>
            </div>
          </div>
          <div class="pd_b-status-01_statusItem">
            <div class="pd_b-status-01_itemIcon"><img class="pd_b-status-01_itemIconImg" src="/store/images/component/largeBtn-01_icon_calendar.svg"></div>
            <div class="pd_b-status-01_statusTexts"><a class="pd_b-status-01_statusHeading pd_e-hover-01" href="/">イベント・体験会</a>
              <p class="pd_b-status-01_statusText">お申込み済みイベント、過去にご参加されたイベントのご確認やアンケートにご回答いただけます。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_b-status-01 {
  padding-bottom 60px
  @media screen and (max-width:MAX_POINT) {
    padding-bottom 0px
  }
  &_inner {
    @media screen and (min-width:MIN_POINT) {
      max-width 980px
      margin 40px auto 0
    }
    @media screen and (max-width:MAX_POINT) {
      margin 40px 15px 0
      padding 24px 0 0
      border-top solid 1px rgba(0,0,0,.2)
    }
  }
  &_colGroup {
    @media screen and (min-width:MIN_POINT) {
      display flex
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_col-01 {
    @media screen and (min-width:MIN_POINT) {
      width 200px
      border-top solid 1px rgba(0,0,0,.2)
      padding-top 24px
    }
    @media screen and (max-width:MAX_POINT) {
      border-bottom solid 1px rgba(0,0,0,.2)
      padding-bottom 24px
    }
  }
  &_col-02 {
    @media screen and (min-width:MIN_POINT) {
      flex 1
      padding-left 48px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 40px
    }
  }
  &_coin {
    display flex
    align-items center
  }
  &_coinItem-01 {
    background-image url("/store/images/block/status-01_icon_point_01.svg")
    background-position left center;
    background-size 20px auto;
    background-repeat no-repeat;
    display inline-block;
    line-height 1
    cursor pointer
    padding: 7px 0 8px 25px;
    font-size 15px
    font-weight 500
  }
  &_coinItem-02 {
    font-size 15px
    margin-left auto
    font-weight 500
  }
  &_period {
    margin-top 8px
    font-size 13px
    line-height (20/13)
    color rgba(0,0,0,.6)
  }
  &_linkListWrapper {
    margin-top 24px
    &+& {
      border-top solid 1px rgba(0,0,0,.2)
    }
  }
  &_linkListHead {
    display flex
    padding-top 24px
    align-items center
    font-size 15px
    position relative
  }
  &_closeIcon {
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: calc(50% + 12px);
    right: 12px;
    margin-top: -9px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 1px;
      background-color: #364563;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      transform-origin: center;
      transition: transform .025s ease-in;
    }
  }
  &_linkListWrapper.pd_js-closed .pd_b-status-01_closeIcon {
    &::after {
      transform-origin: center;
      transform: translate(-50%) rotate(90deg);
    }
  }
  &_linkListHeading {
    font-weight 500
  }
  &_linkListSub {
    font-weight 500
    margin-left auto
  }
  &_linkList {
    font-size 15px
    line-height (20/15)
  }
  &_linkListItem {
    margin-top 17px
  }
  &_linkListAnchor {
    background-image url("/store/images/block/status-01_icon_arrow_01_r.svg")
    background-position right center;
    background-size 8px auto;
    background-repeat no-repeat;
    display block
    &:link,
    &:hover,
    &:visited,
    &:visited:hover,
    &:active,
    &:focus {
      color rgba(0,0,0,.9)
    }
  }
  &_linkListFoot {
    text-align right
    margin-top 16px
  }
  &_infoLink {
    &:link,
    &:hover,
    &:visited,
    &:visited:hover,
    &:active,
    &:focus {
      text-decoration underline
      color #364563
    }
  }
  &_statusItems {
    @media screen and (min-width:MIN_POINT) {
      display flex
      flex-wrap wrap
      margin-left -10px
      margin-left -10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_statusItem {
    @media screen and (min-width:MIN_POINT) {
      width 50%
      padding-right 10px
      padding-left 10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_statusItem {
    @media screen and (min-width:MIN_POINT) {
      &:not(:nth-child(-n+2)){
        margin-top 53px
      }

    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 40px
    }
  }
  &_statusItem {
    display flex
    &--wide {
      width 100%;
    }
  }
  &_itemIcon {
    width 45px
    img {
      width 100%
    }
  }
  &_itemIcon--small {
    width 38px
    img {
      width 100%
    }
  }
  &_itemIcon--margin {
    margin-left 4px
    margin-right 6px
  }
  &_statusTexts {
    flex 1
    padding-left 20px
  }
  &_statusHeading {
    font-size 17px
    font-weight 500
    &:link,
    &:hover,
    &:visited,
    &:visited:hover,
    &:active,
    &:focus {
      color #364563
      text-decoration underline
    }
  }
  &_statusText {
    margin-top 16px
    font-size 15px
  }
  &_subLinkList {
    display flex
    margin-top 8px
  }
  &_subLinkItem {
    &+& {
      margin-left 20px
    }
  }
}
.pd_js-b-status-01_linkListHead {
  cursor pointer
}
