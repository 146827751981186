/*
---
name: pd_c-errormessage
category: component
tag:
  - text
---
```html
    <div class="pd_c-errormessage">
        <p class="pd_c-errormessage_text">エラーメッセージが入ります</p>
    </div> 
```
*/
@media screen and (min-width:641px) {
    .pd_c-errormessage {
        max-width 980px
        border 1px solid #000 
        padding 13px 0
        margin 50px auto 0
        text-align center
    }
}

@media screen and (max-width:640px) {
    .pd_c-errormessage {
        border 1px solid #000 
        padding 13px 0
        margin 50px 15px 0
        text-align center
    }
}



@media screen and (min-width:641px) {
    .pd_campaign__contents .pd_c-errormessage {
        margin 25px auto 10px
    }
}
