/*
---
name:  pd_e-required-01
category: element
tag:
  - tag
---
```html
<span class="pd_e-required-01">必須 </span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-required-01 {
  display inline-block
  align-items: center
  border solid 1px #CC0E00
  border-radius 10px
  color #CC0E00
  padding 0 8px
}
