/*
---
name: pd_c-price-01
category: component
tag:
  - text
---
```html
  <p class="pd_c-price-01 pd_c-price-01--discount"><span class="pd_c-price-01_text-02">特別価格</span><span class="pd_c-price-01_text-01">39,280</span><span class="pd_c-price-01_text-02">円</span><span class="pd_c-price-01_text-03">（税込）</span></p>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-price-01 {
  color rgba(0,0,0,.9)
  margin-top 10px
  &--discount {
    color #CC0E00
  }

  &_text-01,
  &_text-02,
  &_text-03 {
    line-height 1
  }

  &_text-01 {
    font-size 38px
  }

  &_text-02 {
    font-size: 16px;
  }

  &--discount {
    .pd_c-price-01_text-03 {
      color rgba(0,0,0,.3)
      font-size 16px;
      color #CC0E00
    }
  }

  &--before {
    margin-top 0
    .pd_c-price,
    .pd_c-price_small {
      position relative;
      display inline-block
      top 0
      &::after {
        content ''
        border-bottom 1px solid black
        position absolute
        top 10px
        left 0
        height 0
        width 100%
        }
      .pd_c-price-01_text-01,
      .pd_c-price-01_text-02,
      .pd_c-price-01_text-03 {
        font-size 16px
      }
    }
    .pd_c-price{
      .pd_c-price-01_text-01 {
        font-size 22px
      }
      .pd_c-price-01_text-02 {
        font-size 16px
      }
      &::after {
        content ''
        border-bottom 1px solid black
        position absolute
        top 15px
        left 0
        height 0
        width 100%
        }
    }
  }
  &--thin {
    margin-bottom 10px
    color #CC0E00
    .pd_c-price-01_text-01 {
      line-height 1
    }
  }
}
.pd_c-price
  &_small
    margin-top 4px
    .pd_c-price-01_text-01
    .pd_c-price-01_text-02
    .pd_c-price-01_text-03
      font-size 16px
