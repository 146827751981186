/*
---
name: pd_e-panelItem-01
category: element
tag:
  - tag
---
```html
<span class="pd_set_item">セット商品</span>
```
*/
.pd_set_item
    background-color: #DFE3EC
    border-radius: 4px
    color: rgba(0,0,0,0.9)
    display: inline-block
    font-size 13px
    line-height: 20px
    margin-left: 8px
    padding: 0 8px