/*
---
name: pd_c-planSelect
category: component
tag:
  - checkBox
---
```html
<label class="pd_c-planSelect">
    <div class="pd_c-planSelect_btn">
    <input class="pd_c-planSelect_btn_input" type="radio" id="sensor1" name="sensor1"><span class="pd_c-planSelect_btn_label" for="sensor1"></span>
    </div>
    <div class="pd_c-planSelect_left"><span class="pd_e-planName">センサー3年</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
    </div>
    <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円/月(税込)</span><br><span class="pd_e-planTag-01">在庫あり</span>
    </div>
</label>
<label class="pd_c-planSelect">
    <div class="pd_c-planSelect_btn">
    <input class="pd_c-planSelect_btn_input" type="radio" id="sensor1" name="sensor1"><span class="pd_c-planSelect_btn_label" for="sensor1"></span>
    </div>
    <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
    </div>
    <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-01">在庫あり</span>
    </div>
</label>
<label class="pd_c-planSelect">
    <div class="pd_c-planSelect_btn">
    <input class="pd_c-planSelect_btn_input" type="radio" id="sensor1" name="sensor1" disabled><span class="pd_c-planSelect_btn_label" for="sensor1"></span>
    </div>
    <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
    </div>
    <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-02">在庫なし</span>
    </div>
</label>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-planSelect 
    padding 15px 15px
    background #fff
    border 1px solid #F2F2F2
    border-radius 8px
    margin-top 15px
    transition .1s 
    display flex
    justify-content space-between
    box-shadow 0 3px 6px rgba(0,0,0,.16)
    &:hover 
        background #ecf4fc
        border 1px solid #a4ccfc
        transition .1s
    &_left
        width 55%
    &_right
        width 45%
        text-align right
    &_btn 
        width 24px
        height 24px
        margin-right 10px
        margin-top auto
        margin-bottom auto
        
        input
            display none
        input[type="radio"] + span
            position relative
            display inline-block
            background-image url("/store/images/component/radioBtn-01.svg")
            background-repeat no-repeat
            width 24px
            height 24px
        input[type="radio"]:checked + span
            background-image url("/store/images/component/radioBtn-01_checked.svg")
            background-repeat no-repeat
            width 24px
            height 24px
