/*
---
name: pd_e-releaseDate
category: element
tag:
  - text
---
```html
  <p class="pd_e-releaseDate">2020年1月発売</p>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_e-releaseDate {
  margin-top 7px
  color: rgba(0,0,0,.6)
  font-size 13px
  text-align left
}