/*
---
name: pd_m-pagenation
category: module
tag: 
  - icon
  - btn
---
```html
<div class="pd_m-pagenation">
    <div class="pd_m-pagenation_inner">
        <div class="pd_m-pagenation_btn pd_m-pagenation_prevBtn pd_e-hover-01"><a href="#"></a></div>
        <ol class="pd_m-pagenation_list">
            <li class="pd_m-pagenation_listItem pd_current pd_e-hover-01"><a href="#">1</a></li>
            <li class="pd_m-pagenation_listItem pd_e-hover-01 pd_e-hover-01--bgGray"><a href="#">2</a></li>
            <li class="pd_m-pagenation_listItem pd_e-hover-01 pd_e-hover-01--bgGray"><a href="#">3</a></li>
        </ol>
        <div class="pd_m-pagenation_btn pd_m-pagenation_nextBtn pd_e-hover-01"><a href="#"></a></div>
    </div>
</div>
```
*/
.pd_m-pagenation
    width: 100%
    &_inner
        align-items: center
        display: flex
        justify-content: center
        margin: 0 auto
        position: relative
    &_btn
        border-top: 2px solid #23354F
        border-right: 2px solid #23354F
        cursor: pointer
        width: 9px
        height: 9px
        margin: 0 10px
        a
            width: 9px
            height: 9px
            display: block

    &_prevBtn
        transform: rotate(-135deg)
    &_list
        display: flex
        justify-content: space-between
    &_listItem
        border 1px solid #CCCCCC
        border-radius: 8px
        margin: 0 5px
        height: 44px
        width: 40px
        a
            display: flex
            justify-content: center
            align-items: center
            width: 100%
            height: 100%
            color: #000
        &.pd_current
            border 1px solid #364563
            background-color: #364563
            a
                color: #fff
    &_nextBtn
        transform: rotate(45deg)