/*
---
name: pd_b-eventsFirstView
category: block
tag:
  - text
  - img
  - icon
  - tag
---
```html
<div class="pd_b-eventsFirstView">
    <div class="pd_b-eventsFirstView_inner"><a class="pd_c-linkText-01 pd_c-linkText-r pd_e-hover-01" href="">くらしに役立つ各種イベントTOPへ</a>
        <h1 class="pd_m-contentTtl-01">スマートコーヒー焙煎サービス「The Roast」体験会</h1>
    <div class="pd_b-eventsFirstView_itemWrap">
        <div class="pd_b-eventsFirstView_img"><img src="com/images/block/eventsFirstView_img.jpg" alt=""></div>
        <div class="pd_b-eventsFirstView_textWrap">
        <p class="pd_b-eventsFirstView_label"><span class="pd_set_item">有料</span><span class="pd_set_item">有料</span><span class="pd_set_item">事前予約制</span><span class="pd_set_item">事前予約制</span><span class="pd_set_item">事前予約制</span>
        </p>
        <p class="pd_b-eventsFirstView_text">パナソニック発、話題のスマートコーヒー焙煎サービスを、もうお試しになりましたか。<br>世界中から厳選した生豆を、焙煎士のレシピで楽しむ、極上コーヒーを味わうための入門篇「The Roast Basicサービス」。そして、自分好みのコーヒー焙煎工程（プロファイル）を自由に作成できる焙煎上級篇「The Roast Expertサービス」。<br>両ラインナップの焙煎体験をお気軽にぜひどうぞ。 </p>
        <ul class="pd_c-tagList pd_c-tagList-02">
            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray"> <a href="">＃食・レシピ</a></li>
            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray"> <a href="">＃タグ</a></li>
            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray"> <a href="">＃タグ</a></li>
            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray"> <a href="">＃タグ</a></li>
            <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray"> <a href="">＃食・レシピ</a></li>
        </ul>
        <a class="pd_c-btn-01 pd_c-btn-arrow pd_e-hover-01" href="#eventsRequest">
            <p class="pd_c-btn-01Text-01">
            <span>お申し込みへすすむ</span>
            </p>
        </a>
        <button class="pd_c-no-stock-btn_btn" disabled>
            <p class="pd_c-btn-01Text-01">
            <span>申込期間は終了しています。</span>
            </p>
        </button>
        <button class="pd_c-no-stock-btn_btn" disabled>
            <p class="pd_c-btn-01Text-01">
            <span>このイベントは終了しています。</span>
            </p>
        </button>
        </div>
    </div>
    </div>
</div>
```
*/
.pd_b-eventsFirstView
    margin-top: 33px
    &_bnr
        align-self center
        background-color #DEE3ED
        border-radius: 8px
        display: flex
        margin-bottom: 40px
        padding 26px 0px 26px 30px
        width: 100%
        .pd_c-linkText-01
            margin: 0 0 0 225px;
            line-height: 34px;
    &_bnrIcon
        height: 40px
        width: 40px
        img
            display: block
    &_bnrText
        font-size: 22px
        font-weight: 500
        margin: 2px 0 0 20px
        br
            display: none
    &_inner
        margin: 0 auto
        width: 976px
    &_itemWrap
        display: flex
        justify-content: space-between
        margin-top: 22px
        width: 100%
    &_img
        align-items: center
        display: flex
        justify-content: center
        height: 483px
        width: 644px
        img
            max-width: 644px
            max-height: 100%
    &_textWrap
        height: 100%
        padding: 0 0 0 20px
        width: calc(100% - 644px)
        margin-top -10px
    &_label
        // margin-top: 23px
        .pd_set_item
            margin-left: 0
            margin-right: 12px
        .pd_set_item 
            margin-top 10px
    &_text
        margin-top: 19px
    &_reserved
        background-color: #dee3ed
        border-radius: 8px
        margin-top: 25px
        padding: 20px
        .pd_c-linkText-01
            display: table
            text-align: center
            margin: 15px auto 0
    &_reservedTtl
        font-size: 17px
        font-weight: 500
        text-align: center
    &_reservedText
        font-size: 13px
        margin-top: 10px
        text-align: center
    .pd_c-tagList
        margin-top: 16px
        justify-content: flex-start
    .pd_c-btn-01
        margin-top: 25px
        padding: 15px 14px 14px;
    .pd_c-btn-01.pd_c-btn-arrow::after
        top: 45%
        right: 23px
    .pd_c-linkText-r
        display: block
        margin: 0 8px 0 auto
        text-align: right
    .pd_m-contentTtl-01
        margin-top: 0
        letter-spacing: 0.2px
    .pd_c-favoriteBtn-01
        line-height: 1.5
        display: block
        margin: 0 0 0 auto
@media screen and (max-width 640px)
    .pd_b-eventsFirstView
        margin-top: 59px
        &_bnr
            flex-wrap: wrap
            margin: 0 auto 40px
            padding: 10px 20px 15px
            width: 92%
            .pd_c-linkText-01
                display: table
                margin: 6px 0 0 auto
                width: calc(100% - 60px)
                &::after
                    right: 9px
        &_bnrIcon
            margin-top: 10px
            // img
        &_bnrText
            width: calc(100% - 60px)
            br
                display: block
        &_inner
            width: 100%
        &_itemWrap
            display: block
            margin-top: 20px
            padding:  0 15px
        &_img
            height: auto
            width: 100%
            img
                max-height: 100%
                max-width: 100%
        &_textWrap
            margin-top: 20px
            padding: 0
            width: 100%
        // &_label
        //     .pd_set_item
        &_text
            margin-top: 17px
        // .pd_c-tagList
        // .pd_c-btn-01
        // .pd_c-btn-01.pd_c-btn-arrow::after
        .pd_c-linkText-r
            margin-right: 18px
        .pd_m-contentTtl-01
            margin-top: 7px

        // .pd_c-favoriteBtn-01