/*
---
name: pd_c-headingLv1-01
category: component
tag:
  - text
---
```html
  <h1 class="pd_c-headingLv1-01">コードレススティック掃除機 「パワーコードレス」</h1>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-headingLv1-01 {
  font-weight 500
  @media screen and (min-width:MIN_POINT) {
    font-size 40px
  }
  @media screen and (max-width:MAX_POINT) {
    font-size 25px
  }
  &--center {
    text-align center
  }
}
