/*
---
name: pd_m-searchResultContent
category: module
tag:
  - text
  - img
  - icon
  - tag
---
```html
<div class="pd_m-searchResultContent">
  <div class="pd_m-searchResultContent-01">
    <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
  </div>
  <div class="pd_m-searchResultContent-02">
    <div class="pd_m-searchResultContent_detaiWrap-01">
      <div class="pd_c-searchListItem">
        <p class="pd_c-searchListItem_kind">商品</p>
        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
      </div>
    </div>
    <div class="pd_m-searchResultContent-02_detaiWrap-02">
      <div class="pd_m-searchResultContent-02_inner-01">
        <ul class="pd_m-searchResultContent-02_list-01">
          <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
          </li>
        </ul>
        <ul class="pd_m-searchResultContent-02_list-02">
          <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
          </li>
          <li class="pd_m-searchResultContent-02_listItem-02"></li>
        </ul>
        <p class="pd_e-releaseDate">2020年1月発売</p>
        <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
        <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
        <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
      </div>
      <div class="pd_m-searchResultContent-02_inner-02">
        <div class="pd_c-productPanelItem">
          <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
          <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
          <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
            <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
          </div>
          <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
          <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
          <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
.pd_m-searchResultContent
    border-top: 1px solid #CCCCCC
    display: flex
    padding: 20px 0 0
    flex-direction row
    .pd_m-searchResultContent-01
        display: flex
        padding-right 13px
        width 22%
        &_img
            align-items: center
            display: flex
            height: 146px
            justify-content: center
            margin-right: 20px
            width: 146px
            img
                max-width: 146px;
                max-height: 100%;
    .pd_m-searchResultContent-02
        width 78%
        &_detaiWrap-01
            align-content: baseline
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            width: calc(100% - 166px)
        &_detaiWrap-02
            display: flex
            flex-wrap: wrap
            justify-content: space-between
        &_inner-01
            width 250px
        &_inner-02
            margin-top 16px
            width 250px
        &_inner-03
            width 600px
            
        &_list-01
            width 335px
            margin-top 3px
            display flex
            flex-wrap wrap
        &_listItem-01
            margin-top 3px
        &_list-02
            width 335px
            margin-top 3px
            display flex
            flex-wrap wrap
        &_listItem-02
            margin-top 3px
        &_list-03
            width 700px
            margin-top 3px
            display flex
            flex-wrap wrap
        &_list-04
            width 700px
            margin-top 3px
            display flex
            flex-wrap wrap
        // &_ttlArea
        //     color: rgba(0,0,0,0.9)
        //     margin-top: 13px
        //     width: 340px
        &_ttl
            font-size: 17px
            font-weight: 500
            line-height: 1.33
            width: 335px
            color: rgba(0,0,0,0.9)
            margin-top: 7px
        &_ttl-2
            font-size: 17px
            font-weight: 500
            line-height: 1.33
            width: 700px
            color: rgba(0,0,0,0.9)
            margin-top: 7px
        &_text
            margin-top: 9px
            line-height: 1.5
.pd_m-searchResultContent-footer
    padding-left 160px
    margin-bottom 20px
.pd_m-searchResultContent-footer-2
    padding-left 213px
    margin-bottom 20px
    
@media screen and (max-width 640px)
    .pd_m-searchResultContent
        border-top: 1px solid #CCCCCC
        display: flex
        padding: 20px 0 0
        flex-direction row
        .pd_m-searchResultContent-01
            padding-right 13px
            width 36%
            &:last-child
                border-top: 1px solid #CCCCCC
            &_img
                height: 110px
                width: 110px
                img
                    max-width: 110px;
                    max-height: 100%;
        .pd_m-searchResultContent-02
            width 64%
            &_detaiWrap-01
                
            &_detaiWrap-02
                display block
                
            // &_ttlArea
            // &_ttl
            &_text
                line-height: 1.4
            &_inner-01
                width 200px
            &_inner-02
                margin-top 16px
                width 220px
            &_inner-03
                .pd_c-dateArea 
                    width 210px
                
            &_list-01
                width 200px
            &_listItem-01
                margin-top 3px
            &_list-02
                width 228px
            &_listItem-02
                margin-top 3px
            &_list-03
                width 200px
            &_list-04
                width 228px
            &_ttl
                width: 220px
            &_ttl-2
                width: 220px
    .pd_m-searchResultContent-footer
        padding-left 0
        margin-bottom 20px
    .pd_m-searchResultContent-footer-2
        padding-left 0
        margin-bottom 20px
@media screen and (max-width 320px)
    .pd_m-searchResultContent
        .pd_m-searchResultContent-02
            &_inner-02
                margin-top 16px
                width 190px
            &_inner-03
                .pd_c-dateArea 
                    width 190px
            &_list-01
                width 200px
            &_listItem-01
                margin-top 3px
            &_list-02
                width 200px
            &_listItem-02
                margin-top 3px
            &_list-03
                width 200px
            &_list-04
                width 200px
            &_ttl
                width: 200px
            &_ttl-2
                width: 200px
    .pd_c-productPanelItem_discountPriceTax
        padding-left 0