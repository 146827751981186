/*
---
name: pd_c-breadcrumb
category: component
tag:
  - text
---
```html
  <div class="pd_c-breadcrumb">
      <ul>
          <li><a href="/">TOP</a></li>
          <li><a href="/store/events">くらしに役立つ各種イベント</a></li>
          <li>スマートコーヒー焙煎サービス「The Roast」体験会</li>
      </ul>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-breadcrumb {
  @media screen and (min-width:MIN_POINT) {
    margin: 20px 0 0;
  }
  @media screen and (max-width:MAX_POINT) {
    display none
    margin 0px
  }
  &--sp {
    @media screen and (min-width:MIN_POINT) {
      display none
    }
    @media screen and (max-width:MAX_POINT) {
      display block
    }
  }
  & ul {
    font-size: 15px;
    margin: 0 auto;
    max-width: 980px;
    display: flex;
    flex-wrap: wrap;
    color: #000000;
    line-height: 1.1;
    @media screen and (min-width:MIN_POINT) {
      padding: 0 2px 0 2px;
    }
    @media screen and (max-width:MAX_POINT) {
      align-items: center;
      border-top: 1px solid rgba(0,0,0,0.2);
      display: flex;
      padding: 10px 15px 0 20px;
    }
  }
  & ul li {
    color rgba(0,0,0,.9)
    position relative
    padding-left 24px
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      position: relative;
      margin-bottom: 10px;
    }
  }
  & ul li::before {
    content: "";
    background-image: url('/store/com/images/component/breadcrumb_icon_arrow.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    display: block;
    width: 8px
    height: 13px
    position: absolute;
    left: 8px;
    @media screen and (min-width:MIN_POINT) {
      top: 50%;
      transform:translateY(-50%);
    }
    @media screen and (max-width:MAX_POINT) {
      top: .15em;
    }
  }
  & ul li:first-child {
    padding-left 0
  }
  & ul li:first-child::before {
    display none
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  & ul a {
    color: rgba(0,0,0,0.3);
    text-decoration: none;
  }
}
