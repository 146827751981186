/*
---
name: pd_c-sliderBox-01
category: component
tag:
  - slider
---
```html
<div class="pd_c-sliderBox-01">
    <div class="pd_c-sliderBox-01_list pd_c-sliderBox-01_list--top">
        <div class="pd_c-sliderBox-01_listItem">
            <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01" href="#modalBox-01"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png?text=セット商品" alt="" /></a></figure>
        </div>
        <div class="pd_c-sliderBox-01_listItem">
            <figure class="pd_c-sliderBox-01_figure"> <a class="pd_c-sliderBox-01_anchor pd_e-hover-01" href="#modalBox-01"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png?text=セット商品" alt="" /></a></figure>
        </div>
        <div class="pd_c-sliderBox-01_listItem pd_c-sliderBox-01_listItem--movie">
            <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_c-sliderBox-01_anchor--movie" href="#modalBox-01"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png?text=セット商品" alt="" /></a></figure>
        </div>
        <div class="pd_c-sliderBox-01_listItem">
            <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01" href="#modalBox-01"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png?text=セット商品" alt="" /></a></figure>
        </div>
        <div class="pd_c-sliderBox-01_listItem">
            <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01" href="#modalBox-01"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png?text=セット商品" alt="" /></a></figure>
        </div>
        <div class="pd_c-sliderBox-01_listItem">
            <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01" href="#modalBox-01"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png?text=セット商品" alt="" /></a></figure>
        </div>
        <div class="pd_c-sliderBox-01_listItem">
            <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01" href="#modalBox-01"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png?text=セット商品" alt="" /></a></figure>
        </div>
        <div class="pd_c-sliderBox-01_listItem">
            <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01" href="#modalBox-01"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png?text=セット商品" alt="" /></a></figure>
        </div>
        <div class="pd_c-sliderBox-01_listItem">
            <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01" href="#modalBox-01"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png?text=セット商品" alt="" /></a></figure>
        </div>
        <div class="pd_c-sliderBox-01_listItem">
            <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01" href="#modalBox-01"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png?text=セット商品" alt="" /></a></figure>
        </div>
    </div>
    <div class="pd_c-sliderBox-01_thumbnails">
        <ul class="pd_c-sliderBox-01_thumbnailList pd_c-sliderBox-01_thumbnailList--top">
            <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
            <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
            <li class="pd_c-sliderBox-01_thumbnail pd_c-sliderBox-01_thumbnail--movie pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
            <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
            <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
            <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
            <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
            <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
            <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
            <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
        </ul>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_c-sliderBox-01 {
  width: 100%;
  position relative
  @media screen and (min-width:MIN_POINT) {
  }
  @media screen and (max-width:MAX_POINT) {
  }
  &_list {
    &::after {
      content: '';
      display: block;
      clear: both;
    }
    &--inModal {
      // .pd_c-sliderBox-01_listItem {
      //   display: block!important
      // }
    }
    &--top {
      .pd_c-sliderBox-01_anchor--movie {
        position relative
        display block
        &:before {
          position absolute
          content ''
          top 50%
          left 50%
          width 70px
          height 70px
          transform translate(-50%,-50%)
          background-repeat no-repeat
          background-image url('/store/images/component/sliderBox-01_icon_movie.svg')
          background-size 100% auto
        }
      }
    }
  }
  &_list, &_thumbnails {
    position relative
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
    .slick-arrow {
      position absolute
      top 50%
      border none
      z-index 2
      text-indent -9999px
      overflow hidden
      transform translateY(-50%)
    }
  }
  &_list {
    .slick-arrow {
      background-color #fff
      border-radius 50%
      box-shadow 0 3px 6px rgba(0,0,0,.16)
      @media screen and (min-width:MIN_POINT) {
        width 40px
        height 40px
      }
      @media screen and (max-width:MAX_POINT) {
        opacity: 0;
      }
      &::after {
        content ''
        width 8px
        height 13px
        background-position center center
        background-repeat no-repeat
        position absolute
        left 50%
        top 50%
        background-size 100% auto
        transform translate(-50%,-50%)
        background-image url('/store/images/common/sliderBox-01_icon_arrow_01.svg')
      }
      &:hover {
        background-color #F2F2F2
      }
    }
    .slick-next {
      @media screen and (min-width:MIN_POINT) {
        right 10px
      }
      @media screen and (max-width:MAX_POINT) {
      }
    }
    .slick-prev {
      @media screen and (min-width:MIN_POINT) {
        left 10px
      }
      @media screen and (max-width:MAX_POINT) {
      }
      &::after {
        transform-origin center
        transform translate(-50%,-50%) rotate( 180deg )
      }
    }
    &--top {
      .pd_c-sliderBox-01_anchor::after {
        content ''
        position absolute
        width 30px
        height 30px
        background-repeat no-repeat
        background-image url('/store/images/component/sliderBox-01_icon_loupe.svg')
        background-size contain
        z-index 2
        bottom 10px
        right 10px
      }
    }
  }
  &_listItem {
    // float left
    position relative
    width 100%
    
  }
  &_anchor {
    display flex
    position relative
    justify-content center
    align-items center
    width 644px
    height 483px
    @media screen and (max-width:MAX_POINT) {
      width 100%
    }
  }
  &_video {
    display block
    width 100%
    height 480px
    @media screen and (max-width:MAX_POINT) {
      height auto
    }
  }
  &_thumbnails {
    width 100%
    margin-right auto
    margin-left auto
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 20px
    }

    .slick-arrow {
      background-color transparent
      background-repeat no-repeat
      position absolute
      background-image url('/store/images/component/sliderBox-01_icon_arrow_02.svg')
      background-size 8px auto
      background-position center center
      transform translateY(-50%)
      @media screen and (min-width:MIN_POINT) {
        width 30px
        height 30px
        box-shadow: 0 0 0;
      }
      @media screen and (max-width:MAX_POINT) {
        background-color: #fff;
        .slick-next{
          right: 0
        }
      }
      &:hover {
        opacity .9
      }
    }

    .slick-next {
      @media screen and (min-width:MIN_POINT) {
        right -33px
      }
      @media screen and (max-width:MAX_POINT) {
        right 0px
      }
    }

    .slick-prev {
      transform translateY(-50%) rotate(180deg)
      @media screen and (min-width:MIN_POINT) {
        left -33px
      }
      @media screen and (max-width:MAX_POINT) {
      }
    }

  }
  &_thumbnailList {
    @media screen and (min-width:MIN_POINT) {
      width: 540px
      margin-top 20px
      margin-right auto
      margin-left auto
    }
    @media screen and (max-width:MAX_POINT) {
    }
    &::after {
      content: '';
      display: block;
      clear: both;
    }
    .slick-current {
      img {
        border: solid 2px #364563
      }
    }
  }
  &_thumbnail {
    float left
    cursor pointer
    position relative
    width 80px
    height 80px
    @media screen and (min-width:MIN_POINT) {
      // width 90px
      padding-right 5px
      padding-left 5px
    }
    @media screen and (max-width:MAX_POINT) {
      width 88px
      padding-right 4px
      padding-left 4px
    }
    div{
      display flex
      justify-content center
      align-items center
      width 80px
      height 80px
      @media screen and (max-width:MAX_POINT) {
        width 100%
      }
      img {
        max-width 80px
        max-height 100%
        @media screen and (max-width:MAX_POINT) {
          max-width 100%
        }
      }
    }
    &--movie {
      &::after {
        position absolute
        content ''
        top 50%
        left 50%
        width 40px
        height 40px
        transform translate(-50%,-50%)
        background-repeat no-repeat
        background-image url('/store/images/component/sliderBox-01_icon_movie.svg')
        background-size 100% auto
      }
    }
  }
  .pd_c-youtubeMovie-01 {
    margin-top 0
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      margin-left 0
      margin-right 0
    }
  }
}
