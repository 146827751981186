/*
---
name: pd_e-money-01
category: element
tag:
  - text
---
```html
<span class="pd_e-money-01">3000円</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-money-01 {
    line-height 1
    font-size 13px
    font-weight bold
}