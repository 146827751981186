/*
---
name: pd_c-row-03
category: component
tag:
  - text
  - img
---
```html
<div class="pd_c-row-03">
  <div class="pd_c-row-03_content-01">
    <h1 class="pd_c-row-03_heading">1/1フォーマット</h1>
    <p class="pd_c-row-03_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト本文テキスト本文テキスト本文テキスト本文テキスト</p>
  </div>
  <div class="pd_c-row-03_img"><img src="http://placehold.jp/3d4070/ffffff/976x488.png"></div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-row-03 {
  margin-top 40px
  @media screen and (max-width:MAX_POINT) {
  }
  &_leftCol,
  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
      width: 50%;
      padding-right 10px
      padding-left 10px
    }
    > .pd_c-row-03 {
      margin-top 0
    }
  }
  &_heading {
    color rgba(0,0,0,.9)
    font-weight bold
    font-size 22px
    line-height (33/22)
  }
  &_text-01 {
    color rgba(0,0,0,.9)
    font-size 15px
    line-height (23/15)
    @media screen and (min-width:MIN_POINT) {
      margin-top 13px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 16px
    }
  }
  &_img {
        align-items: center
        display: flex
        text-align center
        justify-content: center
        margin-top: 15px
        height: 488px
        width: 100%
        img{
            max-height: 488px
            max-width: 100%
        }
    @media screen and (max-width:MAX_POINT) {
      margin-top 18px
    }
  }
}
