/*
---
name: pd_c-entryMember
category: component
tag:
  - tag
---
```html
<span class="pd_c-entryMember">NEW</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-entryMember-01_span-01 {
    display inline-block
    line-height 1
    font-size 13px
    color rgba(0,0,0,.6)
    margin 8px 5px 0 0

}