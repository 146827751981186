/*
---
name: pd_m-confFlow
category: module
tag:
  - text
---
```html
<ul class="pd_m-confFlow">
    <li class="pd_m-confFlow_list">
        <p class="pd_m-confFlow_title">1. 今回のイベントをどうやってお知りになりましたか？</p>
        <p class="pd_m-confFlow_text">友人からのすすめ</p>
    </li>
    <li class="pd_m-confFlow_list">
        <p class="pd_m-confFlow_title">2. 質問文が入ります</p>
        <p class="pd_m-confFlow_text">選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります</p>
    </li>
    <li class="pd_m-confFlow_list">
        <p class="pd_m-confFlow_title">3. 質問文が入ります</p>
        <p class="pd_m-confFlow_text">選択された内容テキストが入ります</p>
    </li>
    <li class="pd_m-confFlow_list">
        <p class="pd_m-confFlow_title">4. 質問文が入ります</p>
        <p class="pd_m-confFlow_text">選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります</p>
    </li>
</ul>
```
*/
.pd_m-confFlow
    border-bottom: 1px solid #CCCCCC
    border-top: 1px solid #CCCCCC
    &_list
        margin: 40px 0
        line-height: 1.5
    &_title
        font-size: 17px
        font-weight: 500
    &_text
        margin-top: 16.2px
        font-size: 15px
@media screen and (max-width 640px)
    .pd_m-confFlow
        padding-bottom: 4px