/*
---
name: pd_e-circleIcon-01
category: element
---
```html
<span class="pd_e-circleIcon-01 pd_e-circleIcon-01--new"></span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-circleIcon-01 {
  position absolute
  width 44px
  height 44px
  top -10px
  right -10px
  z-index 2
  background-repeat no-repeat
  background-size 100% auto
  &--new {
      background-image: url("/store/com/images/element/circleIcon-01_new.svg");
  }
  &--isOpen {
      background-image: url("/store/com/images/element/circleIcon-01_isOpen.svg");
  }
  &--online {
      background-image: url("/store/com/images/element/circleIcon-01_online.svg");
  }
}
