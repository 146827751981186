/*
---
name: pd_c-coin-01
category: component
tag:
  - text
---
```html
<div class="pd_c-coin-01">
    <div class="pd_c-coin-01_flex"><span class="pd_c-coin-01_text-01">還元コイン</span><span class="pd_c-coin-01_text-02">555</span></div>
</div>
<p class="pd_c-coin-attention">※クーポンなどを利用した場合、獲得コインは変動いたします。</p>
<div class="pd_c-coin-01">
    <div class="pd_c-coin-01_flex"><span class="pd_c-coin-01_text-01">還元コイン</span><span class="pd_c-coin-01_text-02">555</span></div><span class="pd_c-entryMember-01_span-01">（エントリー会員の場合）</span>
</div>
<p class="pd_c-coin-attention">※会員登録した場合、コインが獲得できます</p>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_c-coin-attention
  color #666
  font-size 14px
  margin-top 4px
.pd_c-coin-01 {
  background-image url("/store/com/images/component/coin-01_icon_01.svg")
  background-color #F2F2F2
  background-position top 11px left 20px
  background-repeat no-repeat
  background-size 20px auto
  border-radius 8px
  font-size 15px
  line-height (23/15)
  @media screen and (min-width:MIN_POINT) {
    padding: 9px 20px 9px 25px
  }
  @media screen and (max-width:MAX_POINT) {
    padding: 11px 20px 9px 45px
  }
  &_flex {
    display flex
    justify-content: space-between;
  }

  &_text-01 {
    
    @media screen and (min-width:MIN_POINT) {
      padding-left 20px
    }
    @media screen and (max-width:MAX_POINT) {
      padding-left 20px
    }
  }

  &_text-02 {
    

    @media screen and (min-width:MIN_POINT) {
    }

    @media screen and (max-width:MAX_POINT) {
    }

  }

}
