/*
---
name: pd_m-recommend
category: module
tag:
  - text
  - img
  - icon
  - tag
---
```html
<div class="pd_m-recommend">
    <p class="pd_m-recommend_Ttl">あなたへのおすすめ</p>
    <ul class="pd_m-recommend_slider pd_m-recommend_list">
        <li class="pd_m-recommend_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">記事</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                        </div>
                    </div>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a></li>
        <li class="pd_m-recommend_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">商品<span class="pd_set_item">セット商品</span>
                        </p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                        </div>
                    </div>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productPanelItem_price"><span>348,000</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>348,000</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_discountPrice">
                        特別価格<br><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-mini"><span>348,000</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">
                        月額<br><span class="pd_c-productPanelItem_monthlyPriceNum">42,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                </div>
            </a></li>
        <li class="pd_m-recommend_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">記事</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                        </div>
                    </div>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a></li>
        <li class="pd_m-recommend_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">記事</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                        </div>
                    </div>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a></li>
        <li class="pd_m-recommend_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">記事</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                        </div>
                    </div>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a></li>
        <li class="pd_m-recommend_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">商品<span class="pd_set_item">セット商品</span>
                        </p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                        </div>
                    </div>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productPanelItem_price"><span>348,000</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>348,000</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_discountPrice">
                        特別価格<br><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-mini"><span>348,000</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">
                        月額<br><span class="pd_c-productPanelItem_monthlyPriceNum">42,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                </div>
            </a></li>
        <li class="pd_m-recommend_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">記事</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                        </div>
                    </div>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a></li>
    </ul>
</div>
```
*/
.pd_m-recommend
    margin 50px auto
    width: 995px
    &_Ttl
        font-size: 30px
        font-weight: 500
        text-align: center
    &_list
        margin-top: 15px
        position: relative
        .pd_m-smallBox
            width: 179px
            padding-bottom: 0
            margin: 0
        .pd_m-smallBoxItem_img
            height: 179px
            width: 179px
            img
                max-width: 179px
        .pd_m-smallBoxItem_ttl
            font-size: 15px
            margin-top: 6px
        .pd_c-tagListItem
            padding 0 9px
        .pd_c-productPanelItem_modelNum
            margin-top: 10px
        .pd_c-productPanelItem_price
            font-size: 17px
            margin-top: 10px
        .pd_c-productPanelItem_stock
            margin-top: 5px
        .pd_c-productPanelItem_price-bar
            font-size: 15px
        .pd_c-productPanelItem_discountPrice
            font-size: 12px
        .pd_c-productPanelItem_discountPriceTax
            font-size: 12px
    &_listItem
        padding: 0 10px
    &_Arrow
        background-repeat: no-repeat
        background-position: center center
        background-color: #fff
        border: 0px
        border-radius: 50%
        box-shadow: 2px 2px 6px rgba(0 0 0 10%);
        height: 40px
        position: absolute
        top:89.5px
        transform: translateY(-50%)
        width: 40px
        z-index 1
        &:hover {
          background-color #F2F2F2
        }

    &_prevArrow
        background-image: url('/store/images/common/recently_checked_prev.svg')
        box-shadow: -2px 2px 6px rgba(0 0 0 10%);
        display: none!important
        left: -10px
        &.pd_active
            display: inline-block!important
    &_nextArrow
        background-image: url('/store/images/common/recently_checked_next.svg')
        right: -10px
@media screen and (max-width 640px)
    .pd_m-recommend
        overflow: hidden
        padding: 0 0 0 4vw
        width: 100%
        &_Ttl
            font-size: 22px
            padding-right: 18px;
        &_list
            width: 100%
            // .pd_m-smallBox_Wrap
            .pd_m-smallBox
                width: 140px
            .pd_m-smallBoxItem_img
                height: 140px
                width: 140px
                img
                    max-width: 140px
        &_listItem
            padding: 0
        &_Arrow,.pd_m-recommend_prevArrow.pd_active
            display: none!important
@media screen and (min-width 641px) and (max-width 980px)
    .pd_m-recommend_prevArrow
        left: 15px
@media screen and (min-width 641px) and (max-width 980px)
    .pd_m-recommend_nextArrow
        right: 15px
