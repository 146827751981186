/*
---
name: pd_m-searchSidebar
category: module
tag:
  - text
  - checkBox
  - btn
  - icon
---
```html
<ul class="pd_m-searchSidebar">
    <li class="pd_m-searchSidebar_item">
        <p class="pd_m-searchSidebar_ttl">条件で絞り込む</p>
        <ul class="pd_c-checkBoxList">
            <li class="pd_c-checkBoxList_item pd_e-hover-01">
                <input type="checkBox" id="pd_c-checkBoxList_input1" />
                <label class="pd_c-checkBoxList_label" for="pd_c-checkBoxList_input1">
                    <p>在庫あり</p>
                </label>
            </li>
            <li class="pd_c-checkBoxList_item pd_e-hover-01">
                <input type="checkBox" id="pd_c-checkBoxList_input2" />
                <label class="pd_c-checkBoxList_label" for="pd_c-checkBoxList_input2">
                    <p>キャンペーン商品</p>
                </label>
            </li>
            <li class="pd_c-checkBoxList_item pd_e-hover-01">
                <input type="checkBox" id="pd_c-checkBoxList_input3" />
                <label class="pd_c-checkBoxList_label" for="pd_c-checkBoxList_input3">
                    <p>定額利用サービス</p>
                </label>
            </li>
            <li class="pd_c-checkBoxList_item pd_e-hover-01">
                <input type="checkBox" id="pd_c-checkBoxList_input4" />
                <label class="pd_c-checkBoxList_label" for="pd_c-checkBoxList_input4">
                    <p>おすすめ・限定商品</p>
                </label>
            </li>
            <li class="pd_c-checkBoxList_item pd_e-hover-01">
                <input type="checkBox" id="pd_c-checkBoxList_input5" />
                <label class="pd_c-checkBoxList_label" for="pd_c-checkBoxList_input5">
                    <p>消耗品・付属品</p>
                </label>
            </li>
        </ul>
    </li>
    <li class="pd_m-searchSidebar_item">
        <p class="pd_m-searchSidebar_ttl">価格で絞り込む</p>
        <div class="pd_c-priceChoice pd_c-priceChoice-01">
            <div class="pd_c-priceChoice_inputWrap">
                <input class="pd_c-priceChoice_input" type="text" />
                <p class="pd_c-priceChoice_inputText">~</p>
                <input class="pd_c-priceChoice_input" type="text" />
                <p class="pd_c-priceChoice_inputText">円</p>
            </div>
        </div>
        <button class="pd_c-okBtn pd_e-hover-01">OK</button>
    </li>
    <li class="pd_m-searchSidebar_item">
        <p class="pd_m-searchSidebar_ttl">カテゴリを絞り込む</p>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">映像</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">カメラ</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">オーディオ</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">電話・FAX・テレビドアホン</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">メディア・アクセサリー</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">調理</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">空調・季節</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">家事</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">健康</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">美容</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">電池・充電器</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">住まい</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">あかりと電気設備</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">ホビー（工具）・ペット</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">消耗品・付属品</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">カスタマイズレッツノート</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">カーナビ・地図ソフト</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">SANYOの消耗品・付属品</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">自転車</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">通信サービス</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">商品連携・保証サービス</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">IoT家電</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
        <ul class="pd_c-sideAcc">
            <li class="pd_c-sideAcc_list">
                <div class="pd_c-sideAcc_ttl pd_e-hover-01">その他</div>
                <ul class="pd_c-sideAcc_contentList">
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">テレビ </a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">ポータブルAV</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">映像プロジェクター</a></li>
                    <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="#">チューナー</a></li>
                </ul>
            </li>
        </ul>
    </li>
</ul>
```
*/
.pd_m-searchSidebar
    width: 200px
    &_item
        border-top 1px solid #CCCCCC
        padding: 24px 0
        &:last-child
            .pd_m-searchSidebar_ttl
                margin-bottom: 20px
    &_ttl
        font-size: 17px
        font-weight: 500
        margin-bottom: 14px
@media screen and (max-width 640px)
    .pd_m-searchSidebar
        display: none
