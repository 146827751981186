/*
---
name: pd_e-smallTag-02
category: element
tag:
  - tag
---
```html
<span class="pd_e-smallTag-02">NEW</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-smallTag-02 {
    display inline-block
    background-color #FF8833
    border-radius 4px
    padding 3px 8px
    line-height 1
    font-size 13px
    color #fff
    margin-right 8px
    &+.pd_m-smallBoxItem_ttl {
        margin-top 6px
    }
}