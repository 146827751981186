/*
---
name: pd_b-userInfo-01
category: block
tag:
  - icon
  - text
---
```html
<div class="pd_m-userInfo-01">
  <div class="pd_m-userInfo-01_inner">
    <div class="pd_m-userInfo-01_head">
      <div class="pd_m-userInfo-01_headGroup">
        <div class="pd_m-userInfo-01_col-01 pd_m-userInfo-01_col-01--headGroup">
          <p class="pd_m-userInfo-01_name"><span class="pd_m-userInfo-01_text-01">山田 太郎</span><span class="pd_m-userInfo-01_text-02">さん</span><span class="pd_m-userInfo-01_text-03">いつもありがとうございます。</span></p>
        </div>
        <div class="pd_m-userInfo-01_col-02 pd_m-userInfo-01_col-02--headGroup">
          <ul class="pd_m-userInfo-01_linkList">
            <li class="pd_m-userInfo-01_linkItem"><a class="pd_c-linkText-01 pd_e-hover-01" href="/">ログアウト</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="pd_m-userInfo-01_body">
      <div class="pd_m-userInfo-01_bodyGroup">
        <div class="pd_m-userInfo-01_col-01 pd_m-userInfo-01_col-01--bodyGroup">
          <div class="pd_c-rank-01 pd_c-rank-01--gold">ゴールド </div>
          <div class="pd_c-rank-01 pd_c-rank-01--silver">シルバー</div>
          <div class="pd_c-rank-01 pd_c-rank-01--bronze">ブロンズ</div>
          <div class="pd_c-rank-01 pd_c-rank-01--entry">エントリー</div>
        </div>
        <div class="pd_m-userInfo-01_col-02 pd_m-userInfo-01_col-02--bodyGroup"><a class="pd_c-linkText-01 pd_e-hover-01" href="/">会員ランクについて</a>
        </div>
        <div class="pd_m-userInfo-01_col-03 pd_m-userInfo-01_col-03--bodyGroup">
          <button class="pd_c-favoriteBtn-02 pd_e-hover-01 pd_e-hover-01--bgGray pd_js-favoriteBtn" type="button"><span class="pd_c-favoriteBtn-02_text-01">お気に入り</span></button>
                                    <button class="pd_c-mailBtn pd_e-hover-01--bgGray" type="button"><span class="pd_c-mailBtn_text">入荷案内メール一覧</span></button>
        </div>
      </div>
    </div>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-userInfo-01 {
  background-color #F2F2F2
  border-radius 8px
  @media screen and (min-width:MIN_POINT) {
    max-width 980px
    margin 40px auto
    padding 30px
  }
  @media screen and (max-width:MAX_POINT) {
    margin 40px 15px 0
    padding 20px
  }
  &_headGroup,
  &_bodyGroup {
    @media screen and (min-width:MIN_POINT) {
      display flex
      align-items center
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_bodyGroup {
    @media screen and (max-width:MAX_POINT) {
      display flex
      flex-wrap wrap
    }
  }
  &_body {
    margin-top 20px
    padding-top 24px
    border-top solid 1px rgba(0,0,0,.3)
  }
  &_col-01 {
    &--headGroup {
      flex 1
    }
    &--bodyGroup {
    }
  }
  &_col-02 {
    &--headGroup {
      flex 1
    }
    &--bodyGroup {
      @media screen and (min-width:MIN_POINT) {
        margin-left 40px
      }
      @media screen and (max-width:MAX_POINT) {
        text-align right
        margin-left auto
      }
    }
  }
  &_col-03 {
    margin-left auto
    display: flex;
    justify-content: space-between;
    width: 380px;

    @media screen and (min-width:MIN_POINT) {
      padding-left 10px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-left auto
      margin-top 20px
      flex-flow: column;
      align-items: flex-end;
      justify-content: space-between;
      height: 95px;
    }
  }
  &_linkList {
    display flex
    justify-content:flex-end;
    margin-top 13px
  }
  &_linkItem {
    & + & {
      margin-left 24px
    }
  }
  &_text-01 {
    font-size 25px
    font-weight 500
  }
  &_text-02 {
    font-size 15px
    font-weight 500
    margin-left 1em
  }
  &_text-03 {
    font-size 15px
    @media screen and (min-width:MIN_POINT) {
      margin-left 16px
    }
    @media screen and (max-width:MAX_POINT) {
      display block
    }
  }
}
