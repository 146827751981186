/*
---
name: pd_m-contentTtl-01
category: module
tag:
  - text
---
```html
<h1 class="pd_m-contentTtl-01">申し込みキャンセル確認</h1>
```
*/
.pd_m-contentTtl-01
    font-size: 40px
    font-weight: 500
    margin 20px auto 12px
    width: 976px
@media screen and (max-width 640px)
    .pd_m-contentTtl-01
        font-size: 25px
        margin 55px auto 18px
        padding: 0 15px
        width: 100%
    .pd_campaign__contents .pd_m-contentTtl-01 
        margin: 32px auto 18px;
