/*
---
name: pd_b-searchResultList
category: block
tag:
  - text
  - checkBox
  - btn
  - img
  - tag
---
```html
<div class="pd_b-searchResultList">
    <ul class="pd_b-searchResultList_list">
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
        <li class="pd_b-searchResultList_listItem"><a class="c_black" href="/">
            <div class="pd_m-searchResultContent">
            <div class="pd_m-searchResultContent-01">
                <div class="pd_m-searchResultContent_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt=""></div>
            </div>
            <div class="pd_m-searchResultContent-02">
                <div class="pd_m-searchResultContent_detaiWrap-01">
                                                    <div class="pd_c-searchListItem">
                                                        <p class="pd_c-searchListItem_kind">商品</p>
                                                        <object class="pd_c-searchListItem_favorite pd_js-favoriteBtn"><a><img class="pd_m-searchListItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-searchListItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></a></object>
                                                    </div>
                </div>
                <div class="pd_m-searchResultContent-02_detaiWrap-02">
                <div class="pd_m-searchResultContent-02_inner-01">
                    <ul class="pd_m-searchResultContent-02_list-01">
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">受付中</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">定期</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">近日発売</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">セット商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-01"> <span class="pd_e-smallTag-01">軽減税率対象</span>
                    </li>
                    </ul>
                    <ul class="pd_m-searchResultContent-02_list-02">
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">プレゼント付</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                    <li class="pd_m-searchResultContent-02_listItem-02"></li>
                    </ul>
                                                        <p class="pd_e-releaseDate">2020年1月発売</p>
                    <p class="pd_m-searchResultContent-02_ttl">地上・BS・110度CSデジタルハイビジョン液晶テレビ</p>
                    <p class="pd_c-productListItem_modelNum">TH-65GZ1800</p>
                    <p class="pd_c-productListItem_modelNum-02">TH-65GZ1800後継機</p>
                </div>
                <div class="pd_m-searchResultContent-02_inner-02">
                    <div class="pd_c-productPanelItem">
                    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
                        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
                    </div>
                    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
                    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
                    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="pd_c-productPanelItem_guideArea-02">
            <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
            <p class="pd_c-productPanelItem_note">※枠不要</p>
            <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
            </div>
            <div class="pd_m-searchResultContent-footer">
            <ul class="pd_c-tagList">
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#食・レシピ</a></object>
                </li>
                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                <object><a href="">#タグ</a></object>
                </li>
            </ul>
            </div></a></li>
    </ul>
</div>
```
*/
.pd_b-searchResultList
    width: 100%
.pd_m-searchResultContent_img
    overflow hidden
@media screen and (max-width 640px)
    .pd_b-searchResultList
        padding: 0 15px