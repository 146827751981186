/*
---
name: pd_m-contentSubText-01
category: module
tag:
  - text
---
```html
<p class="pd_m-contentSubText-01">あなたの「＃○○したい」を叶えるイベント情報を発信しますリード文が入ります。リード文が入ります。リード文が入ります。リード文が入ります。</p>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-contentSubText-01 {
  @media screen and (min-width:MIN_POINT) {
    max-width 980px
    margin-right auto
    margin-left auto
  }
  @media screen and (max-width:MAX_POINT) {
    margin-right 15px
    margin-left 15px
  }
}
