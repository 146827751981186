/*
---
name: pd_c-cart-01
category: component
tag:
  - btn
---
```html
  <div class="pd_c-cart-01">
      <div class="pd_c-cart-01_inner">
          <div class="pd_c-cart-01_col-01">
              <div class="pd_c-cart-01_orders">数量：</div>
              <div class="pd_c-cart-01_selectWrapper">
                  <select class="pd_c-cart-01_select">
                      <option value="">999</option>
                  </select>
              </div>
          </div>
          <div class="pd_c-cart-01_col-02">
              <button class="pd_c-cart-01_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)">
                  <p class="pd_c-cart-01_btnText-01"><span>カートへ入れる</span></p>
              </button>
          </div>
      </div>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-cart-01 {
   &_inner {
     display flex
     flex-wrap wrap
     justify-content flex-end
     align-items center
     width 100%
   }
  &_col-01,
  &_col-02 {
    width 100%
  }
  &_col-01 {
    display flex
    justify-content flex-end
    align-items center
  }
  &_orders,
  &_select {
    display inline-block
    vertical-align middle
  }
  &_orders {
    font-size 15px
    margin-right 3px
  }
  &_selectWrapper {
    position relative
    &::before {
      background-image url('/store/images/module/searchForm_arrow.svg')
      background-repeat no-repeat
      background-size contain
      content ""
      display block
      height 10px
      position absolute
      right 12px
      top 50%
      transform translateY(-50%)
      width 18px
      z-index 1
    }

    @media screen and (max-width:MAX_POINT) {
      margin-right: 8px
    }
  }
  &_select {
    width 80px
    -webkit-appearance none
    -moz-appearance none
    appearance none
    display flex
    align-items center
    border 1px solid #707070
    border-radius 8px
    font-size 15px
    height 52px
    letter-spacing 1px
    padding 0 0 0 15px
    background-color #fff
    &::-ms-expand {
      display none
    }
  }
  &_btn {
    color #fff
    text-align center
    padding 0
    background #364563
    border none
    display block;
    width 100%
    border-radius 8px
    padding 14px
    margin-top 24px

    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_btnText-01 {
    display inline-block
    line-height 1
    background-image url("/store/com/images/component/cart-01_icon_cart.svg")
    background-position left 50%
    background-repeat no-repeat
    background-size 24px auto
    &::before {
      content ''
      display inline-block
      height 24px
      vertical-align middle
    }
    > span {
      display inline-block
      padding-top 8px
      margin-left 34px
      white-space: nowrap;
    }
  }

}
