/*
---
name: pd_c-table-01
category: component
tag:
  - table
---
```html
<div class="pd_c-table-01">
    <table class="pd_c-table-01_table">
        <colgroup>
            <col class="pd_c-table-01_col-01" />
            <col class="pd_c-table-01_col-02" />
        </colgroup>
        <tbody>
            <tr>
                <th>放送メディア</th>
                <td>上デジタル放送：3チューナー<br />BSデジタル放送：3チューナー<br />110度CSデジタル放送：3チューナー<br />BS4K/110度CS4K放送：2チューナー<br />CATVパススルー対応：○<br />CATV受信：○</td>
            </tr>
            <tr>
                <th>画面面寸法</th>
                <td>幅：1650mm・高さ：928mm・対角：1893mm</td>
            </tr>
            <tr>
                <th>外形寸法</th>
                <td>幅：1680mm・高さ：1033mm・奥行：350mm(テレビスタンド含む)</td>
            </tr>
            <tr>
                <th>質量</th>
                <td>約60kg(テレビスタンド含む)</td>
            </tr>
            <tr>
                <th>付属品</th>
                <td>リモコン(1), 単3形マンガン乾電池(2), 取扱説明書(1), 組み立て部品(一式), 転倒・落下防止部品（一式）, 壁掛け用ねじ 黒 M8×20(4), ●本機は従来のB-CASカードに代わり「ACASチップ」を内蔵しています。</td>
            </tr>
        </tbody>
    </table>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-table-01 {
  &_table {
    color rgba(0,0,0,.9)
    font-size 13px
  }
  &_col-01 {
    @media screen and (min-width:MIN_POINT) {
      width: 18%
    }
    @media screen and (max-width:MAX_POINT) {
      width: 22%
    }
  }
  &_col-02 {
  }
  th, td {
    border: solid 1px #707070;
    vertical-align: top;
    padding: 21px 15px;
  }
  th {
    background-color: rgba(0,0,0,.05);
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
}
