/*
---
name: pd_b-eventsDetail
category: block
tag:
  - text
  - img
  - icon
  - btn
  - modal
---
```html
<div class="pd_b-eventsDetailModal pd_b-eventsDetailModal01">
  <div class="pd_b-eventsDetailModal_bg"></div>
  <div class="pd_b-eventsDetailModal_main">
    <div class="pd_b-eventsDetailModal_close"></div>
    <p class="pd_b-eventsDetailModal_title">スマートコーヒー焙煎サービス「The Roast」体験会</p>
    <table class="pd_b-eventsDetailModal_table">
      <tr>
        <th>開催日時</th>
        <th>残客数</th>
        <th>お申し込み</th>
      </tr>
      <tr>
        <td>2021年<br class="pd_b-eventsDetailModal-pc">4月17日（土） <span>14:30～15:00</span></td>
        <td>2</td>
        <td> 
          <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
            <p class="pd_c-filterButton_text">申し込む</p>
          </button>
        </td>
      </tr>
      <tr>
        <td>2021年<br>4月17日（土）<span>15:30～16:00</span></td>
        <td>5</td>
        <td> 
          <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
            <p class="pd_c-filterButton_text">申し込む</p>
          </button>
        </td>
      </tr>
      <tr>
        <td>2021年<br>4月17日（土）<span>16:30～17:00</span></td>
        <td>8</td>
        <td> 
          <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
            <p class="pd_c-filterButton_text">申し込む</p>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
```
*/
.pd_modalOpen
    height: 100vh
    overflow: hidden
    width: 100vw
.pd_b-eventsDetailModal-pc
    display: block
.pd_b-eventsDetailModal-sp
    display: none
.pd_b-eventsDetailModal
    display: none
    position: fixed
    top: 0
    left: 0
    min-height: 100%
    height: 100%
    overflow-y: scroll
    padding: 30.6vh 0
    width: 100vw
    z-index 1
    &.pd_active
        display: block
    &_bg
        background-color: rgba(0,0,0,0.3)
        left: 0
        min-height: 100%
        top: 0
        position fixed
        width: 100vw
    &_main
        background-color: #fff
        border-radius: 16px
        box-shadow: 0px 2px 4px rgba(0,0,0,0.3)
        margin: 0 auto
        padding: 60px 35px 60px 30px
        position: relative
        width: 810px
        z-index 1
    &_close
        background-image: url('/store/images/block/eventsDetailModal_close_icon.svg')
        background-size: contain
        background-position: center
        content: ""
        cursor: pointer
        display: block
        height: 24px
        position: absolute
        top: 30px
        right: 30px
        width: 24px
    &_title
        font-size: 17px
        font-weight: 500
        text-align: center
    &_table
        border: 1px solid #707070
        margin-top: 26px
        width 100%
        tr
            border: 1px solid #707070
            font-size: 13px
        th
            background-color: #F2F2F2
            border: 1px solid #707070
            text-align: center
            line-height: 57px
            &:nth-child(1)
                width: 120px
            &:nth-child(2)
                width: 128px
            &:nth-child(3)
                width: 125px
            &:nth-child(4)
                text-align: center
                width: 68px
            &:nth-child(5)
                text-align: center
                width: 68px
            &:nth-child(6)
                width: 91px
            &:nth-child(7)
                width: auto
        td
            border: 1px solid #707070
            padding: 18px 15px 18px 14px;
            vertical-align: middle;
            &:nth-child(4)
                text-align: center
            &:nth-child(5)
                text-align: center
    .pd_c-filterButton-03
        display: block
        margin-top: 0
@media screen and (max-width 640px)
    .pd_b-eventsDetailModal-pc
        display: none
    .pd_b-eventsDetailModal-sp
        display: block
    // .pd_modalOpen
    .pd_b-eventsDetailModal
        padding: 60px 0
        // &.pd_active
        // &_bg
        &_main
            padding: 60px 30px
            width: 345px
        // &_close
        // &_title
        &_table
            border: 0px
            tr
                border: 0px
                display: block
                margin-bottom: 20px
                &:nth-child(1)
                    display: none

            th
                border: 0px
                display: none
            td
                border-bottom: 0px
                display: block
                padding: 19px 15px 18px 113px;
                position: relative
                &::after
                    align-items: center
                    background-color: #F2F2F2
                    border-right: 1px solid #707070
                    box-sizing: border-box
                    display: flex
                    height: 100%
                    left: 0
                    top: 0
                    padding-left: 14px
                    position: absolute
                    width: 100px
                &:nth-child(1)::after
                    content: "開催日時"
                &:nth-child(2)::after
                    content: "店舗"
                &:nth-child(3)::after
                    content: "講師"
                &:nth-child(4)::after
                    content: "店員"
                &:nth-child(5)::after
                    content: "残席数"
                &:nth-child(6)::after
                    content: "参加費"
                &:nth-child(7)::after
                    content: "お申し込み"
                &:last-child
                    border-bottom: 1px solid #707070
                &:nth-child(4),
                &:nth-child(5)
                    text-align: left
        // .pd_c-filterButton-03