/*
---
name: pd_c-linkText-01
category: component
tag:
  - text
---
```html
  <a class="pd_c-linkText-01 pd_e-hover-01" href="#">予約中の予定一覧<span class="pd_e-numberIcon-01">3</span></a>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-linkText-01 {
  display inline-block
  display: inline-block;
  position relative;
  padding-right 13px;
  font-size 15px
  &:link,
  &:hover,
  &:visited,
  &:visited:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: #364563;
  }
  &::after {
    content: '';
    position absolute;
    top: 50%;
    right: 0
    transform: translateY(-50%);
    background: url("/store/images/component/linkText-01_icon_arrow_r.svg")
    background-size: 100% auto;
    width: 8px;
    height: 13px;
  }
}
