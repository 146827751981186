/*
---
name: pd_b-categorySearch
category: block
tag:
  - icon
  - text
  - img
---
```html
<section class="pd_b-categorySearch">
    <div class="pd_b-categorySearch_inner">
        <h3 class="pd_b-categorySearch_ttl">カテゴリから商品を探す</h3>
        <ul class="pd_b-categorySearch_list" id="pd_b-categorySearch_top">
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_sbsc.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">サブスクリプション型<br class="pd_sp" />サービス</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_note.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">カスタマイズレッツ<br class="pd_sp" />ノート</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_bike.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">電動アシスト自転車</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_consumable.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">消耗品</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_homeApp.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">生活家電</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_air.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">空調家電・季節家電</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_cook.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">キッチン家電・調理家電</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_tv.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">映像</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_camera.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">カメラ</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_health.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">健康家電</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_beauty.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">美容家電</p>
                </a></li>
            <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_battery.svg" alt="" />
                    <p class="pd_b-categorySearch_listItemName">電池・モバイルバッテリ<br class="pd_sp" />ー・<br class="pd_pc" />充電器総合</p>
                </a></li>
        </ul>
        <div id="pd_b-categorySearch_bottom">
            <ul class="pd_b-categorySearch_list pd_b-categorySearch_list_bottom">
                <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_tell.svg" alt="" />
                        <p class="pd_b-categorySearch_listItemName">電話・FAX・インターホン</p>
                    </a></li>
                <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_carNav.svg" alt="" />
                        <p class="pd_b-categorySearch_listItemName">カーナビ/カーAV</p>
                    </a></li>
                <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_home.svg" alt="" />
                        <p class="pd_b-categorySearch_listItemName">住まい</p>
                    </a></li>
                <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_light.svg" alt="" />
                        <p class="pd_b-categorySearch_listItemName">あかりと電気設備</p>
                    </a></li>
                <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_audio.svg" alt="" />
                        <p class="pd_b-categorySearch_listItemName">オーディオ</p>
                    </a></li>
                <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_media.svg" alt="" />
                        <p class="pd_b-categorySearch_listItemName">記録メディア・接続コード</p>
                    </a></li>
                <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_etc.svg" alt="" />
                        <p class="pd_b-categorySearch_listItemName">その他商品・サービス</p>
                    </a></li>
                <li class="pd_b-categorySearch_listItem"><a class="pd_e-hover-01" href=""> <img src="/store/images/block/categorySearch_sanyo.svg" alt="" />
                        <p class="pd_b-categorySearch_listItemName">SANYOの消耗品・付属品</p>
                    </a></li>
            </ul>
        </div>
        <div class="pd_b-categorySearch_more"><span class="pd_b-categorySearch_moreText01 pd_e-hover-01">もっと見る</span><span class="pd_b-categorySearch_moreText02 pd_e-hover-01">閉じる </span></div>
        <div class="pd_b-categorySearch_description">
            <div class="pd_b-categorySearch_textWrap">
                <p class="pd_b-categorySearch_textTtl">通信サービスWonderlink</p>
                <p class="pd_b-categorySearch_text">使い方に応じたプランで、格安データ通信SIMをご提供。エリアの広いドコモネットワークだから、MVNOでも安心。</p>
            </div>
            <div class="pd_b-categorySearch_textWrap">
                <p class="pd_b-categorySearch_textTtl">LUMIX CLUB</p>
                <p class="pd_b-categorySearch_text">デジタルカメラ「LUMIX」を使いこなしていただくための講座やサポート情報などをお届けします。</p>
            </div>
        </div>
    </div>
</section>
```
*/
// カテゴリ検索
.pd_b-categorySearch
    background-color #F5F5F5
    padding: 50px 0
    &_inner
        margin 0 auto
        width: 976px
    &_ttl
        font-size: 30px
        font-weight: 500
        text-align: center
    &_list
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        margin-top: 25px;
        a
            display: block
            height: 100%
            width 100%
            img
                display: block
                margin 0 auto
        &.pd_b-categorySearch_list_bottom
            margin-top: 0
    #pd_b-categorySearch_bottom
        display: none
    &_listItem
        margin-bottom: 31px
        width: 229px
    &_listItemName
        color: rgba(0,0,0,0.9)
        margin: 7px 0 0 0;
        letter-spacing: 1px;
        text-align: center
        .pd_sp
            display: none
    &_more
        cursor: pointer
        color: #364563
        display: table
        font-size: 15px
        margin -8px auto 0
        padding-right: 36px;
        position: relative
        &::after
            background-image: url('/store/images/block/categorySearch_plus.svg')
            content: ""
            display: block
            height: 16px
            position: absolute
            right: 4px
            top: 50%
            transform: translateY(-50%)
            width: 16px
        &.pd_open
            &_moreText01
                display: none
            &_moreText02
                display: block
            &::after
                background-image: url('/store/com/images/block/categorySearch_minus.svg')
    &_moreText02
        display: none
    &_description
        display: flex
        justify-content: space-between
    &_textWrap
        border-top: 1px solid #C4C4C4
        margin-top: 45px
        width: 478px
    &_textTtl
        color #364563
        font-size: 17px
        font-weight 500
        margin-top: 16px
        display: block
    &_text
        font-size: 13px
        margin-top: 7px;
        letter-spacing: -0.1px;
@media screen and (max-width 640px)
    // カテゴリ検索
    .pd_b-categorySearch
        padding: 63px 4vw
        &_inner
            width: 100%
        &_ttl
            font-size: 22px
        // &_list
        //     a
        //         img
        //     &&_list_bottom
        // #pd_b-categorySearch_bottom
        &_listItem
            width: 47.83%
        &_listItemName
            .pd_pc
                display: none
            .pd_sp
                display: block
        // &_more
        //     &::after
        //     &.pd_open
        //         &_moreText01
        //         &_moreText02
        //         &::after
        // &_moreText02
        &_description
            flex-wrap: wrap
        &_textWrap
            padding 0 0px 0 3px
            width: 100%
        // &_textTtl
        // &_text
