/*
---
name: pd_e-planName
category: element
tag:
  - text
---
```html
<span class="pd_e-planName">センサー3年</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-planName {
    font-size 15px
    font-weight bold
}