/*
---
name: pd_m-itemImage-01
category: module
tag:
  - text
  - img
---
```html
<div class="pd_m-itemImage-01">
  <div class="pd_m-itemImage-01_img"><img src="http://placehold.jp/ffffff/000000/50x500.png?text=サムネイル" alt=""></div>
  <div class="pd_m-itemImage-01_contents">
    <p class="pd_m-itemImage-01_name">温感かっさ（2019年発売）ピンク</p><a class="pd_c-linkText-01 pd_e-hover-01 pd_js-popup-modal" href="#modal1">商品詳細はこちら</a>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-itemImage-01 {
  display flex
  align-items flex-start
  &_img,
  &_contents {
  }

  &_img {
    width 88px
    height 88px
    border solid 1px rgba(0,0,0,.2)
    display flex
    justify-content center
    align-items center
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
    img {
      max-width 88px
      max-height 100%
    }
  }

  &_contents {
    flex 1
    padding-left 20px
  }

  &_name {
    color #000
    color: rgba(0,0,0,.9)
    font-size 15px
    line-height (23/15)
  }
  .pd_c-linkText-01 {
    margin-top 16px
  }
}
