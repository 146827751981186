/*
---
name: pd_m-requestCalendar
category: module
tag: 
  - text
  - calendar
  - table
---
```html
<div class="pd_m-requestCalendar">
    <div class="pd_c-calendarTemp pd_c-calendarTemp-march">
        <p class="pd_c-calendarTemp_ttl">2021年3月</p>
        <table class="pd_c-calendarTemp_table">
            <tr class="pd_c-calendarTemp_week">
                <th>日</th>
                <th>月</th>
                <th>火</th>
                <th>水</th>
                <th>木</th>
                <th>金</th>
                <th>土</th>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td> </td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>13</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>14</td>
                <td>15</td>
                <td>16</td>
                <td>17</td>
                <td>18</td>
                <td>19</td>
                <td>20</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>21</td>
                <td>22</td>
                <td>23</td>
                <td class="pd_c-calendarTemp_active">24</td>
                <td class="pd_c-calendarTemp_active">25</td>
                <td class="pd_c-calendarTemp_active">26</td>
                <td class="pd_c-calendarTemp_active">27</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td class="pd_c-calendarTemp_active">28</td>
                <td class="pd_c-calendarTemp_active">29</td>
                <td class="pd_c-calendarTemp_active">30</td>
                <td class="pd_c-calendarTemp_active">31</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </table>
    </div>
    <div class="pd_c-calendarTemp pd_c-calendarTemp-march">
        <p class="pd_c-calendarTemp_ttl">2021年4月</p>
        <table class="pd_c-calendarTemp_table">
            <tr class="pd_c-calendarTemp_week">
                <th>日</th>
                <th>月</th>
                <th>火</th>
                <th>水</th>
                <th>木</th>
                <th>金</th>
                <th>土</th>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td class="pd_c-calendarTemp_active">1</td>
                <td class="pd_c-calendarTemp_active">2</td>
                <td class="pd_c-calendarTemp_active">3</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td class="pd_c-calendarTemp_active">4</td>
                <td class="pd_c-calendarTemp_active">5</td>
                <td class="pd_c-calendarTemp_active">6</td>
                <td class="pd_c-calendarTemp_active">7</td>
                <td class="pd_c-calendarTemp_active">8</td>
                <td class="pd_c-calendarTemp_active">9</td>
                <td class="pd_c-calendarTemp_active">10</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td class="pd_c-calendarTemp_active">11</td>
                <td class="pd_c-calendarTemp_active">12</td>
                <td class="pd_c-calendarTemp_active">13</td>
                <td class="pd_c-calendarTemp_active">14</td>
                <td class="pd_c-calendarTemp_active">15</td>
                <td class="pd_c-calendarTemp_active">16</td>
                <td class="pd_c-calendarTemp_active">17</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td class="pd_c-calendarTemp_active">18</td>
                <td class="pd_c-calendarTemp_active">19</td>
                <td class="pd_c-calendarTemp_active">20</td>
                <td class="pd_c-calendarTemp_active">21</td>
                <td>22</td>
                <td>23</td>
                <td>24</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>25</td>
                <td>26</td>
                <td>27</td>
                <td>28</td>
                <td>29</td>
                <td>30</td>
                <td></td>
            </tr>
        </table>
    </div>
</div>
```
*/
.pd_m-requestCalendar
    display: flex
    justify-content: space-between
    margin-top: 24px;
    width: 100%
@media screen and (max-width 640px)
    .pd_m-requestCalendar
        display: block
        margin-top: 18px;