/*
---
name: pd_m-keywords-01
category: module
tag:
  - text 
  - btn
  - icon
---
```html
<div class="pd_m-keywords-01">
    <div class="pd_m-keywords-01_inner">
        <div class="pd_m-keywords-01_col-01">
            <p class="pd_m-keywords-01_heading">人気のキーワード </p>
            <ul class="pd_m-keywords-01_linkList">
                <li class="pd_m-keywords-01_linkItem"><a class="pd_m-keywords-01_anchor pd_e-hover-01" href="#">キーワードが入ります</a></li>
                <li class="pd_m-keywords-01_linkItem"><a class="pd_m-keywords-01_anchor pd_e-hover-01" href="#">キーワード</a></li>
                <li class="pd_m-keywords-01_linkItem"><a class="pd_m-keywords-01_anchor pd_e-hover-01" href="#">キーワード</a></li>
                <li class="pd_m-keywords-01_linkItem"><a class="pd_m-keywords-01_anchor pd_e-hover-01" href="#">キーワードが入ります</a></li>
            </ul>
        </div>
        <div class="pd_m-keywords-01_col-02"><a class="pd_c-btn-02 pd_e-hover-01--bgGray pd_c-btn-02--filter pd_js-m-keywords-01_modalLink" href="#modalBox-eventForm">条件を指定して探す </a>
        </div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-keywords-01 {
  border-radius 8px
  border solid 1px rgba(0,0,0,.2)
  padding 30px
  @media screen and (min-width:MIN_POINT) {
    max-width 980px
    margin 40px auto 0
  }
  @media screen and (max-width:MAX_POINT) {
    margin 40px 15px 0
  }
  &_inner {
    @media screen and (min-width:MIN_POINT) {
      display flex
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_heading {
    color rgba(0,0,0,.9)
    font-size 17px
    line-height (25/17)
  }
  &_col-01 {
    @media screen and (min-width:MIN_POINT) {
      flex 1
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_col-02 {
    @media screen and (min-width:MIN_POINT) {
      width 230px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 21px
    }
  }
  &_linkList {
    margin-top 15px
    &::after {
      content ''
      display block
      clear both
    }
  }
  &_linkItem {
    float left
    margin-right 20px
  }
  &_anchor {
    font-size 15px
    color #364563
    &:link,
    &:hover,
    &:visited,
    &:visited:hover,
    &:active,
    &:focus {
      color #364563
      text-decoration underline
    }
  }
  &+.pd_b-tabArea {
    margin-top 40px
  }
}
