/*
---
name: pd_m-requestPanel
category: module
tag:
  - text
  - btn
---
```html
<div class="pd_m-requestPanel pd_m-requestPanel01">
    <ul class="pd_m-requestPanel_textList">
        <li class="pd_m-requestPanel_textListItem">
            <p class="pd_m-requestPanel_textListTtl">開催日：</p>
            <p class="pd_m-requestPanel_textListText">4月18日（土）</p>
        </li>
    </ul>
    <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
        <p class="pd_c-filterButton_text">申し込む</p>
    </button>
</div>
```
*/
.pd_m-requestPanel
    background-color: #fff
    border-radius: 8px
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2)
    margin-top: 20px
    padding 20px
    width: 312px
    // &_textList
    &_textListItem
        display: flex
    // &_textListTtl
    // &_textListText
    .pd_c-filterButton-03
        display: flex
    .pd_c-filterButton
        margin-top: 26px
@media screen and (max-width 640px)
    .pd_m-requestPanel
        margin: 15px 0 6px
        width 100%
        // &_textList
        // &_textListItem
        // &_textListTtl
        // &_textListText
        // .pd_c-filterButton-03
        // .pd_c-filterButton