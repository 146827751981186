/*
---
name: pd_c-store_Name
category: component
tag:
  - text
---
```html
    <p class="pd_c-storeName_store">Panasonic shop 渋谷店</p>
```
*/
.pd_c-storeName
    &_store
        font-size: 13px
        color: rgba(0,0,0,0.9)
    &_name
        font-size: 13px
        color: rgba(0,0,0,0.9)
@media screen and (max-width 640px)
    .pd_c-storeName
        &_store,
        &_name
            margin-top: 6px;