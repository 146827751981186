/*
---
name: pd_c-largeBtn-01
category: component
tag:
  - text
  - img
---
```html
  <a class="pd_c-largeBtn-01 pd_e-hover-01 pd_c-largeBtn-01--calendar pd_e-hover-01--bgGray" href="#"><span class="pd_c-largeBtn-01_inner"><span class="pd_c-largeBtn-01_period">10月5日 17:00-19:00</span><span class="pd_c-largeBtn-01_eventTitle">予約イベントタイトル<span class="pd_e-numberIcon-01">3</span></span></span></a>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-largeBtn-01 {
    display block
    background-color #fff
    background-repeat no-repeat
    background-size 36px auto
    background-position 30px center
    padding 30px 24px 30px 100px
    border solid 1px #364563
    box-shadow 0 3px 6px rgba(0,0,0,.16)
    border-radius 8px
    &_period,
    &_eventTitle {
      display block
    }
    &_period {
      display block
      color rgba(0,0,0,.9)
      font-size 15px
      line-height (22/15)
    }
    &_eventTitle {
      margin-top 5px
      font-size 17px
      line-height (22/17)
      color #364563
    }
    &--calendar {
      background-image url("/store/images/component/largeBtn-01_icon_calendar.svg")
    }
}
