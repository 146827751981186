/*
---
name: pd_e-bnrText-01
category: element
---
```html
<div class="pd_e-bnrText-01">
    <span class="pd_e-bnrText-01_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入り</span>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-bnrText-01 {
    margin-top 20px
    &_text {
        font-size 13px
        line-height 1
    }
}




