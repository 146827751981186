/*
---
name: pd_m-searchForm-tag
category: module
tag:
  - text
  - tag
---
```html
<div class="pd_m-searchForm-tag">
  <div class="pd_m-searchForm-tag_inner">
    <p class="pd_m-searchForm-tag_ttl">＃タグから探す</p>
    <div class="pd_m-searchForm-tag_box">
      <p class="pd_m-searchForm-tag_subTtl">あなたに合った＃タグで検索</p>
      <ul class="pd_c-tagList">
        <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
          <object><a href="/test.html">#快適な空間づくり</a></object>
        </li>
        <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
          <object><a href="/test2.html">#家事・暮らし</a></object>
        </li>
        <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
          <object><a href="/test3.html">#タグ</a></object>
        </li>
      </ul>
    </div>
    <div class="pd_m-searchForm-tag_box">
      <p class="pd_m-searchForm-tag_subTtl">人気のある＃タグで検索</p>
      <ul class="pd_c-tagList">
        <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
          <object><a href="/test.html">#快適な空間づくり</a></object>
        </li>
        <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
          <object><a href="/test2.html">#家事・暮らし</a></object>
        </li>
        <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
          <object><a href="/test3.html">#タグ</a></object>
        </li>
      </ul>
    </div>
  </div>
</div>
```
*/
.pd_m-searchForm-tag
    border-bottom: 1px solid #F2F2F2
    margin-top: 40px
    margin-bottom: 60px
    &_inner
        background-color: #F2F2F2
        border-radius: 8px
        margin: 0 auto
        padding: 30px 30px 07px
        width: 976px
    &_ttl
        font-size: 30px
        font-weight: 500
        text-align: center
        line-height: 1
    &_box
        border-bottom: 1px solid #c2c2c2
        margin-top: 22px
        padding-bottom: 24px
        &:last-child
            border-bottom: 0px
    &_subTtl
        font-size: 17px
        font-weight: 500
@media screen and (max-width 640px)
    .pd_m-searchForm-tag
        padding: 0 15px
        &_inner
            padding: 30px 20px 0px
            width: 100%
        &_ttl
            font-size: 21px
        &_box
            margin-top: 30px
            // &:nth-child(2)
        &_subTtl
            padding-left: 5px