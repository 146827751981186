/*
---
name: pd_c-row-01
category: component
tag:
  - text
  - img
---
```html
<div class="pd_c-row-01">
    <div class="pd_c-row-01_leftCol">
        <div class="pd_c-row-01_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1000.png" /></div>
    </div>
    <div class="pd_c-row-01_rightCol">
        <div class="pd_c-row-01_content-01">
            <h1 class="pd_c-row-01_heading">1/2フォーマット</h1>
            <p class="pd_c-row-01_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト本文テキスト本文テキスト本文テキスト本文テキスト</p>
        </div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-row-01 {
  @media screen and (min-width:MIN_POINT) {
    margin-top 40px
    display: flex;
  }
  @media screen and (max-width:MAX_POINT) {
    margin-top 20px
  }
  &_leftCol,
  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
      width: 56%;
      padding-right 10px
      padding-left 10px
    }
    > .pd_c-row-01 {
      margin-top 0
    }
  }
  &_leftCol {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 15px
    }
  }
  &_heading {
    color rgba(0,0,0,.9)
    font-weight bold
    font-size 22px
    line-height (33/22)
    margin-bottom 16px
  }
  &_text-01,
  &_text-02 {
    color rgba(0,0,0,.9)
    font-size 15px
    line-height (23/15)
    margin-bottom 16px
  }
  &_color-01 {
    color: #CC0E00
  }
  &_img {
    text-align center
    display flex
    justify-content center
    align-items center
    width 470px
    height 313px
    @media screen and (max-width:MAX_POINT) {
      width 100%
    }
    img {
      max-width 470px
      max-height 100%
      @media screen and (max-width:MAX_POINT) {
      max-width 100%
      }

    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 18px
    }
  }
  &_content-01{
    // border-top: 1px solid #e5e5e5;
    // padding: 30px 0 0 0;
    // margin: 30px 0 0 0;
  }
  &_content-01-ttl {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
