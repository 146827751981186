/*
---
name: pd_m-campaign
category: module
tag:
  - text
  - img
  - icon
  - tag
---
```html
<div class="pd_m-campaign">
                                      <div class="pd_m-campaign_wrap">
                                        <p class="pd_m-campaign_Ttl">その他のキャンペーン</p>
                                        <ul class="pd_m-campaign_slider pd_m-campaign_list">
                                          <li class="pd_m-campaign_listItem"><a class="pd_m-smallBox" href="/">
                                              <div class="pd_m-smallBoxItem">
                                                <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                                <div class="pd_m-smallBoxItem_mid">
                                                  <p class="pd_m-smallBoxItem_kind">記事</p>
                                                  <div class="pd_m-smallBoxItem_favoriteWrap">
                                                    <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                                  </div>
                                                </div>
                                                                              <ul class="pd_c-smallTag_bkg_gray">
                                                                                <li class="pd_c-smallTag-gray">受付中</li>
                                                                                <li class="pd_c-smallTag-gray">定額</li>
                                                                                <li class="pd_c-smallTag-gray">近日発売</li>
                                                                                <li class="pd_c-smallTag-gray">セット商品</li>
                                                                                <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                              </ul>
                                                                              <ul class="pd_c-smallTag_bkg_orange">
                                                                                <li class="pd_c-smallTag-orange">NEW</li>
                                                                                <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                                <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                                <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">予約商品</li>
                                                                                <li class="pd_c-smallTag-orange">特別価格</li>
                                                                              </ul>
                                                <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                              </div>
                                              <div class="pd_c-dateArea">
                                                <p class="pd_c-dateArea_date">2021年04月15日</p>
                                                <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                                <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                              </div>
                                              <ul class="pd_c-tagList">
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                                  <object><a href="/test.html">#快適な空間づくり</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test2.html">#家事・暮らし</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test3.html">#タグ</a></object>
                                                </li>
                                              </ul></a></li>
                                          <li class="pd_m-campaign_listItem"><a class="pd_m-smallBox" href="/">
                                              <div class="pd_m-smallBoxItem">
                                                <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                                <div class="pd_m-smallBoxItem_mid">
                                                  <p class="pd_m-smallBoxItem_kind">記事</p>
                                                  <div class="pd_m-smallBoxItem_favoriteWrap">
                                                    <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                                  </div>
                                                </div>
                                                                              <ul class="pd_c-smallTag_bkg_gray">
                                                                                <li class="pd_c-smallTag-gray">受付中</li>
                                                                                <li class="pd_c-smallTag-gray">定額</li>
                                                                                <li class="pd_c-smallTag-gray">近日発売</li>
                                                                                <li class="pd_c-smallTag-gray">セット商品</li>
                                                                                <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                              </ul>
                                                                              <ul class="pd_c-smallTag_bkg_orange">
                                                                                <li class="pd_c-smallTag-orange">NEW</li>
                                                                                <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                                <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                                <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">予約商品</li>
                                                                                <li class="pd_c-smallTag-orange">特別価格</li>
                                                                              </ul>
                                                <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                              </div>
                                              <div class="pd_c-dateArea">
                                                <p class="pd_c-dateArea_date">2021年04月15日</p>
                                                <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                                <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                              </div>
                                              <ul class="pd_c-tagList">
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                                  <object><a href="/test.html">#快適な空間づくり</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test2.html">#家事・暮らし</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test3.html">#タグ</a></object>
                                                </li>
                                              </ul></a></li>
                                          <li class="pd_m-campaign_listItem"><a class="pd_m-smallBox" href="/">
                                              <div class="pd_m-smallBoxItem">
                                                <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                                <div class="pd_m-smallBoxItem_mid">
                                                  <p class="pd_m-smallBoxItem_kind">記事</p>
                                                  <div class="pd_m-smallBoxItem_favoriteWrap">
                                                    <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                                  </div>
                                                </div>
                                                                              <ul class="pd_c-smallTag_bkg_gray">
                                                                                <li class="pd_c-smallTag-gray">受付中</li>
                                                                                <li class="pd_c-smallTag-gray">定額</li>
                                                                                <li class="pd_c-smallTag-gray">近日発売</li>
                                                                                <li class="pd_c-smallTag-gray">セット商品</li>
                                                                                <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                              </ul>
                                                                              <ul class="pd_c-smallTag_bkg_orange">
                                                                                <li class="pd_c-smallTag-orange">NEW</li>
                                                                                <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                                <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                                <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">予約商品</li>
                                                                                <li class="pd_c-smallTag-orange">特別価格</li>
                                                                              </ul>
                                                <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                              </div>
                                              <div class="pd_c-dateArea">
                                                <p class="pd_c-dateArea_date">2021年04月15日</p>
                                                <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                                <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                              </div>
                                              <ul class="pd_c-tagList">
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                                  <object><a href="/test.html">#快適な空間づくり</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test2.html">#家事・暮らし</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test3.html">#タグ</a></object>
                                                </li>
                                              </ul></a></li>
                                          <li class="pd_m-campaign_listItem"><a class="pd_m-smallBox" href="/">
                                              <div class="pd_m-smallBoxItem">
                                                <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                                <div class="pd_m-smallBoxItem_mid">
                                                  <p class="pd_m-smallBoxItem_kind">記事</p>
                                                  <div class="pd_m-smallBoxItem_favoriteWrap">
                                                    <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                                  </div>
                                                </div>
                                                                              <ul class="pd_c-smallTag_bkg_gray">
                                                                                <li class="pd_c-smallTag-gray">受付中</li>
                                                                                <li class="pd_c-smallTag-gray">定額</li>
                                                                                <li class="pd_c-smallTag-gray">近日発売</li>
                                                                                <li class="pd_c-smallTag-gray">セット商品</li>
                                                                                <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                              </ul>
                                                                              <ul class="pd_c-smallTag_bkg_orange">
                                                                                <li class="pd_c-smallTag-orange">NEW</li>
                                                                                <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                                <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                                <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">予約商品</li>
                                                                                <li class="pd_c-smallTag-orange">特別価格</li>
                                                                              </ul>
                                                <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                              </div>
                                              <div class="pd_c-dateArea">
                                                <p class="pd_c-dateArea_date">2021年04月15日</p>
                                                <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                                <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                              </div>
                                              <ul class="pd_c-tagList">
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                                  <object><a href="/test.html">#快適な空間づくり</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test2.html">#家事・暮らし</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test3.html">#タグ</a></object>
                                                </li>
                                              </ul></a></li>
                                          <li class="pd_m-campaign_listItem"><a class="pd_m-smallBox" href="/">
                                              <div class="pd_m-smallBoxItem">
                                                <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                                <div class="pd_m-smallBoxItem_mid">
                                                  <p class="pd_m-smallBoxItem_kind">記事</p>
                                                  <div class="pd_m-smallBoxItem_favoriteWrap">
                                                    <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                                  </div>
                                                </div>
                                                                              <ul class="pd_c-smallTag_bkg_gray">
                                                                                <li class="pd_c-smallTag-gray">受付中</li>
                                                                                <li class="pd_c-smallTag-gray">定額</li>
                                                                                <li class="pd_c-smallTag-gray">近日発売</li>
                                                                                <li class="pd_c-smallTag-gray">セット商品</li>
                                                                                <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                              </ul>
                                                                              <ul class="pd_c-smallTag_bkg_orange">
                                                                                <li class="pd_c-smallTag-orange">NEW</li>
                                                                                <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                                <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                                <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">予約商品</li>
                                                                                <li class="pd_c-smallTag-orange">特別価格</li>
                                                                              </ul>
                                                <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                              </div>
                                              <div class="pd_c-dateArea">
                                                <p class="pd_c-dateArea_date">2021年04月15日</p>
                                                <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                                <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                              </div>
                                              <ul class="pd_c-tagList">
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                                  <object><a href="/test.html">#快適な空間づくり</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test2.html">#家事・暮らし</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test3.html">#タグ</a></object>
                                                </li>
                                              </ul></a></li>
                                          <li class="pd_m-campaign_listItem"><a class="pd_m-smallBox" href="/">
                                              <div class="pd_m-smallBoxItem">
                                                <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                                <div class="pd_m-smallBoxItem_mid">
                                                  <p class="pd_m-smallBoxItem_kind">記事</p>
                                                  <div class="pd_m-smallBoxItem_favoriteWrap">
                                                    <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                                  </div>
                                                </div>
                                                                              <ul class="pd_c-smallTag_bkg_gray">
                                                                                <li class="pd_c-smallTag-gray">受付中</li>
                                                                                <li class="pd_c-smallTag-gray">定額</li>
                                                                                <li class="pd_c-smallTag-gray">近日発売</li>
                                                                                <li class="pd_c-smallTag-gray">セット商品</li>
                                                                                <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                              </ul>
                                                                              <ul class="pd_c-smallTag_bkg_orange">
                                                                                <li class="pd_c-smallTag-orange">NEW</li>
                                                                                <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                                <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                                <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                                <li class="pd_c-smallTag-orange">予約商品</li>
                                                                                <li class="pd_c-smallTag-orange">特別価格</li>
                                                                              </ul>
                                                <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                              </div>
                                              <div class="pd_c-dateArea">
                                                <p class="pd_c-dateArea_date">2021年04月15日</p>
                                                <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                                <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                              </div>
                                              <ul class="pd_c-tagList">
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                                  <object><a href="/test.html">#快適な空間づくり</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test2.html">#家事・暮らし</a></object>
                                                </li>
                                                <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                                  <object><a href="/test3.html">#タグ</a></object>
                                                </li>
                                              </ul></a></li>
                                        </ul>
                                      </div>
                                    </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_m-campaign {
  margin-top 40px
  border-bottom: 1px solid #ccc;
  @media screen and (min-width:MIN_POINT) {

  }
  @media screen and (max-width:MAX_POINT) {
    overflow-x scroll
    -webkit-overflow-scrolling: touch;
    padding: 0;
  }
  &_wrap{
    @media screen and (min-width:MIN_POINT) {
      width: 995px;
      margin: 50px auto;
    }
    @media screen and (max-width:MAX_POINT) {
   
    }
  }
  &_Ttl{
    font-size: 30px
    font-weight: 500
    text-align: center
    @media screen and (max-width:MAX_POINT) {
        font-size: 22px
        padding-right: 18px;
    }
  }
  &_list {
    @media screen and (min-width:MIN_POINT) {
      display flex
      margin-right -10px
      margin-left -10px
      margin-top: 15px;
    }
    @media screen and (max-width:MAX_POINT) {
      width: 100%
      margin-top: 15px;
      
    }

    .pd_m-smallBox {
      margin-right 0
      margin-left 0
      @media screen and (max-width:MAX_POINT) {
        width: 140px
        margin-right auto
        margin-left auto
      }
    }
    .pd_m-smallBoxItem_img {
      width: 140px
      height: 140px
      img {
        max-width: 140px
        width 100%
      }
    }
  }
  &_listItem {
    @media screen and (min-width:MIN_POINT) {
      padding-right 10px
      padding-left 10px
      width 332px
    }
    @media screen and (max-width:MAX_POINT) {
      width 160px
    }
  }
  &_Arrow {
    background-repeat: no-repeat
    background-position: center center
    background-color: #fff
    border: 0px
    border-radius: 50%
    box-shadow: 2px 2px 6px rgba(0 0 0 10%);
    height: 40px
    position: absolute
    // top:156px
    top:89.5px
    transform: translateY(-50%)
    width: 40px
    z-index 1
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      // display: none!important
    }
  }
  &_prevArrow {
    background-image: url('/store/images/module/recently_checked_prev.svg')
    box-shadow: -2px 2px 6px rgba(0 0 0 10%);
    // display: none!important
    left: -10px
    &.pd_active {
    }
  }
  &_nextArrow {
    background-image: url('/store/images/module/recently_checked_next.svg')
    @media screen and (min-width:MIN_POINT) {
      right: -10px;
    }
    @media screen and (max-width:MAX_POINT) {
      right: auto;
      left: 80vw;
    }
  }
  .pd_m-smallBox {
    @media screen and (min-width:MIN_POINT) {
      width 229px
    }
    @media screen and (max-width:MAX_POINT) {
      width: 140px
    }
  }
  .pd_m-smallBoxItem_img {
    @media screen and (min-width:MIN_POINT) {
      width 229px
      height 229px
    }
    @media screen and (max-width:MAX_POINT) {
      height: 140px
      width: 140px
    }
    img {
      @media screen and (min-width:MIN_POINT) {
        max-width 312px
      }
      @media screen and (max-width:MAX_POINT) {
        max-width: 140px
      }
    }
  }
  .pd_m-campaign_prevArrow.pd_active {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      // display: none!important
    }
  }
}
