/*
---
name: pd_c-checkBoxList
category: component
tag:
  - checkBox
---
```html
    <ul class="pd_c-checkBoxList">
        <li class="pd_c-checkBoxList_item pd_e-hover-01">
            <input type="checkBox" id="pd_c-checkBoxList_input1" />
            <label class="pd_c-checkBoxList_label" for="pd_c-checkBoxList_input1">
                <p>在庫あり</p>
            </label>
        </li>
        <li class="pd_c-checkBoxList_item pd_e-hover-01">
            <input type="checkBox" id="pd_c-checkBoxList_input2" />
            <label class="pd_c-checkBoxList_label" for="pd_c-checkBoxList_input2">
                <p>キャンペーン商品</p>
            </label>
        </li>
        <li class="pd_c-checkBoxList_item pd_e-hover-01">
            <input type="checkBox" id="pd_c-checkBoxList_input3" />
            <label class="pd_c-checkBoxList_label" for="pd_c-checkBoxList_input3">
                <p>定額利用サービス</p>
            </label>
        </li>
        <li class="pd_c-checkBoxList_item pd_e-hover-01">
            <input type="checkBox" id="pd_c-checkBoxList_input4" />
            <label class="pd_c-checkBoxList_label" for="pd_c-checkBoxList_input4">
                <p>おすすめ・限定商品</p>
            </label>
        </li>
        <li class="pd_c-checkBoxList_item pd_e-hover-01">
            <input type="checkBox" id="pd_c-checkBoxList_input5" />
            <label class="pd_c-checkBoxList_label" for="pd_c-checkBoxList_input5">
                <p>消耗品・付属品</p>
            </label>
        </li>
    </ul>
```
*/
.pd_c-checkBoxList
    &_item
        align-items: center
        display: flex
        margin-bottom: 16px
        &:last-child
            margin-bottom: 0
    input
        display: none
    &_label
        display: flex
        line-height: 24px
        letter-spacing: 1px
        padding-left: 34px
        position: relative
        &::after
            border: 1px solid #999999
            border-radius: 6px
            content: ""
            display: block
            left: 0
            position: absolute
            top: 50%
            transform: translateY(-50%)
            height: 23px
            width: 23px

    input:checked + .pd_c-checkBoxList_label::after
        background-color #364563
        border: 1px solid #364563
        background-image: url('/store/com/images/component/checkList-iconWhite.svg')
        background-position: center
        background-repeat: no-repeat