/*
---
name: pd_e-label_bkg_gray
category: element
---
```html
<label class="pd_e-label_bkg_gray">付帯サービス付き商品のため<br>同時購入できません</label>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-label_bkg_gray {
  background: #E6E6E6;
  display: block;
  text-align: center;
  padding: 9px;
  color: rgba(0,0,0,0.3);
  border-radius: 8px;
  font-size: 1.2rem;
  margin: 16px 0 0;

  @media screen and (max-width:MAX_POINT) {
    font-size: 1rem;
    padding: 5px;
    max-width: 140px;
    margin 16px auto 0
  }
}
