/*
---
name: pd_m-fieldset-01
category: module
tag:
  - text
  - checkBox

---
```html
<fieldset class="pd_m-fieldset-01">
    <div class="pd_m-fieldset-01_legend">開催日時</div>
    <div class="pd_m-fieldset-01_inputGroup">
        <ul class="pd_m-radioBtnList-01">
            <li class="pd_m-radioBtnList-01_listItem">
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="date" value="指定する" /><span class="pd_c-radioBtn-01_indicator"></span><span class="pd_c-radioBtn-01_text-01">指定する</span>
                </label>
            </li>
            <li class="pd_m-radioBtnList-01_listItem">
                <label class="pd_c-radioBtn-01">
                    <input class="pd_c-radioBtn-01_input-01 pd_js-c-radioBtn-01_input-01" type="radio" name="date" value="指定しない" /><span class="pd_c-radioBtn-01_indicator"></span><span class="pd_c-radioBtn-01_text-01">指定しない</span>
                </label>
            </li>
        </ul>
        <div class="pd_m-selectGroup-01">
            <div class="pd_m-selectGroup-01_row-01">
                <div class="pd_m-selectGroup-01_col-01">
                    <div class="pd_c-selectBox-01">
                        <select class="pd_c-selectBox-01_selector">
                            <option value="option1" selected="" disabled="">2021年</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                    </div>
                </div>
                <div class="pd_m-selectGroup-01_col-02">
                    <div class="pd_c-selectBox-01">
                        <select class="pd_c-selectBox-01_selector">
                            <option value="option1" selected="" disabled="">4月</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                    </div>
                </div>
                <div class="pd_m-selectGroup-01_col-03">
                    <div class="pd_c-selectBox-01">
                        <select class="pd_c-selectBox-01_selector">
                            <option value="option1" selected="" disabled="">30日</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                    </div>
                </div>
                <div class="pd_m-selectGroup-01_col-04">
                    <p class="pd_m-selectGroup-01_text-01">から</p>
                </div>
            </div>
            <div class="pd_m-selectGroup-01_row-02">
                <div class="pd_m-selectGroup-01_col-01">
                    <div class="pd_c-selectBox-01">
                        <select class="pd_c-selectBox-01_selector">
                            <option value="option1" selected="" disabled="">2021年</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                    </div>
                </div>
                <div class="pd_m-selectGroup-01_col-02">
                    <div class="pd_c-selectBox-01">
                        <select class="pd_c-selectBox-01_selector">
                            <option value="option1" selected="" disabled="">4月</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                    </div>
                </div>
                <div class="pd_m-selectGroup-01_col-03">
                    <div class="pd_c-selectBox-01">
                        <select class="pd_c-selectBox-01_selector">
                            <option value="option1" selected="" disabled="">30日</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-fieldset-01 {
  border-top solid 1px rgba(0,0,0,.2)
  padding-top 20px
  padding-bottom 20px
  @media screen and (min-width:MIN_POINT) {
    display flex
  }
  @media screen and (max-width:MAX_POINT) {
  }
  &:first-child {
    border none
    padding-top 0
  }
  &_legend {
    font-size 17px
    line-height (22/17)
    font-weight 500
    @media screen and (min-width:MIN_POINT) {
      width 229px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_inputGroup {
    @media screen and (min-width:MIN_POINT) {
      flex 1
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 26px
    }
  }
  .pd_m-selectGroup-01 {
    @media screen and (min-width:MIN_POINT) {
      margin-top 24px - 8px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
}
