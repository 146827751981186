/*
---
name: pd_c-itemImage-03
category: component
tag:
  - text
  - img
---
```html
  <div class="pd_c-itemImage-03">
      <div class="pd_c-itemImage-03_img"><img src="http://placehold.jp/3d4070/ffffff/100x100.png" alt="" /></div>
      <div class="pd_c-itemImage-03_contents">
          <p class="pd_c-itemImage-03_name">スチーマー ナノケア　定額利用サービス（2019年発売温感かっさ セット）</p>
          <p class="pd_c-itemImage-03_price"><span class="pd_c-itemImage-03_textGroup"><span class="pd_c-itemImage-03_text-01">初回請求</span><span class="pd_c-itemImage-03_text-02">0</span><span class="pd_c-itemImage-03_text-01">円</span><span class="pd_c-itemImage-03_text-03">（税込）</span></span><span class="pd_c-itemImage-03_textGroup"><span class="pd_c-itemImage-03_text-01">月額請求</span><span class="pd_c-itemImage-03_text-02">2,000</span><span class="pd_c-itemImage-03_text-01">円</span><span class="pd_c-itemImage-03_text-03">（税込）</span></span></p>
      </div>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-itemImage-03 {
  display flex
  align-items center
  &_img,
  &_contents {
  }
  &_img {
    width 70px
    img {
      width 100%
    }
  }
  &_contents {
    flex 1
    padding-left 20px
  }
  &_name {
    color #000
    font-size 14px
    line-height (17/14)
  }
  &_price {
  }
  &_textGroup {
    display: inline-block;
    &+& {
      @media screen and (max-width:MAX_POINT) {
        margin-top -6px
      }
    }
  }
  &_text-01 {
    font-size 13px
    color rgba(0,0,0,.9)
    line-height (20/13)
  }
  &_text-02 {
    font-size 22px
    color rgba(0,0,0,.9)
  }
  &_text-03 {
    font-size 13px
    color rgba(0,0,0,.6)
  }
}
