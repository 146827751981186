/*
---
name: pd_m-searchForm
category: module
tag:
  - text
  - tag
  - icon
  - select
---
```html
<div class="pd_m-searchForm">
  <form class="pd_m-searchForm_inner" action="">
    <div class="pd_m-searchForm_categorySelectorWrap">
      <select class="pd_m-searchForm_categorySelector" id="pd_m-searchForm_categorySelector" name=""> 
        <option value="">すべてのカテゴリ</option>
        <option value="">すべてのカテゴリ</option>
        <option value="">すべてのカテゴリ</option>
        <option value="">すべてのカテゴリ</option>
      </select>
    </div>
    <div class="pd_m-searchForm_inputWrap">
      <input class="pd_m-searchForm_input" type="text" placeholder="テレビ">
      <button class="pd_m-searchForm_button pd_e-hover-01"></button>
    </div>
    <ul class="pd_c-tagList pd_c-tagList-02">
      <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
        <object><a href="/text01.html">#テレビ</a></object>
      </li>
      <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
        <object><a href="/text02.html">#4K</a></object>
      </li>
      <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
        <object><a href="/text03.html">#ビエラ</a></object>
      </li>
      <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
        <object><a href="/text04.html">#壁掛けテレビ</a></object>
      </li>
      <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
        <object><a href="/text05.html">#リビング</a></object>
      </li>
      <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
        <object><a href="/text06.html">#ブルーレイ</a></object>
      </li>
      <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
        <object><a href="/text07.html">#画面サイズの選び方</a></object>
      </li>
    </ul>
  </form>
</div>
```
*/
.pd_m-searchForm
    margin: 40px auto 0
    width: 976px
    &_inner
        background-color #F2F2F2
        border-radius: 8px
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        padding: 30px
        width: 100%
    &_categorySelectorWrap
        cursor: pointer
        position: relative
        &::before
            background-image: url('/store/images/module/searchForm_arrow.svg')
            background-repeat: no-repeat
            background-size: cover
            content ""
            display: block
            height: 9px
            position: absolute
            right: 12px
            top: 50%
            transform: translateY(-50%)
            width: 18px
            z-index 1
            pointer-events: none;
        // &:hover&::after
        //     background-color rgba(255,255,255,0.1)
        //     content: ""
        //     display: block
        //     height: 100%
        //     left: 0
        //     position: absolute
        //     top: 0
        //     width: 100%
        //     z-index 1
    &_categorySelector
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        align-items: center
        background-color: #FFF
        border: 1px solid #666666
        border-radius: 8px
        display: flex
        font-size: 15px
        height: 52px
        padding: 15px
        position: relative
        width: 296px
        &::-ms-expand
            display: none
    &_inputWrap
        border: 1px solid #666666
        border-radius: 7.5px
        height: 52px
        position: relative
        width: 600px
    &_input
        border: 0px
        border-radius: 7.5px
        box-sizing: border-box
        font-size: 15px
        height: 100%
        padding: 0 0 0 14.3px
        width: 100%
    &_button
        background-color: #364563;
    background-image: url('/store/com/images/top/header_search_white.svg');
   background-repeat: no-repeat;
        background-size: 22px;
        background-position: center;
        border: 0px;
        border-radius: 0 5px 5px 0px;
        display: block;
        height: 52px
        outline: none;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 52px;
    .pd_c-tagList-02
        width: 100%
@media screen and (max-width 640px)
    .pd_m-searchForm
        width: 100%
        &_inner
            margin: 0 auto
            padding: 20px
            width: 92%
        &_categorySelectorWrap
            width: 100%
            &::before
                top: 40%
        &_categorySelector
            margin-bottom 16px
            padding 15px
            width: 100%
        // &_inputWrap
        // &_input
        // &_button
        .pd_c-tagList-02
            justify-content: flex-start