/*
---
name: pd_c-productListItem_modelNum
category: component
tag:
  - text
---
```html
    <p class="pd_c-productListItem_modelNum">MC-RSF1000</p>
```
*/
.pd_c-productListItem_modelNum
    color: rgba(0,0,0,0.6);
    font-size: 13px;
    margin-top: 10px

.pd_c-productListItem_modelNum-02
    color: rgba(0,0,0,0.6);
    font-size: 13px;