/*
---
name: pd_c-tagList
category: component
tag:
  - tag
---
```html
<ul class="pd_c-tagList pd_c-tagList-02">
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray"> <a href="">＃食・レシピ</a></li>
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray"> <a href="">＃タグ</a></li>
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray"> <a href="">＃タグ</a></li>
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray"> <a href="">＃タグ</a></li>
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray"> <a href="">＃食・レシピ</a></li>
</ul>
```
*/
.pd_c-tagList
    display: flex
    flex-wrap: wrap
    margin-top: 5px
    &_item
        border: 1px solid rgba(0,0,0,0.3)
        border-radius: 15px
        color: rgba(0,0,0,0.6)
        cursor: pointer
        font-size 13px
        margin-top: 10px
        margin-right: 8px
        position: relative
        z-index 1
        object
            display: block
        a
            color: rgba(0,0,0,0.6)
            display: block
            line-height: 28px
            padding: 0 9px
        // &:hover&::after
        //     background-color rgba(255,255,255,0.1)
        //     content: ""
        //     display: block
        //     height: 100%
        //     left: 0
        //     position: absolute
        //     top: 0
        //     width: 100%
        //     z-index 1
.pd_c-tagList-02
    margin-top: 14px
    justify-content: center
    .pd_c-tagList_item:last-child
        margin-right: 0
@media screen and (max-width 640px)
    .pd_c-tagList-02
        margin-top: 3px
    .pd_m-campaign .pd_c-tagList
        display none
    