/*
---
name: pd_c-normalList-01
category: component
tag:
  - text
---
```html
  <ul class="pd_c-normalList-01">
      <li class="pd_c-normalList-01_listItem"><span>・</span>ノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリスト</li>
      <li class="pd_c-normalList-01_listItem"><span>・</span>ノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリスト</li>
  </ul>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-normalList-01 {
  color rgba(0,0,0,.9)
  margin-top 16px
  font-size: 15px
  line-height: (22/15)
  &_listItem {
    padding-left 1.2em
    > span {
      width 1.2em
      text-align center
      display inline-block
      margin-left -1.2em
    }
  }
}
