/*
---
name: pd_b-eventsDetail
category: block
tag:
  - text
  - img
  - icon
  - btn
---
```html
<div class="pd_b-eventsDetail">
    <div class="pd_b-eventsDetail_inner">
    <div class="pd_m-eventsOverview">
                            <p class="pd_c-eventsSubTitle">イベント概要</p>
        <div class="pd_m-eventsOverview_main">
        <div class="pd_m-eventsOverview_mainText">
            <div class="pd_m-eventsOverview_textWrap">
            <p class="pd_m-eventsOverview_ttl">開催日時</p>
            <p class="pd_m-eventsOverview_text">3月・4月<br>水曜日:14:30～17:30(最終受付17:00)<br>土/日曜日:11:30～17:30(最終受付17:00)</p>
            </div>
            <div class="pd_m-eventsOverview_textWrap">
            <p class="pd_m-eventsOverview_ttl">開催方法</p>
            <p class="pd_m-eventsOverview_text">店舗実演　約30分 1,000円</p>
            </div>
            <div class="pd_m-eventsOverview_textWrap">
            <p class="pd_m-eventsOverview_ttl">内容</p>
            <div class="pd_m-eventsOverview_text">焙煎プロファイル作成方法、焙煎実演のご紹介。<br>「The Roast」のプロファイルづくりを手がける 焙煎士・後藤直紀氏を講師にお迎えし、 自宅でおいしいコーヒーを楽しむためのコツを伝授します。</div>
            </div>
            <div class="pd_m-eventsOverview_textWrap">
            <p class="pd_m-eventsOverview_ttl">定員</p>
            <div class="pd_m-eventsOverview_text">〇〇名</div>
            </div>
            <div class="pd_m-eventsOverview_textWrap">
            <p class="pd_m-eventsOverview_ttl">講師</p>
            <p class="pd_m-eventsOverview_text">後藤直紀　焙煎士</p>
            </div>
            <div class="pd_m-eventsOverview_textWrap">
            <p class="pd_m-eventsOverview_ttl">講師プロフィール</p>
            <p class="pd_m-eventsOverview_text">「豆香洞コーヒー」のオーナーであり、日本人としては唯一の「World Coffee Roasting Championship」に優勝者。</p>
            </div>
            <div class="pd_m-eventsOverview_textWrap">
            <p class="pd_m-eventsOverview_ttl">開催場所</p>
            <ul class="pd_m-eventsOverview_accessList">
                <li class="pd_m-eventsOverview_accessListItem"> 
                <p class="pd_m-eventsOverview_accessListItemTtl">店舗名：</p><a href="">リライフスタジオ フタコ</a>
                </li>
                <li class="pd_m-eventsOverview_accessListItem">
                <p class="pd_m-eventsOverview_accessListItemTtl">住所：</p><span class="pd_m-eventsOverview_accessListSp-01">東京都世田谷区玉川1丁目14番1号<br> 二子玉川ライズ S.C.テラスマーケット 二子玉川 蔦屋家電 2階</span>
                </li>
                <li class="pd_m-eventsOverview_accessListItem">
                <p class="pd_m-eventsOverview_accessListItemTtl">電話番号：</p><span class="pd_m-eventsOverview_accessListSpan-02">03-5717-9545</span>
                </li>
            </ul>
            </div>
        </div>
        <div class="pd_m-eventsOverview_imgWrap">
            <div class="pd_m-eventsOverview_img"><img src="com/images/module/eventsOverview_img01.jpg" alt="イベント概要"></div>
            <div class="pd_m-eventsOverview_img"><img src="com/images/module/eventsOverview_img02.jpg" alt="イベント概要"></div>
        </div>
        </div>
    </div>
    <div class="pd_m-eventsRequest" id="eventsRequest">
                            <p class="pd_c-eventsSubTitle">イベント申込</p>
        <div class="pd_m-requestCalendar">
        <div class="pd_c-calendarTemp pd_c-calendarTemp-march">
            <p class="pd_c-calendarTemp_ttl">2021年3月</p>
            <table class="pd_c-calendarTemp_table">
            <tr class="pd_c-calendarTemp_week">
                <th>日</th>
                <th>月</th>
                <th>火</th>
                <th>水</th>
                <th>木</th>
                <th>金</th>
                <th>土</th>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td> </td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>13</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>14</td>
                <td>15</td>
                <td>16</td>
                <td>17</td>
                <td>18</td>
                <td>19</td>
                <td>20</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>21</td>
                <td>22</td>
                <td>23</td>
                <td class="pd_c-calendarTemp_active">24</td>
                <td class="pd_c-calendarTemp_active">25</td>
                <td class="pd_c-calendarTemp_active">26</td>
                <td class="pd_c-calendarTemp_active">27</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td class="pd_c-calendarTemp_active">28</td>
                <td class="pd_c-calendarTemp_active">29</td>
                <td class="pd_c-calendarTemp_active">30</td>
                <td class="pd_c-calendarTemp_active">31</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            </table>
        </div>
        <div class="pd_c-calendarTemp pd_c-calendarTemp-march">
            <p class="pd_c-calendarTemp_ttl">2021年4月</p>
            <table class="pd_c-calendarTemp_table">
            <tr class="pd_c-calendarTemp_week">
                <th>日</th>
                <th>月</th>
                <th>火</th>
                <th>水</th>
                <th>木</th>
                <th>金</th>
                <th>土</th>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td class="pd_c-calendarTemp_active">1</td>
                <td class="pd_c-calendarTemp_active">2</td>
                <td class="pd_c-calendarTemp_active">3</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td class="pd_c-calendarTemp_active">4</td>
                <td class="pd_c-calendarTemp_active">5</td>
                <td class="pd_c-calendarTemp_active">6</td>
                <td class="pd_c-calendarTemp_active">7</td>
                <td class="pd_c-calendarTemp_active">8</td>
                <td class="pd_c-calendarTemp_active">9</td>
                <td class="pd_c-calendarTemp_active">10</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td class="pd_c-calendarTemp_active">11</td>
                <td class="pd_c-calendarTemp_active">12</td>
                <td class="pd_c-calendarTemp_active">13</td>
                <td class="pd_c-calendarTemp_active">14</td>
                <td class="pd_c-calendarTemp_active">15</td>
                <td class="pd_c-calendarTemp_active">16</td>
                <td class="pd_c-calendarTemp_active">17</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td class="pd_c-calendarTemp_active">18</td>
                <td class="pd_c-calendarTemp_active">19</td>
                <td class="pd_c-calendarTemp_active">20</td>
                <td class="pd_c-calendarTemp_active">21</td>
                <td>22</td>
                <td>23</td>
                <td>24</td>
            </tr>
            <tr class="pd_c-calendarTemp_date">
                <td>25</td>
                <td>26</td>
                <td>27</td>
                <td>28</td>
                <td>29</td>
                <td>30</td>
                <td></td>
            </tr>
            </table>
        </div>
        </div>
    </div>
    <div class="pd_m-eventsProduct01">
                            <p class="pd_c-eventsSubTitle">イベントで使用する商品</p>
        <ul class="pd_m-eventsProduct01_list">
        <li class="pd_m-eventsProduct01_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                            <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                            <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">コーヒー焙煎機</p>
                                            </div>
                                            <p class="pd_m-smallBoxItem_ttl">The Roast</p>
                                        </div></a></li>
        <li class="pd_m-eventsProduct01_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                            <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                            <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">消耗品・付属品</p>
                                            </div>
                                            <p class="pd_m-smallBoxItem_ttl">スマート コーヒー焙煎機 ガラス容器</p>
                                        </div></a></li>
        <li class="pd_m-eventsProduct01_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                            <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                            <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">消耗品・付属品</p>
                                            </div>
                                            <p class="pd_m-smallBoxItem_ttl">エチオピア カフェインレス</p>
                                        </div></a></li>
        <li class="pd_m-eventsProduct01_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                            <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                            <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">消耗品・付属品</p>
                                            </div>
                                            <p class="pd_m-smallBoxItem_ttl">コロンビア カフェインレス</p>
                                        </div></a></li>
        </ul>
    </div>
    <div class="pd_m-eventsAccess">
                            <p class="pd_c-eventsSubTitle">アクセス</p>
        <div class="pd_m-eventsAccess_inner">
        <div class="pd_m-eventsAccess_itemWrap">
            <div class="pd_m-eventsAccess_googleMap"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.6744055431823!2d139.62746431561072!3d35.6110973414219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f43f861e61d7%3A0xdcf3f93c46fd055f!2z44Oq44Op44Kk44OV44K544K_44K444KqIOODleOCv-OCsw!5e0!3m2!1sja!2sjp!4v1621936179181!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe></div>
            <ul class="pd_m-eventsAccess_textList">
            <li class="pd_m-eventsAccess_textListItem"> 
                <p>〒158-0094</p>
            </li>
            <li class="pd_m-eventsAccess_textListItem"> 
                <p>東京都世田谷区玉川1丁目14番1号<br>二子玉川ライズS.C.テラスマーケット 二子玉川 蔦屋家電 2階</p>
            </li>
            <li class="pd_m-eventsAccess_textListItem"> 
                <p class="pd_m-eventsAccess_textListItem-01">交通：</p><span>東急電鉄 大井町・田園都市線【二子玉川駅】より徒歩4分</span>
            </li>
            <li class="pd_m-eventsAccess_textListItem"> 
                <p>TEL：</p><span>03-5717-9545</span>
            </li>
            <li class="pd_m-eventsAccess_textListItem"> 
                <p>休業日：</p><span>1月1日</span>
            </li>
            </ul>
        </div>
        <div class="pd_m-eventsAccess_itemWrap">
            <div class="pd_m-eventsAccess_mapPdf"><img src="com/images/module/eventsAccess_map.png" alt=""></div><a class="pd_c-pdfLink pd_e-hover-01" href="/">PDFファイル:蔦屋家電内マップはこちら</a>
            <div class="pd_m-eventsAccess_text">
            <p>
                蔦屋家電2F<br>館内エスカレーターを上がって右手にお進みください。</p>
            </div>
        </div>
        </div>
    </div>
    </div>
</div>
```
*/
.pd_b-eventsDetail
    border-bottom: 1px solid #CCCCCC
    padding-bottom: 60px
    &_inner
        margin: 50px auto 0
        width: 976px
@media screen and ( max-width 640px)
    .pd_b-eventsDetail
        padding: 0 15px 60px
        &_inner
            margin: 54px auto 0
            width: 100%