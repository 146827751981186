/*
---
name: pd_b-tabDetail-02
category: block
---
```html
```
*/
.pd_b-tabDetail
    display: none
    margin: 0 auto
    padding-top 30px
    position: relative
    max-width: 976px
    width: 976px!important
    &.pd_active
        display: block
.pd_b-tabDetail_inner
    margin: 0 auto
    padding: 40px 0 0 0
    width: 976px
#pd_b-tabDetail_list
    &::after
        content: '';
        display: block;
        clear: both;
.pd_b-tabDetail_list
    &.pd_b-tabDetail_list--event
        display: flex;
        flex-wrap: wrap;
.pd_b-tabDetail_listItem
    float: left;
    width: 249px
    &.pd_circleImg
        .pd_m-smallBoxItem_img
            background-color transparent
            img
                height: 190px
                border-radius:50%;
                font-family: 'object-fit:none;'
                object-fit:none;
                width: 190px
    &.pd_b-tabDetail_listItem--event
        float: none;

.pd_b-tabDetail_listItem-big
    margin-right: 0px
    width: 498px
// lifestyleタブ固有
.pd_b-tabDetail_lifestyleBtm_list
    display: flex
    flex-wrap: wrap
    justify-content: space-between
.pd_b-tabDetail_more
    cursor: pointer
    color: #364563
    display: table
    font-size: 15px
    margin 0px auto 60px
    padding-right: 36px;
    position: relative
    &::after
        background-image: url('/store/images/block/categorySearch_plus.svg')
        content: ""
        display: block
        height: 16px
        position: absolute
        right: 4px
        top: 50%
        transform: translateY(-50%)
        width: 16px
    .pd_b-tabDetail_more_text02
        display: none
    &.pd_open
        .pd_b-tabDetail_more_text01
            display: none
        .pd_b-tabDetail_more_text02
            display: block
        &::after
            background-image: url('/store/com/images/block/categorySearch_minus.svg')
// 商品情報・イベントキャンペーン
#pd_productInfo,#pd_event
    #pd_b-tabDetail_list
        align-items flex-start
        display: flex
        flex-wrap: wrap
        justify-content: space-between
// アコーディオン用
.pd_b-tabDetail_acc
    display: none
@media screen and (min-width: 641px)
    .pd_b-tabDetail_lifestyleBtm_list,
    #pd_b-tabDetail_list
        margin-right: -10px
        margin-left: -10px
    .pd_b-tabDetail_list
        .pd_m-smallBox
            margin-right: 10px;
            margin-left: 10px;
        .pd_m-bigBox
            margin-right: 10px;
            margin-left: 10px;
@media screen and (max-width 640px)
    .pd_b-tabDetail
        width: 100%!important
    #pd_b-tabDetail_list
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        padding: 0 4vw
        &::after
          display none
    .pd_b-tabDetail_listItem
        width: 44vw
        &.pd_circleImg
            .pd_m-smallBoxItem_img img
                height: 155px
                width: 155px
    .pd_b-tabDetail_listItem-big
        width: 100%
    // // lifestyleタブ固有
    .pd_b-tabDetail_lifestyleBtm_list
        padding: 0 4vw
