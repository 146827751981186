/*
---
name: pd_c-btn-02
category: component
tag:
  - btn
---
```html
  <a class="pd_c-btn-02 pd_e-hover-01 pd_e-hover-01--bgGray" href="#">くらしに役立つ各種イベントトップに戻る</a>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-btn-02 {
  background-color #fff
  display block
  border solid 1px #364563
  border-radius 8px
  font-size 15px
  line-height (23/15k)
  text-align center
  box-shadow 0 3px 6px rgba(0,0,0,.16)
  @media screen and (min-width:MIN_POINT) {
    padding 14px 14px 13px
  }
  @media screen and (max-width:MAX_POINT) {
    padding 14px 14px 11px
  }
  &:link,
  &:hover,
  &:visited,
  &:visited:hover,
  &:active,
  &:focus {
    color #364563
  }
  &--filter {
    &::before {
      content ''
      width 24px
      height 24px
      background-repeat no-repeat
      background-size 100% auto
      background-image url('/store/com/images/component/btn-02_icon_filter.svg')
      display inline-block
      vertical-align middle
      margin-right 10px
    }
  }
}
