/*
---
name: pd_c-group-01
category: component
tag:
  - text
  - bnr
---
```html
  <div class="pd_c-group-01">
      <h1 class="pd_c-group-01_heading">1/1フォーマット</h1>
      <p class="pd_c-group-01_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト本文テキスト本文テキスト本文テキスト本文テキスト</p>
      <figure class="pd_c-figure-01 pd_c-figure-01--expand"><img class="pd_c-figure-01_img" src="http://placehold.jp/3d4070/ffffff/1500x500.png" alt="" /></figure>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-group-01 {
  margin-top 40px
  &_heading {
    color rgba(0,0,0,.9)
    font-weight bold
    font-size 22px
    line-height (33/22)
  }
  &_text-01 {
    font-size 15px
    margin-top 12px
  }
  &_img {
    margin-top 16px
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right -15px
      margin-left -15px
    }
  }
}
