/*
---
name: footer_nav
category: pd
---
```html
<div class="pd_footer_navWrap">
    <ul class="pd_footer_navList">
        <li class="pd_footer_navListItem pd_e-hover-01 pd_e-hover-01--bgGray"><a href="">Panasonic Store Plusのサービス</a></li>
        <li class="pd_footer_navListItem pd_e-hover-01 pd_e-hover-01--bgGray"><a href="">ご利用ガイド</a></li>
        <li class="pd_footer_navListItem pd_e-hover-01 pd_e-hover-01--bgGray"><a href="">よくあるご質問</a></li>
    </ul>
</div>
```
*/

.pd_footer_navWrap
  background-color: #fff
  padding: 40px 0
  width: 100vw

.pd_footer_navList
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  margin: 0 auto
  width: 976px
.pd_footer_navListItem
  width: 312px
  border-radius 8px
  box-shadow: 0px 4px 3px rgba(0,0,0,0.1)
  a
    box-sizing: border-box;
    align-items: center
    border: 1px solid #364563
    border-radius: 8px
    color #364563
    cursor: pointer
    display: flex
    font-size: 15px
    justify-content: center
    height: 52px
    padding-left 30px
    position: relative
    width: 100%
    &::after
      background-image: url('/store/images/top/footer_nav_iconCart.svg')
      background-repeat: no-repeat
      background-size: cover
      content: ""
      display block
      height 24px
      left: calc(50% - 125px);
      position: absolute
      top: 50%
      transform: translateY(-50%)
      width 24px
.pd_footer_navListItem:first-child
  letter-spacing: -0.4px
.pd_footer_navListItem:nth-child(2)
  a::after
    background-image: url('/store/images/top/footer_nav_iconGuide.svg')
    left: calc(50% - 60px);
.pd_footer_navListItem:last-child
  a::after
    background-image: url('/store/images/top/footer_nav_iconQA.svg')
    left: calc(50% - 67px);
/* SP */
@media screen and (max-width: 640px)
  // footer nav
  .pd_footer_navWrap
    padding 40px 15px 20px
    width: 100%
  .pd_footer_navList
    width: 100%
  .pd_footer_navListItem
    margin-bottom: 20px
    width: 100%