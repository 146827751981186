/*
---
name: pd_m-contract-01
category: module
tag:
  - text
  - btn
  - img
---
```html
<div class="pd_m-contract-01 pd_m-js_scroll">
    <div class="pd_m-contract-01_inner">
        <div class="pd_m-contract-01_leftCol">
            <div class="pd_c-itemImage-03">
                <div class="pd_c-itemImage-03_img"><img src="http://placehold.jp/3d4070/ffffff/100x100.png" alt="" /></div>
                <div class="pd_c-itemImage-03_contents">
                    <p class="pd_c-itemImage-03_name">スチーマー ナノケア　定額利用サービス（2019年発売温感かっさ セット）</p>
                    <p class="pd_c-itemImage-03_price"><span class="pd_c-itemImage-03_textGroup"><span class="pd_c-itemImage-03_text-02">2000</span><span class="pd_c-itemImage-03_text-01">円</span><span class="pd_c-itemImage-03_text-03">（税込）</span></span><span class="pd_c-itemImage-03_textGroup"><span class="pd_c-itemImage-03_text-01">初回請求</span><span class="pd_c-itemImage-03_text-02">0</span><span class="pd_c-itemImage-03_text-01">円</span><span class="pd_c-itemImage-03_text-03">（税込）</span></span><span class="pd_c-itemImage-03_textGroup"><span class="pd_c-itemImage-03_text-01">月額請求</span><span class="pd_c-itemImage-03_text-02">2,000</span><span class="pd_c-itemImage-03_text-01">円</span><span class="pd_c-itemImage-03_text-03">（税込）</span></span><span class="pd_c-itemImage-03_textGroup"><span class="pd_c-itemImage-03_text-01">年額請求</span><span class="pd_c-itemImage-03_text-02">2,000</span><span class="pd_c-itemImage-03_text-01">円</span><span class="pd_c-itemImage-03_text-03">（税込）</span></span><span class="pd_c-itemImage-03_textGroup"><span class="pd_c-itemImage-03_text-01">支払総額</span><span class="pd_c-itemImage-03_text-02">2,000</span><span class="pd_c-itemImage-03_text-01">円</span><span class="pd_c-itemImage-03_text-03">（税込）</span></span></p>
                </div>
            </div>
        </div>
        <div class="pd_m-contract-01_rightCol">
            <button class="pd_m-contract-01_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)">
                <p class="pd_m-contract-01_btnText-01"><span>契約へ進む</span></p>
            </button>
        </div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-contract-01 {
  box-shadow 0 -3px 6px rgba(0,0,0,.16)
  position fixed
  width 100%
  left 0
  bottom 0
  background-color #fff
  padding 19px 0
  z-index 100
  &_inner {
    @media screen and (min-width:MIN_POINT) {
      display flex
      max-width 980px
      margin-right auto
      margin-left auto
      align-items center
      justify-content space-between
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right 15px
      margin-left 15px
    }
  }
  &_leftCol,
  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_leftCol {
    flex: 1;
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
      padding-left 20px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 16px
    }
  }

      640*0.56
  &_btn {
    font-size 15px
    color #fff
    text-align center
    padding 0
    background #364563
    border none
    display block;
    width 100%
    border-radius: 8px
    padding: 14px;
    @media screen and (min-width:MIN_POINT) {
      min-width: 190px;
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
}

.pd_m-contract-02 {
  box-shadow 0 -3px 6px rgba(0,0,0,.16)
  position fixed
  width 100%
  left 0
  bottom 0
  background-color #fff
  padding 19px 0
  z-index 100
  &_inner {
    @media screen and (min-width:MIN_POINT) {
      display flex
      max-width 980px
      margin-right auto
      margin-left auto
      align-items center
      justify-content space-between
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right 15px
      margin-left 15px
    }
  }
  &_leftCol,
  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_leftCol {
    flex: 1;
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
      padding-left 20px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 16px
    }
  }

      640*0.56
  &_btn {
    font-size 15px
    color #fff
    text-align center
    padding 0
    background #ccc
    border none
    display block;
    width 100%
    border-radius: 8px
    padding: 14px;
    @media screen and (min-width:MIN_POINT) {
      min-width: 190px;
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
}