/*
---
name: pd_m-modalBox-02
category: module
tag: 
  - text
  - img
---
```html
<div class="pd_m-modalBox-02" id="modalBox-viewer-360">
  <picture>
    <source srcset="http://placehold.jp/666666/ffffff/1640x1230.png?text=仕様確定後再調整" media="(min-width: 641px)" /><img src="http://placehold.jp/666666/ffffff/750x1624.png?text=仕様確定後再調整" alt="" />
  </picture>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.mfp-content .pd_m-modalBox-02 {
  margin auto
  padding auto
  height auto
  overflow auto
}
.pd-m-modalBox-bg{
  background-color #fff
  padding 20px 0
  width 96%
  margin-right auto
  margin-left auto
  @media screen and (min-width:MIN_POINT) {
    width 820px
    margin-right auto
    margin-left auto
    padding 20px 0
  }
}
.pd_m-modalBox-02 {
  background-color #fff
  margin 0
  padding 0
  height 0
  overflow hidden
  position relative
  img {
    vertical-align middle
  }
  @media screen and (min-width:MIN_POINT) {
    width: 90%
    margin-right auto
    margin-left auto
  }
  @media screen and (max-width:MAX_POINT) {
    margin-right -6px
    margin-left -6px
    margin-bottom -6px
  }
  .mfp-close {
    text-indent -9999px
    overflow hidden
    background-repeat no-repeat
    background-size 100% auto
    background-image url('/store/images/module/modal_icon_close.svg')
    width 30px
    height 30px
    @media screen and (min-width:MIN_POINT) {
      top 5px
      right 5px
    }
    @media screen and (max-width:MAX_POINT) {
      top 24px
      right 15px
    }
  }
}
