/*
---
name: pd_c-checkBox-02
category: component
tag:
  - checkBox
---
```html
  <label>
      <input type="checkbox" name="status" /><span class="pd_c-checkBox-01_indicator-02"></span><span class="pd_c-checkBox-01_text-02">配信中/開催中</span>
  </label>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_b-tabDetail {
  label {
    position: relative;
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      margin-left 15px
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
    &.pd_js-isActive {
      .pd_c-checkBox-01_indicator-02 {
        background-image: url("/store/com/images/component/checkBox-01_checked.svg");
      }
    }
    .pd_c-checkBox-01_indicator-02 {
      background-image: url("/store/com/images/component/checkBox-01.svg");
      background-repeat: no-repeat;
      display: inline-block;
      width:24px;
      height: 24px;
      margin-right: 10px;
    }
    .pd_c-checkBox-01_indicator-02,
    .pd_c-checkBox-01_text-02 {
      display inline-block
      vertical-align top
    }
  }
  .pd_b-tabDetail_list {
      margin-top 34px
  }
}
