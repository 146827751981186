/*
---
name: pd_m-eventsInChange
category: module
tag:
  - text
  - img
---
```html
<div class="pd_m-eventsInCharge">
  <p class="pd_c-eventsSubTitle">担当エキスパート</p>
  <div class="pd_m-eventsInCharge_profile">
    <div class="pd_m-eventsInCharge_img"><img src="/store/images/module/eventsInCharge_img.png" alt="担当エキスパート"></div>
    <div class="pd_m-eventsInCharge_textWrap">
      <p class="pd_m-eventsInCharge_name">小宮 朝子</p>
      <div class="pd_m-eventsInCharge_subNameWrap">
        <p class="pd_m-eventsInCharge_subName">RELIFE STUDIOアドバイザー</p><a class="pd_m-eventsInCharge_link pd_e-hover-01" href="">詳しいプロフィールはこちら</a>
      </div>
      <p class="pd_m-eventsInCharge_comment">担当エキスパートからのコメントが入ります担当エキスパートからのコメントが入ります担当エキスパートからのコメントが入ります担当エキスパートからのコメントが入ります担当エキスパートからのコメントが入ります担当エキスパートからのコメントが入ります担当エキスパートからのコメントが入ります担当エキスパートからのコメントが入ります</p>
    </div>
  </div>
</div>
```
*/
.pd_m-eventsInCharge
    margin-top: 52px
    &_profile
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        margin-top: 11px
    &_img
        border-radius: 50%
        display: block
        height: 146px
        margin-top: 7px
        width: 146px
    &_textWrap
        width: 810px
    &_name
        font-size: 22px
        font-weight: 500
    &_subNameWrap
        display: flex
        margin-top: 4px
        justify-content: space-between
    // &_subName
    &_link
        color: #364563
        text-decoration: underline
        letter-spacing: 1px;
        padding-right: 13px
        position: relative
        &::after
            border-top: 2px solid #364563
            border-right: 2px solid #364563
            content: ""
            display: block
            height: 6px
            position: absolute
            right: 2px
            top: 50%
            transform: translateY(-50%) rotate(45deg)
            width: 6px
    &_comment
        border: 1px solid rgba(0,0,0,0.2)
        border-radius: 8px
        margin-top: 16px
        padding: 30px
        width: 100%
@media screen and (max-width 640px)
    .pd_m-eventsInCharge
        margin-top: 55px
        &_profile
            margin-top: 7px
            position: relative
        &_img
            height: 120px
            margin-top: 7px
            position: absolute
            width: 120px
        &_textWrap
            width: 100%
        &_name
            margin-left: 135px
            max-width: 210px
            width: calc(100% - 135px);
        &_subNameWrap
            display: block
            max-width: 210px
            width: calc(100% - 135px);
            margin-left: 135px
        // &_subName
        &_link
            display: block
            margin-top: 16px
        //     &::after
        &_comment
            margin-top: 60px
            padding: 20px