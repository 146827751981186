/*
---
name: pd_b-plan-02
category: block
tag:
  - text
  - checkBox
  - btn
  - img
---
```html
<div class="pd_b-plan-02">
    <div class="pd_b-plan-02_head">
    <h1 class="pd_b-plan-02_heading">プランを選択</h1>
    </div>
    <div class="pd_b-plan-02_body">
    <div class="pd_b-plan-02_inner-01">
        <div class="pd_b-plan-02_headSub"><span class="pd_b-plan-02_title">センサー</span><span class="pd_e-required-01">必須  </span></div>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor1" name="sensor1"><span class="pd_c-planSelect_btn_label" for="sensor1"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">センサー3年</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
            </div>
            <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円/月(税込)</span><br><span class="pd_e-planTag-01">在庫あり</span>
            </div>
        </label>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor1" name="sensor1"><span class="pd_c-planSelect_btn_label" for="sensor1"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
            </div>
            <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-01">在庫あり</span>
            </div>
        </label>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor1" name="sensor1" disabled><span class="pd_c-planSelect_btn_label" for="sensor1"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
            </div>
            <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-02">在庫なし</span>
            </div>
        </label>
    </div>
    <div class="pd_b-plan-02_inner">
        <div class="pd_b-plan-02_headSub"><span class="pd_b-plan-02_title">照明</span><span class="pd_e-required-01">必須  </span></div>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor2" name="sensor2" disabled><span class="pd_c-planSelect_btn_label" for="sensor2"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
            </div>
            <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-02">販売停止中</span>
            </div>
        </label>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor2" name="sensor2" disabled><span class="pd_c-planSelect_btn_label" for="sensor2"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
            </div>
            <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-02">在庫なし</span>
            </div>
        </label>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor2" name="sensor2" disabled><span class="pd_c-planSelect_btn_label" for="sensor2"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span><br><span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
            </div>
            <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span><br><span class="pd_e-planTag-02">販売終了</span>
            </div>
        </label>
    </div>
    <div class="pd_b-plan-02_inner">
        <div class="pd_b-plan-02_headSub"><span class="pd_b-plan-02_title">設置</span><span class="pd_e-required-01">必須  </span></div>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor3" name="sensor3"><span class="pd_c-planSelect_btn_label" for="sensor3"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">標準設置</span>
            </div>
            <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span>
            </div>
        </label>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor3" name="sensor3"><span class="pd_c-planSelect_btn_label" for="sensor3"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span>
            </div>
            <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span>
            </div>
        </label>
    </div>
    <div class="pd_b-plan-02_inner">
        <div class="pd_b-plan-02_headSub"><span class="pd_b-plan-02_title">定期お掃除</span></div>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor4" name="sensor4"><span class="pd_c-planSelect_btn_label" for="sensor4"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">選択なし</span>
            </div>
            <div class="pd_c-planSelect_right"></div>
        </label>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor4" name="sensor4"><span class="pd_c-planSelect_btn_label" for="sensor4"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">項目名が入ります</span>
            </div>
            <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span>
            </div>
        </label>
    </div>
    <div class="pd_b-plan-02_inner">
        <div class="pd_b-plan-02_headSub"><span class="pd_b-plan-02_title">延長保証</span></div>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor5" name="sensor5"><span class="pd_c-planSelect_btn_label" for="sensor5"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">選択なし</span>
            </div>
            <div class="pd_c-planSelect_right"></div>
        </label>
        <label class="pd_c-planSelect">
            <div class="pd_c-planSelect_btn">
            <input class="pd_c-planSelect_btn_input" type="radio" id="sensor5" name="sensor5"><span class="pd_c-planSelect_btn_label" for="sensor5"></span>
            </div>
            <div class="pd_c-planSelect_left"><span class="pd_e-planName">説明が入ります</span>
            </div>
            <div class="pd_c-planSelect_right"><span class="pd_e-planView">2,020円(税込)</span>
            </div>
        </label>
    </div>
    </div>
    <div class="pd_m-plan-01_row">
    <div class="pd_m-plan-01_leftCol">
        <div class="pd_m-itemImage-01">
        <div class="pd_m-itemImage-01_img"><img src="http://placehold.jp/ffffff/000000/50x500.png?text=サムネイル" alt=""></div>
        <div class="pd_m-itemImage-01_contents">
            <p class="pd_m-itemImage-01_name">温感かっさ（2019年発売）ピンク</p><a class="pd_c-linkText-01 pd_e-hover-01 pd_js-popup-modal" href="#modal1">商品詳細はこちら</a>
        </div>
        </div>
    </div>
    <div class="pd_m-plan-01_rightCol">
        <div class="pd_m-itemImage-01">
        <div class="pd_m-itemImage-01_img"><img src="http://placehold.jp/ffffff/000000/50x500.png?text=サムネイル" alt=""></div>
        <div class="pd_m-itemImage-01_contents">
            <p class="pd_m-itemImage-01_name">温感かっさ（2019年発売）ピンク</p><a class="pd_c-linkText-01 pd_e-hover-01 pd_js-popup-modal" href="#modal1">商品詳細はこちら</a>
        </div>
        </div>
    </div>
    </div>
    <div class="pd_m-plan-01_row">
    <div class="pd_m-plan-01_leftCol">
        <div class="pd_m-itemImage-01">
        <div class="pd_m-itemImage-01_img"><img src="http://placehold.jp/ffffff/000000/50x500.png?text=サムネイル" alt=""></div>
        <div class="pd_m-itemImage-01_contents">
            <p class="pd_m-itemImage-01_name">温感かっさ（2019年発売）ピンク</p><a class="pd_c-linkText-01 pd_e-hover-01 pd_js-popup-modal" href="#modal1">商品詳細はこちら</a>
        </div>
        </div>
    </div>
    <div class="pd_m-plan-01_rightCol"></div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_b-plan-02
    
    &_head

    &_heading 
        font-size 22px
        line-height 1.5

    &_body
        background #f2f2f2
        border-radius 8px
        padding 25px 20px 
        margin 15px 0
    &_inner,
    &_inner-01
        margin-bottom 50px
        .pd_c-planSelect
            &.is_select
                background #ecf4fc
                border 1px solid #a4ccfc
    &_headSub
        display flex
        flex-direction row
    &_title
        font-size 18px
        font-weight bold
        line-height 1.5
        margin-right 20px