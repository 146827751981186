/*
---
name: pd_e-money-02
category: element
tag:
  - text
---
```html
<span class="pd_e-money-02">3000円</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-money-02 {
    line-height 1
    font-size 17px
    font-weight bold
}