/*
---
name: pd_b-questionnaire
category: block
tag:
  - text
  - checkBox
  - btn
---
```html
<div class="pd_b-questionnaire">
                  <div class="pd_b-questionnaire_inner">
                                        <h1 class="pd_m-contentTtl-01">アンケート回答</h1>
                    <p class="pd_b-questionnaire_attention">以下の項目を選択・記入いただき、「回答する」ボタンを押してください。<br>必須の項目は必ずご記入ください。</p>
                    <form class="pd_b-questionnaire_form" action="">
                      <div class="pd_b-questionnaire_content pd_b-questionnaire_content01">
                        <div class="pd_m-radioWrap">
                                                    <p class="pd_c-requiredTitle">1. 今回のイベントをどうやってお知りになりましたか？<span class="pd_c-requiredTitle_small">（単一回答）</span></p>
                          <ul class="pd_m-radioWrap_list">
                            <li class="pd_m-radioWrap_listItem">
                                                            <label class="pd_c-radioBtn-01__event">
                                                              <input type="radio" name="event" value="ウェブサイト"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">ウェブサイト</span>
                                                            </label>
                            </li>
                            <li class="pd_m-radioWrap_listItem">
                                                            <label class="pd_c-radioBtn-01__event">
                                                              <input type="radio" name="event" value="友人からのすすめ"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">友人からのすすめ</span>
                                                            </label>
                            </li>
                            <li class="pd_m-radioWrap_listItem">
                                                            <label class="pd_c-radioBtn-01__event">
                                                              <input type="radio" name="event" value="Facebook"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">Facebook</span>
                                                            </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="pd_b-questionnaire_content pd_b-questionnaire_content02">
                                                <p class="pd_c-requiredTitle">2. 質問文が入ります<span class="pd_c-requiredTitle_small">（複数回答）</span></p>
                        <ul class="pd_b-questionnaire_content02List">
                          <li class="pd_b-questionnaire_check01__event">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name=""><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check01__event">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name=""><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check01__event">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name=""><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります選択肢テキストが入ります</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check01__event">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name=""><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキストが入ります選択肢テキストが入ります</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check01__event">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name=""><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキストが入ります</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check01__event">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name=""><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">その他</span>
                                                    </label>
                            <p class="pd_c-checkTextArea-01_textArea__event" type="textarea">
                              <textarea></textarea>
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div class="pd_b-questionnaire_content pd_b-questionnaire_content03">
                                                <p class="pd_c-requiredTitle">3. 質問文が入ります<span class="pd_c-requiredTitle_small">（複数回答）</span></p>
                        <ul class="pd_b-questionnaire_content03List">
                          <li class="pd_b-questionnaire_check03">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name="question"><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check03">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name="question"><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check03">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name="question"><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check03">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name="question"><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check03">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name="question"><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check03">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name="question"><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check03">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name="question"><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check03">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name="question"><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check03">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name="question"><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">選択肢テキスト</span>
                                                    </label>
                          </li>
                          <li class="pd_b-questionnaire_check01__event">
                                                    <label class="pd_c-checkbox__event">
                                                      <input type="checkbox" name=""><span class="pd_c-checkBox-01_indicator-01__event"></span><span class="pd_c-checkBox-01_text-01">その他</span>
                                                    </label>
                            <p class="pd_c-checkTextArea-01_textArea__event" type="textarea">
                              <textarea></textarea>
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div class="pd_b-questionnaire_content pd_b-questionnaire_content04">
                                                <p class="pd_c-requiredTitle">4. 質問文が入ります<span class="pd_c-requiredTitle_small">（自由回答）</span></p>
                        <p class="pd_b-questionnaire_textarea">
                          <textarea></textarea>
                        </p>
                      </div>
                    </form>
                                        <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                          <p class="pd_c-filterButton_text">回答する</p>
                                        </button>
                  </div>
                </div>
```
*/
.pd_b-questionnaire
  margin: 0 auto 60px
  &_inner
      margin: 0 auto
      width: 976px
  .pd_m-contentTtl-01
      font-size 40px
      font-weight bold
      margin 50px 0 0
  &_attention
      margin: 20px 0 0 0;
      letter-spacing: 1px;
      padding: 0 0 44px
  &_form
      border-bottom: 1px solid #CCCCCC
      border-top: 1px solid #CCCCCC
  &_content
      margin: 40px 0
      .pd_c-checkTextArea-01
          display: block
          margin-top: 15px
  // &_content02List
  &_check01
      margin-top: 16px
      letter-spacing: 1px
  &_content03List
      display: flex
      flex-wrap wrap
  &_check03
      margin-top: 16px
      letter-spacing: 1px
      width: calc(100% / 3)
  &_textarea
      box-sizing: border-box
      border: 1px solid  #707070
      border-radius: 8px
      display: block
      margin-top: 16px
      height: 104px
      padding 15px
      vertical-align: top
      width 100%
  .pd_c-filterButton
      margin: 37px auto 0
      display: block
      width: 345px
      @media screen and (max-width 640px)
          width: 100%
@media screen and (max-width 640px)
  .pd_b-questionnaire
      &_inner
          width: 92%
      .pd_m-contentTtl-01
          margin: 34px auto 18px
          padding: 0
      &_check01
          margin-top: 16px
          letter-spacing: 1px
      .pd_c-checkBox-01_text-01
          width: 85%
          margin-top: 2px
      &_check03
          width: calc(100% / 2)
          .pd_c-checkBox-01_text-01
              width: calc(100% - 34px)
.pd_c-radioBtn-01__event
  position: relative;
  font-size: 15px;
  line-height: 1.466666666666667;

  input:checked+span
    background-image: url(../../images/component/radioBtn-01_checked.svg)
.pd_c-radioBtn-01_indicator__event
  position: absolute;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  background-image: url(../../images/component/radioBtn-01.svg);
  left: 0;

.pd_b-questionnaire_check01__event
  width: 100%
  margin-top: 16px;
  letter-spacing: 1px;

.pd_b-questionnaire_content
  textarea
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    appearance: none;
    resize: none;

.pd_c-checkTextArea-01_textArea__event
  box-sizing: border-box;
  border: 1px solid #707070;
  border-radius: 8px;
  display: block;
  margin-top: 16px;
  height: 52px;
  padding: 15px;
  vertical-align: top;
  width: 100%;

.pd_c-checkBox-01_indicator-01__event
  background-image: url(../../com/images/component/checkBox-01.svg);
  background-repeat: no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;

.pd_c-checkbox__event
  input
    display: none
  input:checked+span
    background-image: url(../../com/images/component/checkBox-01_checked.svg);
