/*
---
name: pd_c-productPanelItem
category: component
tag:
  - text
---
```html
<div class="pd_c-productPanelItem">
    <p class="pd_c-productPanelItem_modelNum">TH-65GZ1800</p>
    <p class="pd_c-productPanelItem_modelNum-02">TH-65GZ1800後継機</p>
    <p class="pd_c-productPanelItem_price"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
    <p class="pd_c-productPanelItem_price-bar"><span>50,700</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
    <div class="pd_c-productPanelItem_discountPrice"><span class="pd_c-productPanelItem_discountPriceTax">値引後価格</span>
        <p><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
    </div>
    <p class="pd_c-productPanelItem_price-mini"><span>226,050</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
    <p class="pd_c-productPanelItem_monthlyPrice">月額<span class="pd_c-productPanelItem_monthlyPriceNum">5,000</span><span class="pd_c-productPanelItem_monthlyPriceTax">円 (税込) ~</span></p>
    <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
    <div class="pd_c-productPanelItem_guideArea-01">
        <p class="pd_c-productPanelItem_guideline">・交換の目安：約1年</p>
        <p class="pd_c-productPanelItem_note">※枠不要</p>
        <p class="pd_c-productPanelItem_note">※エアコン室内機のエアフィルターに取り付けてご使用ください</p>
    </div>
</div>
```
*/

.pd_c-productPanelItem
  text-align right
.pd_c-productPanelItem_discountPrice
    color: #CC0E00
    font-size: 13px
    margin-top: 6px
    text-align: right
    p
      line-height 1
.pd_c-productPanelItem_discountPriceNum
    font-size: 22px
    font-weight: 500
    padding-left: 10px
.pd_c-productPanelItem_discountPriceTax
    font-size: 13px
    padding-left: 2px
.pd_c-productPanelItem_price-mini
    font-size: 17px;
    margin-top: 13px;
    font-weight: 500;
    margin-top: 6px;
    text-align: right;
.pd_c-productPanelItem_monthlyPrice
    color: rgba(0,0,0,0.9)
    font-size: 13px
    margin-top: -6px
    text-align: right
.pd_c-productPanelItem_monthlyPriceNum
    font-size: 22px
    font-weight: 500
    padding-left: 10px
.pd_c-productPanelItem_monthlyPriceTax
    color rgba(0,0,0,0.6)
    font-size: 13px
    padding-left: 2px
.pd_c-productPanelItem_modelNum
    color rgba(0,0,0,0.6)
    font-size 13px
    margin-top: 7px
    text-align left
.pd_c-productPanelItem_modelNum-02
    color rgba(0,0,0,0.6)
    font-size 13px
    text-align left
.pd_c-productPanelItem_price
    color #000
    font-size 22px
    font-weight 500
    margin-top: 6px
    text-align: right
.pd_c-productPanelItem_priceTax
    color rgba(0,0,0,0.6)
    font-size 13px
    padding-left: 2px
.pd_c-productPanelItem_stock
    color rgba(0,0,0,0.6)
    font-size 13px
    margin-top: 3px
    text-align: right
.pd_c-productPanelItem_price-bar
    display inline-block
    font-size: 17px
    margin-top: 13px
    font-weight 500
    margin-top: 6px
    text-align: right
    position relative
    &::after
      content ''
      display block
      height 1px
      background #333
      width 100%
      position absolute
      left 0
      top 13px

@media screen and (max-width: 640px)
    .pd_c-productPanelItem_discountPrice
        line-height: 1;
        margin-top: 1px;
        br
            display: block
    .pd_c-productPanelItem_stock
        margin-top: 10px
    .pd_c-productPanelItem_monthlyPrice
        line-height: 1;
        margin-top: 1px;
