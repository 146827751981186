/*
---
name: pd_m-accordion-01
category: module
tag:
  - text
  - img
  - table
---
```html
<div class="pd_m-accordion-01">
    <div class="pd_m-accordion-01_item pd_js-closed">
        <div class="pd_m-accordion-01_itemHead pd_js-m-accordion-01_itemHead pd_e-hover-01 pd_e-hover-01--bgGray"><span class="pd_m-accordion-01_icon-01"></span>
            <div class="pd_c-itemImage-01">
                <div class="pd_c-itemImage-01_img"> <img src="http://placehold.jp/3d4070/ffffff/62x62.png" alt="" /></div>
                <div class="pd_c-itemImage-01_contents">
                    <p class="pd_c-itemImage-01_name">デジタル一眼カメラ/ダブルレンズキット（オレンジ）（DC-GF90W-D）</p>
                </div>
            </div>
        </div>
        <div class="pd_m-accordion-01_itemBody">
            <div class="pd_c-row-01">
                <div class="pd_c-row-01_leftCol">
                    <div class="pd_c-row-01_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1000.png" /></div>
                </div>
                <div class="pd_c-row-01_rightCol">
                    <div class="pd_c-row-01_content-01">
                        <p class="pd_c-row-01_text-01">商品コード：DC-GF90W-D</p>
                        <p class="pd_c-row-01_text-01">在庫：売り出し中</p>
                        <p class="pd_c-row-01_text-01">発売日 2019年1月</p>
                    </div>
                </div>
            </div>
            <div class="pd_b-freeArea">
                <p>フリーエリアです</p>
                <div class="pd_c-table-01">
                    <table class="pd_c-table-01_table">
                        <colgroup>
                            <col class="pd_c-table-01_col-01" />
                            <col class="pd_c-table-01_col-02" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>放送メディア</th>
                                <td>上デジタル放送：3チューナー<br />BSデジタル放送：3チューナー<br />110度CSデジタル放送：3チューナー<br />BS4K/110度CS4K放送：2チューナー<br />CATVパススルー対応：○<br />CATV受信：○</td>
                            </tr>
                            <tr>
                                <th>画面面寸法</th>
                                <td>幅：1650mm・高さ：928mm・対角：1893mm</td>
                            </tr>
                            <tr>
                                <th>外形寸法</th>
                                <td>幅：1680mm・高さ：1033mm・奥行：350mm(テレビスタンド含む)</td>
                            </tr>
                            <tr>
                                <th>質量</th>
                                <td>約60kg(テレビスタンド含む)</td>
                            </tr>
                            <tr>
                                <th>付属品</th>
                                <td>リモコン(1), 単3形マンガン乾電池(2), 取扱説明書(1), 組み立て部品(一式), 転倒・落下防止部品（一式）, 壁掛け用ねじ 黒 M8×20(4), ●本機は従来のB-CASカードに代わり「ACASチップ」を内蔵しています。</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="pd_m-linkList-01">
                <ul class="pd_m-linkList-01_list">
                    <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--features pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">商品の特長</span></a></li>
                    <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--spec pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">詳細スペック</span></a></li>
                    <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--compare pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">商品を比較する</span></a></li>
                    <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--extra pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">消耗品・別売品</span></a></li>
                </ul>
            </div>
            <div class="pd_b-freeArea">
                <p>フリーエリアです</p>
                <div class="pd_c-group-01">
                    <h1 class="pd_c-group-01_heading">1/1フォーマット</h1>
                    <p class="pd_c-group-01_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト本文テキスト本文テキスト本文テキスト本文テキスト</p>
                    <figure class="pd_c-figure-01 pd_c-figure-01--expand"><img class="pd_c-figure-01_img" src="http://placehold.jp/3d4070/ffffff/1500x500.png" alt="" /></figure>
                </div>
            </div>
        </div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-accordion-01 {
  margin-top -1px
  &_itemHead {
    position relative
    border-width 1px 0
    border-style solid
    border-color rgba(0,0,0,.2)
    @media screen and (min-width:MIN_POINT) {
      padding 4px 44px 4px 4px
    }
    @media screen and (max-width:MAX_POINT) {
      padding 22px 44px 22px 4px
    }
  }
  &_itemBody {
    padding-bottom 20px
    display none
  }
  &_icon-01 {
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 50%;
    right: 12px;
    margin-top: -9px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 1px;
      background-color: #364563;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      transform-origin: center;
      transition: transform .025s ease-in;
    }
  }
  &_item.pd_js-closed .pd_m-accordion-01_icon-01 {
    &::after {
      transform-origin: center;
      transform: translate(-50%) rotate(90deg);
    }
  }
  .pd_c-itemImage-01 {
    &_name {
      font-size 17px
      color rgba(0,0,0,.9)
      font-weight bold
    }
  }
}

.pd_js-m-accordion-01_itemHead {
  cursor pointer
}
