/*
---
name: pd_c-textRed
category: component
tag:
  - tag
---
```html
<span class="pd_c-textRed">NEW</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-textRed-01 {
    @media screen and (min-width:MIN_POINT) {
        margin-top 10px
    }
    @media screen and (max-width:MAX_POINT) {
        margin-top 10px
    }
}

.pd_c-textRed-01_span-01 {
    display inline-block
    line-height 1
    font-size 16px
    color #CC0E00
    margin-right 5px
}