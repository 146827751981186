/*
---
name: pd_e-smallTag-01
category: element
tag:
  - tag
---
```html
<span class="pd_e-smallTag-01">予約不要</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-smallTag-01 {
  display inline-block
  background-color #E6E6E6
  border-radius 4px
  padding 3px 8px
  line-height 1
  font-size 13px
  color rgba(0,0,0,.9)
  margin-top 4px
  margin-right 8px
  &+.pd_m-smallBoxItem_ttl {
    margin-top 6px
  }
}
