/*
---
name: pd_b-emphasis-01
category: block
tag:
  - text
  - img
  - icon
---
```html
<div class="pd_b-emphasis-01">
    <div class="pd_b-section-01">
        <div class="pd_b-section-01_inner">
            <div class="pd_b-section-01_head">
                <h1 class="pd_c-headingLv2-01 pd_c-headingLv2-01--center">イベント・体験会</h1>
                <p class="pd_b-section-01_subText-01 pd_b-section-01_subText-01--center">予約状況を見るテキストが入りますテキストが入りますテキストが入ります</p>
            </div>
            <div class="pd_b-section-01_body">
                <div class="pd_b-section-01_row-01">
                    <div class="pd_b-section-01_col-01"><a class="pd_c-largeBtn-01 pd_e-hover-01 pd_c-largeBtn-01--calendar pd_e-hover-01--bgGray" href="/"><span class="pd_c-largeBtn-01_inner"><span class="pd_c-largeBtn-01_period">10月5日 17:00-19:00</span><span class="pd_c-largeBtn-01_eventTitle">予約イベントタイトル<span class="pd_e-numberIcon-01">3</span></span></span></a></div>
                    <div class="pd_b-section-01_col-02"><a class="pd_c-largeBtn-01 pd_c-largeBtn-01--calendar pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_c-largeBtn-01_inner"><span class="pd_c-largeBtn-01_period">10月5日 17:00-19:00</span><span class="pd_c-largeBtn-01_eventTitle">予約イベントタイトル</span></span></a></div>
                </div>
                <div class="pd_b-section-01_link"><a class="pd_c-linkText-01 pd_e-hover-01" href="/">予約中の予定一覧<span class="pd_e-numberIcon-01">3</span></a></div>
            </div>
        </div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
INDENT_SIZE = 1.2em;
.pd_b-emphasis-01 {
  background: rgba( 0, 0, 0, .05 );
  margin-top 60px
  padding 60px 0
  > .pd_b-section-01 {
    margin-top 0
  }
  li > ul {
    margin-left INDENT_SIZE
  }
  &_inner {
    @media screen and (min-width:MIN_POINT) {
      max-width 980px
      margin-right auto
      margin-left auto
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right 15px
      margin-left 15px
    }
  }

  &_heading,
  &_inner li,
  &_inner p {
    color rgba(0,0,0,.9)
    font-size 15px
    line-height (23/15)
  }

  &_heading {
    margin-top 40px
    font-weight bold
    &:first-child {
      margin-top 0
    }
  }

  &_text-01 {
    margin-top 16px
  }
  &_notes {
    @media screen and (min-width:MIN_POINT) {
      margin-top 16px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_normalList {
    margin-top 16px
  }
  &_listItem {
    &--normalList,
    &--notes {
      >span {
        display inline-block
        width INDENT_SIZE
        margin-left -1em * INDENT_SIZE
      }
      padding-left INDENT_SIZE
    }
  }
  .pd_m-pageHistory:first-child {
    margin-top 0
  }
}
