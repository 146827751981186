/*
---
name: pd_c-discontinued-01
category: component
tag:
  - text
---
```html
  <p class="pd_c-discontinued-01">こちらの商品は販売を終了しました。</p>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-discontinued-01 {
  font-size 15px
  list-style (15/22)
  color rgba(0,0,0,.6)
  border solid 1px rgba(0,0,0,.6)
  text-align center
  @media screen and (min-width:MIN_POINT) {
    padding 12px 15px 11px
  }
  @media screen and (max-width:MAX_POINT) {
    padding 12px 15px
  }
}
