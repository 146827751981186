/*
---
name: pd_c-checkBoxList
category: component
tag:
  - checkBox
---
```html
<label class="pd_c-checkTextArea-01">
    <input class="pd_c-checkTextArea-01_input-01 pd_js-c-checkTextArea-01_checkTextArea" type="checkbox" name="" /><span class="pd_c-checkTextArea-01_indicator-01"></span><span class="pd_c-checkTextArea-01_text-01">その他</span>
</label>
<textarea class="pd_c-checkTextArea-01_textArea" type="textarea"></textarea>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-checkTextArea-01 {
  position: relative;
  &_input-01 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }
  &.pd_js-isActive {
    .pd_c-checkTextArea-01_indicator-01 {
      background-image: url("/store/com/images/component/checkBox-01_checked.svg");
    }
  }
  &_indicator-01 {
    background-image: url("/store/com/images/component/checkBox-01.svg");
    background-repeat: no-repeat;
    display: inline-block;
    width:24px;
    height: 24px;
    margin-right: 10px;
  }
  &_indicator-01,
  &_text-01 {
    display inline-block
    vertical-align top
  }
  &+.pd_b-tabDetail_list {
    margin-top 34px
  }
  &_textArea{
    box-sizing: border-box
    border: 1px solid  #707070
    border-radius: 8px
    display: block
    margin-top: 16px
    height: 52px
    padding: 15px
    pointer-events: none
    vertical-align:top
    width 100%
  }
}
.pd_c-checkTextArea-01.pd_js-isActive + .pd_c-checkTextArea-01_textArea
  pointer-events: auto