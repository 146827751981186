/*
---
name: pd_m-form-01
category: module
tag:
  - text
  - icon
  - btn
  - checkBox
---
```html
<div class="pd_m-form-01">
                        <form action="">
                            <div class="pd_m-form-01_right">
                                <button class="pd_c-favoriteBtn-01 pd_e-hover-01 pd_e-hover-01--bgGray pd_js-favoriteBtn" type="button"><span class="pd_c-favoriteBtn-01_text-01">お気に入りに追加</span><span class="pd_c-favoriteBtn-01_text-02">98</span></button>
                            </div>
                            <p class="pd_c-released-01">発売日：xxxx年xx月 / 発送予定日：2021年04月01日～10日</p>
                            <p class="pd_c-stocked-01"><span class="pd_c-stocked-01_span-01">在庫状況：</span><span class="pd_c-stocked-01_span-02">販売中</span><span class="pd_c-stocked-01_span-03">※キャンセル不可</span></p>
                            <p class="pd_c-Buy-in-bulk-01"><span class="pd_c-Buy-in-bulk-01_span-01">【まとめ買い】2個で10%割引</span><span class="pd_c-Buy-in-bulk-01_span-01">【まとめ買い】3個で15%割引</span><span class="pd_c-Buy-in-bulk-01_span-01">【まとめ買い】4個で20%割引</span></p>
                            <div class="pd_c-price-01"><span class="pd_c-price-01_text-01">39,280</span><span class="pd_c-price-01_text-02">円（税込）</span></div>
                            <div class="pd_c-price-01 pd_c-price-01--discount">
                                <div class="pd_c-price">
                                    <p class="pd_c-price-01_text-02">値引後価格</p><span class="pd_c-price-01_text-01">49,280</span><span class="pd_c-price-01_text-02">円（税込）</span>
                                </div>
                                <div class="pd_c-price-01 pd_c-price-01--before">
                                    <div class="pd_c-price"><span class="pd_c-price-01_text-01">49,280</span><span class="pd_c-price-01_text-02">円（税込）</span></div>
                                </div>
                            </div>
                            <div class="pd_c-coin-01">
                                <div class="pd_c-coin-01_flex"><span class="pd_c-coin-01_text-01">還元コイン</span><span class="pd_c-coin-01_text-02">555</span></div>
                            </div>
                            <p class="pd_c-coin-attention">※クーポンなどを利用した場合、獲得コインは変動いたします。</p>
                            <div class="pd_c-coin-01">
                                <div class="pd_c-coin-01_flex"><span class="pd_c-coin-01_text-01">還元コイン</span><span class="pd_c-coin-01_text-02">555</span></div><span class="pd_c-entryMember-01_span-01">（エントリー会員の場合）</span>
                            </div>
                            <p class="pd_c-coin-attention">※会員登録した場合、コインが獲得できます</p>
                            <div class="pd_c-option-01">
                                <div class="pd_c-option-01_head pd_js-c-option-01_head">オプション選択<span class="pd_c-option-01_icon-01"></span></div>
                                <div class="pd_c-option-01_body">
                                    <div class="pd_c-option-01--disabled">
                                        <div class="pd_c-option-01_label-01"></div>
                                        <label>
                                            <input class="pd_c-option-01_input-01 pd_js-c-option-01_checkbox" type="checkbox" name="recycling" /><span class="pd_c-option-01_indicator-01"></span><span class="pd_c-option-01_text-01">リサイクル引取申し込み(テレビ)</span>
                                        </label>
                                        <select class="pd_c-option-01_type pd_js-c-option-01_type">
                                            <option value="" selected="" disabled="">選択してください</option>
                                            <option value="">1</option>
                                            <option value="">2</option>
                                        </select>
                                    </div>
                                    <div class="pd_c-option-01_label-01">
                                        <label>
                                            <input class="pd_c-option-01_input-01 pd_js-c-option-01_checkbox pd_js-c-option-01_checkbox-toggle" type="checkbox" name="recycling" /><span class="pd_c-option-01_indicator-01"></span><span class="pd_c-option-01_text-01">下取り品引き上げ</span>
                                        </label>
                                        <div class="pd_c-option-01_selectWrapper">
                                            <select class="pd_c-option-01_type pd_js-c-option-01_type">
                                                <option value="option1" selected="" disabled="">選択してください</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="pd_c-inputText-area"><span class="pd_c-inputText-text">引取り時のご要望があれば、入力してください。</span><span class="pd_e-required-02">必須 </span></div>
                                    <input class="pd_c-inputText-01" placeholder="" type="text" name="" />
                                </div>
                            </div>
                            <div class="pd_c-cart-01">
                                <div class="pd_c-cart-01_inner">
                                    <div class="pd_c-cart-01_col-01">
                                        <div class="pd_c-cart-01_orders">数量：</div>
                                        <div class="pd_c-cart-01_selectWrapper">
                                            <select class="pd_c-cart-01_select">
                                                <option value="">999</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="pd_c-cart-01_col-02 pd_c-js_scrollTrigger">
                                        <button class="pd_c-cart-01_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)">
                                            <p class="pd_c-cart-01_btnText-01"><span>カートへ入れる</span></p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="pd_c-quote-btn">
                                <button class="pd_c-quote-btn_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)"><span class="pd_c-quote-btn_Text-01">お見積もり手続きへ</span></button>
                            </div>
                            <div class="pd_c-infoEmail-btn">
                                <button class="pd_c-infoEmail-btn_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)">
                                    <p class="pd_c-infoEmail-btn_Text-01"><span>入荷案内メールの申し込み</span></p>
                                </button>
                            </div>
                            <div class="pd_c-no-stock-btn">
                                <button class="pd_c-no-stock-btn_btn" type="button"><span class="pd_c-no-stock-btn_Text-01">在庫がありません</span></button>
                            </div>
                        </form>
                    </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-form-01 {
  &_right {
    text-align right
  }
  .pd_c-released-01 {
    margin-top 24px
  }
  .pd_c-stocked-01 {
    margin-top 16px
  }
  .pd_c-coin-01 {
    margin-top 13px
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {

    }
  }
  .pd_c-option-01 {
    @media screen and (min-width:MIN_POINT) {
      margin-top 32px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 32px
    }
  }
  .pd_c-cart-01 {
    margin-top 32px
  }
  .pd_c-discontinued-01 {
    margin-top 24px
  }
}
