/*
---
name: pd_c-option-01
category: component
tag:
  - checkBox
---
```html
<div class="pd_c-option-01">
    <div class="pd_c-option-01_head pd_js-c-option-01_head">オプション選択<span class="pd_c-option-01_icon-01"></span></div>
    <div class="pd_c-option-01_body">
        <div class="pd_c-option-01--disabled">
            <div class="pd_c-option-01_label-01"></div>
            <label>
                <input class="pd_c-option-01_input-01 pd_js-c-option-01_checkbox" type="checkbox" name="recycling" /><span class="pd_c-option-01_indicator-01"></span><span class="pd_c-option-01_text-01">リサイクル引取申し込み(テレビ)</span>
            </label>
            <select class="pd_c-option-01_type pd_js-c-option-01_type">
                <option value="" selected="" disabled="">選択してください</option>
                <option value="">1</option>
                <option value="">2</option>
            </select>
        </div>
        <div class="pd_c-option-01_label-01">
            <label>
                <input class="pd_c-option-01_input-01 pd_js-c-option-01_checkbox pd_js-c-option-01_checkbox-toggle" type="checkbox" name="recycling" /><span class="pd_c-option-01_indicator-01"></span><span class="pd_c-option-01_text-01">下取り品引き上げ</span>
            </label>
            <div class="pd_c-option-01_selectWrapper">
                <select class="pd_c-option-01_type pd_js-c-option-01_type">
                    <option value="option1" selected="" disabled="">選択してください</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                </select>
            </div>
        </div>
        <div class="pd_c-inputText-area"><span class="pd_c-inputText-text">引取り時のご要望があれば、入力してください。</span><span class="pd_e-required-02">必須 </span></div>
        <input class="pd_c-inputText-01" placeholder="" type="text" name="" />
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-option-01 {
  background-color #F2F2F2
  border-radius 8px
  font-size 15px
  line-height (23/15)
  color rgba(0,0,0,.9)
  &--disabled {
    position relative
    &::before {
      content ''
      position absolute
      top 0
      bottom 0
      left 0
      width 100%
      z-index: 2
    }
  }

  &_head {
    cursor: pointer;
    position: relative;
    padding: 13px 36px 12px 20px;
  }

  &_icon-01 {
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 50%;
    right: 12px;
    margin-top: -9px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 1px;
      background-color: #364563;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      transform-origin: center;
      transition: transform .025s ease-in;
    }
  }

  &.pd_js-closed .pd_c-option-01_icon-01 {
    &::after {
      transform-origin: center;
      transform: translate(-50%) rotate(90deg);
    }
  }

  &_body {
    border-top: solid 1px #CCC;
    padding: 24px 20px 21px;
  }

  &_label-01 {
    position: relative;
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
  }

  &_input-01 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    &:checked+span{
      background-image: url(../../com/images/component/checkBox-01_checked.svg);
    }
  }

  &_indicator-01 {
    background-image: url("/store/images/component/option-01_icon_checkbox-01.svg");
    background-repeat: no-repeat;
    display: inline-block;
    width:24px;
    height: 24px;
    margin-right: 10px;
  }

  &_indicator-01,
  &_text-01 {
    display inline-block
    vertical-align top
  }

  &_text-01 {
    margin-top .025em
  }
  &_selectWrapper {
    position relative
    &::before {
      background-image url('/store/images/module/searchForm_arrow.svg')
      background-repeat no-repeat
      background-size contain
      content ""
      display block
      height 10px
      position absolute
      right 12px
      top 50%
      transform translateY(-50%)
      width 18px
      z-index 1
    }
  }
  &_type {
    margin-top: 8px
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    align-items: center
    border: 1px solid #707070
    border-radius: 8px
    display: flex
    font-size: 15px
    height: 52px
    letter-spacing: 1px
    padding: 0 0 0 15px
    width: 100%
    &::-ms-expand {
      display: none
    }
  }

  &_checkbox-02 {
    display: block;
    position relative;
  }

}

.pd_c-option-01--disabled {
  color rgba(0,0,0,.3)
  .pd_c-option-01_input-01,
  .pd_c-option-01_type {
    color rgba(0,0,0,.3)
    border-color rgba(0,0,0,.2)
  }
  .pd_c-option-01_indicator-01 {
    background-image: url("/store/images/component/inputForm-01_icon_checkbox_disabled.svg");
  }
}

.pd_c-option-01_selectWrapper {
  position relative
  &.pd_js-isDisable {
    &::after {
      content ''
      position absolute
      top 0
      bottom 0
      left 0
      right 0
    }
    .pd_c-option-01_type {
      color rgba(0,0,0,.3)
      border-color rgba(0,0,0,.2)
    }
  }
}

.pd_c-inputText-area {
  margin 20px 0 10px 
}

.pd_c-inputText-text {
  font-size 15px
  line-height 1

}

.pd_c-inputText-01 {
  border-color rgba(0,0,0,0.2)
  &.color {
    border-color #000
  }
}

