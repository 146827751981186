/*
---
name: pd_m-tabList-02
category: module
tag:
  - text
  - tab
---
```html
<ul class="pd_m-tabList pd_m-tabList-02">
  <li class="pd_m-tabList_item pd_m-tabList_item01 pd_active pd_e-hover-01" data-tab="pd_b-searchResultTab-all">すべて</li>
  <li class="pd_m-tabList_item pd_m-tabList_item02 pd_e-hover-01" data-tab="pd_b-searchResultTab-product">商品情報</li>
  <li class="pd_m-tabList_item pd_m-tabList_item03 pd_e-hover-01" data-tab="pd_b-searchResultTab-article">記事</li>
  <li class="pd_m-tabList_item pd_m-tabList_item04 pd_e-hover-01" data-tab="pd_b-searchResultTab-event">イベント・キャンペーン</li>
  <li class="pd_m-tabList_item pd_m-tabList_item05 pd_e-hover-01" data-tab="pd_b-searchResultTab-support">サポート情報</li>
</ul>
```
*/
.pd_m-tabList
    display: flex
    font-size: 15px
    justify-content: space-between
    margin:  0 auto -1px
    width 976px
    &_item
        border-bottom: 4px solid transparent
        color: rgba(0,0,0,0.3)
        cursor pointer
        display: block
        line-height: 42px
        text-align: center
        width: 195px
        br
            display: none
        &.pd_active
            border-bottom: 4px solid #364563
            color: rgba(0,0,0,0.9)
            a
                color: rgba(0,0,0,0.9)
.pd_tabList-arrow
    display: none
@media screen and (max-width: 640px)
    .pd_b-tabArea
        border-bottom: 0px
    .pd_m-tabListWrap-02
        display: block
        font-size: 13px
        position: relative
    .pd_m-tabList
        border-bottom: 2px solid #364563;
        width: fit-content
        &_item
            align-items: center
            display: flex
            justify-content: center
            line-height: 44px
            padding: 0
            width: fit-content
            &01
                width: 80px!important
            &02
                width: 93px!important
            &03
                width: 66px!important
            &04
                width: 182px!important
            &05
                width: 118px!important
            br
                display: none
    .pd_m-tabListInner-02
        margin: 0 auto
        overflow-x scroll
        overflow-y hidden
        width: 78.66vw
    .pd_tabList-arrow
        background-image: url('/store/images/module/tabLsit-arrow.svg')
        background-size: contain
        background-repeat: no-repeat
        background-color: transparent;
        border: 0px;
        content: ""
        display: block
        height: 18px
        position: absolute
        top: 50%
        transform: translateY(-50%)
        width: 9.5px
        z-index 1
    .pd_tabList-arrow-prev
        left: 20px
        &.pd_active
            pointer-events: none
            opacity 0.2
    .pd_tabList-arrow-next
        right: 20px
        transform: translateY(-50%) rotate(180deg)
        &.pd_active
            pointer-events: none
            opacity 0.2