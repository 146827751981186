/*
---
name: pd_m-radioBtnList-01
category: module
tag:
  - checkBox
---
```html
<ul class="pd_m-radioBtnList-01">
  <li class="pd_m-radioBtnList-01_listItem">
    <label class="pd_c-radioBtn-01__event">
      <input type="radio" name="date" value="指定する"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">指定する</span>
    </label>
  </li>
  <li class="pd_m-radioBtnList-01_listItem">
    <label class="pd_c-radioBtn-01__event">
      <input type="radio" name="date" value="指定しない"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">指定しない</span>
    </label>
  </li>
</ul>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-radioBtnList-01 {
  display flex
  justify-content flex-start
  &_listItem {
    @media screen and (min-width:MIN_POINT) {
      width 33.3333%
    }
    @media screen and (max-width:MAX_POINT) {
      flex 1
    }
  }
}
