/*
---
name: pd_m-eventBnr
category: module
tag:
  - text
---
```html
<div class="pd_m-eventBnr">
  <p class="pd_m-eventBnr_ttl">スマートコーヒー焙煎サービス「The Roast」体験会</p>
  <div class="pd_m-eventBnr_btm">
    <p class="pd_m-eventBnr_date">4月28日14:30～15:00</p>
    <div class="pd_m-eventBnr_price"><span class="pd_m-eventBnr_priceAbout">参加費</span><span
        class="pd_m-eventBnr_priceNum">無料</span></div>
  </div>
</div>
```
*/
.pd_m-eventBnr
    background-color: #F2F2F2
    border-radius: 8px
    margin: 20px 0 0 0
    padding: 30px
    &_ttl
        font-size: 22px
        font-weight: 500
    &_btm
        display: flex
        justify-content: space-between
        align-items: flex-end
    &_date
        font-size: 17px
        font-weight: 500
        display: inline-block
    &_price
        display: inline-block
        text-align: right
    &_priceAbout
        font-size: 15px
        font-weight: 500
    &_priceTotal
        font-size: 13px
        padding: 0px 3px 0 15px;
    &_priceNum
        font-size: 15px
        margin-left 10px
        font-weight: 500
    &_priceYen
        font-size: 13px
        padding: 0px 3px 0 7px;
    &_priceTax 
        color: rgba(0,0,0,0.6)
        font-size: 13px
@media screen and (max-width 640px)
    .pd_m-eventBnr
        margin 0 auto
        padding: 20px 20px 17px 20px
        &_ttl
            line-height: 1.5
        &_btm
            flex-wrap wrap
        &_date
            margin-top: 10px
        &_price
            display: block;
            margin: 5px 10px 0 auto;