/*
---
name: pd_m-eventsOverview
category: module
tag: 
  - text
  - img
---
```html
<div class="pd_m-eventsOverview">
                                            <p class="pd_c-eventsSubTitle">イベント概要</p>
                      <div class="pd_m-eventsOverview_main">
                        <div class="pd_m-eventsOverview_mainText">
                          <div class="pd_m-eventsOverview_textWrap">
                            <p class="pd_m-eventsOverview_ttl">開催日時</p>
                            <p class="pd_m-eventsOverview_text">3月・4月<br>水曜日:14:30～17:30(最終受付17:00)<br>土/日曜日:11:30～17:30(最終受付17:00)</p>
                          </div>
                          <div class="pd_m-eventsOverview_textWrap">
                            <p class="pd_m-eventsOverview_ttl">開催方法</p>
                            <p class="pd_m-eventsOverview_text">店舗実演　約30分 1,000円</p>
                          </div>
                          <div class="pd_m-eventsOverview_textWrap">
                            <p class="pd_m-eventsOverview_ttl">内容</p>
                            <div class="pd_m-eventsOverview_text">焙煎プロファイル作成方法、焙煎実演のご紹介。<br>「The Roast」のプロファイルづくりを手がける 焙煎士・後藤直紀氏を講師にお迎えし、 自宅でおいしいコーヒーを楽しむためのコツを伝授します。</div>
                          </div>
                          <div class="pd_m-eventsOverview_textWrap">
                            <p class="pd_m-eventsOverview_ttl">定員</p>
                            <div class="pd_m-eventsOverview_text">〇〇名</div>
                          </div>
                          <div class="pd_m-eventsOverview_textWrap">
                            <p class="pd_m-eventsOverview_ttl">講師</p>
                            <p class="pd_m-eventsOverview_text">後藤直紀　焙煎士</p>
                          </div>
                          <div class="pd_m-eventsOverview_textWrap">
                            <p class="pd_m-eventsOverview_ttl">講師プロフィール</p>
                            <p class="pd_m-eventsOverview_text">「豆香洞コーヒー」のオーナーであり、日本人としては唯一の「World Coffee Roasting Championship」に優勝者。</p>
                          </div>
                          <div class="pd_m-eventsOverview_textWrap">
                            <p class="pd_m-eventsOverview_ttl">開催場所</p>
                            <ul class="pd_m-eventsOverview_accessList">
                              <li class="pd_m-eventsOverview_accessListItem"> 
                                <p class="pd_m-eventsOverview_accessListItemTtl">店舗名：</p><a href="">リライフスタジオ フタコ</a>
                              </li>
                              <li class="pd_m-eventsOverview_accessListItem">
                                <p class="pd_m-eventsOverview_accessListItemTtl">住所：</p><span class="pd_m-eventsOverview_accessListSp-01">東京都世田谷区玉川1丁目14番1号<br> 二子玉川ライズ S.C.テラスマーケット 二子玉川 蔦屋家電 2階</span>
                              </li>
                              <li class="pd_m-eventsOverview_accessListItem">
                                <p class="pd_m-eventsOverview_accessListItemTtl">電話番号：</p><span class="pd_m-eventsOverview_accessListSpan-02">03-5717-9545</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="pd_m-eventsOverview_imgWrap">
                          <div class="pd_m-eventsOverview_img"><img src="com/images/module/eventsOverview_img01.jpg" alt="イベント概要"></div>
                          <div class="pd_m-eventsOverview_img"><img src="com/images/module/eventsOverview_img02.jpg" alt="イベント概要"></div>
                        </div>
                      </div>
                    </div>
```
*/
.pd_m-eventsOverview
    &_main
        display: flex
        justify-content: space-between
        margin 13px 0 0 0
    &_mainText
        width: 561px
    &_textWrap
        line-height: 1.5;
        margin-top: 34px
        &:first-child
            margin-top: 0px
    &_ttl
        font-size: 22px
        font-weight: 500
    &_text
        margin-top: 14px
    &_accessList
        margin-top: 15px
    &_accessListItem
        display: flex
        list-style-type: none
        line-height: 1.6
        a
            color: #364563
            text-decoration: underline
            letter-spacing: 1px
    &_accessListItemTtl
        white-space nowrap
    &_accessListSp-01
        letter-spacing: 1px
    &_accessListSpan-02
        letter-spacing: 0.6px
    &_imgWrap
        margin 6px 0 0 0
    &_img
        align-items: center
        display: flex
        justify-content: center
        margin-bottom: 20px
        height: 296px
        width: 395px
        img
            max-height: 100%
            max-width: 395px
@media screen and (max-width 640px)
    .pd_m-eventsOverview
        &_main
            display: block
        &_mainText
            width: 100%
        &_textWrap
            margin-top: 37px
            &:nth-child(4)
                margin-top: 43px
        &_ttl
            font-size: 17px
            white-space: nowrap
        &_text
            word-break: break-word
        // &_accessList
        // &_accessListItem
        //     a
        // &_accessListSp-01
        // &_accessListSpan-02
        &_imgWrap
            margin-top: 42px
        &_img
            height: auto
            width: 100%
            img
                max-height: 100%
                max-width: 100%