/*
---
name: pd_c-itemImage-02
category: component
tag:
  - text
  - img
---
```html
<div class="pd_c-itemImage-01">
    <div class="pd_c-itemImage-01_img"> <img src="http://placehold.jp/3d4070/ffffff/62x62.png" alt="" /></div>
    <div class="pd_c-itemImage-01_contents">
        <p class="pd_c-itemImage-01_name">デジタル一眼カメラ/ダブルレンズキット（オレンジ）（DC-GF90W-D）</p>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-itemImage-02 {
  display flex
  align-items center
  &_img,
  &_contents {
  }
  &_img {
    width 70px
    img {
      width 100%
    }
  }
  &_contents {
    flex 1
    padding-left 20px
  }
  &_name {
    color #000
    font-size 14px
  }
  &_price {
  }
  &_text-01 {
    font-size 22px
    color rgba(0,0,0,.9)
  }
  &_text-02 {
    font-size 13px
    color rgba(0,0,0,.9)
  }
  &_text-03 {
    font-size 13px
    color rgba(0,0,0,.6)
  }
}
