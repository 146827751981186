/*
---
name: pd_c-figure-01
category: component
tag:
  - bnr
---
```html
  <figure class="pd_c-figure-01 pd_c-figure-01--expand"><img class="pd_c-figure-01_img" src="http://placehold.jp/3d4070/ffffff/1500x500.png" alt="" /></figure>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_c-figure-01 {
  text-align center
  margin-top 16px
  &--expand {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
    &_img {
      display block;
    }
  }
}
