/*
---
name: pd_m-breakdown-01
category: module
tag:
  - text
  - icon
  - btn
---
```html
<div class="pd_m-breakdown-01">
  <div class="pd_m-breakdown-01_head">
    <h1 class="pd_m-breakdown-01_heading">内訳</h1>
  </div>
  <div class="pd_m-breakdown-01_body-01">
    <p class="pd_m-breakdown-01_text-01">初回請求(税込)</p>
    <div class="pd_c-productPanelItem">
      <p class="pd_c-productPanelItem_price"><span>0</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
    </div>
    <p class="pd_m-breakdown-01_text-01">月額請求(税込)</p>
    <div class="pd_c-productPanelItem">
      <p class="pd_c-productPanelItem_price-bar"><span>348,000</span><span class="pd_c-productPanelItem_priceTax">円 (税込)</span></p>
      <p class="pd_c-productPanelItem_discountPrice">
        特別価格<br><span class="pd_c-productPanelItem_discountPriceNum">42,000</span><span class="pd_c-productPanelItem_discountPriceTax">円 (税込)</span></p>
      <p class="pd_c-productPanelItem_stock">在庫状況：<span>販売中</span></p>
    </div>
  </div>
  <div class="pd_m-breakdown-01_body-02">
    <button class="pd_c-btn-01 pd_e-hover-01" onclick=""><span>契約へ進む</span></button>
    <p class="pd_m-breakdown-01_link"><a class="pd_c-linkText-01 pd_e-hover-01" href="/">マイプランに保存する </a></p>
    <ul class="pd_c-notes-02">
      <li class="pd_c-notes-02_listItem pd_c-js_scrollTrigger"><span>※</span>解約時は、24ヶ月間どのタイミングにおいても解約手数料としてご利用料金の2ヶ月分相当の6,600円(税込)がかかります。</li>
    </ul>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-breakdown-01 {
  background-color #fff
  border-radius 8px
  @media screen and (min-width:MIN_POINT) {
    padding 20px
  }
  @media screen and (max-width:MAX_POINT) {
    padding 40px 20px 20px
  }
  &_heading {
    font-size 22px
    line-height (33/22)
  }
  &_text-01 {
    font-size 13px
    line-height (20/13)
    margin-top 16px
    &:first-child {
      margin-top 0
    }
  }
  &_body-01 {
    margin-top 16px
  }
  &_body-02 {
    border-top solid 1px rgba(0,0,0,.2)
    margin-top 24px
    padding-top 24px
  }
  &_link {
    text-align center
    margin-top 24px
  }
  .pd_c-coin-01 {
    margin-top 24px
  }
  .pd_c-productPanelItem {
    margin-top 8px
  }
}
