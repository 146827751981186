/*
---
name: pd_m-submit-01
category: module
tag:
  - btn
---
```html
<div class="pd_m-submit-01">
  <div class="pd_m-submit-01_row">
    <div class="pd_m-submit-01_col-01">
      <button class="pd_c-filterButton pd_c-filterButton-04 pd_e-hover-01 pd_e-hover-01--bgGray" type="reset">戻る</button>
    </div>
    <div class="pd_m-submit-01_col-02">
      <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
        <p class="pd_c-filterButton_text">キャンセル確定</p>
      </button>
    </div>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-submit-01 {
  border-top solid 1px rgba(0,0,0,.2)
  padding-top 20px
  @media screen and (min-width:MIN_POINT) {
  }
  @media screen and (max-width:MAX_POINT) {
  }
  &_row {
    @media screen and (min-width:MIN_POINT) {
      display flex
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_col-01,
  &_col-02 {
    @media screen and (min-width:MIN_POINT) {
      width 50%;
      padding-right 10px
      padding-left 10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_col-01 {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      order 2
    }
  }
  &_col-02 {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      order 1
    }
  }
  .pd_c-filterButton {
    display flex
    &-03 {
      background-color #364563
      p {
        color #fff
      }
    }
  }
}
