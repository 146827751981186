/*
---
name: pd_m-eventsProduct
category: module
tag:
  - text
  - img
  - icon
---
```html
<div class="pd_m-eventsProduct">
                                            <p class="pd_c-eventsSubTitle">イベントで使用する商品</p>
                      <ul class="pd_m-eventsProduct_list">
                        <li class="pd_m-eventsProduct_listItem"><a class="pd_m-smallBox" href="/">
                                                        <div class="pd_m-smallBoxItem">
                                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                                          <div class="pd_m-smallBoxItem_mid">
                                                            <p class="pd_m-smallBoxItem_kind">コーヒー焙煎機</p>
                                                          </div>
                                                          <p class="pd_m-smallBoxItem_ttl">The Roast</p>
                                                        </div></a></li>
                        <li class="pd_m-eventsProduct_listItem"><a class="pd_m-smallBox" href="/">
                                                        <div class="pd_m-smallBoxItem">
                                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                                          <div class="pd_m-smallBoxItem_mid">
                                                            <p class="pd_m-smallBoxItem_kind">コーヒー焙煎機</p>
                                                          </div>
                                                          <p class="pd_m-smallBoxItem_ttl">The Roast</p>
                                                        </div></a></li>
                        <li class="pd_m-eventsProduct_listItem"><a class="pd_m-smallBox" href="/">
                                                        <div class="pd_m-smallBoxItem">
                                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                                          <div class="pd_m-smallBoxItem_mid">
                                                            <p class="pd_m-smallBoxItem_kind">コーヒー焙煎機</p>
                                                          </div>
                                                          <p class="pd_m-smallBoxItem_ttl">The Roast</p>
                                                        </div></a></li>
                        <li class="pd_m-eventsProduct_listItem"><a class="pd_m-smallBox" href="/">
                                                        <div class="pd_m-smallBoxItem">
                                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                                          <div class="pd_m-smallBoxItem_mid">
                                                            <p class="pd_m-smallBoxItem_kind">コーヒー焙煎機</p>
                                                          </div>
                                                          <p class="pd_m-smallBoxItem_ttl">The Roast</p>
                                                        </div></a></li>
                        <li class="pd_m-eventsProduct_listItem"><a class="pd_m-smallBox" href="/">
                                                        <div class="pd_m-smallBoxItem">
                                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                                          <div class="pd_m-smallBoxItem_mid">
                                                            <p class="pd_m-smallBoxItem_kind">コーヒー焙煎機</p>
                                                          </div>
                                                          <p class="pd_m-smallBoxItem_ttl">The Roast</p>
                                                        </div></a></li>
                        <li class="pd_m-eventsProduct_listItem"><a class="pd_m-smallBox" href="/">
                                                        <div class="pd_m-smallBoxItem">
                                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                                          <div class="pd_m-smallBoxItem_mid">
                                                            <p class="pd_m-smallBoxItem_kind">コーヒー焙煎機</p>
                                                          </div>
                                                          <p class="pd_m-smallBoxItem_ttl">The Roast</p>
                                                        </div></a></li>
                      </ul>
                    </div>
```
*/
.pd_m-eventsProduct
    margin 50px auto
    &_Ttl
        font-size: 30px
        font-weight: 500
        text-align: center
    &_list
        margin-top: 20px
        position: relative
        .pd_m-smallBox
            width: 179px
            padding-bottom: 0
            margin: 0
        .pd_m-smallBoxItem_img
            height: 179px
            width: 179px
            img
                max-width: 179px
        .pd_m-smallBoxItem_ttl
            font-size: 15px
            margin-top: 6px
        .pd_c-tagListItem
            padding 0 9px
        .pd_c-productPanelItem_modelNum
            margin-top: 10px
        .pd_c-productPanelItem_price
            font-size: 17px
            margin-top: 10px
        .pd_c-productPanelItem_stock
            margin-top: 5px
        .pd_c-productPanelItem_price-bar
            font-size: 15px
        .pd_c-productPanelItem_discountPrice
            font-size: 12px
        .pd_c-productPanelItem_discountPriceTax
            font-size: 12px
    &_listItem
        padding: 0
    &_Arrow
        background-repeat: no-repeat
        background-position: center center
        background-color: #fff
        border: 0px
        border-radius: 50%
        box-shadow: 2px 2px 6px rgba(0 0 0 10%);
        height: 40px
        position: absolute
        top:89.5px
        transform: translateY(-50%)
        width: 40px
        z-index 1
        &:hover
            background-color #F2F2F2

    &_prevArrow
        background-image: url('/store/images/module/recently_checked_prev.svg')
        box-shadow: -2px 2px 6px rgba(0 0 0 10%);
        display: none!important
        left: -10px
        &.pd_active
            display: inline-block!important
    &_nextArrow
        background-image: url('/store/images/module/recently_checked_next.svg')
        right: -10px
@media screen and (max-width 640px)
    .pd_m-eventsProduct
        margin 57px auto
        // overflow: hidden
        // padding: 0 0 0 4vw
        width: 100%
        &_Ttl
            font-size: 22px
            padding-right: 18px;
        &_list
            margin-top: 16px
            width: 780px
            // .pd_m-smallBox_Wrap
            .pd_m-smallBox
                width: 140px
            .pd_m-smallBoxItem_img
                height: 140px
                width: 140px
                img
                    max-width: 140px
        &_listItem
            padding: 0
        &_Arrow,.pd_m-eventsProduct_prevArrow.pd_active
            display: none!important
@media screen and (min-width 641px) and (max-width 980px)
    .pd_m-eventsProduct_prevArrow
        left: 15px
@media screen and (min-width 641px) and (max-width 980px)
    .pd_m-eventsProduct_nextArrow
        right: 15px
