/*
---
name: pd_c-filteringItem-01
category: component
tag:
  - btn
---
```html
    <li class="pd_c-filteringItem">
        <p class="pd_c-filteringItem_text">映像</p>
        <button class="pd_c-filteringItem_button pd_e-hover-01 pd_e-hover-01--bgDarkGrayk">×</button>
    </li>
```
*/
.pd_c-filteringItem
    display: flex
    margin: 0 16px 16px 0
    &_text
        font-size: 15px
    &_button
        align-items: center
        background-color: #F2F2F2
        border 0px
        border-radius: 8px
        display: flex
        justify-content: center
        margin-left: 3px
        height: 24px
        width: 24px
