/*
---
name: pd_c-btn-04
category: component
tag:
  - btn
---
```html
  <button class="pd_c-btn-04_btn pd_c-btn-arrow" type="button" onclick="">
    <p class="pd_c-btn-04_btnText-01"><span>お申し込みへすすむ</span></p>
  </button>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-btn-04 {
  color #fff
  text-align center
  padding 0
  background #ccc
  border none
  display block;
  width 100%
  border-radius: 8px
  padding: 14px 14px 12px;
  box-shadow 0 3px 6px rgba(0,0,0,.16)
  font-size 15px
  margin-bottom 24px
  &.pd_c-btn-arrow{
    position: relative
    &::after{
      border-top: 2px solid #fff
      border-left: 2px solid #fff
      content: ""
      display: block
      height: 7px
      position absolute
      top: 50%
      transform: translateY(-50%) rotate(225deg)
      right: 20px
      width: 7px
    }
  }
  @media screen and (min-width:MIN_POINT) {
  }
  @media screen and (max-width:MAX_POINT) {
  }
}