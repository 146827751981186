/*
---
name: pd_c-stocked-01
category: component
tag:
  - text
---
```html
<p class="pd_c-stocked-01"><span class="pd_c-stocked-01_span-01">在庫状況：</span><span class="pd_c-stocked-01_span-02">販売中</span><span class="pd_c-stocked-01_span-03">※キャンセル不可</span></p>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-stocked-01 {
  color rgba(0,0,0,.6)
  font-size 13px
  &_span-01 {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_span-03 {
      font-size 13px
      font-weight:400
      color:rgba(0,0,0,0.9)
      margin-left: 1rem
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
}