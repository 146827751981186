/*
---
name: pd_b-guest_mypage
category: block
tag:
  - text
---
```html
<div class="pd_b-guest_mypage">
  <div class="pd_b-guest_mypage_inner">
    <h1 class="pd_m-contentTtl-01">お客様のページ</h1>
    <p class="pd_b-guest_mypage_attention">イベント申込情報内容の確認と変更ができます。</p>
    <p class="pd_m-dataPanel__subTitle">お申し込み内容</p>
    <ul class="pd_m-dataPanel__inner">
      <li class="pd_m-dataPanel__white_multiple"><a class="pd_m-dataPanel__title_multiple" href="#">スマートコーヒー焙煎サービス「The Roast」体験会</a>
        <p class="pd_m-dataPanel__date_multiple">開催日時: XXXX/XX/XX  XX:XX〜XX:XX</p><a class="pd_m-dataPanel__cancelLink">申し込みキャンセル</a>
      </li>
      <li class="pd_m-dataPanel__white_multiple"><a class="pd_m-dataPanel__title_multiple" href="#">スマートコーヒー焙煎サービス「The Roast」体験会</a>
        <p class="pd_m-dataPanel__date_multiple">開催日時: XXXX/XX/XX  XX:XX〜XX:XX</p><a class="pd_m-dataPanel__cancelLink">申し込みキャンセル</a>
      </li>
      <li class="pd_m-dataPanel__white_multiple"><a class="pd_m-dataPanel__title_multiple" href="#">スマートコーヒー焙煎サービス「The Roast」体験会</a>
        <p class="pd_m-dataPanel__date_multiple">開催日時: XXXX/XX/XX  XX:XX〜XX:XX</p><a class="pd_m-dataPanel__cancelLink">申し込みキャンセル</a>
      </li>
      <li class="pd_m-dataPanel__white_multiple"><a class="pd_m-dataPanel__title_multiple" href="#">スマートコーヒー焙煎サービス「The Roast」体験会</a>
        <p class="pd_m-dataPanel__date_multiple">開催日時: XXXX/XX/XX  XX:XX〜XX:XX</p><a class="pd_m-dataPanel__cancelLink">申し込みキャンセル</a>
      </li>
      <li class="pd_m-dataPanel__white_multiple"><a class="pd_m-dataPanel__title_multiple" href="#">スマートコーヒー焙煎サービス「The Roast」体験会</a>
        <p class="pd_m-dataPanel__date_multiple">開催日時: XXXX/XX/XX  XX:XX〜XX:XX</p><a class="pd_m-dataPanel__cancelLink">申し込みキャンセル</a>
      </li>
      <li class="pd_m-dataPanel__white_multiple"><a class="pd_m-dataPanel__title_multiple" href="#">スマートコーヒー焙煎サービス「The Roast」体験会</a>
        <p class="pd_m-dataPanel__date_multiple">開催日時: XXXX/XX/XX  XX:XX〜XX:XX</p><a class="pd_m-dataPanel__cancelLink">申し込みキャンセル</a>
      </li>
    </ul>
    <p class="pd_m-dataPanel__subTitle">過去に参加したイベント</p>
    <ul class="pd_m-dataPanel__inner">
      <li class="pd_m-dataPanel__white_multiple"><a class="pd_m-dataPanel__title_multiple" href="#">スマートコーヒー焙煎サービス「The Roast」体験会</a>
        <p class="pd_m-dataPanel__date_multiple">開催日時: XXXX/XX/XX  XX:XX〜XX:XX</p>
        <button class="pd_m-dataPanel__surveyButton">アンケート回答</button>
      </li>
      <li class="pd_m-dataPanel__white_multiple"><a class="pd_m-dataPanel__title_multiple" href="#">スマートコーヒー焙煎サービス「The Roast」体験会</a>
        <p class="pd_m-dataPanel__date_multiple">開催日時: XXXX/XX/XX  XX:XX〜XX:XX</p>
        <button class="pd_m-dataPanel__surveyButton">アンケート回答</button>
      </li>
      <li class="pd_m-dataPanel__white_multiple"><a class="pd_m-dataPanel__title_multiple" href="#">スマートコーヒー焙煎サービス「The Roast」体験会</a>
        <p class="pd_m-dataPanel__date_multiple">開催日時: XXXX/XX/XX  XX:XX〜XX:XX</p>
        <button class="pd_m-dataPanel__surveyButton">アンケート回答</button>
      </li>
    </ul>
  </div>
</div>
```
*/
.pd_b-guest_mypage
  margin: 0 auto 86px
  &_inner
      margin: 0 auto
      width: 976px
    .pd_m-contentTtl-01
        font-size 40px
        font-weight bold
        margin 50px 0 0
    &_attention
        margin: 20px 0 0 0;
        letter-spacing: 1px;
        padding: 0 0 44px

.pd_b-guest_mypage_bg
  background-color #F2F2F2
  &_inner
    padding 50px 0 60px
    .pd_c-filterButton 
      margin: 30px auto 0;
      display: block;
      width: 345px;

@media screen and (max-width: 640px)
  .pd_b-guest_mypage
    &_inner
      margin: 0 auto
      width: 100vw
    .pd_m-contentTtl-01
      font-size: 25px;
      margin: 55px auto 18px;
      padding: 0 15px;
      width: 100%;
    &_attention
      margin: 20px 0 0 0;
      letter-spacing: 1px;
      padding: 0 15px 44px
    .pd_m-dataPanel__subTitle
      font-size: 6.5vw;