/*
---
name: detail-01-type-notfound
category: block
tag:
  - btn
---
```html
<a class="pd_c-btn-02 pd_e-hover-01--bgGray pd_c-btn-02-totop" href="/">トップページへ戻る  </a>
```
*/
// notfound
@media screen and  (min-width 641px)
    .pd_c-btn-02.pd_e-hover-01--bgGray.pd_c-btn-02-totop 
        width: 345px
        margin 0 auto 180px
@media screen and (max-width 640px)
   .pd_c-btn-02.pd_e-hover-01--bgGray.pd_c-btn-02-totop 
        margin: 0 15px 90px;