/*
---
name: pd_c-hr-01
category: component
tag:
  - text
---
```html
  <div class="pd_c-hr-01"></div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-hr-01 {
  height 1px
  background-color #707070
  margin-top 60px
}
