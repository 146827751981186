/*
---
name: pd_m-attention
category: module
tag:
  - text
---
```html
<div class="pd_m-attention">
  <div class="pd_m-attention_inner">
    <p class="pd_m-attention_title">＜ご注意ください＞パナソニックを名乗る企業等について</p>
    <ul class="pd_m-attention_list">
      <li class="pd_m-attention_item"><a class="pd_m-attention_anchor pd_e-hover-01" href="/">不審なショッピングサイトにご注意ください</a></li>
      <li class="pd_m-attention_item"><a class="pd_m-attention_anchor pd_e-hover-01" href="/">パナソニック製品の販売と称しているインターネット通信販売サイトについて</a></li>
      <li class="pd_m-attention_item"><a class="pd_m-attention_anchor pd_e-hover-01" href="/">シアターバー「SC-HTB01」およびプライベート・ビエラ「UN-19Z1」ご愛用のお客様へお詫びとお知らせについて</a></li>
    </ul>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_m-attention {
  padding: 19px 0 13px;
  @media screen and (min-width:MIN_POINT) {
    margin-bottom: 22px;
    background-color: rgba(204, 14, 0, .1);
  }
  @media screen and (max-width:MAX_POINT) {
    background-color: rgba(204, 14, 0, .05);
  }
  &_inner {
    max-width: 980px;
    @media screen and (min-width:MIN_POINT) {
      margin-right: auto;
      margin-left: auto;
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right: 15px;
      margin-left: 15px;
    }
  }
  &_title {
    color: #CC0E00;
    font-size: 15px;
    font-weight: bold;
  }
  &_list {
    @media screen and (min-width:MIN_POINT) {
      margin-top: 4px;
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top: 8px;
    }
  }
  &_item {
    font-size: 15px;
    line-height: ( 24 / 15 );
    &+& {
      margin-top: 6px;
    }
  }
  &_anchor {
    text-decoration: underline;
    &:link,
    &:hover,
    &:visited,
    &:visited:hover,
    &:active {
      color: #364563;
    }
  }
}
