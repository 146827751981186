/*
---
name: pd_b-detail-02
category: block
tag:
  - icon
  - text
  - img
  - btn
  - movie
  - table
---
```html
<div class="pd_b-detail-02">
    <div class="pd_b-detail-02_inner">
        <h1 class="pd_b-detail-02_heading">商品詳細</h1>
        <div class="pd_b-freeArea">
            <p class="pd_b-detail-02_sub">商品改良のため、仕様・外観は予告なしに変更することがありますのでご了承ください。</p>
            <p class="pd_b-detail-02_text-01">商品詳細が入ります。商品詳細が入ります。商品詳細が入ります。商品詳細が入ります。商品詳細が入ります。商品詳細が入ります。商品詳細が入ります。商品詳細が入ります。商品詳細が入ります。商品詳細が入ります。商品詳細が入ります。
            <div class="pd_c-table-01">
                <table class="pd_c-table-01_table">
                    <colgroup>
                        <col class="pd_c-table-01_col-01" />
                        <col class="pd_c-table-01_col-02" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>放送メディア</th>
                            <td>上デジタル放送：3チューナー<br />BSデジタル放送：3チューナー<br />110度CSデジタル放送：3チューナー<br />BS4K/110度CS4K放送：2チューナー<br />CATVパススルー対応：○<br />CATV受信：○</td>
                        </tr>
                        <tr>
                            <th>画面面寸法</th>
                            <td>幅：1650mm・高さ：928mm・対角：1893mm</td>
                        </tr>
                        <tr>
                            <th>外形寸法</th>
                            <td>幅：1680mm・高さ：1033mm・奥行：350mm(テレビスタンド含む)</td>
                        </tr>
                        <tr>
                            <th>質量</th>
                            <td>約60kg(テレビスタンド含む)</td>
                        </tr>
                        <tr>
                            <th>付属品</th>
                            <td>リモコン(1), 単3形マンガン乾電池(2), 取扱説明書(1), 組み立て部品(一式), 転倒・落下防止部品（一式）, 壁掛け用ねじ 黒 M8×20(4), ●本機は従来のB-CASカードに代わり「ACASチップ」を内蔵しています。</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </p>
        </div>
        <div class="pd_m-linkList-01">
            <ul class="pd_m-linkList-01_list">
                <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--features pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">商品の特長</span></a></li>
                <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--spec pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">詳細スペック</span></a></li>
                <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--compare pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">商品を比較する</span></a></li>
                <li class="pd_m-linkList-01_listItem"><a class="pd_m-linkList-01_anchor pd_m-linkList-01_anchor--extra pd_e-hover-01 pd_e-hover-01--bgGray" href="/"><span class="pd_m-linkList-01_linkText">消耗品・別売品</span></a></li>
            </ul>
        </div>
        <div class="pd_b-freeArea">
            <h1 class="pd_b-detail-02_heading">商品の特長</h1>
            <div class="pd_c-row-01">
                <div class="pd_c-row-01_leftCol">
                    <div class="pd_c-row-01_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1000.png" /></div>
                </div>
                <div class="pd_c-row-01_rightCol">
                    <div class="pd_c-row-01_content-01">
                        <h1 class="pd_c-row-01_heading">1/2フォーマット</h1>
                        <p class="pd_c-row-01_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト本文テキスト本文テキスト本文テキスト本文テキスト</p>
                    </div>
                </div>
            </div>
            <div class="pd_c-row-01">
                <div class="pd_c-row-01_leftCol">
                    <div class="pd_c-row-01">
                        <div class="pd_c-row-01_leftCol">
                            <div class="pd_c-imageSet-01">
                                <div class="pd_c-imageSet-01_img"><img src="http://placehold.jp/3d4070/ffffff/229x129.png" alt="" /></div>
                                <h1 class="pd_c-imageSet-01_heading">1/4フォーマット</h1>
                                <p class="pd_c-imageSet-01_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト</p>
                            </div>
                        </div>
                        <div class="pd_c-row-01_rightCol">
                            <div class="pd_c-imageSet-01">
                                <div class="pd_c-imageSet-01_img"><img src="http://placehold.jp/3d4070/ffffff/229x129.png" alt="" /></div>
                                <h1 class="pd_c-imageSet-01_heading">1/4フォーマット</h1>
                                <p class="pd_c-imageSet-01_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pd_c-row-01_rightCol">
                    <div class="pd_c-row-01">
                        <div class="pd_c-row-01_leftCol">
                            <div class="pd_c-imageSet-02">
                                <div class="pd_c-imageSet-02_img"><img src="http://placehold.jp/3d4070/ffffff/229x129.png" alt="" /></div>
                                <p class="pd_c-imageSet-02_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト</p>
                            </div>
                        </div>
                        <div class="pd_c-row-01_rightCol">
                            <div class="pd_c-imageSet-02">
                                <div class="pd_c-imageSet-02_img"><img src="http://placehold.jp/3d4070/ffffff/229x129.png" alt="" /></div>
                                <p class="pd_c-imageSet-02_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pd_c-group-01">
                <h1 class="pd_c-group-01_heading">1/1フォーマット</h1>
                <p class="pd_c-group-01_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト本文テキスト本文テキスト本文テキスト本文テキスト</p>
                <figure class="pd_c-figure-01 pd_c-figure-01--expand"><img class="pd_c-figure-01_img" src="http://placehold.jp/3d4070/ffffff/1500x500.png" alt="" /></figure>
            </div>
            <div class="pd_c-youtubeMovie-01">
                <div class="pd_c-youtubeMovie-01_inner"><iframe class="pd_c-youtubeMovie-01_iframe" width="980" height="549" src="https://www.youtube.com/embed/uk0QcppaVLg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
            </div>
            <div class="pd_c-group-01">
                <h1 class="pd_c-group-01_heading">リストフォーマット</h1>
                <ul class="pd_c-normalList-01">
                    <li class="pd_c-normalList-01_listItem"><span>・</span>ノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリスト</li>
                    <li class="pd_c-normalList-01_listItem"><span>・</span>ノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリストノーマルリスト</li>
                </ul>
                <ul class="pd_c-notes-01">
                    <li class="pd_c-notes-01_listItem"><span>・</span>注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト</li>
                    <li class="pd_c-notes-02_listItem"><span>・</span>注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト</li>
                </ul>
                <ul class="pd_c-notes-02">
                    <li class="pd_c-notes-02_listItem"><span>※</span>注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト</li>
                    <li class="pd_c-notes-02_listItem"><span>※</span>注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト</li>
                </ul>
                <ul class="pd_c-notes-03">
                    <li class="pd_c-notes-03_listItem"><span>1.</span>注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト</li>
                    <li class="pd_c-notes-03_listItem"><span>2.</span>注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト注記リスト</li>
                </ul>
            </div>
        </div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_b-detail-02 {
  @media screen and (min-width:MIN_POINT) {
    margin-top 60px
  }
  @media screen and (max-width:MAX_POINT) {
    margin-top 55px
  }
  img {
    vertical-align bottom
  }

  &_inner {

    @media screen and (min-width:MIN_POINT) {
      max-width: 980px;
      margin-right: auto;
      margin-left: auto;
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right 15px
      margin-left 15px
    }

  }

  &_heading {
    text-align center
    color #000
    margin-top 40px
    @media screen and (min-width:MIN_POINT) {
      font-size: 30px;
      line-height: (45/30);
    }
    @media screen and (max-width:MAX_POINT) {
      font-size: 22px;
      line-height: (33/22);
    }
    &:first-child {
      margin-top 0
    }
    & + div {
      @media screen and (min-width:MIN_POINT) {
        margin-top 24px
      }
      @media screen and (max-width:MAX_POINT) {
        margin-top 16px
      }
    }
  }
  &_heading + &_text-01 {
    @media screen and (min-width:MIN_POINT) {
      margin-top 18px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_text-01 {
    color: rgba(0,0,0,.9)
    @media screen and (min-width:MIN_POINT) {
      margin-top 24px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 16px
    }
  }

  &_figure {
    @media screen and (min-width:MIN_POINT) {
      margin-top 24px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 16px
    }
  }

  .pd_c-table-01 {
    margin-top 24px
  }

  .pd_m-linkList-01 {
    @media screen and (min-width:MIN_POINT) {
      margin-top 40px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
}

.pd_b-detail-02
  &_sub
    color RED
    font-size 18px
    font-weight bold
    margin-top 10px
