/*
---
name: pd_e-hover-01
category: element
---
```html
  <a class="pd_m-attention_anchor pd_e-hover-01" href="#">opacity0.9になるやつ</a>
```
*/
.pd_e-hover-01 {
  &:hover {
    opacity .9
  }
  &--bgGray {
    &:hover {
      opacity 1
      background-color #F2F2F2
    }
  }
  &--bgDarkGray{
    &:hover {
      opacity 1
      background-color #E6E6E6
    }
  }
}
