/*
---
name: pd_c-no-stock-btn
category: component
tag:
  - btn
---
```html
<div class="pd_c-no-stock-btn">
    <button class="pd_c-no-stock-btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)">
        <p class="pd_c-no-stock-btn"><span>在庫がありません</span></p>
    </button>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;


.pd_c-no-stock-btn {
    width 100%

    &_btn {
        color rgba(0,0,0,.3)
        text-align center
        padding 0
        background #E6E6E6
        border none
        display block;
        width 100%
        border-radius 8px
        padding 17px
        margin-top 24px
    }

    &_Text-01 {

    }
}