/*
---
name: pd_c-quote-btn
category: component
tag:
  - btn
---
```html
<div class="pd_c-quote-btn">
    <button class="pd_c-quote-btn_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)"><span class="pd_c-quote-btn_Text-01">お見積もり手続きへ</span></button>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;


.pd_c-quote-btn {
    width 100%

    &_btn {
        color #fff
        text-align center
        padding 0
        background #364563
        border none
        display block;
        width 100%
        border-radius 8px
        padding 17px
        margin-top 24px
    }

    &_Text-01 {

    }
}
