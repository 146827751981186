*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;


.pd_e-honin-box-text
  margin-bottom 40px

.pd_e-honin-box-description
  color #cc0e00
  margin-bottom 40px