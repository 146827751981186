*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;


.pd_e-honin-number 
  display flex
  -webkit-box-align center
  -ms-flex-align center
  align-items center
  margin-bottom 32px
.pd_e-honin-number_title
  width 65px
.pd_e-honin-number_text
  flex 1
.pd_e-honin-sub-title
  font-weight bold
  font-size 18px
  margin-bottom 12px
.pd_e-honin-name
  margin-bottom 16px
  font-size 16px
.pd_e-honin-address
   margin-bottom 40px
   line-height: 1.3
.pd_e-honin-description
  font-weight bold
  font-size 16px
  margin-bottom 32px
.pd_e-honin-scroll-box
  width 90%
  margin 0 auto 32px
  height 70px
  overflow-y auto
  border solid 1px rgba(0,0,0,0.1)
  padding 8px 2%
.pd_e-honin-text
  margin-bottom 16px
@media screen and (min-width:MIN_POINT) {
  .pd_e-honin-description {
      font-size 18px
  }
  .pd_e-honin-scroll-box {
    max-width 700px
    margin 0 0 32px 24px
  }
}