/*
---
name: pd_c-viewer-01
category: component
tag:
  - text
  - img
---
```html
<div class="pd_c-viewer-01">
    <div class="pd_c-viewer-01_inner">
        <div class="pd_c-viewer-01_leftCol"><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-pc pd_js-popup-modal" href="#modalBox-viewer-360">
                <div class="pd_c-viewer-01_group-01">
                    <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_360.svg" /></div>
                    <div class="pd_c-viewer-01_texts">
                        <p class="pd_c-viewer-01_text-01">360&deg;ビュー</p>
                        <p class="pd_c-viewer-01_text-02">商品を様々な角度から自由に見ることができます。</p>
                    </div>
                </div>
            </a><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-sp pd_js-popup-modal" href="#modalBox-viewer-3602">
                <div class="pd_c-viewer-01_group-01">
                    <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_360.svg" /></div>
                    <div class="pd_c-viewer-01_texts">
                        <p class="pd_c-viewer-01_text-01">360&deg;ビュー</p>
                        <p class="pd_c-viewer-01_text-02">商品を様々な角度から自由に見ることができます。</p>
                    </div>
                </div>
            </a></div>
        <div class="pd_c-viewer-01_rightCol"><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-pc pd_js-popup-modal" href="#modalBox-viewer-AR">
                <div class="pd_c-viewer-01_group-01">
                    <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_ar.svg" /></div>
                    <div class="pd_c-viewer-01_texts">
                        <p class="pd_c-viewer-01_text-01">AR設置シミュレーション</p>
                        <p class="pd_c-viewer-01_text-02">商品を部屋に設置したイメージをご確認いただけます。</p>
                    </div>
                </div>
            </a><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-sp pd_js-popup-modal" href="#modalBox-viewer-AR2">
                <div class="pd_c-viewer-01_group-01">
                    <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_ar.svg" /></div>
                    <div class="pd_c-viewer-01_texts">
                        <p class="pd_c-viewer-01_text-01">AR設置シミュレーション</p>
                        <p class="pd_c-viewer-01_text-02">商品を部屋に設置したイメージをご確認いただけます。</p>
                    </div>
                </div>
            </a></div>
    </div>
    <p class="pd_c-viewer-text"><a href="">詳しいご利用方法はこちら</a></p>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-viewer-text
  font-size 12px
  text-align right
  margin-top: 6px

.pd_c-viewer-01 {
  &_inner {
    @media screen and (min-width:MIN_POINT) {
      display: flex;
      margin-left -10px
      margin-right -10px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }

  &_leftCol,
  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
      padding-right 10px
      padding-left 10px
      width 50%
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 32px
    }
  }

  &_leftCol {
  }

  &_rightCol {
    @media screen and (max-width:MAX_POINT) {
      margin-top 15px
    }
  }

  &_group-01 {
    display: flex;
  }
  &_img,
  &_texgts {
    box-sizing: border-box;
  }
  &_img {
    @media screen and (min-width:MIN_POINT) {
      width: 61px;
      padding-right 16px;
    }
    @media screen and (max-width:MAX_POINT) {
      width 61px
      padding-right 16px
    }
    img {
      display: block;
      width: 100%;
    }
  }

  &_texts {
    flex 1
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }

  &_text-01 {
    text-decoration underline
    color #364563
    font-size 17px
    font-weight bold
  }

  &_text-02 {
    font-size 13px
    color rgba(0,0,0,.6)
    margin-top 5px
  }
}
