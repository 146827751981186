/*
---
name: pd_c-imageSet-01
category: component
tag:
  - text
  - img
---
```html
  <div class="pd_c-imageSet-01">
      <div class="pd_c-imageSet-01_img"><img src="http://placehold.jp/3d4070/ffffff/229x129.png" alt="" /></div>
      <h1 class="pd_c-imageSet-01_heading">1/4フォーマット</h1>
      <p class="pd_c-imageSet-01_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト</p>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-imageSet-01 {
  &_heading {
    font-size 22px
    line-height (33/22)
    @media screen and (min-width:MIN_POINT) {
      margin-top 16px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 12px
    }
  }
  &_img {
    text-align center
    display flex
    justify-content center
    align-items center
    width 215px
    height 121px
    @media screen and (max-width:MAX_POINT) {
      width 100%
    }
    img {
      @media screen and (min-width:MIN_POINT) {
        max-width 215px
        max-height 100%
      }
      @media screen and (max-width:MAX_POINT) {
        max-width 100%
      }
    }
  }
  &_text-01 {
    font-size 15px
    margin-top 16px
    line-height (22/15)
  }
}
