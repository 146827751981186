/*
---
name: pd_c-imageSet-02
category: component
tag:
  - text
  - img
---
```html
  <div class="pd_c-imageSet-02">
      <div class="pd_c-imageSet-02_img"><img src="http://placehold.jp/3d4070/ffffff/229x129.png" alt="" /></div>
      <p class="pd_c-imageSet-02_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト</p>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-imageSet-02 {
  &_img {
    text-align center
    @media screen and (min-width:MIN_POINT) {
      width 100%
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_text-01 {
    color: rgba(0,0,0,.6)
    font-size 13px
    line-height (20/13)
    @media screen and (min-width:MIN_POINT) {
      margin-top 16px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 10px
    }
  }
}
