/*
---
name: pd_c-sideAcc
category: component
tag:
  - text
---
```html
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">映像</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">カメラ</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">オーディオ</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">電話・FAX・テレビドアホン</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">メディア・アクセサリー</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">調理</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">空調・季節</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">家事</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">健康</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">美容</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">電池・充電器</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">住まい</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">あかりと電気設備</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">ホビー（工具）・ペット</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">消耗品・付属品</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">カスタマイズレッツノート</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">カーナビ・地図ソフト</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">SANYOの消耗品・付属品</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">自転車</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">通信サービス</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">商品連携・保証サービス</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">IoT家電</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
<ul class="pd_c-sideAcc">
    <li class="pd_c-sideAcc_list">
    <div class="pd_c-sideAcc_ttl pd_e-hover-01">その他</div>
    <ul class="pd_c-sideAcc_contentList">
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">テレビ </a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ブルーレイ/DVDレコーダー・プレーヤー</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">ポータブルAV</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">映像プロジェクター</a></li>
        <li class="pd_c-sideAcc_contentListItem"><a class="pd_e-hover-01" href="/">チューナー</a></li>
    </ul>
    </li>
</ul>
```
*/
.pd_c-sideAcc
    // &_list
    &_ttl
        border-top: 1px solid #CCCCCC
        cursor: pointer
        font-size: 15px
        font-weight: 500
        line-height: 1.5
        padding: 16px 18px 16px 0
        position: relative
        word-break: keep-all;
        word-wrap:break-word;
        &::after
            background-image: url('/store/images/component/searchSidebar-plus.svg')
            background-size: cover
            background-repeat: no-repeat
            content: ""
            display: block
            height: 13px
            position: absolute
            right: 0
            top: 50%
            transform: translateY(-50%)
            width: 13px
        &.pd_open::after
            background-image: url('/store/images/component/searchSidebar-minus.svg')
    &_contentList
        display: none
    &_contentListItem
        border-top: 1px solid #CCCCCC
        cursor: pointer
        a
            color: rgba(0,0,0,0.6)
            display: block
            height: 100%
            padding: 16px 0
            width: 100%