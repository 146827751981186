/*
---
name: pd_c-tag-01
category: component
tag:
  - tag
---
```html
<span class="pd_c-tag-01">セット商品</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-tag-01 {
  font-size 13px
  list-style (19/13)
  color rgba(0,0,0,.9)
  display inline-block
  background #E6E6E6
  border-radius 4px
  padding: 0 8px 0 10px
  @media screen and (min-width:MIN_POINT) {
  }
  @media screen and (max-width:MAX_POINT) {
    &+span {
      display block
      margin-top 9px
    }
  }
}
