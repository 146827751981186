/*
---
name: pd_b-textAbout
category: block
tag:
  - text
---
```html
<div class="pd_b-textAbout">
    <div class="pd_b-textAbout_area"><span class="pd_b-textAbout_head">お届けについて</span><span class="pd_b-textAbout_text">年末年始のため配送が遅れることがあります。予めご了承ください。テキストが入ります。テキストが入ります。年末年始のため配送が遅れることがあります。予めご了承ください。テキストが入ります。テキストが入ります。</span></div>
    <div class="pd_b-textAbout_area"><span class="pd_b-textAbout_head">この消耗品・付属品に対応する本体商品</span><span class="pd_b-textAbout_text">CS-Z282A/CS-50RBE2/CS-E222AZ/CS-E252AZ/CS-36RBE/CS-E282AD/CS-E402AZ/CS-22RBZ/CS-E402A2Z/CS-25DEAZ/CS-E402A2/CS-E402A2D/CS-40RBE/CS-E252A2/CS-Z252A/CS-28RBZ/CS-22DEAZ/CS-25RBE/CS-E222A/CS-Z222A/CS-E282AZ/CS-E252A/CS-40RBZ2/CS-25RBZ/CS-Z402A2/CS-28DEAZ/CS-E252AD/CS-28RBE/CS-40RBE2/CS-E282A2/CS-Z362A/CS-E402A/CS-E282A/CS-36RBZ/CS-22RBE/</span>
        <ul class="pd_b-textAbout_list">
        <li class="pd_b-textAbout_listItem"><span>※</span>この商品はAMC-NC2/AMC-S2/AMC-DS2/AMC-NS2/AMC-NC5の後継品です。</li>
        </ul>
    </div>
    <div class="pd_b-textAbout_area"><span class="pd_b-textAbout_head">返品について</span><span class="pd_b-textAbout_text">原則8日以内まで返品可（送料はお客様負担）テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</span></div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_b-textAbout {
    &_area {
        margin-top 40px
        display flex 
        flex-direction: column;
    }
    &_head {
        color rgba(0,0,0,.9)
        font-weight 500
        font-size 22px
        line-height (33/22)
    }
    &_text {
        font-size 15px
        margin-top 12px
    }

    &_list {
        font-size 13px
        color rgba(0,0,0,.6)
        margin-top 16px
    }

    &_listItem {
        > span {
            display: inline-block;
            width: 1.2em;
            margin-left -1.2em;
        }
        padding-left: 1.2em;
    }
}
.pd_b-textAbout
  &_sub
    font-size 12px
    margin-left 10px
    color #777
  &_red
    font-size 18px
    font-weight bold
    margin-top 5px
    color #CC0E00

