/*
---
name: pd_c-youtubeMovie-01
category: component
tag:
  - movie
---
```html
  <div class="pd_c-youtubeMovie-01">
      <div class="pd_c-youtubeMovie-01_inner"><iframe class="pd_c-youtubeMovie-01_iframe" width="980" height="549" src="https://www.youtube.com/embed/uk0QcppaVLg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-youtubeMovie-01 {
  margin-top 40px
  @media screen and (max-width:MAX_POINT) {
    margin-right -15px
    margin-left -15px
  }
  &_inner {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      overflow: hidden;
    }
  }

  &_iframe {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      position absolute
      top 0
      left 0
      width 100%
      height 100%
    }
  }
}
