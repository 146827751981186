/*
---
name: pd_b-detail-01
category: block
tag:
  - icon
  - text
  - img
  - slider
  - checkBox
  - btn
---
```html
<div class="pd_b-detail-01">
    <div class="pd_b-detail-01_inner">
        <div class="pd_b-detail-01_head">
            <h1 class="pd_c-headingLv1-01">コードレススティック掃除機 「パワーコードレス」</h1>
        </div>
        <div class="pd_b-detail-01_headTag">
            <div class="pd_b-detail-01_headLeft">
                <div class="pd_b-detail-01_headGroup">
                    <p class="pd_b-detail-01_headSub-01"><span class="pd_e-smallTag-01">近日発売</span><span class="pd_e-smallTag-01">セット商品</span><span class="pd_e-smallTag-01">軽減税率対象</span>
                    </p>
                </div>
                <ul class="pd_e-smallTag-02_list">
                    <li class="pd_e-smallTag-02_listItem"><span class="pd_e-smallTag-02">NEW</span>
                    </li>
                    <li class="pd_e-smallTag-02_listItem"><span class="pd_e-smallTag-02">SALE40%OFF</span>
                    </li>
                    <li class="pd_e-smallTag-02_listItem"><span class="pd_e-smallTag-02">1000コイン進呈</span>
                    </li>
                    <li class="pd_e-smallTag-02_listItem"><span class="pd_e-smallTag-02">プレゼント付き</span>
                    </li>
                    <li class="pd_e-smallTag-02_listItem"><span class="pd_e-smallTag-02">2倍コイン進呈</span>
                    </li>
                    <li class="pd_e-smallTag-02_listItem"><span class="pd_e-smallTag-02">予約商品</span>
                    </li>
                    <li class="pd_e-smallTag-02_listItem"><span class="pd_e-smallTag-02">特別価格</span>
                    </li>
                </ul>
                <p class="pd_b-detail-01_headSub-01"><span class="pd_b-detail-01_subText-01">SET-GF90W-ENTRY-D</span></p>
            </div>
            <div class="pd_b-detail-01_headRight">
                <p class="pd_b-detail-01_headSub-02"><img src="http://placehold.jp/3d4070/ffffff/106x22.png" alt="" /></p>
            </div>
        </div>
        <div class="pd_b-detail-01_body">
            <div class="pd_b-detail-01_row">
                <div class="pd_b-detail-01_leftCol">
                    <div class="pd_m-itemImages-01">
                        <div class="pd_c-sliderBox-01">
                            <div class="pd_c-sliderBox-01_list pd_c-sliderBox-01_list--top pd_js-productSlider">
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d4070/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d40dd/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d40dd/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d4070/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d40dd/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d40dd/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d4070/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d40dd/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d40dd/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d4070/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d4070/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                                <div class="pd_c-sliderBox-01_listItem">
                                    <figure class="pd_c-sliderBox-01_figure"><a class="pd_c-sliderBox-01_anchor pd_e-hover-01 pd_js-noClick" href="http://placehold.jp/3d4070/ffffff/1288x966.png"><img src="http://placehold.jp/3d40dd/ffffff/1288x966.png" data-zoom-image="http://placehold.jp/3d40dd/ffffff/1288x966.png" alt="" /></a></figure>
                                </div>
                            </div>
                            <div class="pd_c-sliderBox-01_thumbnails">
                                <ul class="pd_c-sliderBox-01_thumbnailList pd_c-sliderBox-01_thumbnailList--top">
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/3d40dd/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/3d40dd/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/3d40dd/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/cccccc/ffffff/600x600.png" alt="" /></li>
                                    <li class="pd_c-sliderBox-01_thumbnail pd_e-hover-01"><img src="http://placehold.jp/3d40dd/ffffff/600x600.png" alt="" /></li>
                                </ul>
                            </div>
                        </div>
                        <div class="pd_c-viewer-01">
                            <div class="pd_c-viewer-01_inner">
                                <div class="pd_c-viewer-01_leftCol"><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-pc pd_js-popup-modal" href="#modalBox-viewer-360">
                                        <div class="pd_c-viewer-01_group-01">
                                            <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_360.svg" /></div>
                                            <div class="pd_c-viewer-01_texts">
                                                <p class="pd_c-viewer-01_text-01">360&deg;ビュー</p>
                                                <p class="pd_c-viewer-01_text-02">商品を様々な角度から自由に見ることができます。</p>
                                            </div>
                                        </div>
                                    </a><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-sp pd_js-popup-modal" href="#modalBox-viewer-3602">
                                        <div class="pd_c-viewer-01_group-01">
                                            <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_360.svg" /></div>
                                            <div class="pd_c-viewer-01_texts">
                                                <p class="pd_c-viewer-01_text-01">360&deg;ビュー</p>
                                                <p class="pd_c-viewer-01_text-02">商品を様々な角度から自由に見ることができます。</p>
                                            </div>
                                        </div>
                                    </a></div>
                                <div class="pd_c-viewer-01_rightCol"><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-pc pd_js-popup-modal" href="#modalBox-viewer-AR">
                                        <div class="pd_c-viewer-01_group-01">
                                            <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_ar.svg" /></div>
                                            <div class="pd_c-viewer-01_texts">
                                                <p class="pd_c-viewer-01_text-01">AR設置シミュレーション</p>
                                                <p class="pd_c-viewer-01_text-02">商品を部屋に設置したイメージをご確認いただけます。</p>
                                            </div>
                                        </div>
                                    </a><a class="pd_c-viewer-01_anchor-01 pd_e-hover-01 is-sp pd_js-popup-modal" href="#modalBox-viewer-AR2">
                                        <div class="pd_c-viewer-01_group-01">
                                            <div class="pd_c-viewer-01_img"><img class="pd_viewer-icon360deg" src="/store/images/component/viewer-01_icon_ar.svg" /></div>
                                            <div class="pd_c-viewer-01_texts">
                                                <p class="pd_c-viewer-01_text-01">AR設置シミュレーション</p>
                                                <p class="pd_c-viewer-01_text-02">商品を部屋に設置したイメージをご確認いただけます。</p>
                                            </div>
                                        </div>
                                    </a></div>
                            </div>
                            <p class="pd_c-viewer-text"><a href="">詳しいご利用方法はこちら</a></p>
                        </div>
                        <div class="pd_m-modalBox-02 is-pc" id="modalBox-viewer-360"><img src="http://placehold.jp/666666/ffffff/1640x1230.png?text=360°ビュー" alt="" /></div>
                        <div class="pd_m-modalBox-02 is-sp" id="modalBox-viewer-3602"><img src="http://placehold.jp/666666/ffffff/750x1624.png?text=仕様確定後再調整" alt="" /></div>
                        <div class="pd_m-modalBox-02 is-pc" id="modalBox-viewer-AR"><img src="http://placehold.jp/666666/ffffff/1640x1230.png?text=AR" alt="" /></div>
                        <div class="pd_m-modalBox-02 is-sp" id="modalBox-viewer-AR2"><img src="http://placehold.jp/666666/ffffff/750x1624.png?text=ARSP" alt="" /></div>
                        <div class="pd_c-colorSelect">
                            <p class="pd_c-colorSelect_ttl-02">カラー選択
                            </p>
                            <ul class="pd_c-colorSelect_list-02">
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                            </ul>
                        </div>
                        <div class="pd_c-colorSelect">
                            <p class="pd_c-colorSelect_ttl-02">サイズ選択<span>: 25</span>
                            </p>
                            <ul class="pd_c-colorSelect_list-02">
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                            </ul>
                        </div>
                        <div class="pd_c-colorSelect">
                            <p class="pd_c-colorSelect_ttl-02">サイズ選択<span>: 26</span>
                            </p>
                            <ul class="pd_c-colorSelect_list-02">
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                                <li class="pd_c-colorSelect_list_item-02"><a href=""><img src="http://placehold.jp/d9d9d9/ffffff/80x68.png" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="pd_b-detail-01_rightCol">
                    <div class="pd_m-form-01">
                        <form action="">
                            <div class="pd_m-form-01_right">
                                <button class="pd_c-favoriteBtn-01 pd_e-hover-01 pd_e-hover-01--bgGray pd_js-favoriteBtn" type="button"><span class="pd_c-favoriteBtn-01_text-01">お気に入りに追加</span><span class="pd_c-favoriteBtn-01_text-02">98</span></button>
                            </div>
                            <p class="pd_c-released-01">発売日：xxxx年xx月 / 発送予定日：2021年04月01日～10日</p>
                            <p class="pd_c-stocked-01"><span class="pd_c-stocked-01_span-01">在庫状況：</span><span class="pd_c-stocked-01_span-02">販売中</span><span class="pd_c-stocked-01_span-03">※キャンセル不可</span></p>
                            <p class="pd_c-Buy-in-bulk-01"><span class="pd_c-Buy-in-bulk-01_span-01">【まとめ買い】2個で10%割引</span><span class="pd_c-Buy-in-bulk-01_span-01">【まとめ買い】3個で15%割引</span><span class="pd_c-Buy-in-bulk-01_span-01">【まとめ買い】4個で20%割引</span></p>
                            <div class="pd_c-price-01"><span class="pd_c-price-01_text-01">39,280</span><span class="pd_c-price-01_text-02">円（税込）</span></div>
                            <div class="pd_c-price-01 pd_c-price-01--discount">
                                <div class="pd_c-price">
                                    <p class="pd_c-price-01_text-02">値引後価格</p><span class="pd_c-price-01_text-01">49,280</span><span class="pd_c-price-01_text-02">円（税込）</span>
                                </div>
                                <div class="pd_c-price-01 pd_c-price-01--before">
                                    <div class="pd_c-price"><span class="pd_c-price-01_text-01">49,280</span><span class="pd_c-price-01_text-02">円（税込）</span></div>
                                </div>
                            </div>
                            <div class="pd_c-coin-01">
                                <div class="pd_c-coin-01_flex"><span class="pd_c-coin-01_text-01">還元コイン</span><span class="pd_c-coin-01_text-02">555</span></div>
                            </div>
                            <p class="pd_c-coin-attention">※クーポンなどを利用した場合、獲得コインは変動いたします。</p>
                            <div class="pd_c-coin-01">
                                <div class="pd_c-coin-01_flex"><span class="pd_c-coin-01_text-01">還元コイン</span><span class="pd_c-coin-01_text-02">555</span></div><span class="pd_c-entryMember-01_span-01">（エントリー会員の場合）</span>
                            </div>
                            <p class="pd_c-coin-attention">※会員登録した場合、コインが獲得できます</p>
                            <div class="pd_c-option-01">
                                <div class="pd_c-option-01_head pd_js-c-option-01_head">オプション選択<span class="pd_c-option-01_icon-01"></span></div>
                                <div class="pd_c-option-01_body">
                                    <div class="pd_c-option-01--disabled">
                                        <div class="pd_c-option-01_label-01"></div>
                                        <label>
                                            <input class="pd_c-option-01_input-01 pd_js-c-option-01_checkbox" type="checkbox" name="recycling" /><span class="pd_c-option-01_indicator-01"></span><span class="pd_c-option-01_text-01">リサイクル引取申し込み(テレビ)</span>
                                        </label>
                                        <select class="pd_c-option-01_type pd_js-c-option-01_type">
                                            <option value="" selected="" disabled="">選択してください</option>
                                            <option value="">1</option>
                                            <option value="">2</option>
                                        </select>
                                    </div>
                                    <div class="pd_c-option-01_label-01">
                                        <label>
                                            <input class="pd_c-option-01_input-01 pd_js-c-option-01_checkbox pd_js-c-option-01_checkbox-toggle" type="checkbox" name="recycling" /><span class="pd_c-option-01_indicator-01"></span><span class="pd_c-option-01_text-01">下取り品引き上げ</span>
                                        </label>
                                        <div class="pd_c-option-01_selectWrapper">
                                            <select class="pd_c-option-01_type pd_js-c-option-01_type">
                                                <option value="option1" selected="" disabled="">選択してください</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="pd_c-inputText-area"><span class="pd_c-inputText-text">引取り時のご要望があれば、入力してください。</span><span class="pd_e-required-02">必須 </span></div>
                                    <input class="pd_c-inputText-01" placeholder="" type="text" name="" />
                                </div>
                            </div>
                            <div class="pd_c-cart-01">
                                <div class="pd_c-cart-01_inner">
                                    <div class="pd_c-cart-01_col-01">
                                        <div class="pd_c-cart-01_orders">数量：</div>
                                        <div class="pd_c-cart-01_selectWrapper">
                                            <select class="pd_c-cart-01_select">
                                                <option value="">999</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="pd_c-cart-01_col-02 pd_c-js_scrollTrigger">
                                        <button class="pd_c-cart-01_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)">
                                            <p class="pd_c-cart-01_btnText-01"><span>カートへ入れる</span></p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="pd_c-quote-btn">
                                <button class="pd_c-quote-btn_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)"><span class="pd_c-quote-btn_Text-01">お見積もり手続きへ</span></button>
                            </div>
                            <div class="pd_c-infoEmail-btn">
                                <button class="pd_c-infoEmail-btn_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)">
                                    <p class="pd_c-infoEmail-btn_Text-01"><span>入荷案内メールの申し込み</span></p>
                                </button>
                            </div>
                            <div class="pd_c-no-stock-btn">
                                <button class="pd_c-no-stock-btn_btn" type="button"><span class="pd_c-no-stock-btn_Text-01">在庫がありません</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="pd_c-emphasis-01">
                <div class="pd_c-emphasis-01_head">
                    <h2 class="pd_c-emphasis-01_heading">ご購入前に必ずご確認ください</h2>
                </div>
                <div class="pd_c-emphasis-01_body">
                    <ul class="pd_c-emphasis-01_list">
                        <li class="pd_c-emphasis-01_listItem"><a class="pd_c-emphasis-01_anchor pd_e-hover-01" href="/">工事空き状況ご確認</a></li>
                        <li class="pd_c-emphasis-01_listItem"><a class="pd_c-emphasis-01_anchor pd_e-hover-01" href="/">取付工事の詳細について</a></li>
                        <li class="pd_c-emphasis-01_listItem"><a class="pd_c-emphasis-01_anchor pd_e-hover-01" href="/">リサイクル回収について</a></li>
                    </ul>
                    <ul class="pd_c-emphasis-01_notes">
                        <li class="pd_c-emphasis-01_notesItem"><span>※</span>SGムービング（株）の配送となります。配送日については、お客様のお近くの拠点に商品が届きましたら、電話にてご連絡いたします。目安は出荷ご連絡メールから５～６日前後となります。</li>
                    </ul>
                </div>
            </div>
            <div class="pd_c-emphasis-01">
                <div class="pd_c-emphasis-01_head">
                    <h2 class="pd_c-emphasis-01_heading">ご購入前に必ずご確認ください</h2>
                </div>
                <div class="pd_c-emphasis-01_body">
                    <ul class="pd_c-emphasis-01_list">
                        <li class="pd_c-emphasis-01_listItem"><a class="pd_c-emphasis-01_anchor pd_e-hover-01" href="/">工事空き状況ご確認</a></li>
                        <li class="pd_c-emphasis-01_listItem"><a class="pd_c-emphasis-01_anchor pd_e-hover-01" href="/">取付工事の詳細について</a></li>
                        <li class="pd_c-emphasis-01_listItem"><a class="pd_c-emphasis-01_anchor pd_e-hover-01" href="/">リサイクル回収について</a></li>
                    </ul>
                    <ul class="pd_c-emphasis-01_notes">
                        <li class="pd_c-emphasis-01_notesItem"><span>※</span>SGムービング（株）の配送となります。配送日については、お客様のお近くの拠点に商品が届きましたら、電話にてご連絡いたします。目安は出荷ご連絡メールから５～６日前後となります。</li>
                    </ul>
                </div>
            </div>
            <div class="pd_b-textAbout_area">
                <p class="pd_b-textAbout_head">お届けについて</p><span class="pd_b-textAbout_text">年末年始のため配送が遅れることがあります。予めご了承ください。テキストが入ります。テキストが入ります。年末年始のため配送が遅れることがあります。予めご了承ください。テキストが入ります。テキストが入ります。</span>
            </div>
            <div class="pd_b-textAbout_area">
                <p class="pd_b-textAbout_head">この消耗品・付属品に対応する本体商品<span class="pd_b-textAbout_sub">※品番末尾の「-K」や「-W」などは色柄コードです。</span></p>
                <p class="pd_b-textAbout_red">必ず対応本体品番をお確かめの上、ご購入ください。</p><span class="pd_b-textAbout_text">CS-Z282A/CS-50RBE2/CS-E222AZ/CS-E252AZ/CS-36RBE/CS-E282AD/CS-E402AZ/CS-22RBZ/CS-E402A2Z/CS-25DEAZ/CS-E402A2/CS-E402A2D/<br />CS-40RBE/CS-E252A2/CS-Z252A/CS-28RBZ/CS-22DEAZ/CS-25RBE/CS-E222A/CS-Z222A/CS-E282AZ/CS-E252A/CS-40RBZ2/CS-25RBZ/CS-<br />Z402A2/CS-28DEAZ/CS-E252AD/CS-28RBE/CS-40RBE2/CS-E282A2/CS-Z362A/CS-E402A/CS-E282A/CS-36RBZ/CS-22RBE/</span>
            </div>
            <ul class="pd_b-textAbout_list">
                <li class="pd_b-textAbout_listItem"><span>※</span>この商品はAMC-NC2/AMC-S2/AMC-DS2/AMC-NS2/AMC-NC5の後継品です。</li>
            </ul>
            <div class="pd_b-textAbout_area"><span class="pd_b-textAbout_head">返品について</span><span class="pd_b-textAbout_text">原則8日以内まで返品可（送料はお客様負担）テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</span></div>
            <div class="pd_e-bnr-01"><a class="bd_e-bnr-01_anchor pd_e-hover-01 is-pc" href="/"> <img src="http://placehold.jp/3d4070/ffffff/976x160.png?text=PCバナー" alt="" /></a><a class="bd_e-bnr-01_anchor pd_e-hover-01 is-sp" href="/"><img src="http://placehold.jp/3d4070/ffffff/976x160.png?text=スマホバナー" alt="" /></a></div>
            <div class="pd_e-bnr-01"><a class="bd_e-bnr-01_anchor pd_e-hover-01 is-pc" href="/"> <img src="http://placehold.jp/3d4070/ffffff/976x160.png?text=PCバナー" alt="" /></a><a class="bd_e-bnr-01_anchor pd_e-hover-01 is-sp" href="/"><img src="http://placehold.jp/3d4070/ffffff/976x160.png?text=スマホバナー" alt="" /></a></div>
            <div class="pd_e-bnrText-01"><span class="pd_e-bnrText-01_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入り</span></div>
        </div>
    </div>
    <div class="pd_m-cart-01 pd_m-js_scroll">
        <div class="pd_m-cart-01_inner">
            <div class="pd_m-cart-01_leftCol">
                <div class="pd_c-itemImage-01">
                    <div class="pd_c-itemImage-01_img"><img src="http://placehold.jp/3d4070/ffffff/100x100.png" alt="" /></div>
                    <div class="pd_c-itemImage-01_contents">
                        <p class="pd_c-itemImage-01_name">コードレススティック掃除機 「パワーコードレス」</p>
                        <p class="pd_c-itemImage-01_price"><span class="pd_c-itemImage-01_text-01">50,700</span><span class="pd_c-itemImage-01_text-02">円</span><span class="pd_c-itemImage-01_text-03">（税込）</span></p>
                    </div>
                </div>
            </div>
            <div class="pd_m-cart-01_rightCol">
                <div class="pd_c-cart-01">
                    <div class="pd_c-cart-01_inner">
                        <div class="pd_c-cart-01_col-01">
                            <div class="pd_c-cart-01_orders">数量：</div>
                            <div class="pd_c-cart-01_selectWrapper">
                                <select class="pd_c-cart-01_select">
                                    <option value="">999</option>
                                </select>
                            </div>
                        </div>
                        <div class="pd_c-cart-01_col-02">
                            <button class="pd_c-cart-01_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)">
                                <p class="pd_c-cart-01_btnText-01"><span>カートへ入れる</span></p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_b-detail-01 {
  @media screen and (min-width:MIN_POINT) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  @media screen and (max-width:MAX_POINT) {
    margin-top 40px
    margin-bottom: 60px;
  }
  img {
    vertical-align: bottom;
    max-width 644px
    max-height 100%
    @media screen and (max-width:MAX_POINT) {
      max-width 100%
    }
  }
  &_inner {
    @media screen and (min-width:MIN_POINT) {
      max-width 980px
      margin-right auto
      margin-left auto
      .pd_c-sp_Image{
        display: none;
      }
      .pd_c-pc_Image{
        display: block;
      }
      
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right 15px
      margin-left 15px
      .pd_c-sp_Image{
        display: block;
      }
      .pd_c-pc_Image{
        display: none;
      }
    }
  }

  &_head {
    .pd_c-discontinued-01 + & {
      @media screen and (min-width:MIN_POINT) {
        margin-top 21px
      }
      @media screen and (max-width:MAX_POINT) {
        margin-top 24px
      }
    }
  }

  &_headTag {
    @media screen and (min-width:MIN_POINT) {
      display flex
      justify-content space-between
      margin-top 10px
    }

    @media screen and (max-width:MAX_POINT) {
      margin-top 8px
    }
  }

  &_heading {
  }

  &_headRight {
    @media screen and (min-width:MIN_POINT) {
      padding-top 13px
    }

    @media screen and (max-width:MAX_POINT) {
      padding-top 10px
    }
  }

  &_headGroup {
    @media screen and (min-width:MIN_POINT) {
      display flex
      align-items center
      margin-top 5px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }

  &_headSub-01 {
    @media screen and (min-width:MIN_POINT) {

    }
    @media screen and (max-width:MAX_POINT) {
    }
  }


  &_headSub-01 {
    @media screen and (min-width:MIN_POINT) {
      font-size 17px
      padding-right 20px
      box-sizing border-box
    }
    @media screen and (max-width:MAX_POINT) {
    }
    .pd_c-tag-01 {
      @media screen and (min-width:MIN_POINT) {
        margin-right 20px
      }
      @media screen and (max-width:MAX_POINT) {
      }
    }
  }

  &_subText-01 {
    font-size 17px
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }

  &_headSub-02 {
    @media screen and (min-width:MIN_POINT) {
      text-align right
    }
    @media screen and (max-width:MAX_POINT) {
      text-align right
    }
  }

  &_row {
    @media screen and (min-width:MIN_POINT) {
      display flex
      margin-top 32px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 49px
    }
  }

  &_leftCol {
    @media screen and (min-width:MIN_POINT) {
      width: 664px;
      padding-right: 20px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }

  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
      width 312px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 40px
    }
  }

  &_right {
    text-align right
  }
  .pd_c-emphasis-01 {
    margin-top 35px
  }

  .pd_e-bnr-01 {
    margin-top 40px
  }

}

@media screen and (min-width:MIN_POINT) {
  .pd_e-smallTag-02_list {
    width 700px
    height auto
    display flex
    flex-direction: row;
    margin 7px 0 0
  }
}

@media screen and (max-width:MAX_POINT) {
  .pd_e-smallTag-02_list {
    width 280px
    height auto
    display flex
    flex-wrap: wrap;
    margin 3px 0 0
  }

  .pd_e-smallTag-02_listItem {
    margin 5px 0 0
  }
}


@media screen and (min-width:MIN_POINT) {
  .mfp-content .pd_b-detail-01_inner{
      max-height: 775px;
      overflow-y: scroll;
  }
}
@media screen and (min-width:MIN_POINT) {
  .pd_campaign__contents .pd_b-detail-01_headTag {
      margin: 0 auto;
      width: 976px;
  }
}
@media screen and (max-width:MAX_POINT) {
  .pd_campaign__contents .pd_b-detail-01_headTag {
      padding: 0 15px;
      width: 100%;
  }
}