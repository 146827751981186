MAX_POINT = 640px
MIN_POINT = MAX_POINT+1px
/*
---
name: pd_m-dataPanel__white
category:
  - module
---

## テキスト

* この辺りはマークダウンで体裁自由にコメント可能です。

```html
<div class="pd_m-dataPanel__wrap">
                                  <h1 class="pd_m-contentTtl-01">キャンセル完了</h1>
                  <p class="pd_m-dataPanel__danger">以下のイベントをキャンセルしました</p>
                                    <div class="pd_m-dataPanel__white">
                                      <p class="pd_m-dataPanel__title">スマートコーヒー焙煎サービス「The Roast」体験会</p>
                                      <p class="pd_m-dataPanel__date">開催日時: XXXX/XX/XX  XX:XX〜XX:XX</p>
                                    </div><a class="pd_c-btn-02 pd_e-hover-01--bgGray" href="#">トップページへ戻る  </a>
                </div>

```
*/

.pd_m-dataPanel
  &__wrap
    max-width:  976px
    margin: auto
    .pd_m-contentTtl-01
      margin: 60px auto 12px
    .pd_m-submit-01
      border-top: 0
      padding-top: 0
      max-width: 700px
      margin: 0 auto 80px
      .pd_c-filterButton
          margin-top: 0
      @media screen and (max-width 640px)
        width: 90%
        margin: 0 auto 60px
        .pd_c-filterButton
          margin-bottom: 20px
    &_multiple
      max-width:  976px
      margin: auto
      padding-bottom: 60px
      .pd_m-contentTtl-01
        margin: 60px auto 12px
    .pd_c-btn-02
      max-width: 345px
      margin: 0 auto 80px
      @media screen and (max-width 640px)
        width: 90%
        margin: 0 auto 60px
  &__danger
    font-size: 17px
    margin-bottom: 30px
    @media screen and (max-width 640px)
      font-size: 4vw
      margin-left: 15px
  &__subTitle
    font-size: 25px
    font-weight: 500
    text-align: center
    @media screen and (max-width 640px)
      font-size: 6.5vw
  &__inner
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    margin-bottom: 60px
    @media screen and (max-width 640px)
      flex-flow: column;
      align-items: center;
  &__inner01
    display: flex
    flex-wrap: wrap
    margin-bottom: 60px
    @media screen and (max-width 640px)
      flex-flow: column;
      align-items: center;
  &__white01
    background: #fff
    border-radius: 8px
    padding: 30px
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3)
    margin: auto
    max-width: 700px
    margin: 0 auto 50px
    @media screen and (max-width 640px)
      padding: 10px
      width: 90%
    &_multiple
      background: #fff
      border-radius: 8px
      padding: 20px
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3)
      margin-top: 20px
      width: calc(976px / 3.15)
      &:not(:nth-of-type(3n+1))
        margin-left 20px
      @media screen and (max-width 640px)
        padding: 10px
        width: 90%
        &:not(:nth-of-type(3n+1))
          margin-left 0px
  &__white
    background: #fff
    border-radius: 8px
    padding: 30px
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3)
    margin: auto
    max-width: 700px
    margin: 0 auto 50px
    @media screen and (max-width 640px)
      padding: 10px
      width: 90%
    &_multiple
      background: #fff
      border-radius: 8px
      padding: 20px
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3)
      margin-top: 20px
      width: calc(976px / 3.15)
      @media screen and (max-width 640px)
        padding: 10px
        width: 90%
  &__title
    font-size: 22px
    font-weight: 500
    margin-bottom: 10px
    @media screen and (max-width 640px)
      font-size: 5.5vw
    &_multiple
      color: #000
      text-decoration: underline
      font-size: 18px
      margin-bottom: 10px
      display: block
      font-weight: 500
      padding: 0 1rem
      @media screen and (max-width 640px)
        font-size: 4.5vw;
  &__title01
    font-size: 22px
    font-weight: 500
    margin-bottom: 10px
    @media screen and (max-width 640px)
      font-size: 5.5vw
    &_multiple
      color: #000
      text-decoration: underline
      font-size: 18px
      display: block
      font-weight: 500
      padding: 0 1rem
      @media screen and (max-width 640px)
        font-size: 4.5vw;
  &__date
    font-size: 17px
    @media screen and (max-width 640px)
      font-size: 4.5vw
    &_multiple
      margin-bottom: 20px
  &__cancelLink
    color: #364563
    text-decoration: underline
    display: inline-block
    text-align: center
    position: relative
    transform: translateX(53%)
    &::after
      content: ''
      display: inline-block
      width: 6px
      height: 6px
      margin: auto
      border-top: 2px solid #23354f
      border-right: 2px solid #23354f
      position: absolute
      top: 1px
      right: -1rem
      bottom: 0
      transform: rotate(45deg)
  &__surveyButton
    background-color: #364563;
    border: 1px solid #364563;
    border-radius: 8px;
    box-shadow: 0px 2px 2px rgba(0 0 0 0.2);
    color: #FFF;
    cursor: pointer;
    font-size: 15px;
    padding: 10px
    width: 100%;
    &:hover
      opacity: 0.9








