/*
---
name: pd_m-breakdown-02
category: module
tag:
  - text
  - icon
  - btn
---
```html
<div class="pd_m-breakdown-02">
  <div class="pd_m-breakdown-02_head">
    <h1 class="pd_m-breakdown-02_heading">内訳</h1>
  </div>
  <div class="pd_m-breakdown-02_body-01">
    <div class="pd_m-breakdown-02_inner-01">
      <p class="pd_m-breakdown-02_text-01">商品代金(税込)</p><span class="pd_e-money-01">3,000円</span>
    </div>
    <div class="pd_m-breakdown-02_inner-01">
      <p class="pd_m-breakdown-02_text-01">初期費用(税込)</p><span class="pd_e-money-01">3,000円</span>
    </div>
    <div class="pd_m-breakdown-02_inner-01">
      <p class="pd_m-breakdown-02_text-01">月額請求(税込)</p><span class="pd_e-money-01">1,500円</span>
    </div>
    <div class="pd_m-breakdown-02_inner-01">
      <p class="pd_m-breakdown-02_text-01">年額請求(税込)</p><span class="pd_e-money-01">2,000円</span>
    </div>
    <div class="pd_m-breakdown-02_border"></div>
    <div class="pd_m-breakdown-02_inner-01">
      <p class="pd_m-breakdown-02_text-02">支払総額(税込)</p><span class="pd_e-money-02">56,000円</span>
    </div>
    <p class="pd_m-breakdown-02_note"><span>※</span>xxヶ月</p>
  </div>
  <div class="pd_m-breakdown-02_body-02">
    <button class="pd_c-btn-01 pd_e-hover-01" onclick=""><span>契約へ進む</span></button>
    <button class="pd_c-btn-04 pd_e-hover-01" onclick="" disabled><span>契約へ進む</span></button>
    <p class="pd_m-breakdown-02_link"></p><a class="pd_c-linkText-01 pd_e-hover-01" href="/">マイプランに保存する </a>
    <ul class="pd_c-notes-02">
      <li class="pd_c-notes-02_listItem pd_c-js_scrollTrigger"><span>※</span>解約時は、24ヶ月間どのタイミングにおいても解約手数料としてご利用料金の2ヶ月分相当の6,600円(税込)がかかります。</li>
    </ul>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-breakdown-02 {
    background-color #fff
    border-radius 8px
    @media screen and (min-width:MIN_POINT) {
        padding 15px
    }
    @media screen and (max-width:MAX_POINT) {
        padding 40px 20px 20px
    }
    &_heading {
        font-size 18px
        font-weight bold
        line-height (33/22)

    }
    &_text-01 {
        font-size 13px
        line-height (20/13)
        margin-top 16px
        &:first-child {
        margin-top 0
        }
    }
    &_text-02 {
        font-size 14px
        line-height (20/13)
        margin-top 16px
        &:first-child {
        margin-top 0
        }
    }
    &_note{
        color: rgba(0,0,0,0.6);
        font-size: 13px;
    }
    &_body-01 {
        margin-top 16px
        background #F2F2F2
        border-radius 8px
        padding 25px 10px

    }
    &_inner-01 {
        display flex
        justify-content: space-between;
        margin-bottom 10px
    }
    &_border {
        border-top 1px solid rgba(0,0,0,.2)
        padding-top 18px
    }
    &_body-02 {
        border-top solid 1px rgba(0,0,0,.2)
        margin-top 24px
        padding-top 24px
    }
    &_link {
        text-align center
        margin-top 24px
    }
    .pd_c-coin-01 {
        margin-top 24px
    }
    .pd_c-productPanelItem {
        margin-top 8px
    }
}
