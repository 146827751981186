/*
---
name: pd_c-attention
category: component
tag:
  - text
---
```html
    <div class="pd_c-attention">
        <p class="pd_c-attention_text1">キャンセルについて</p>
        <p class="pd_c-attention_text2">開催5日前の17:00までにお申し出があった場合は、キャンセル手続きを承ります。</p>
    </div>
```
*/
.pd_c-attention
    color: rgba(0,0,0,0.6)
    font-size: 13px
    margin: 40px auto 0
    padding-left: 16px
    position: relative
    width: 975px
    &::after
        content: "※"
        display: block
        position: absolute
        top: 0
        left: 0
@media screen and (max-width 640px)
    .pd_c-attention
        width: 92%
