/*
---
name: pd_b-flowStep2
category: block
tag:
  - text
  - icon
  - btn
```html
<div class="pd_b-flowStep2">
    <div class="pd_b-flowStep2_inner">
        <h1 class="pd_m-contentTtl-01">参加内容/アンケートの確認</h1>
        <div class="pd_m-eventBnr">
            <p class="pd_m-eventBnr_ttl">スマートコーヒー焙煎サービス「The Roast」体験会</p>
            <div class="pd_m-eventBnr_btm">
                <p class="pd_m-eventBnr_date">4月28日14:30～15:00</p>
                <div class="pd_m-eventBnr_price"><span class="pd_m-eventBnr_priceAbout">参加費</span><span class="pd_m-eventBnr_priceTotal">合計</span><span class="pd_m-eventBnr_priceNum">1,000</span><span class="pd_m-eventBnr_priceYen">円</span><span class="pd_m-eventBnr_priceTax">(税込)</span></div>
            </div>
        </div>
        <p class="pd_b-flowStep2_attention">以下の内容でよろしければ、「次へ」ボタンを押してください。</p>
        <ul class="pd_m-confFlow">
            <li class="pd_m-confFlow_list">
                <p class="pd_m-confFlow_title">1. 今回のイベントをどうやってお知りになりましたか？</p>
                <p class="pd_m-confFlow_text">友人からのすすめ</p>
            </li>
            <li class="pd_m-confFlow_list">
                <p class="pd_m-confFlow_title">2. 質問文が入ります</p>
                <p class="pd_m-confFlow_text">選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります</p>
            </li>
            <li class="pd_m-confFlow_list">
                <p class="pd_m-confFlow_title">3. 質問文が入ります</p>
                <p class="pd_m-confFlow_text">選択された内容テキストが入ります</p>
            </li>
            <li class="pd_m-confFlow_list">
                <p class="pd_m-confFlow_title">4. 質問文が入ります</p>
                <p class="pd_m-confFlow_text">選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります選択された内容テキストが入ります</p>
            </li>
        </ul>
    </div>
    <div class="pd_c-attention">
        <p class="pd_c-attention_text1">キャンセルについて</p>
        <p class="pd_c-attention_text2">開催5日前の17:00までにお申し出があった場合は、キャンセル手続きを承ります。</p>
    </div>
    <div class="pd_m-submit-01">
        <div class="pd_m-submit-01_row">
            <div class="pd_m-submit-01_col-01">
                <input class="pd_c-filterButton pd_c-filterButton-04 pd_e-hover-01 pd_e-hover-01--bgGray" type="reset" value="修正する" />
            </div>
            <div class="pd_m-submit-01_col-02">
                <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                    <p class="pd_c-filterButton_text">次へ</p>
                </button>
            </div>
        </div>
    </div>
</div>
```
*/
.pd_b-flowStep2
    margin: 0 auto 60px
    &_inner
        margin: 0 auto
        width: 976px
    &_attention
        margin: 39px 0 0 0;
        letter-spacing: 1px;
        padding: 0 0 40px
    .pd_m-submit-01
        border-top: 0px
        padding-top: 0
        &_row
            margin 20px auto 0
            width: 730px
@media screen and (max-width 640px)
    .pd_b-flowStep2
        &_inner
            width: 92%
        .pd_m-contentTtl-01
            margin: 34px auto 18px
            padding: 0
        &_attention
            margin-bottom: 5px
        .pd_m-submit-01
            border-top: 0px
            padding-top: 0
            &_row
                display: flex
                flex-direction: column
                margin 18px auto 0
                width: 92%
                &_col-01
                    width: 100%
                &_col-02
                    width: 100%