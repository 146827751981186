/*
---
name: pd_m-eventsRequest
category: module
tag:
  - text
  - btn
  - calendar
---
```html
<div class="pd_m-eventsRequest" id="eventsRequest">
                      <div class="pd_m-requestTop">
                                                <p class="pd_c-eventsSubTitle">イベント申込</p>
                        <div class="pd_m-requestTop_panelWrap">
                                                    <div class="pd_m-requestPanel pd_m-requestPanel01">
                                                      <ul class="pd_m-requestPanel_textList">
                                                        <li class="pd_m-requestPanel_textListItem">
                                                          <p class="pd_m-requestPanel_textListTtl">開催日：</p>
                                                          <p class="pd_m-requestPanel_textListText">4月18日（土）</p>
                                                        </li>
                                                      </ul>
                                                      <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                                        <p class="pd_c-filterButton_text">申し込む</p>
                                                      </button>
                                                    </div>
                                                    
                                                    <div class="pd_m-requestPanel pd_m-requestPanel01">
                                                      <ul class="pd_m-requestPanel_textList">
                                                        <li class="pd_m-requestPanel_textListItem">
                                                          <p class="pd_m-requestPanel_textListTtl">開催日：</p>
                                                          <p class="pd_m-requestPanel_textListText">4月18日（日）</p>
                                                        </li>
                                                      </ul>
                                                                                  <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                                                                    <p class="pd_c-filterButton_text">申し込む</p>
                                                                                  </button>
                                                    </div>
                                                    <div class="pd_m-requestPanel pd_m-requestPanel01">
                                                      <ul class="pd_m-requestPanel_textList">
                                                        <li class="pd_m-requestPanel_textListItem">
                                                          <p class="pd_m-requestPanel_textListTtl">開催日：</p>
                                                          <p class="pd_m-requestPanel_textListText">4月18日（月）</p>
                                                        </li>
                                                      </ul>
                                                                                  <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                                                                    <p class="pd_c-filterButton_text">申し込む</p>
                                                                                  </button>
                                                    </div>
                                                    <div class="pd_m-requestPanel pd_no-shadow pd_m-requestPanel01">
                                                      <button class="pd_c-filterButton pd_c-filterButton-03 pd_e-hover-01">
                                                        <p class="pd_c-filterButton_text">申し込む</p>
                                                      </button>
                                                    </div>
                        </div>
                      </div>
                    </div>
```
*/
.pd_m-eventsRequest
    margin-top: 50px
    &_reserved
        background-color: #dee3ed
        border-radius: 8px
        margin-top: 25px
        padding: 30px 0
        .pd_c-linkText-01
            display: inline-block
            text-align: center
            margin: 11px 13px 0
    &_reservedTtl
        font-size: 17px
        font-weight: 500
        text-align: center
    &_reservedText
        display: inline-block
        font-size: 13px
        margin-top: 0px
        margin-left: 302px
        text-align: center
@media screen and (max-width 640px)
    .pd_m-eventsRequest
        margin-top: 57px
        &_reserved
            margin-top: 13px
            padding: 20px 0
            .pd_c-linkText-01
                display: table
                margin: 14px auto 0
        &_reservedText
            display: block
            margin-top: 10px
            margin-left: 0