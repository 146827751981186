/*
---
name: pd_m-eventsSimultaneous
category: module
tag:
  - text
  - img
  - icon
  - tag
---
```html
<div class="pd_m-eventsSimultaneous">
    <div class="pd_m-eventsSimultaneous_ttl">
        <p class="pd_c-eventsSubTitle">同時期に開催されているイベント</p>
    </div>
    <ul class="pd_m-eventsSimultaneous_list">
        <li class="pd_m-eventsSimultaneous_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_m-eventsSimultaneous_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_m-eventsSimultaneous_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_m-eventsSimultaneous_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
    </ul><a class="pd_c-btn-02 pd_e-hover-01 pd_e-hover-01--bgGray" href="#">くらしに役立つ各種イベントトップに戻る</a>
</div>
```
*/
.pd_m-eventsSimultaneous
    margin-top: 60px
    padding-top: 18px
    &_ttl
        cursor pointer
        padding-bottom: 20px
        position: relative
    .pd_m-smallBox
        margin-right:0
        margin-left:0
    // &_inner
    //     display: none
    &_list
        display: flex
        justify-content: space-between
    .pd_c-btn-02
        margin: 0 auto
        width: 345px
@media screen and (max-width 640px)
    .pd_m-eventsSimultaneous
        padding-top: 11px
        // &_ttl
        //     &::after
        // .pd_m-smallBox
        // &_inner
        &_list
            flex-wrap: wrap
        // &_listItem
        //     width: 140px