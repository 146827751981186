/*
--
name: pd_e-planView
category: element
tag:
  - text
---
```html
<span class="pd_e-planView">説明が入ります。説明が入ります。説明が入ります。説明が入ります。</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-planView {
    display inline-block
    font-size 15px
}