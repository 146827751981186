/*
---
name: pd_b-detail-03
category: block
tag:
  - text
  - img
  - btn
---
```html
<div class="pd_b-detail-03">
    <div class="pd_b-detail-03_row">
        <div class="pd_b-detail-03_leftCol">
            <figure class="pd_b-detail-03_figure"><img src="http://placehold.jp/3d4070/ffffff/1500x1000.png" alt="" /></figure>
            <ul class="pd_b-detail-03_thumbnails">
                <li class="pd_b-detail-03_thumbnail">
                    <div class="pd_b-detail-03_thumbnailImg"><img src="http://placehold.jp/ffffff/000000/500x500.png?text=サムネイル" alt="" /></div>
                </li>
                <li class="pd_b-detail-03_thumbnail">
                    <div class="pd_b-detail-03_thumbnailImg"><img src="http://placehold.jp/ffffff/000000/500x500.png?text=サムネイル" alt="" /></div>
                </li>
            </ul>
        </div>
        <div class="pd_b-detail-03_rightCol">
            <div class="pd_b-detail-03_right">
                <button class="pd_c-favoriteBtn-01 pd_e-hover-01 pd_e-hover-01--bgGray"><span class="pd_c-favoriteBtn-01_text-01">お気に入りに追加</span><span class="pd_c-favoriteBtn-01_text-02">98</span></button>
            </div>
            <p class="pd_b-detail-03_text-01">商品説明が入ります商品説明が入ります商品説明が入ります商品説明が入ります商品説明が入ります商品説明が入ります商品説明が入ります商品説明が入ります商品説明が入ります商品説明が入ります商品説明が入ります商品説明が入ります商品説明が入ります</p>
            <ul class="pd_b-detail-03_list">
                <li class="pd_b-detail-03_listItem"><span>・</span>お申し込み後、本人確認書類のアップロードが必要です。<br />本人確認ができましたら、契約成立となります。 </li>
                <li class="pd_b-detail-03_listItem"><span>・</span>商品の発送は、契約成立後、1週間から場合により2週間前後かかることがあります。</li>
            </ul>
        </div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_b-detail-03 {
  @media screen and (min-width:MIN_POINT) {
    margin-top: 35px;
  }
  @media screen and (max-width:MAX_POINT) {
    margin-top: 32px;
  }
  &_row {
    @media screen and (min-width:MIN_POINT) {
      display flex
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_leftCol {
    @media screen and (min-width:MIN_POINT) {
      width 664px
      padding-right 20px
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_rightCol {
    color rgba(0,0,0,.9)
    font-size 15px
    line-height (23/15)
    @media screen and (min-width:MIN_POINT) {
      width 312px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 24px
    }
  }
  &_thumbnails {
    display flex
    @media screen and (min-width:MIN_POINT) {
      margin 4px -10px 0
    }
    @media screen and (max-width:MAX_POINT) {
      margin 4px -7.5px 0
    }
  }
  &_thumbnail {
    @media screen and (min-width:MIN_POINT) {
      width 166px
      padding 20px 10px 0
    }
    @media screen and (max-width:MAX_POINT) {
      width 50%
      padding 15px 7.5px 0
    }
  }
  &_thumbnailImg {
    border solid 1px rgba(0,0,0,.2)
    img {
      display block
    }
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_right {
    text-align right
  }
  &_text-01 {
    margin-top 24px
    pre {
      white-space pre-wrap
    }
  }
  &_list {
    margin-top 17px
  }
  &_listItem {
    padding-left 1.2em
    >span {
      display inline-block
      width 1.2em
      margin-left -1.2em
    }
  }
}
