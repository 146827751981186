/*
---
name: pd_m-eventsProduct01
category: module
tag:
  - text
  - img
  - tag
---
```html
<div class="pd_m-eventsProduct01">
                                            <p class="pd_c-eventsSubTitle">イベントで使用する商品</p>
                      <ul class="pd_m-eventsProduct01_list">
                        <li class="pd_m-eventsProduct01_listItem"><a class="pd_m-smallBox" href="/">
                                                        <div class="pd_m-smallBoxItem">
                                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                                          <div class="pd_m-smallBoxItem_mid">
                                                            <p class="pd_m-smallBoxItem_kind">コーヒー焙煎機</p>
                                                          </div>
                                                          <p class="pd_m-smallBoxItem_ttl">The Roast</p>
                                                        </div></a></li>
                        <li class="pd_m-eventsProduct01_listItem"><a class="pd_m-smallBox" href="/">
                                                        <div class="pd_m-smallBoxItem">
                                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                                          <div class="pd_m-smallBoxItem_mid">
                                                            <p class="pd_m-smallBoxItem_kind">消耗品・付属品</p>
                                                          </div>
                                                          <p class="pd_m-smallBoxItem_ttl">スマート コーヒー焙煎機 ガラス容器</p>
                                                        </div></a></li>
                        <li class="pd_m-eventsProduct01_listItem"><a class="pd_m-smallBox" href="/">
                                                        <div class="pd_m-smallBoxItem">
                                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                                          <div class="pd_m-smallBoxItem_mid">
                                                            <p class="pd_m-smallBoxItem_kind">消耗品・付属品</p>
                                                          </div>
                                                          <p class="pd_m-smallBoxItem_ttl">エチオピア カフェインレス</p>
                                                        </div></a></li>
                        <li class="pd_m-eventsProduct01_listItem"><a class="pd_m-smallBox" href="/">
                                                        <div class="pd_m-smallBoxItem">
                                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像"></div>
                                                          <div class="pd_m-smallBoxItem_mid">
                                                            <p class="pd_m-smallBoxItem_kind">消耗品・付属品</p>
                                                          </div>
                                                          <p class="pd_m-smallBoxItem_ttl">コロンビア カフェインレス</p>
                                                        </div></a></li>
                      </ul>
                    </div>
```
*/
.pd_m-eventsProduct01
    margin 50px auto
    &_Ttl
        font-size: 30px
        font-weight: 500
        text-align: center
    &_list
        margin-top: 20px
        position: relative
        display flex
        flex-wrap: wrap;
        justify-content: space-between;
        .pd_m-smallBox
            width: 225px
            padding-bottom: 0
            margin: 0
        .pd_m-smallBoxItem_img
            height: 225px
            width: 225px
            border 1px solid #c2c2c2
            img
                max-width: 225px
        .pd_m-smallBoxItem_ttl
            font-size: 15px
            margin-top: 6px
        .pd_c-tagListItem
            padding 0 9px
        .pd_c-productPanelItem_modelNum
            margin-top: 10px
        .pd_c-productPanelItem_price
            font-size: 17px
            margin-top: 10px
        .pd_c-productPanelItem_stock
            margin-top: 5px
        .pd_c-productPanelItem_price-bar
            font-size: 15px
        .pd_c-productPanelItem_discountPrice
            font-size: 12px
        .pd_c-productPanelItem_discountPriceTax
            font-size: 12px
    &_listItem
        padding: 0
    
@media screen and (max-width 640px)
    .pd_m-eventsProduct
        margin 57px auto
        // overflow: hidden
        // padding: 0 0 0 4vw
        width: 100%
        &_Ttl
            font-size: 22px
            padding-right: 18px;
        &_list
            margin-top: 16px
            width: 780px
            // .pd_m-smallBox_Wrap
            .pd_m-smallBox
                width: 140px
            .pd_m-smallBoxItem_img
                height: 140px
                width: 140px
                img
                    max-width: 140px
        &_listItem
            padding: 0