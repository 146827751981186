/*
---
name: pd_c-emphasis-01
category: component
tag:
  - text
---
```html
  <div class="pd_c-emphasis-01">
      <div class="pd_c-emphasis-01_head">
          <h2 class="pd_c-emphasis-01_heading">ご購入前に必ずご確認ください</h2>
      </div>
      <div class="pd_c-emphasis-01_body">
          <ul class="pd_c-emphasis-01_list">
              <li class="pd_c-emphasis-01_listItem"><a class="pd_c-emphasis-01_anchor pd_e-hover-01" href="#">工事空き状況ご確認</a></li>
              <li class="pd_c-emphasis-01_listItem"><a class="pd_c-emphasis-01_anchor pd_e-hover-01" href="#">取付工事の詳細について</a></li>
              <li class="pd_c-emphasis-01_listItem"><a class="pd_c-emphasis-01_anchor pd_e-hover-01" href="#">リサイクル回収について</a></li>
          </ul>
          <ul class="pd_c-emphasis-01_notes">
              <li class="pd_c-emphasis-01_notesItem"><span>※</span>SGムービング（株）の配送となります。配送日については、お客様のお近くの拠点に商品が届きましたら、電話にてご連絡いたします。目安は出荷ご連絡メールから５～６日前後となります。</li>
          </ul>
      </div>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-emphasis-01 {
  border: solid 1px #007BFF;
  border-radius: 8px;
  @media screen and (min-width:MIN_POINT) {
    padding 30px
  }
  @media screen and (max-width:MAX_POINT) {
    padding 20px
  }
  &--center {
    @media screen and (min-width:MIN_POINT) {
      max-width: 980px
      margin-right auto
      margin-left auto
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right 15px
      margin-left 15px
    }
  }
  &_heading {
    color:#007BFF;
    font-size: 17px;
    font-weight 500
  }
  &_body {
    font-size 15px
    margin-top 24px
  }

  &_list {
    @media screen and (min-width:MIN_POINT) {
      &::after {
        content ''
        display block
        clear both
      }
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }

  &_listItem {
    @media screen and (min-width:MIN_POINT) {
      float left
    }
    &+& {
      @media screen and (min-width:MIN_POINT) {
        margin-left 32px
      }
      @media screen and (max-width:MAX_POINT) {
        margin-top 16px
      }
    }
  }

  &_anchor {
    display: inline-block;
    position relative;
    padding-right 13px;
    &:link,
    &:hover,
    &:visited,
    &:visited:hover,
    &:active,
    &:focus {
      color: #364563;
      text-decoration: underline;
    }
    &::after {
      content: '';
      position absolute;
      top: 50%;
      right: 0
      transform: translateY(-50%);
      background: url("/store/images/component/emphasis-01_icon_arrow_r.svg")
      background-size: 100% auto;
      width: 8px;
      height: 13px;
    }
  }

  &_notes {
    font-size 13px
    color rgba(0,0,0,.6)
    margin-top 16px
  }

  &_notesItem {
    > span {
      display: inline-block;
      width: 1.2em;
      margin-left -1.2em;
    }
    padding-left: 1.2em;
  }
  &_importantList {
    margin-top -24px
  }
  &_importantListItem {
    border-bottom: 1px solid #CCCCCC
    display: flex
    padding: 15px 0
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
      flex-wrap: wrap
      padding: 8px 0 15px
    }
    &:last-child {
      border 0px
    }
  }
  &_importantDate {
    color rgba(0,0,0,0.54)
    @media screen and (min-width:MIN_POINT) {
      font-size: 15px
      width: 112px
    }
    @media screen and (max-width:MAX_POINT) {
      font-size: 13px
      width: 100%
    }
  }
  &_importantLink {
    color #364563
    font-size: 15px
    margin-left: 55px
    text-decoration: underline
    @media screen and (min-width:MIN_POINT) {
      width 745px
    }
    @media screen and (max-width:MAX_POINT) {
      margin: 8px 0 0 0;
      line-height: 1.5;
      width: 100%
    }
  }
  &_importantText {
    color #364563
    font-size: 15px
    margin-left: 55px
    @media screen and (min-width:MIN_POINT) {
      width 745px
    }
    @media screen and (max-width:MAX_POINT) {
      margin: 8px 0 0 0;
      line-height: 1.5;
      width: 100%
    }
  }

}
