/*
---
name: pd_m-pageHistory
category: module
tag:
  - text
  - img
  - icon
  - tag
---
```html
<div class="pd_m-pageHistory">
                                  <p class="pd_m-pageHistory_Ttl">最近チェックしたページ</p>
                                  <ul class="pd_m-pageHistory_slider pd_m-pageHistory_list">
                                    <li class="pd_m-productHistory_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                          <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">記事</p>
                                            <div class="pd_m-smallBoxItem_favoriteWrap">
                                              <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                            </div>
                                          </div>
                                                                    <ul class="pd_c-smallTag_bkg_gray">
                                                                      <li class="pd_c-smallTag-gray">受付中</li>
                                                                      <li class="pd_c-smallTag-gray">定額</li>
                                                                      <li class="pd_c-smallTag-gray">近日発売</li>
                                                                      <li class="pd_c-smallTag-gray">セット商品</li>
                                                                      <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                    </ul>
                                                                    <ul class="pd_c-smallTag_bkg_orange">
                                                                      <li class="pd_c-smallTag-orange">NEW</li>
                                                                      <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                      <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                      <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">予約商品</li>
                                                                      <li class="pd_c-smallTag-orange">特別価格</li>
                                                                    </ul>
                                          <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                        </div>
                                        <div class="pd_c-dateArea">
                                          <p class="pd_c-dateArea_date">2021年04月15日</p>
                                          <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                          <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                        </div>
                                        <ul class="pd_c-tagList">
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                            <object><a href="/test.html">#快適な空間づくり</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test2.html">#家事・暮らし</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test3.html">#タグ</a></object>
                                          </li>
                                        </ul></a></li>
                                    <li class="pd_m-productHistory_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                          <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">記事</p>
                                            <div class="pd_m-smallBoxItem_favoriteWrap">
                                              <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                            </div>
                                          </div>
                                                                    <ul class="pd_c-smallTag_bkg_gray">
                                                                      <li class="pd_c-smallTag-gray">受付中</li>
                                                                      <li class="pd_c-smallTag-gray">定額</li>
                                                                      <li class="pd_c-smallTag-gray">近日発売</li>
                                                                      <li class="pd_c-smallTag-gray">セット商品</li>
                                                                      <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                    </ul>
                                                                    <ul class="pd_c-smallTag_bkg_orange">
                                                                      <li class="pd_c-smallTag-orange">NEW</li>
                                                                      <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                      <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                      <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">予約商品</li>
                                                                      <li class="pd_c-smallTag-orange">特別価格</li>
                                                                    </ul>
                                          <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                        </div>
                                        <div class="pd_c-dateArea">
                                          <p class="pd_c-dateArea_date">2021年04月15日</p>
                                          <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                          <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                        </div>
                                        <ul class="pd_c-tagList">
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                            <object><a href="/test.html">#快適な空間づくり</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test2.html">#家事・暮らし</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test3.html">#タグ</a></object>
                                          </li>
                                        </ul></a></li>
                                    <li class="pd_m-productHistory_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                          <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">記事</p>
                                            <div class="pd_m-smallBoxItem_favoriteWrap">
                                              <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                            </div>
                                          </div>
                                                                    <ul class="pd_c-smallTag_bkg_gray">
                                                                      <li class="pd_c-smallTag-gray">受付中</li>
                                                                      <li class="pd_c-smallTag-gray">定額</li>
                                                                      <li class="pd_c-smallTag-gray">近日発売</li>
                                                                      <li class="pd_c-smallTag-gray">セット商品</li>
                                                                      <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                    </ul>
                                                                    <ul class="pd_c-smallTag_bkg_orange">
                                                                      <li class="pd_c-smallTag-orange">NEW</li>
                                                                      <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                      <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                      <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">予約商品</li>
                                                                      <li class="pd_c-smallTag-orange">特別価格</li>
                                                                    </ul>
                                          <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                        </div>
                                        <div class="pd_c-dateArea">
                                          <p class="pd_c-dateArea_date">2021年04月15日</p>
                                          <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                          <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                        </div>
                                        <ul class="pd_c-tagList">
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                            <object><a href="/test.html">#快適な空間づくり</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test2.html">#家事・暮らし</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test3.html">#タグ</a></object>
                                          </li>
                                        </ul></a></li>
                                    <li class="pd_m-productHistory_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                          <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">記事</p>
                                            <div class="pd_m-smallBoxItem_favoriteWrap">
                                              <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                            </div>
                                          </div>
                                                                    <ul class="pd_c-smallTag_bkg_gray">
                                                                      <li class="pd_c-smallTag-gray">受付中</li>
                                                                      <li class="pd_c-smallTag-gray">定額</li>
                                                                      <li class="pd_c-smallTag-gray">近日発売</li>
                                                                      <li class="pd_c-smallTag-gray">セット商品</li>
                                                                      <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                    </ul>
                                                                    <ul class="pd_c-smallTag_bkg_orange">
                                                                      <li class="pd_c-smallTag-orange">NEW</li>
                                                                      <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                      <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                      <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">予約商品</li>
                                                                      <li class="pd_c-smallTag-orange">特別価格</li>
                                                                    </ul>
                                          <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                        </div>
                                        <div class="pd_c-dateArea">
                                          <p class="pd_c-dateArea_date">2021年04月15日</p>
                                          <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                          <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                        </div>
                                        <ul class="pd_c-tagList">
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                            <object><a href="/test.html">#快適な空間づくり</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test2.html">#家事・暮らし</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test3.html">#タグ</a></object>
                                          </li>
                                        </ul></a></li>
                                    <li class="pd_m-productHistory_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                          <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">記事</p>
                                            <div class="pd_m-smallBoxItem_favoriteWrap">
                                              <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                            </div>
                                          </div>
                                                                    <ul class="pd_c-smallTag_bkg_gray">
                                                                      <li class="pd_c-smallTag-gray">受付中</li>
                                                                      <li class="pd_c-smallTag-gray">定額</li>
                                                                      <li class="pd_c-smallTag-gray">近日発売</li>
                                                                      <li class="pd_c-smallTag-gray">セット商品</li>
                                                                      <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                    </ul>
                                                                    <ul class="pd_c-smallTag_bkg_orange">
                                                                      <li class="pd_c-smallTag-orange">NEW</li>
                                                                      <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                      <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                      <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">予約商品</li>
                                                                      <li class="pd_c-smallTag-orange">特別価格</li>
                                                                    </ul>
                                          <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                        </div>
                                        <div class="pd_c-dateArea">
                                          <p class="pd_c-dateArea_date">2021年04月15日</p>
                                          <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                          <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                        </div>
                                        <ul class="pd_c-tagList">
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                            <object><a href="/test.html">#快適な空間づくり</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test2.html">#家事・暮らし</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test3.html">#タグ</a></object>
                                          </li>
                                        </ul></a></li>
                                    <li class="pd_m-productHistory_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                          <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">記事</p>
                                            <div class="pd_m-smallBoxItem_favoriteWrap">
                                              <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                            </div>
                                          </div>
                                                                    <ul class="pd_c-smallTag_bkg_gray">
                                                                      <li class="pd_c-smallTag-gray">受付中</li>
                                                                      <li class="pd_c-smallTag-gray">定額</li>
                                                                      <li class="pd_c-smallTag-gray">近日発売</li>
                                                                      <li class="pd_c-smallTag-gray">セット商品</li>
                                                                      <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                    </ul>
                                                                    <ul class="pd_c-smallTag_bkg_orange">
                                                                      <li class="pd_c-smallTag-orange">NEW</li>
                                                                      <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                      <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                      <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">予約商品</li>
                                                                      <li class="pd_c-smallTag-orange">特別価格</li>
                                                                    </ul>
                                          <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                        </div>
                                        <div class="pd_c-dateArea">
                                          <p class="pd_c-dateArea_date">2021年04月15日</p>
                                          <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                          <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                        </div>
                                        <ul class="pd_c-tagList">
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                            <object><a href="/test.html">#快適な空間づくり</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test2.html">#家事・暮らし</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test3.html">#タグ</a></object>
                                          </li>
                                        </ul></a></li>
                                    <li class="pd_m-productHistory_listItem"><a class="pd_m-smallBox" href="/">
                                        <div class="pd_m-smallBoxItem">
                                          <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x900.png" alt="商品画像"></div>
                                          <div class="pd_m-smallBoxItem_mid">
                                            <p class="pd_m-smallBoxItem_kind">記事</p>
                                            <div class="pd_m-smallBoxItem_favoriteWrap">
                                              <object class="pd_m-smallBoxItem_favorite pd_js-favoriteBtn"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加"><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加"></object>
                                            </div>
                                          </div>
                                                                    <ul class="pd_c-smallTag_bkg_gray">
                                                                      <li class="pd_c-smallTag-gray">受付中</li>
                                                                      <li class="pd_c-smallTag-gray">定額</li>
                                                                      <li class="pd_c-smallTag-gray">近日発売</li>
                                                                      <li class="pd_c-smallTag-gray">セット商品</li>
                                                                      <li class="pd_c-smallTag-gray">軽減税率対象</li>
                                                                    </ul>
                                                                    <ul class="pd_c-smallTag_bkg_orange">
                                                                      <li class="pd_c-smallTag-orange">NEW</li>
                                                                      <li class="pd_c-smallTag-orange">SALE 40%OFF</li>
                                                                      <li class="pd_c-smallTag-orange">1000コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">プレゼント付</li>
                                                                      <li class="pd_c-smallTag-orange">2倍コイン進呈</li>
                                                                      <li class="pd_c-smallTag-orange">予約商品</li>
                                                                      <li class="pd_c-smallTag-orange">特別価格</li>
                                                                    </ul>
                                          <p class="pd_m-smallBoxItem_ttl">イベントタイトル</p>
                                        </div>
                                        <div class="pd_c-dateArea">
                                          <p class="pd_c-dateArea_date">2021年04月15日</p>
                                          <p class="pd_c-dateArea_text">RELIFE STUDIO FUTAKO</p>
                                          <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                                        </div>
                                        <ul class="pd_c-tagList">
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray"> 
                                            <object><a href="/test.html">#快適な空間づくり</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test2.html">#家事・暮らし</a></object>
                                          </li>
                                          <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                                            <object><a href="/test3.html">#タグ</a></object>
                                          </li>
                                        </ul></a></li>
                                  </ul>
                                </div>
```
*/
.pd_m-pageHistory
    margin 50px auto
    width: 995px
    &_Ttl
        font-size: 30px
        font-weight: 500
        text-align: center
    &_list
        margin-top: 15px
        position: relative
        .pd_m-smallBox
            width: 179px
            padding-bottom: 0
            margin: 0
        .pd_m-smallBoxItem_img
            height: 179px
            width: 179px
            img
                max-width: 179px
        .pd_m-smallBoxItem_ttl
            font-size: 15px
            margin-top: 6px
        .pd_c-tagListItem
            padding 0 9px
        .pd_c-productPanelItem_modelNum
            margin-top: 10px
        .pd_c-productPanelItem_price
            font-size: 17px
            margin-top: 10px
        .pd_c-productPanelItem_stock
            margin-top: 5px
        .pd_c-productPanelItem_discountPrice
            font-size: 12px
        .pd_c-productPanelItem_discountPriceTax
            font-size: 12px
    &_listItem
        padding: 0 10px
    &_Arrow
        background-repeat: no-repeat
        background-position: center center
        background-color: #fff
        border: 0px
        border-radius: 50%
        box-shadow: 2px 2px 6px rgba(0 0 0 10%);
        height: 40px
        position: absolute
        top:89.5px
        transform: translateY(-50%)
        width: 40px
        z-index 1
        &:hover {
          background-color #F2F2F2
        }
    &_prevArrow
        background-image: url('/store/images/module/recently_checked_prev.svg')
        box-shadow: -2px 2px 6px rgba(0 0 0 10%);
        left: -10px
    &_nextArrow
        background-image: url('/store/images/module/recently_checked_next.svg')
        right: -10px
        @media screen and (max-width 640px){
            right: auto;
            left: 80vw;
        }
    .slick-prev.slick-disabled,
    .slick-next.slick-disabled
        opacity 0

@media screen and (max-width 640px)
    .pd_m-pageHistory
        overflow: hidden
        width: 100%
        &_Ttl
            font-size: 22px
            padding-right: 18px;
        &_list
            width: 100%
            // .pd_m-smallBox_Wrap
            .pd_m-smallBox
                width: 140px
                margin 0 auto
            .pd_m-smallBoxItem_img
                height: 140px
                width: 140px
                img
                    max-width: 140px
        &_listItem
            padding: 0
@media screen and (min-width 641px) and (max-width 980px)
    .pd_m-pageHistory_prevArrow
        left: 15px
@media screen and (min-width 641px) and (max-width 980px)
    .pd_m-pageHistory_nextArrow
        right: 15px
