/*
---
name: pd_c-title
category: component
tag:
  - text
---
```html
  <p class="pd_c-title">1. 質問　　</p>
```
*/
.pd_c-title
    font-size: 17px
    font-weight: 500

    &_small
        font-size: 13px
