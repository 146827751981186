/*
---
name: pd_m-searchResultTagList
category: module
tag:
  - text
  - tag
---
```html
<div class="pd_m-searchResultTagList">
  <div class="pd_m-searchResultTagList_ttl">#ペットに関する情報</div>
  <ul class="pd_c-tagList">
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray">
      <object><a href="/text1.html"> #ペットのくらし</a></object>
    </li>
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray">
      <object><a href="/text2.html"> #ペットの毛</a></object>
    </li>
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray">
      <object><a href="/text3.html"> #ペットカメラ</a></object>
    </li>
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray">
      <object><a href="/text4.html"> #安心</a></object>
    </li>
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray">
      <object><a href="/text5.html"> #掃除</a></object>
    </li>
    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgGray">
      <object><a href="/text6.html"> #簡単に掃除したい</a></object>
    </li>
  </ul>
</div>
```
*/
.pd_m-searchResultTagList
    border: 1px solid rgba(0,0,0,0.2)
    border-radius: 8px
    margin: 50px auto 0
    padding: 30px
    width: 976px
    &_ttl
        font-size: 17px
        font-weight: 500
@media screen and (max-width 640px)
    .pd_m-searchResultTagList
        margin: 20px auto 0
        padding: 20px
        width: 92%
        // &_ttl
        .pd_c-tagList
            margin-top: 5px
        .pd_c-tagList_item
            margin-top: 8px