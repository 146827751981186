/*
---
name: pd_c-eventsSubTitle
category: component
tag:
  - text
---
```html
    <p class="pd_c-eventsSubTitle">イベント概要</p>
```
*/
.pd_c-eventsSubTitle
    font-size: 30px
    font-weight: 500
    line-height: 1.5
    text-align: center
    box-sizing: border-box
@media screen and ( max-width 640px)
    .pd_c-eventsSubTitle
        font-size: 22px