/*
---
name: pd_e-planTag-02
category: element
tag:
  - tag
---
```html
<span class="pd_e-planTag-02">在庫なし</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-planTag-02 {
    display inline-block
    background-color #cccccc
    border-radius 4px
    margin-top 10px
    padding 8px 7px
    line-height 1
    font-size 13px
    color #fff
}