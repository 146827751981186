/*
---
name: pd_m-eventsAccess
category: module
tag:
  - text
  - img
  - icon
---
```html
<div class="pd_m-eventsAccess">
                                            <p class="pd_c-eventsSubTitle">アクセス</p>
                      <div class="pd_m-eventsAccess_inner">
                        <div class="pd_m-eventsAccess_itemWrap">
                          <div class="pd_m-eventsAccess_googleMap"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.6744055431823!2d139.62746431561072!3d35.6110973414219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f43f861e61d7%3A0xdcf3f93c46fd055f!2z44Oq44Op44Kk44OV44K544K_44K444KqIOODleOCv-OCsw!5e0!3m2!1sja!2sjp!4v1621936179181!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe></div>
                          <ul class="pd_m-eventsAccess_textList">
                            <li class="pd_m-eventsAccess_textListItem"> 
                              <p>〒158-0094</p>
                            </li>
                            <li class="pd_m-eventsAccess_textListItem"> 
                              <p>東京都世田谷区玉川1丁目14番1号<br>二子玉川ライズS.C.テラスマーケット 二子玉川 蔦屋家電 2階</p>
                            </li>
                            <li class="pd_m-eventsAccess_textListItem"> 
                              <p class="pd_m-eventsAccess_textListItem-01">交通：</p><span>東急電鉄 大井町・田園都市線【二子玉川駅】より徒歩4分</span>
                            </li>
                            <li class="pd_m-eventsAccess_textListItem"> 
                              <p>TEL：</p><span>03-5717-9545</span>
                            </li>
                            <li class="pd_m-eventsAccess_textListItem"> 
                              <p>休業日：</p><span>1月1日</span>
                            </li>
                          </ul>
                        </div>
                        <div class="pd_m-eventsAccess_itemWrap">
                          <div class="pd_m-eventsAccess_mapPdf"><img src="com/images/module/eventsAccess_map.png" alt=""></div><a class="pd_c-pdfLink pd_e-hover-01" href="/">PDFファイル:蔦屋家電内マップはこちら</a>
                          <div class="pd_m-eventsAccess_text">
                            <p>
                              蔦屋家電2F<br>館内エスカレーターを上がって右手にお進みください。</p>
                          </div>
                        </div>
                      </div>
                    </div>
```
*/
.pd_m-eventsAccess
    &_inner
        display: flex
        justify-content: space-between
        margin-top: 16px
    &_itemWrap
        width: 478px
    &_googleMap
        border: 1px solid #C2C3C3
        height: 358px
        iframe
            display: block
            height: 356px
            width: 100%
    &_textList
        margin-top: 25px;
    &_textListItem
        display: flex
    &_mapPdf
        height: 358px
        width: 100%
        img
            display: block
    .pd_c-pdfLink
        display: block
        margin-top: 25px
    &_text
        margin-top: 15px
    span
        display: inline-block
@media screen and (max-width 640px)
    .pd_m-eventsAccess
        &_inner
            display: block
        &_itemWrap
            margin-bottom: 42px
            width: 100%
        &_googleMap
            height: 68.8vw
            width: 100%
            iframe
                height: 68.8vw
        &_textList
            margin-top: 24px
        // &_textListItem
        &_textListItem-01
            white-space: nowrap
        &_mapPdf
            height: 68.8vw
            width: 100%
            img
                height: 68.8vw
        // .pd_c-pdfLink
        // &_text
        // span