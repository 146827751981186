/*
---
name: pd_c-requiredTitle
category: component
tag:
  - text
---
```html
  <p class="pd_c-requiredTitle">1. 今回のイベントをどうやってお知りになりましたか？<span class="pd_c-requiredTitle_small">（単一回答）</span></p>
```
*/
.pd_c-requiredTitle
    font-size: 17px
    font-weight: 500

    &::after
      display: inline-block
      content: '必須'
      border: solid 1px #cc0e00;
      border-radius: 10px;
      color: #cc0e00;
      padding: 0 8px;
      font-size: 13px;
    &_small
        font-size: 13px
    .pd_e-required-01
        font-size: 13px