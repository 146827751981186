/*
---
name: pd_m-modalBox-03
category: module
tag:
  - text
  - img
---
```html
<div class="pd_m-modalBox-03" id="modal1">
  <div class="pd_m-modalBox-closeBtn pd_js-modal-cloase">×</div>
  <div class="pd_b-detail-01_inner"></div>
  <div class="pd_b-detail-01_head">
    <h1 class="pd_c-headingLv1-01">コードレススティック掃除機 「パワーコードレス」</h1>
  </div>
  <div class="pd_b-detail-01_headTag">
    <div class="pd_b-detail-01_headLeft"></div>
    <div class="pd_b-detail-01_headGroup">
      <p class="pd_b-detail-01_headSub-01"></p>
    </div>
    <p class="pd_b-detail-01_headSub-01 pd_margin-top_10"><span class="pd_b-detail-01_subText-01">SET-GF90W-ENTRY-D</span></p>
  </div>
  <div class="pd_c-row-01">
    <div class="pd_c-row-01_leftCol"><img src="http://placehold.jp/3d4070/ffffff/1500x1000.png"></div>
    <div class="pd_c-row-01_rightCol">
      <div class="pd_c-row-01_content-01"></div>
      <p class="pd_c-row-01_text-02">22.5型（横幅499mm）のコンパクトな筐体に、WUXGA（1920×1200）解像度のIPSパネルを搭載したEIZOフレームレス最小モデル。<br>レッツノートとの互換性評価済みです。<a href="">本体色ホワイトはこちら</a></p>
      <p class="pd_c-row-01_text-02">DisplayPort、 HDMI、VGA端子［D-Sub 15ピン（ミニ）］ヘッドホン端子、USB［Type-A×2、Type-B×1］</p>
      <p class="pd_c-row-01_text-02">同梱ケーブル：HDMI、Displayport<br><span class="pd_c-row-01_color-01">※Displayportはレッツノートでは、ご使用できません。</span></p>
      <p class="pd_c-row-01_text-02">【22.5型 EIZOのフレームレス最小モデルで机も作業領域も広々】</p>
    </div>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-modalBox-03 {
  display: none;
  position fixed
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: hidden;
  width: 100%;
  height: 100vh;
  z-index: 1002;
  &_bg{
    background: rgba(0,0,0,0.2);
    height: 100vh;
    position: absolute;
    width: 100%;
  }
  &_content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 102;
    overflow-y: auto;
    background-color: #fff;
    border-radius 20px;
    max-height: 90vh;
    @media screen and (min-width:MIN_POINT) {
      max-width 1036px
      width: 90%
      height: auto
      max-height: 90%
      margin-right auto
      margin-left auto
      padding: 70px 0 30px;
    }
    @media screen and (max-width:MAX_POINT) {
      bottom: 20px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%,-50%);
      padding: 30px 10px 10px;
      width 90%
      height: 80%;
    }
  }
     
  img {
    vertical-align middle
  }
  .mfp-close {
    display none
  }
  .pd_m-modalBox-closeBtn{
    font-size 40px;
    font-weight bold
    display inline-block
    position absolute
    right 0
    top 0
    margin-top 20px
    margin-right 20px
    cursor pointer

    @media screen and (max-width:MAX_POINT) {
      margin: 0;
      font-weight: normal;
      font-size: 30px;
      right: 22px;
      top: -3px;
    }
  }
  .pd_b-detail-01_headTag {
    display block
  }
  .pd_b-detail-01_headSub-01 {
    margin-left 10px
  }
  .pd_b-detail-01_head {
    margin-left 5px
  }
}

.pd_modal-bkg{
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.3);
  z-index: 1001;
}

.pd-js-modal-fix{
  overflow: hidden
}
