/*
---
name: pd_c-Buy-in-bulk
category: component
tag:
  - text
---
```html
<p class="pd_c-Buy-in-bulk-01">
    <span class="pd_c-Buy-in-bulk-01_span-01">【まとめ買い】2個で10%割引</span>
    <span class="pd_c-Buy-in-bulk-01_span-01">【まとめ買い】3個で15%割引</span>
    <span class="pd_c-Buy-in-bulk-01_span-01">【まとめ買い】4個で20%割引</span>
</p>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-Buy-in-bulk-01 {
    @media screen and (min-width:MIN_POINT) {
        margin-top 10px
    }
    @media screen and (max-width:MAX_POINT) {
        margin-top 10px
    }
}

.pd_c-Buy-in-bulk-01_span-01 {
    display inline-block
    padding 3px 4px
    line-height 1
    font-size 13px
    color #CC0E00
    margin-right 5px
}