
/*
---
name: pd_m-smallBoxItem
category: module
---
```html
<div class="pd_m-smallBoxItem">
    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
    <div class="pd_m-smallBoxItem_mid">
        <p class="pd_m-smallBoxItem_kind">記事</p>
        <div class="pd_m-smallBoxItem_favoriteWrap">
            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
        </div>
    </div>
    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_c-checkBox-add-cart {
    border-radius: 8px;
    background: #fff;
    border: 1px solid #364563;
    padding: 7px;
    font-size: 1.2rem;
    margin: 16px 0 0;
    box-shadow: 3px 3px 6px rgba(0 0 0 , 0.3);

    @media screen and (max-width:MAX_POINT) {
      font-size: 1rem;
      max-width: 140px;
      margin 16px auto 0
    }

    .pd_c-checkBox-01_indicator-01{
        width: 21px;
        height: 21px;
        background-size: contain;

        @media screen and (max-width:MAX_POINT) {
            width: 18px;
            height: 18px;
            margin-right: 3px;
        }
    }
}

