/*
---
name: pd_m-radioWrap
category: module
tag:
  - text
  - icon
  - checkBox
---
```html
<div class="pd_m-radioWrap">
  <p class="pd_c-requiredTitle">1. 今回のイベントをどうやってお知りになりましたか？<span class="pd_c-requiredTitle_small">（単一回答）</span></p>
  <ul class="pd_m-radioWrap_list">
    <li class="pd_m-radioWrap_listItem">
      <label class="pd_c-radioBtn-01__event">
        <input type="radio" name="event" value="ウェブサイト"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">ウェブサイト</span>
      </label>
    </li>
    <li class="pd_m-radioWrap_listItem">
      <label class="pd_c-radioBtn-01__event">
        <input type="radio" name="event" value="友人からのすすめ"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">友人からのすすめ</span>
      </label>
    </li>
    <li class="pd_m-radioWrap_listItem">
      <label class="pd_c-radioBtn-01__event">
        <input type="radio" name="event" value="Facebook"><span class="pd_c-radioBtn-01_indicator__event"></span><span class="pd_c-radioBtn-01_label">Facebook</span>
      </label>
    </li>
  </ul>
</div>
```
*/


.pd_m-radioWrap
    &_list
        display: flex
        justify-content: space-between
        margin-top: 14px
    &_listItem
        width: 32%
@media screen and (max-width 640px)
    .pd_m-radioWrap
        &_list
            flex-wrap wrap
            justify-content: space-between
            margin-top: 14px
        &_listItem
            margin: 0 0 18px 0
            width: 100%
            &:last-child
                margin: 0 
        .pd_c-radioBtn-01_text-01
            padding-left: 34px
