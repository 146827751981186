/*
---
name: pd_e-date-01
category: element
tag:
  - text
---
* /store/
* /store/
* /store/
```html
  <div class="pd_e-date-01">
    <p class="pd_e-date-01_date">2021年04月10日・17日</p>
    <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
  </div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_e-date-01 {
  margin-top 8px
  color: rgba(0,0,0,.9)
  font-size 13px
  line-height (20/13)
  &_date {
  }
}
