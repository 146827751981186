/*
---
name: pd_b-freearea
category: block
tag:
  - free
---
```html
<div class="pd_b-freearea"></div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_b-freearea
    display block
