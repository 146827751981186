/*
---
name: pd_e-numberIcon-01
category: element
tag:
  - icon
---
```html
<span class="pd_e-numberIcon-01">3</span>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_e-numberIcon-01 {
  font-size 12px
  color #fff
  display inline-block
  background #364563
  border-radius 50%
  width 1.5em
  height 1.5em
  margin-left .5em
  text-align center
}
