/*
---
name: pd_c-released-01
category: component
tag:
  - text
---
```html
  <p class="pd_c-released-01">発売日：xxxx年xx月 / 発送予定日：2021年04月01日～10日</p>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;
.pd_c-released-01 {
  color: rgba(0,0,0,.6)
  font-style 13px
  line-height: (19.5/13)
}
