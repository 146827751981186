/*
---
name: pd_m-eventsReport
category: module
tag:
  - text
  - img
  - movie
---
```html
<div class="pd_m-eventsReport">
                                            <p class="pd_c-eventsSubTitle">イベントレポート</p>
                      <div class="pd_c-row-03">
                        <div class="pd_c-row-03_content-01">
                          <h1 class="pd_c-row-03_heading">1/1フォーマット</h1>
                          <p class="pd_c-row-03_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト本文テキスト本文テキスト本文テキスト本文テキスト</p>
                        </div>
                        <div class="pd_c-row-03_img"><img src="http://placehold.jp/3d4070/ffffff/976x488.png"></div>
                      </div>
                      <div class="pd_c-row-01">
                        <div class="pd_c-row-01_leftCol">
                          <div class="pd_c-row-01_img"><img src="http://placehold.jp/3d4070/ffffff/150x100.png"></div>
                        </div>
                        <div class="pd_c-row-01_rightCol">
                          <div class="pd_c-row-01_content-01">
                            <h1 class="pd_c-row-01_heading">1/2フォーマット</h1>
                            <p class="pd_c-row-01_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト本文テキスト本文テキスト本文テキスト本文テキスト</p>
                          </div>
                        </div>
                      </div>
                      <div class="pd_c-row-01">
                        <div class="pd_c-row-01_leftCol">
                          <div class="pd_c-row-01_img"><img src="http://placehold.jp/3d4070/ffffff/150x100.png"></div>
                        </div>
                        <div class="pd_c-row-01_rightCol">
                          <div class="pd_c-row-01_content-01">
                            <h1 class="pd_c-row-01_heading">1/2フォーマット</h1>
                            <p class="pd_c-row-01_text-01">本文テキスト本文テキスト本文テキスト本文テキスト本文本文テキスト本文テキスト本文テキスト本文テキストテキスト本文テキスト本文テキスト本文テキスト本文テキスト</p>
                          </div>
                        </div>
                      </div>
                      <div class="pd_m-eventsReport_youtube"><img src="/store/images/module/eventsReport_youtube.png" alt=""></div>
                    </div>
```
*/
.pd_m-eventsReport
    .pd_c-row-03
        margin-top: 11px
    .pd_c-row-01_content-01
        margin-top: -5px
    .pd_c-row-01_text-01
        margin-top: 12px
    &_youtube
        margin: 34px 0 50px 0
        img
            display: block
@media screen and (max-width 640px)
    .pd_m-eventsReport
        .pd_c-row-03
            margin-top: 7px
        .pd_c-row-01_content-01
            margin-top: -5px
        .pd_c-row-01_text-01
            margin-top: 12px
        .pd_c-row-03_text-01
            margin-top: 12px
        .pd_c-row-03_img
            margin: 15px -15px
            height: 50vw
            width: 100vw
        &_youtube
            margin: 39px -15px 55px