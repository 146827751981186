/*
---
name: pd_b-tabDetail
category: block
tag:
  - text
  - img
  - icon
---
```html
<div class="pd_b-tabDetail" id="pd_closedEvent">
    <ul class="pd_b-tabDetail_list" id="pd_b-tabDetail_list">
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">オンライン</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
        <li class="pd_b-tabDetail_listItem"><a class="pd_m-smallBox" href="#">
                <div class="pd_m-smallBoxItem">
                    <div class="pd_m-smallBoxItem_img"><img src="http://placehold.jp/3d4070/ffffff/1500x1500.png" alt="商品画像" /></div>
                    <div class="pd_m-smallBoxItem_mid">
                        <p class="pd_m-smallBoxItem_kind">店舗</p>
                        <div class="pd_m-smallBoxItem_favoriteWrap">
                            <object class="pd_m-smallBoxItem_favorite"><img class="pd_m-smallBoxItem_favorite_img-01" src="/store/images/module/icon_favorite.svg" alt="お気に入りに追加" /><img class="pd_m-smallBoxItem_favorite_img-02" src="/store/images/module/icon_favoriteOn.svg" alt="お気に入りに追加" /></object>
                        </div>
                    </div><span class="pd_e-smallTag-01">予約不要</span><span class="pd_e-smallTag-01">受付中</span><span class="pd_e-smallTag-01">有料</span><span class="pd_e-smallTag-01">キャンセル待ち</span><span class="pd_e-smallTag-01">受付終了</span>
                    <p class="pd_m-smallBoxItem_ttl">テスト用のタイトルテスト用のタイトルテスト用のタイトルテス</p>
                </div>
                <div class="pd_c-dateArea">
                    <div class="pd_e-date-01">
                        <p class="pd_e-date-01_date">2021年04月10日・17日</p>
                        <p class="pd_e_date-01_place">パナソニックセンター大阪</p>
                    </div>
                    <p class="pd_c-dateArea_text">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
                </div>
                <ul class="pd_c-tagList">
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test.html">#快適な空間づくり</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test2.html">#家事・暮らし</a></object>
                    </li>
                    <li class="pd_c-tagList_item pd_e-hover-01 pd_e-hover-01--bgDarkGray">
                        <object><a href="/test3.html">#タグ</a></object>
                    </li>
                </ul>
            </a>
        </li>
    </ul>
    <div class="pd_m-pagenation">
        <div class="pd_m-pagenation_inner">
            <div class="pd_m-pagenation_btn pd_m-pagenation_prevBtn pd_e-hover-01"><a href="#"></a></div>
            <ol class="pd_m-pagenation_list">
                <li class="pd_m-pagenation_listItem pd_current pd_e-hover-01"><a href="#">1</a></li>
                <li class="pd_m-pagenation_listItem pd_e-hover-01 pd_e-hover-01--bgGray"><a href="#">2</a></li>
                <li class="pd_m-pagenation_listItem pd_e-hover-01 pd_e-hover-01--bgGray"><a href="#">3</a></li>
            </ol>
            <div class="pd_m-pagenation_btn pd_m-pagenation_nextBtn pd_e-hover-01"><a href="#"></a></div>
        </div>
    </div>
</div>
```
*/
.pd_b-tabDetail
    display: none
    margin: 0 auto
    padding-top 30px
    position: relative
    max-width: 976px
    width: 976px!important
    &.pd_active
        display: block
    .pd_c-checkBox-01
      margin-left 15px
.pd_b-tabDetail_inner
    margin: 0 auto
    padding: 40px 0 0 0
    width: 976px
#pd_b-tabDetail_list
    &::after
        content: '';
        display: block;
        clear: both;
.pd_b-tabDetail_listItem
    position relative
    float: left;
    width: 249px
    &.pd_circleImg
        .pd_m-smallBoxItem_img
            background-color transparent
            img
                height: 190px
                border-radius:50%;
                font-family: 'object-fit:none;'
                object-fit:none;
                width: 190px

.pd_b-tabDetail_listItem-big
    margin-right: 0px
    width: 498px
// lifestyleタブ固有
.pd_b-tabDetail_lifestyleBtm_list
    display: flex
    flex-wrap: wrap
    justify-content: space-between
.pd_b-tabDetail_more
    cursor: pointer
    color: #364563
    display: table
    font-size: 15px
    margin 0px auto 60px
    padding-right: 36px;
    position: relative
    &::after
        background-image: url('/store/images/block/categorySearch_plus.svg')
        content: ""
        display: block
        height: 16px
        position: absolute
        right: 4px
        top: 50%
        transform: translateY(-50%)
        width: 16px
    .pd_b-tabDetail_more_text02
        display: none
    &.pd_open
        .pd_b-tabDetail_more_text01
            display: none
        .pd_b-tabDetail_more_text02
            display: block
        &::after
            background-image: url('/store/com/images/block/categorySearch_minus.svg')
// 商品情報・イベントキャンペーン
#pd_productInfo,#pd_event
    #pd_b-tabDetail_list
        align-items flex-start
        display: flex
        flex-wrap: wrap
        justify-content: space-between
// アコーディオン用
.pd_b-tabDetail_acc
    display: none
@media screen and (min-width: 641px)
    .pd_b-tabDetail_lifestyleBtm_list,
    #pd_b-tabDetail_list
        margin-right: -10px
        margin-left: -10px
    .pd_b-tabDetail_list
        .pd_m-smallBox
            margin-right: 10px;
            margin-left: 10px;
        .pd_m-bigBox
            margin-right: 10px;
            margin-left: 10px;
@media screen and (max-width 640px)
    .pd_b-tabDetail
        width: 100%!important
    #pd_b-tabDetail_list
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        padding: 0 4vw
        &::after
          display none
    .pd_b-tabDetail_listItem
        width: 44vw
        &.pd_circleImg
            .pd_m-smallBoxItem_img img
                height: 155px
                width: 155px
    .pd_b-tabDetail_listItem-big
        width: 100%
    // // lifestyleタブ固有
    .pd_b-tabDetail_lifestyleBtm_list
        padding: 0 4vw
