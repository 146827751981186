/*
---
name: pd_b-flowStep3
category: block
tag:
  - text
  - icon
  - btn
---
```html
<div class="pd_b-flowStep3">
    <div class="pd_b-flowStep3_inner">
        <h1 class="pd_m-contentTtl-01">申し込み完了</h1>
        <div class="pd_m-eventBnr">
            <p class="pd_m-eventBnr_ttl">スマートコーヒー焙煎サービス「The Roast」体験会</p>
            <div class="pd_m-eventBnr_btm">
                <p class="pd_m-eventBnr_date">4月28日14:30～15:00</p>
                <div class="pd_m-eventBnr_price"><span class="pd_m-eventBnr_priceAbout">参加費</span><span class="pd_m-eventBnr_priceTotal">合計</span><span class="pd_m-eventBnr_priceNum">1,000</span><span class="pd_m-eventBnr_priceYen">円</span><span class="pd_m-eventBnr_priceTax">(税込)</span></div>
            </div>
        </div>
        <p class="pd_b-flowStep3_attention">お申し込みありがとうございます。登録内容をメールでお送りいたしました。ご確認下さい。</p>
    </div>
    <div class="pd_c-attention">
        <p class="pd_c-attention_text1">「閉じる」ボタンを押してもページが閉じない場合は、ブラウザのタブを閉じる操作を行って下さい。</p>
        <p class="pd_c-attention_text2"></p>
    </div><a class="pd_c-btn-03 pd_e-hover-01 pd_e-hover-01--bgGray" href="#">閉じる</a>
</div>
```
*/
.pd_b-flowStep3
    margin: 0 auto 60px
    &_inner
        border-bottom: 1px solid #cccccc
        margin: 0 auto
        width: 976px
    &_attention
        margin: 39px 0 0 0;
        letter-spacing: 1px;
        padding: 0 0 40px
    .pd_c-btn-03
        color: #364563
        margin 40px auto 0
        width: 345px
@media screen and (max-width 640px)
    .pd_b-flowStep3
        &_inner
            width: 92%
        .pd_m-contentTtl-01
            margin: 34px auto 18px
            padding: 0
        &_attention
            margin-top: 41px
            width: 100%
        .pd_c-btn-03
            width: 90%
