/*
---
name: pd_m-cart-01
category: module
tag:
  - text
  - img
  - btn
---
```html
<div class="pd_m-cart-01">
    <div class="pd_m-cart-01_inner">
        <div class="pd_m-cart-01_leftCol">
            <div class="pd_c-itemImage-01">
                <div class="pd_c-itemImage-01_img"><img src="http://placehold.jp/3d4070/ffffff/100x100.png" alt="" /></div>
                <div class="pd_c-itemImage-01_contents">
                    <p class="pd_c-itemImage-01_name">コードレススティック掃除機 「パワーコードレス」</p>
                    <p class="pd_c-itemImage-01_price"><span class="pd_c-itemImage-01_text-01">50,700</span><span class="pd_c-itemImage-01_text-02">円</span><span class="pd_c-itemImage-01_text-03">（税込）</span></p>
                </div>
            </div>
        </div>
        <div class="pd_m-cart-01_rightCol">
            <div class="pd_c-cart-01">
                <div class="pd_c-cart-01_inner">
                    <div class="pd_c-cart-01_col-01">
                        <div class="pd_c-cart-01_orders">数量：</div>
                        <div class="pd_c-cart-01_selectWrapper">
                            <select class="pd_c-cart-01_select">
                                <option value="">999</option>
                            </select>
                        </div>
                    </div>
                    <div class="pd_c-cart-01_col-02">
                        <button class="pd_c-cart-01_btn pd_e-hover-01" type="button" onclick="Dialog.showAlert(opt)">
                            <p class="pd_c-cart-01_btnText-01"><span>カートへ入れる</span></p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-cart-01 {
  box-shadow 0 -3px 6px rgba(0,0,0,.16)
  position fixed
  width 100%
  left 0
  bottom 0
  background-color #fff
  padding 19px 0
  z-index 100
  box-sizing border-box
  &_inner {
    @media screen and (min-width:MIN_POINT) {
      display flex
      max-width 980px
      margin-right auto
      margin-left auto
      align-items center
      justify-content space-between
    }
    @media screen and (max-width:MAX_POINT) {
      margin-right 15px
      margin-left 15px
    }
  }
  &_leftCol,
  &_rightCol {
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_leftCol {
    flex 1
    @media screen and (min-width:MIN_POINT) {
    }
    @media screen and (max-width:MAX_POINT) {
    }
  }
  &_rightCol {
    flex 1
    @media screen and (min-width:MIN_POINT) {
      padding-left 20px
    }
    @media screen and (max-width:MAX_POINT) {
      margin-top 16px
    }
  }
  .pd_c-cart-01 {
    @media screen and (max-width:MAX_POINT) {
      .pd_c-cart-01_col-01{
        margin-right: 1rem;
        display: -webkit-inline-box;
        -webkit-box-pack: start;
      }
      .pd_c-cart-01_btn{
        padding: 11px 0;
      }
    }
    &_inner {
     flex-wrap nowrap
    }
    &_col-01 {
      flex 1
    }
    &_col-02 {
      padding-left 20px
      width 205px
    }
    &_btn {
      padding 11px 30px 11px 20px
      margin-top 0
    }
  }
}

.pd_m-js_scroll
  opacity: 0
  transition: 0.3s ease-in-out
  pointer-events: none;

.pd_m-js_scroll_fadeIn
  opacity: 1;
  transition: 0.3s ease-in-out
  pointer-events: all;

