/*
---
name: modalBox-ar-pc
category: module
tag:
  - text
  - img
---
```html
<div class="pd_m-modalBox-ar-pc">
  <p class="pd_m-modalBox-ar-pc__title">本機能はパソコンではご利用いただけません。スマートフォン・タブレットで下のQRコードを読み込んでお試し下さい。</p>
  <div class="pd_m-modalBox-ar-pc-content">
    <ul class="pd_m-modalBox-ar-pc-content-left"> 
      <li>専用アプリのインストールは不要です。</li>
      <li>パソコンでは本機能はご利用いただけません。スマートフォン・タブレットをご使用ください。</li>
      <li> <a href="#">動作環境</a>でOS・デバイスをご確認ください。</li>
      <li> <span>本シュミレーションは、若干の誤差が生じる場合があります。</span></li>
      <li> <span>ご購入前には必ず実際の設置スペースを測ってください。</span></li>
      <li> <span>新規取り付けには、別途分岐水栓の取り付けが必要です。</span><br><a href="#">食洗機の取り付け方について詳しく見る</a></li>
      <li>本シュミレーションで表示される商品の色味は実際とは異なります。</li>
    </ul>
    <div class="pd_m-modalBox-ar-pc-content-right"><img src="/store/images/module/viewer-ar-qr.png"></div>
  </div>
</div>
```
*/
MAX_POINT = 640px;
MIN_POINT = MAX_POINT+1px;

.pd_m-modalBox-ar-pc
  background-color #fff
  padding 32px 40px
  width 100%
  max-width 820px
  margin 20px auto
  padding 32px 40px
  button{
    background-image url("/store/images/module/modal_icon_close.svg")
  }
  .pd_m-modalBox-ar-pc__title
    font-size 24px
    color #000
    line-height 36px
    margin-bottom 20px
  .pd_m-modalBox-ar-pc-content
    display -webkit-box
    display -ms-flexbox
    display flex
    -webkit-box-align start
    -ms-flex-align start
    align-items flex-start
    .pd_m-modalBox-ar-pc-content-left
      -webkit-box-flex 1
      -ms-flex-positive 1
      flex-grow 1
      li
        position relative
        color #000
        font-size 16px
        line-height 24px
        margin-bottom 8px
        padding-left 16px
        a
         color #007bff
        span
         font-weight bold
      li:last-child
        margin-bottom 0
      li:before
        content ""
        position absolute
        width 8px
        height 8px
        left 0
        top 9px
        background-color #000
        border-radius 50%
    .pd_m-modalBox-ar-pc-content-right
      width 295px
      hight 234px
      margin-left 16px
      display flex
      justify-content center
      align-items center
      img 
        max-width 237px
        max-height 100%
        
  

